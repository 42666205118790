import getIndexedDBObjectStore from './getIndexedDBObjectStore.js';

const getIndexedDBValue = async key => {
  const objectStore = await getIndexedDBObjectStore();

  return new Promise((resolve, reject) => {
    const request = objectStore.get(key);

    request.onerror = reject;
    request.onsuccess = () => {
      resolve(request.result);
    };
  });
};

export default getIndexedDBValue;