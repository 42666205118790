const splice = (arr, index, numberToRemove = 0, ...newValues) => {
  if (index < 0 || index >= arr.length) {
    throw new Error('Index for splicing must be greater than zero and less than the max index of the array.');
  }

  return [
    ...arr.slice(0, index),
    ...newValues,
    ...arr.slice(index + numberToRemove)
  ];
};

export default splice;