import * as THREE from 'three';

const maybeLog = (...params) => {
  // eslint-disable-next-line no-console
  // console.log(...params);
};

export const createTransformedImage = ({ matrix, acqData, refData, transformedScalarData,
  originalScalarData, MIN_VALUE, patientOrientation }) => {
  // use for 2D images only

  // get reference image data 
  const { gantryAngle, couchAngle, collimatorAngle, sad, iso, xSize, xRes, ySize, yRes,
    xImageOrigin, yImageOrigin, xOrientation, yOrientation } =
    getReferenceImageData(refData);

  // get acquired image data
  const { gantryAngleAcq, couchAngleAcq, isoAcqAtSAD, xSizeAcq, xResAcq, ySizeAcq, yResAcq,
    xImageOriginAcq, yImageOriginAcq, xOrientationAcq, yOrientationAcq, xImageReceptorTranslation,
    yImageReceptorTranslation, zImageReceptorTranslation } =
    getAcquiredImageData(acqData);

  // get the directions for the reference image in patient coordinates in the reference FOR
  const { centralRayDir, xDir, yDir, source } =
    getDirectionInPatientCoordinates(gantryAngle, couchAngle, collimatorAngle, sad, iso, patientOrientation);

  maybeLog({
    referenceDirections: 'reference Directions',
    centralRayDir: centralRayDir, xDir: xDir, yDir: yDir, source: source
  });

  // get the directions for the acquired image in patient coordinates in the acquired FOR
  // TODO: currently assume acquired image is at zero pitch & roll 
  const { centralRayDir: centralRayDirAcq, xDir: xDirAcq, yDir: yDirAcq, source: sourceAcq } =
    getDirectionInPatientCoordinates(gantryAngleAcq, couchAngleAcq,
      collimatorAngle, sad, isoAcqAtSAD, patientOrientation);

  maybeLog({
    acquiredDirections: 'acquired Directions',
    centralRayDir: centralRayDirAcq, xDir: xDirAcq, yDir: yDirAcq, source: sourceAcq
  });

  // get the reference image center at the image plane
  // TODO: currently assume reference image center is at iso.

  // get the acquired image center at the image plane (SID) in the acquired FOR
  const imageCenterAcqAtSID = getImageCenterAtImagePlane(isoAcqAtSAD, xDirAcq, yDirAcq, centralRayDirAcq,
    xImageReceptorTranslation, yImageReceptorTranslation, zImageReceptorTranslation);
  
  // for referenceImage - get image origin in reference FOR (in the isocenter plane/at SAD)
  const { originInPatient } = getImageOriginInPatientCoordinates({ xImageOrigin, xOrientation, yImageOrigin,
    yOrientation, xDir, yDir, imageCenter: iso, scale: 1 });

  maybeLog({ referenceTarget: 'originInPatient info', patientOrigin: originInPatient, imageCenter: iso });

  // for acquired Image - get image origin in acquired FOR (at SID)
  const { originInPatient: originInPatientAcq } = getImageOriginInPatientCoordinates({
    xImageOrigin: xImageOriginAcq,
    xOrientation: xOrientationAcq,
    yImageOrigin: yImageOriginAcq,
    yOrientation: yOrientationAcq,
    xDir: xDirAcq,
    yDir: yDirAcq,
    imageCenter: imageCenterAcqAtSID,
    // sad / sid:  only use sad/ sid for approach where scale the imageRes to
    // look up values of acquiredImage at SAD, leave at 1 when looking up values at SID
    scale: 1
  });

  maybeLog({
    acquiredSource: 'originInPatient info',
    patientOriginAcq: originInPatientAcq,
    imageCenter: imageCenterAcqAtSID
  });

  // get the transformationMatrix - note dicom saves in row-major order
  // & THREE.js sets in row-major order but THREE.js elements are in column-major order.
  const transformationMatrix = new THREE.Matrix4();
  transformationMatrix.set(
    matrix[0], matrix[1], matrix[2], matrix[3],
    matrix[4], matrix[5], matrix[6], matrix[7],
    matrix[8], matrix[9], matrix[10], matrix[11],
    matrix[12], matrix[13], matrix[14], matrix[15]);
  // check the position to make sure the ordering is correct. 
  const transformPosition = new THREE.Vector3().setFromMatrixPosition(transformationMatrix);

  maybeLog({ transformMatrix: transformationMatrix, transformPosition: transformPosition });
 
  const targetScalarData = transformedScalarData; 
  // noinspection UnnecessaryLocalVariableJS
  const sourceScalarData = originalScalarData;

  // loop over the pixel coordinates of the reference image
  for (let j = 0; j < ySize; j++) {
    for (let i = 0; i < xSize; i++) {
      // get the point in the patient in the reference FOR at this i, j location
      const pointInPatient = getPointInPatientInReferenceFOR(xDir, yDir, i, j, xRes, yRes, originInPatient);

      // transform the point according to the transformationMatrix into the acquired FOR
      const transformedPoint = getPointInPatientInAcquiredFOR(pointInPatient, transformationMatrix); 

      // project the transformed point at SAD plane (where the reference image was generated)
      // onto the SID plane (where the source image was generated).
      // * note if going to look up values at SAD plane via scaling then
      //   point on plane should be isoAcqAtSAD not imageCenterAcqAtSID.
      const transformedPointOnImagePlane =
        getTransformedPointOnPlane(transformedPoint, centralRayDirAcq, sourceAcq, imageCenterAcqAtSID);
      
      // lookup the value of this point from the source/acquired data using bi-linear interpolation
      const interpolatedValue = getInterpolatedValue({
        point: transformedPointOnImagePlane,
        scalarData: sourceScalarData,
        xRes: xResAcq, yRes: yResAcq, /* only scale xResAcq and yResAcq by sad/sid if looking up values on SAD plane */
        xSize: xSizeAcq, ySize: ySizeAcq,
        originInPatient: originInPatientAcq,
        xDir: xDirAcq, yDir: yDirAcq,
        minValue: MIN_VALUE
      });

      // set the interpolated value for the point onto the targetScalarData
      const scalarIndex = getScalarIndex(i, j, xSize);
      targetScalarData[scalarIndex] = interpolatedValue;
    }
  }
};

// eslint-disable-next-line max-params
function getPointInPatientInReferenceFOR(xDir, yDir, i, j, xRes, yRes, originInPatient) {
  const xPosition = xDir.clone().multiplyScalar(i * xRes);
  const yPosition = yDir.clone().multiplyScalar(j * yRes);

  return originInPatient.clone().add(xPosition).add(yPosition);
}

function getPointInPatientInAcquiredFOR(pointInPatient, transformationMatrix) {
  return pointInPatient.clone().applyMatrix4(transformationMatrix);
}

// eslint-disable-next-line max-params
function getImageCenterAtImagePlane(isoAcqAtSAD, xDirAcq, yDirAcq, centralRayDirAcq,
  xImageReceptorTranslation, yImageReceptorTranslation, zImageReceptorTranslation) {
  /*
   see note2 in sect c.8.8.2 https://dicom.nema.org/medical/Dicom/2016e/output/chtml/part03/sect_C.8.8.2.html
   image receptor translation x,y,z are in image receptor coordinates looking down the gantry/centralRay direction.
   'z coordinate will be equal to SAD - SID ... if the image receptor is further from the beam source than the
   machine iso, the z coordinate will be negative'
  */
  const xDirOffIso = xDirAcq.clone().multiplyScalar(xImageReceptorTranslation);
  const yDirOffIso = yDirAcq.clone().multiplyScalar(yImageReceptorTranslation * -1);
  // same as const centralRayDir.clone().multiplyScalar(sid - sad);
  const zDirOffIso = centralRayDirAcq.clone().multiplyScalar(zImageReceptorTranslation * -1);

  return isoAcqAtSAD.clone().add(zDirOffIso).add(xDirOffIso).add(yDirOffIso);
}

function getImageOriginInPatientCoordinates({ xImageOrigin, xOrientation,
  yImageOrigin, yOrientation, xDir, yDir, imageCenter, scale }) {
  // origin (location pixel 0,0) is the upper left corner
  // either use given origin or calc from center is half the image size minus half of one pixel
  // only scale if getting origin at a different plane from where image was generated
  const xOffset = xImageOrigin * xOrientation; // (xSize - 1) / 2 * xRes;
  const yOffset = yImageOrigin * yOrientation; // (ySize - 1) / 2 * yRes;
  const xOffsetFromCenter = xDir.clone().multiplyScalar(xOffset * scale); 
  const yOffsetFromCenter = yDir.clone().multiplyScalar(yOffset * scale);
  const originInPatient = imageCenter.clone().add(xOffsetFromCenter).add(yOffsetFromCenter);

  return { originInPatient }; 
}

function getInterpolatedValue({ point, scalarData, xRes, yRes, xSize, ySize, originInPatient, xDir, yDir, minValue }) {
  // convert from patient coordinates back to pixel coordinates of the source/acquired image.
  const { i, j } = getPixelCoordinates(point, xDir, yDir, originInPatient, xRes, yRes);

  // i, j are continuous - need to find nearest index positions around i, j.
  const x1 = Math.floor(i); 
  const y1 = Math.floor(j);
  const x2 = x1 + 1; 
  const y2 = y1 + 1; 

  const outOfBounds = x1 < 0 || y1 < 0 || x2 >= xSize || y2 >= ySize; 
  if (outOfBounds) {
    return minValue;
  }

  const diffX = i - x1; 
  const diffY = j - y1; 

  // values at the 4 closest pixels in the source/acquired image
  const f11 = scalarData[getScalarIndex(x1, y1, xSize)]; 
  const f21 = scalarData[getScalarIndex(x2, y1, xSize)]; 
  const f12 = scalarData[getScalarIndex(x1, y2, xSize)]; 
  const f22 = scalarData[getScalarIndex(x2, y2, xSize)]; 

  // collapse
  /* https://en.wikipedia.org/wiki/Bilinear_interpolation */
  // const fx1 = (x2 - i) / (x2 - x1) * f11 + (i - x1) / (x2 - x1) * f21;
  // const fx2 = (x2 - i) / (x2 - x1) * f12 + (i - x1) / (x2 - x1) * f22;
  // except x2 - x1 = 1 
  // and x2 - i = 1 - (i - x1)
  // simplifies to: 
  const fx1 = ((1 - diffX) * f11) + (diffX * f21);
  const fx2 = ((1 - diffX) * f12) + (diffX * f22);

  // const fxy = (y2 - j) / (y2 - y1) * fx1 + (j - y1) / (y2 - y1) * fx2;
  // except y2 - y1 = 1
  // and y2 - j = 1 - (j - y1)
  // simplifies to:
  return ((1 - diffY) * fx1) + (diffY * fx2);
}

function getScalarIndex(i, j, sizeX) {
  return i + (j * sizeX);
}

// eslint-disable-next-line max-params
function getPixelCoordinates(point, xDir, yDir, originInPatient, xRes, yRes) {
  const directionOriginToPoint = point.clone().sub(originInPatient);
  const xIndexContinuous = xDir.clone().dot(directionOriginToPoint); 
  const yIndexContinuous = yDir.clone().dot(directionOriginToPoint); 
  const i = xIndexContinuous / xRes; 
  const j = yIndexContinuous / yRes; 

  return { i, j }; 
}

function getTransformedPointOnPlane(transformedPoint, centralRayDir, sourceAcq, pointOnPlane) {
  let intersectDistance = 0.0; 
  const directionFromPointToSource = transformedPoint.clone().sub(sourceAcq);
  directionFromPointToSource.normalize(); // dir = p - source

  // denom = dot(normal, dir) = component along the centralRay
  const denom = centralRayDir.clone().dot(directionFromPointToSource);

  if (denom > 0.000001 || denom < -0.000001) {
    const directionFromPlaneToPoint = pointOnPlane.clone().sub(transformedPoint); // p010 =   (pointOnPlane aka iso) - p

    // num = dot(normal, p010) = component along the centralRay
    const num = centralRayDir.clone().dot(directionFromPlaneToPoint);

    intersectDistance = num / denom;
  }

  const intersectDistanceAlongDirection = directionFromPointToSource.clone().multiplyScalar(intersectDistance);

  // p + dir * intersectDist
  return transformedPoint.clone().add(intersectDistanceAlongDirection);
}

// eslint-disable-next-line max-params
function getDirectionInPatientCoordinates(gantryAngle, couchAngle, collimatorAngle, sad, iso, patientOrientation) {
  // eslint-disable-next-line max-len
  /* adapted from clear calc BeamGenerator: https://bitbucket.org/radformation/clearcalc/src/master/src/ClearCalc.Domain/Tps/Fspb/Services/BeamOrientationGenerator.cs */

  // TODO: decubitus
  const flipAntPost = ['HFP', 'FFP'];
  const flipLtRt = ['FFS', 'HFP'];
  const flipSupInf = ['FFS', 'FFP'];

  const gantryDir = new THREE.Vector3(0, 1, 0); // POST @G0 for HFS
  const xDir = new THREE.Vector3(1, 0, 0); // LEFT @G0 for HFS,

  // INF @G0 for HFS --> *** change this to -1 instead of +1 b/c image direction is
  // INF not SUP (from the upper left corner points down the rows)
  const yDir = new THREE.Vector3(0, 0, -1);

  if (flipAntPost.includes(patientOrientation)) gantryDir.multiplyScalar(-1); 
  if (flipLtRt.includes(patientOrientation)) xDir.multiplyScalar(-1);
  if (flipSupInf.includes(patientOrientation)) yDir.multiplyScalar(-1);

  // create the quaternions 
  const collRotationQuat = new THREE.Quaternion();

  // leave at zero for images.
  collRotationQuat.setFromAxisAngle(new THREE.Vector3(0, 1, 0), -collimatorAngle * Math.PI / 180);

  const gantryRotationQuat = new THREE.Quaternion(); 
  gantryRotationQuat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), gantryAngle * Math.PI / 180);

  const couchRotationQuat = new THREE.Quaternion(); 
  couchRotationQuat.setFromAxisAngle(new THREE.Vector3(0, 1, 0), couchAngle * Math.PI / 180); 

  // multiply the quaternions together & apply final quaternion to the direction vectors
  const finalQuat = collRotationQuat.premultiply(gantryRotationQuat).premultiply(couchRotationQuat);
  gantryDir.applyQuaternion(finalQuat); 
  xDir.applyQuaternion(finalQuat); 
  yDir.applyQuaternion(finalQuat); 

  // the source position is SAD away from the isocenter in the gantry direction
  const source = new THREE.Vector3().subVectors(iso, gantryDir.clone().multiplyScalar(sad)); 
  const centralRayDir = new THREE.Vector3().subVectors(iso, source);
  centralRayDir.normalize(); // unit vector
  maybeLog({
    gantryDirection: gantryDir, sad, iso, source, centralRayDir, gantryAngle, couchAngle
  });
  
  return { centralRayDir, xDir, yDir, source };
}

function getReferenceImageData(refData) {
  // reference image data
  const gantryAngle = refData.GantryAngle; // 0.0
  const couchAngle = refData.PatientSupportAngle; // 0.0;
  const collimatorAngle = 0.0; // refData.ExposureSequence[0].BeamLimitingDeviceAngle ?
  const sad = refData.RadiationMachineSAD; // 1000.00;
  const iso = new THREE.Vector3(
    refData.IsocenterPosition[0],
    refData.IsocenterPosition[1],
    refData.IsocenterPosition[2]
  );
  const xSize = refData.Columns;
  const [xRes, yRes] = refData.ImagePlanePixelSpacing;
  const ySize = refData.Rows;

  // image location of (0, 0,)
  const [xImageOrigin, yImageOrigin] = refData.RTImagePosition;

  const [xOrientation] = refData.RTImageOrientation; // what we call xDir, yDir in 3D.
  // eslint-disable-next-line prefer-destructuring
  const yOrientation = refData.RTImageOrientation[4];

  maybeLog({
    referenceTarget: 'data set info',
    gantryAngle: gantryAngle, couchAngle: couchAngle, collimatorAngle: collimatorAngle,
    sad: sad, iso: iso,
    xSize: xSize, xRes: xRes,
    ySize: ySize, yRes: yRes,
    xImageOrigin: xImageOrigin, yImageOrigin: yImageOrigin,
    xOrientation: xOrientation, yOrientation: yOrientation
  });

  return { gantryAngle, couchAngle, collimatorAngle, sad, iso, xSize,
    xRes, ySize, yRes, xImageOrigin, yImageOrigin, xOrientation, yOrientation };
}

function getAcquiredImageData(acqData) {
  const [gantryAngleAcq] = acqData.dict['300A011E'].Value;
  const [couchAngleAcq] = acqData.dict['300A0122'].Value;
  const [sid] = acqData.dict['30020026'].Value;
  const isoAcqAtSAD = new THREE.Vector3(
    acqData.dict['300A012C'].Value[0],
    acqData.dict['300A012C'].Value[1],
    acqData.dict['300A012C'].Value[2]
  );
  const [xSizeAcq] = acqData.dict['00280011'].Value;
  const [xResAcq, yResAcq] = acqData.dict['30020011'].Value;
  const [ySizeAcq] = acqData.dict['00280010'].Value;
  const [xImageOriginAcq, yImageOriginAcq] = acqData.dict['30020012'].Value; // image location of (0, 0,)
  const [xOrientationAcq] = acqData.dict['30020010'].Value; // what we call xDir, yDir in 3D.
  // eslint-disable-next-line prefer-destructuring
  const yOrientationAcq = acqData.dict['30020010'].Value[4];

  const [xImageReceptorTranslation, yImageReceptorTranslation, zImageReceptorTranslation] =
    acqData.dict['3002000D'].Value;

  maybeLog({
    acquiredSource: 'data set info',
    sid, isoAcquired: isoAcqAtSAD,
    xSizeAcquired: xSizeAcq, xResAcquired: xResAcq,
    ySizeAcquired: ySizeAcq, yResAcquired: yResAcq,
    xImageOriginAcquired: xImageOriginAcq, yImageOriginAcquired: yImageOriginAcq,
    xOrientationAcquired: xOrientationAcq, yOrientationAcquired: yOrientationAcq,
    xImageReceptorTranslation, yImageReceptorTranslation, zImageReceptorTranslation
  });

  return {
    gantryAngleAcq, couchAngleAcq, sid, isoAcqAtSAD, xSizeAcq, xResAcq, ySizeAcq, yResAcq, xImageOriginAcq,
    yImageOriginAcq, xOrientationAcq, yOrientationAcq, xImageReceptorTranslation, yImageReceptorTranslation,
    zImageReceptorTranslation
  }; 
}

export default createTransformedImage;