const PassState = {
  PASS: 'PASS',
  FAIL: 'FAIL',
  NO_RX: 'NO_RX',
  NA: 'NA',
  CONDITION: 'CONDITION',
  OVERRIDE: 'OVERRIDE',
  INDETERMINATE: 'INDETERMINATE',
  EXCEPTION: 'EXCEPTION',
  VERIFIED: 'VERIFIED'
};

// From best to worst
export const passStatePriority = [
  // "best" because if we usually order from worst to best, and we'll only want this if there are no others
  PassState.NA,
  PassState.NO_RX,
  PassState.PASS,
  PassState.VERIFIED,
  PassState.INDETERMINATE,
  PassState.CONDITION,
  PassState.OVERRIDE,
  PassState.EXCEPTION,
  PassState.FAIL
];

export default PassState;