import { css } from 'lit';
import commonStyles from '../../../styles-common';

const sidebarStyle = [
  commonStyles,
  css`  
    h2 {
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--side-border);
      margin: 8px 16px 16px 0;
    }
    
    a {
      color: inherit;
      text-decoration: none;
    }
    
    ul {
      list-style: none;
      margin: 0 -16px;
      padding: 0;
    }
  
    li {
      cursor: pointer;
      padding: 5px 16px;
      font-size: 14px;
      line-height: 16px;
      position: relative;
      font-weight: bold;
    }
    
    li:not([current]):not(.nested):not(.no-hover):hover,
    li[current]:not(.nested), 
    li.nested:not([current]):not(.no-hover) h3:hover,
    li.nested[current]:not(.no-hover) h3:hover {
      background: var(--hover-color);
    }
    
    li.nested {
      padding-bottom: 0;
    }
    
    li.nested select {
      float: none;
      margin: 3px 0 8px;
    }
    
    li h3 {
      font-size: inherit;
      margin: -5px -16px 0px;
      padding: 6px 16px;
    }
    
    li ul {
      margin-left: -16px;
    }
    
    li ul li {
      font-weight: normal;
      color: var(--neutral-2);
      font-size: 14px;
    }
    
    li ul li {
      font-weight: normal;
      color: var(--neutral-2);
      font-size: 14px;
    }
    
    li ul li > span {
      font-weight: normal;
      color: var(--neutral-4);
      font-size: 11px;
      text-transform: uppercase;
      margin-left: 2px;
    }
  `
].flat();

export default sidebarStyle;