import { COOKIE_NAME, EVENT_NAME } from './constants';
import getHidePhi from './getHidePhi';
import hidePhi from './hidePhi';
import showPhi from './showPhi';

const HidePhiConstants = {
  COOKIE_NAME,
  EVENT_NAME
};

export {
  HidePhiConstants,
  getHidePhi,
  hidePhi,
  showPhi
};