import { html, css, LitElement } from 'lit';
import h from '../../../utils/h';
import commonStyles from '../../styles-common';
import '../../common/range-slider.js';
import '../../common/number-slider.js';
import './dicom-image.js';
import './image-header.js';
import { patientIdSelector } from '../../../redux/app/selectors.js';
import { getPopoutUrl } from './utils/getPopoutUrl.js';
import { getOrientations, applyCoordinateSystem } from './utils/getOrientations.js';
import { ImageTypes } from './utils/initializeDicomGroup';
import getCoronal2DOrientations from './utils/getCoronal2DOrientations.js';

export class ImageViewer extends LitElement {
  static styles = [
    commonStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        flex: 1;
        
        --area-padding: 0 70px 30px 0;
      }
      
      #container {
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      
      dicom-image {
         display: inline-block;
         flex: 1 1 50%;
         border: 2px solid #333;
         position: relative;
         padding: var(--area-padding);
         background: #000;
       }

      image-area {
        position: absolute;
        inset: var(--area-padding);
      }
      
      dicom-image:first-of-type {
        flex: 1 1 100%;
      }
      
      range-slider {
        position: absolute;
        top: 150px;
        bottom: 50px;
        right: 10px;
        z-index: 10000;
        
        --label-color: #FF6;
      }
      
      number-slider {
        position: absolute;
        bottom: 0;
        width: 30%;
        min-width: 300px;
        max-width: 95%;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 10px;
      }
    `
  ].flat();

  // We should only ever have patient OR patientData
  static properties = {
    patient: { type: Object }, // the mapped patient we use in most of weekly-check
    patientData: { type: Object }, // the raw patient data we get directly from the endpoint
    allImages: { type: Array },
    planSer: { type: Number },
    fraction: { type: Number },
    groupIndex: { type: Number },
    dicomGroupData: { type: Object },
    showButtons: { type: Boolean, attribute: 'show-buttons' }
  };

  get popoutUrl() {
    const { planSer, fraction, groupIndex } = this;

    return getPopoutUrl(patientIdSelector(), planSer, fraction, groupIndex);
  }

  updated(changed) {
    const { patientData, patient, planSer } = this;

    if (['patientData', 'planSer'].some(::changed.has) && patientData && planSer) {
      this.allImages = patientData?.episodes
        ?.filter(({ imaging }) => imaging && imaging.planSer === planSer && imaging.displayDicoms?.length)
        ?.map(({ dateTime, imaging = {} }) => ({
          dateTime,
          images: imaging.displayDicoms,
          fraction: imaging.fraction
        })) ?? [];
    }

    if (['patient', 'planSer'].some(::changed.has) && patient && planSer) {
      this.allImages = patient.courses
        ?.flatMap(({ plans }) => plans)
        ?.find(plan => plan.ser === planSer)
        ?.imagings
        ?.filter(({ displayDicoms }) => displayDicoms?.length)
        ?.map(({ episodeDateTime, displayDicoms, fraction }) => ({
          dateTime: episodeDateTime,
          images: displayDicoms,
          fraction
        }));
    }
  }

  render() {
    const { showButtons, dicomGroupData, popoutUrl, allImages, planSer, fraction, groupIndex } = this;
    const { type } = (dicomGroupData?.acquiredImages?.[0] || dicomGroupData?.referenceImages?.[0]);
    const { axial, sagittal, coronal } = getOrientations(dicomGroupData.patientOrientation);

    applyCoordinateSystem(axial, sagittal, coronal,
      dicomGroupData.coordinateSystem, dicomGroupData.usePatientDicomCoordinates);

    const { coronal2D, coronal2DPaired } = getCoronal2DOrientations(type, axial, coronal, dicomGroupData);
    
    return html`
      <image-header 
        .images=${allImages}
        .planSer=${planSer}
        .currentFraction=${fraction}
        .currentGroupIndex=${groupIndex}
        popoutUrl=${popoutUrl}
        ?show-buttons=${showButtons}
        .currentDicomGroup=${dicomGroupData}
      ></image-header>
      <div id="container">
        ${h(type === ImageTypes.TwoD, () => html`
          ${this.renderImage('base', { ...coronal2D, sourceAngle: dicomGroupData.gantryAngle,
            couchAngle: dicomGroupData.patientSupportAngle }, false, true)}
          ${h(dicomGroupData?.pairedAcquiredImage,
          html`${this.renderImage('pair', { ...coronal2DPaired, sourceAngle: dicomGroupData.pairedGantryAngle,
            couchAngle: dicomGroupData.patientSupportAngle }, true)}`)}
        `)}
        ${h(type === ImageTypes.ThreeD, () => html`
          ${this.renderImage('axial', { ...axial, sourceAngle: 0,
            couchAngle: dicomGroupData.patientSupportAngle }, false, true)}
          ${this.renderImage('coronal', { ...coronal, sourceAngle: 0,
            couchAngle: dicomGroupData.patientSupportAngle })}
          ${this.renderImage('sagittal', { ...sagittal, sourceAngle: 270,
            couchAngle: dicomGroupData.patientSupportAngle })}
        `)}
      </div>
    `;
  }

  renderImage(id, orientation, paired = false, keyboardControls = false) {
    const { planSer, fraction, groupIndex, dicomGroupData } = this;

    return html`
      <dicom-image
        id=${id}
        planSer=${planSer}
        fraction=${fraction}
        groupIndex=${groupIndex}
        .dicomGroupData=${dicomGroupData}
        .orientation=${orientation}
        levels-control
        blend-control
        ?paired=${paired}
        ?show-keyboard-controls=${keyboardControls}
      ></dicom-image>
    `;
  }
}

customElements.define('image-viewer', ImageViewer);