import { html, css, LitElement } from 'lit';
import { INITIAL_LEVELS, INITIAL_BLEND, LEVELS, ORIENTATIONS, VOI, INITIAL_VOI } from './constants.js';
import h from '../../../utils/h.js';
import '../../common/range-slider.js';
import '../../common/number-slider.js';
import initializeDicomGroup, { ImageTypes } from './utils/initializeDicomGroup.js';
import { Enums, setVolumesForViewports } from '@cornerstonejs/core';
import { renderingEngineId } from './utils/cornerstone.js';
import {
  SynchronizerManager,
  synchronizers
} from '@cornerstonejs/tools';
import * as cornerstoneTools from '@cornerstonejs/tools';
import createToolGroup, { ToolGroupTypes } from './utils/createToolGroup.js';
import orientationToString from './utils/orientationToString.js';
import createKramer from './utils/createKramer.js';
import './structure-toggle.js';
import connect from '../../../utils/redux/connect.js';
import { store } from '../../../redux/store.js';
import valueToPercentage from '../../../utils/math/valueToPercentage.js';
import percentageToValue from '../../../utils/math/percentageToValue.js';
import eatEvent from '../../../utils/event/eatEvent.js';
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';
import { styleMap } from 'lit/directives/style-map.js';
import toPrecision from '../../../utils/math/toPrecision.js';
import deepEquals from '../../../utils/object/deepEquals.js';
import turnOnSegmentation from './utils/turnOnSegmentation.js';
import metaDataManager from './utils/metaDataManager.js';
import drawStructures2D from './utils/drawStructures2D.js';
import buildGraticule from './utils/buildGraticule.js';
import randomColor from '../../../utils/color/randomColor.js';
import { RegistrationNotFoundException } from './utils/registrationNotFoundException';
import fontAwesome from '../../../utils/font-awesome.js';
import { ReferenceAngleInvalidException } from './utils/referenceAngleInvalidException';

const {
  Enums: csToolsEnums,
  segmentation
} = cornerstoneTools;

const GRATICULE_COLOR = '#FFFF00'; // yellow

const { ViewportType } = Enums;
const { createVOISynchronizer, createZoomPanSynchronizer } = synchronizers;

const toValue = percentage => percentageToValue(percentage, LEVELS.min, LEVELS.max);

const BROADCAST_LEVELS = 'broadcast_levels';
const BROADCAST_BLEND = 'broadcast_blend';
const BROADCAST_ERROR = 'broadcast_error'; 
const BROADCAST_INITIALZOOM = 'broadcast_initialzoom';

const shouldResizeCanvas = canvas =>
  (canvas.width !== canvas.offsetWidth || canvas.height !== canvas.offsetHeight)
    && canvas.offsetWidth > 0
    && canvas.offsetHeight > 0;

const fillAlpha = localStorage.getItem('structureFill') ?? 0.2;
const outlineWidth = localStorage.getItem('structureWidth') ?? 1;

const setActorOpacity = (actor, opacity) => {
  const [start] = actor.getProperty().getRGBTransferFunction(0).getRange();

  // opacity = clamp(ease(opacity), 0, 1);

  const ofun = vtkPiecewiseFunction.newInstance();

  ofun.addPoint(start, 0);
  ofun.addPoint(start + 3, 0.05);
  ofun.addPoint(start + 4, opacity);

  actor
    .getProperty()
    .setScalarOpacity(0, ofun);
};

class DicomImage extends connect(store)(LitElement) {
  static styles = css`
    :host {
      --area-padding: 0 70px 30px;

      user-select: none;
      position: relative;
      display: block;
      border: 1px solid #333;
      background: #000;
      padding: 0;
    }
    
    :host([simple]) image-area {
      cursor: pointer !important;
    }
    
    :host([simple]) image-area * {
      pointer-events: none;
    }
    
    :host(:not([simple])) {
      padding: var(--area-padding);
    }
    
    :host([has-blend][blend-below][blend-control]) {
      margin-bottom: 30px;
    }
    
    :host([has-blend][blend-below][blend-control]) number-slider {
      bottom: -32px;
    }

    image-area, overlay, overlay canvas {
      position: absolute;
      inset: 0;
    }

    :host(:not([simple])) image-area,
    :host(:not([simple])) overlay {
      inset: var(--area-padding)
    }
    
    overlay {
      pointer-events: none;
      z-index: 999;
    }

    range-slider {
      position: absolute;
      top: 50px;
      bottom: 50px;
      right: 10px;
      z-index: 997;

      --label-color: #FF6;
    }
    
    range-slider.left {
      left: 10px;
      margin-left: 20px;
      right: unset;
    }

    number-slider {
      position: absolute;
      bottom: 0;
      width: min(300px, 95%);
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 10px;
      z-index: 996 ;
    }
    
    structure-toggle {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 20px;
      z-index: 60000;
    }
    
    structure-toggle:not([open]) {
      z-index: 0;
    }
    
    :host([blend-below]) [has-blend] {
      padding-bottom: 30px;
    }
    
    svg line {
      stroke-dasharray: 4 4;
      stroke: rgb(200, 200, 200) !important;
      cursor: n-resize !important;
    }
    
    svg circle {
      display: none;
    }
    
    image-area {
      /* don't want it to be possible to be 0 */
      min-width: 20px;
      min-height: 20px;
      outline: none !important;
    }
    
    image-area canvas {
      cursor: default !important;
    }
    
    image-area[main] {
      z-index: 0;
    }
    
    image-area[reference] {
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }
    
    image-area[reference][hide] {
      display: none;
    }
    
    [loading] {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #FFF;
    }
    
    [loading] loading {
      animation: spin 2s steps(8, end) infinite;
    }
    
    [labels] {
      color: #FF0;
      position: absolute;
      inset: var(--area-padding);
      font-weight: 600;
      z-index: 50000;
      pointer-events: none;
    }
    
    [labels] span {
      position: absolute;
    }
    
    [labels] span[top] {
      top: 2px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }
    
    [labels] span[left] {
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
    }
    
    [labels] span[right] {
      top: 50%;
      transform: translateY(-50%);
      right: 2px;
    }
    
    [labels] span[controls] {
      top: 8px;
      left: 8px;
      color: #FFF;
    }
    
    [labels] [controls] ul {
      list-style: none;
      font-size: smaller;
      padding: 0;
      margin: 0;
    }
    
    [labels] [dimension] {
      bottom: 18px;
      left: 85px;
    }
    
    #container.error {
      position: relative;
      height: 100%;
    }
    
    #container.error span {
      color: #FFF;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `;

  static properties = {
    // Required
    planSer: { type: String },
    fraction: { type: String },
    groupIndex: { type: String },
    dicomGroupData: { type: Object },
    orientation: { type: String },
    loaded: { type: Boolean },
    loading: { type: Boolean },
    priority: { type: Number },

    // Optional
    hasBlend: { type: Boolean, reflect: true, attribute: 'has-blend' },
    levelsControl: { type: Boolean, attribute: 'levels-control' },
    blendControl: { type: Boolean, attribute: 'blend-control' },
    blendControlBelow: { type: Boolean, attribute: 'blend-below' },
    paired: { type: Boolean }, // if this is true, it shows the pairedImage, otherwise it shows the main one,
    simple: { type: Boolean }, // if this is true, uses the simple tool group, otherwise it uses the main one
    minLevel: { type: Number },
    maxLevel: { type: Number },
    blendValue: { type: Number },
    cameraPosition: { type: Object },
    cameraFocalPoint: { type: Object },
    userOrigin: { type: Object },
    contours: { type: Object },
    colorLUTIndex: { type: Number },
    showKeyboardControls: { type: Boolean, attribute: 'show-keyboard-controls' },
    referenceMinLevel: { type: Number },
    referenceMaxLevel: { type: Number },
    loadedStructures: { type: Array },
    layers: { type: Array },
    hasError: { type: Boolean },
    errorDisplayMessage: { type: String },
    type: { type: String },
    imageVOI: { type: Object },
    referenceImageVOI: { type: Object },
    pairedImageVOI: { type: Object },
    pairedReferenceImageVOI: { type: Object },
    isoCenter: { type: Object },
    acquiredVolumeVOI: { type: Object },
    referenceVolumeVOI: { type: Object },
    initialFocalPoint: { type: Object }
  };

  constructor() {
    super();

    this.loadedStructures = [];
  }

  // Purposely overlaps with others of the same type.
  get cameraSynchronizerId() {
    const { planSer, fraction, groupIndex, simple } = this;

    /* eslint-disable-next-line */
    return `camera-synch-${planSer}-${fraction}-${groupIndex}-${simple ? 'simple' : 'primary' }`;
  }

  // Purposely overlaps with others of the same type.
  get zoomPanSynchronizerId() {
    const { planSer, fraction, groupIndex, simple } = this;

    /* eslint-disable-next-line */
    return `zoom-pan-synch-${planSer}-${fraction}-${groupIndex}-${simple ? 'simple' : 'primary' }`;
  }

  // Purposely overlaps with others of the same type.
  get voiSynchronizerId() {
    const { planSer, fraction, groupIndex, simple, paired } = this;

    return `voi-synch-${planSer}-${fraction}-${groupIndex}-${simple ? 'simple' : 'primary' }${paired ? '-pair' : ''}`;
  }

  // Purposely overlaps with others of the same type.
  get viewportId() {
    const { planSer, fraction, groupIndex, orientation, paired, simple } = this;

    return `viewport-${planSer}-${fraction}-${groupIndex}-${simple ? 'simple' : 'primary' }${orientation 
      ? `-${orientationToString(orientation)}` : ''}${paired ? '-pair' : ''}`;
  }

  // Only used with 2D, since we have to have two viewports
  get referenceViewportId() {
    const { planSer, fraction, groupIndex, orientation, paired, simple, type, referenceImageIds } = this;

    if (type !== ImageTypes.TwoD || !referenceImageIds.length) return undefined;

    return `viewport-${planSer}-${fraction}-${groupIndex}-${simple ? 'simple' : 'primary' }${orientation
      ? `-${orientationToString(orientation)}` : ''}${paired ? '-pair' : ''}-reference`;
  }

  // Purposely overlaps with others of the same type.
  get toolGroupId() {
    const { planSer, fraction, groupIndex, simple, paired } = this;

    return `toolgroup-${planSer}-${fraction}-${groupIndex}-${simple ? 'simple' : 'primary' }${paired ? '-pair' : ''}`;
  }

  get readyToLoad() {
    const { planSer, fraction, dicomGroupData, groupIndex } = this;

    return [planSer, fraction, dicomGroupData, groupIndex].every(v => v !== undefined && v !== null);
  }

  get largeEnough() {
    return Boolean(this.shadowRoot?.querySelector('image-area')?.offsetWidth);
  }

  get structures() {
    const { contours, layers, type } = this;

    return type === ImageTypes.ThreeD
      ? contours
      : layers;
  }

  set structures(structures) {
    const { type } = this;

    if (type === ImageTypes.TwoD) {
      this.layers = structures;
    } else {
      this.contours = structures;
    }
  }

  get imageVOIs() {
    const { paired, imageVOI, pairedImageVOI, referenceImageVOI, pairedReferenceImageVOI } = this; 
    const referenceVOI = paired ? pairedReferenceImageVOI : referenceImageVOI;
    const acquiredVOI = paired ? pairedImageVOI : imageVOI;
    return { referenceVOI, acquiredVOI }; 
  }

  connectedCallback() {
    super.connectedCallback();

    this.resizeIfNecessary();
  }

  stateChanged = state => {
    this.checkReady(state);
  }

  firstUpdated() {
    const imageArea = this.shadowRoot.querySelector('image-area');
    const resizeObserver = new ResizeObserver(() => {
      this.checkReady();

      resizeObserver.unobserve(imageArea);
    });

    resizeObserver.observe(imageArea);
  }

  updated(changed) {
    if (!this.element) {
      this.prepareElement();
    }

    if (['planSer', 'fraction', 'groupIndex', 'dicomGroupData'].some(::changed.has) && this.readyToLoad) {
      this.checkReady();
    }

    if (changed.has('layers') && this.loaded) {
      this.annotateView();
    }
  }

  checkReady = () => {
    if (this.readyToLoad && !this.loading && !this.loaded && this.largeEnough) {
      this.initialize();
    }
  };

  prepareElement = () => {
    const element = this.shadowRoot.querySelector('image-area[main]');
    const referenceElement = this.shadowRoot.querySelector('image-area[reference]');

    this.element = element;
    this.referenceElement = referenceElement;

    element.addEventListener(Enums.Events.CAMERA_MODIFIED, event => {
      if (!deepEquals(event.detail.camera.position, this.cameraPosition)
        || !deepEquals(event.detail.camera.focalPoint, this.cameraFocalPoint)) {
        const referenceViewport = this.renderingEngine.getViewport(this.referenceViewportId);

        // update cameraFocalPoint for 3D image position readout without referenceViewport
        this.cameraPosition = event.detail.camera.position;
        this.cameraFocalPoint = event.detail.camera.focalPoint;
        this.colorLUTIndex = 1;

        // override axial's initial camera when the page has been resized
        const viewport = this.renderingEngine.getViewport(this.viewportId);
        if (viewport) {
          const previousParallelScale = event.detail.previousCamera.parallelScale; // set after broadcast from sagittal
          // original parallel scale from the resize event
          const currentParallelScale = event.detail.camera.parallelScale;
          const currentZoom = viewport.getZoom();

          // keep axial's initialZoom set to match sagittal/coronal on resize
          if (this.orientation?.name.includes('axial') // axial only
            && previousParallelScale !== currentParallelScale // identify when initialCamera's zoom has been lost
            && currentZoom === 1 // distinguish from when user has zoomed.
            && previousParallelScale > 3 // ignore when very small
          ) {
            // reset the initialZoom.
            // eslint-disable-next-line no-console
            console.log('override initialZoom', currentParallelScale, previousParallelScale);
            viewport.setZoom(currentParallelScale / previousParallelScale, true);
            viewport.setCamera({ ...event.detail.previousCamera });
            viewport.render();
          }

          // keep initialCamera's focal point set to iso on resize
          if (this.initialFocalPoint
            && this.initialFocalPoint[0].toFixed(3) !== viewport?.initialCamera.focalPoint[0].toFixed(3)
            && this.initialFocalPoint[1].toFixed(3) !== viewport?.initialCamera.focalPoint[1].toFixed(3)
            && this.initialFocalPoint[2].toFixed(3) !== viewport?.initialCamera.focalPoint[2].toFixed(3)
          ) {
            // reset the initial camera's focal point and position
            // eslint-disable-next-line no-console
            console.log('override initialCamera', this.initialFocalPoint, viewport?.initialCamera.focalPoint);
            this.setInitialCameraPosition();
          }
        }

        // console.log({
        //  'cam for ': this.viewportId, /*'from ': event.detail.viewportId,*/
        //  //zoom: viewport?.getZoom(), initialCamera: viewport?.initialCamera,
        //  camera: event.detail.camera,
        //  cameraPrior: event.detail.previousCamera
        // });

        // updates camera positions to keep 2D image referenceViewport and acquiredViewport sync'd. 
        // Don't store the camera change until referenceViewport is initialized, so we'll
        //  run this code and sync them up when everything is ready.
        if (!referenceViewport) return;
                
        const [{ actor }] = referenceViewport.getActors();
        const scale = actor.getScale();
        const position = actor.getPosition();

        actor.setPosition(actor.getPosition().map(v => v + 1));
        actor.setPosition(actor.getPosition().map(v => v - 1));

        const referenceValues = {
          position: event.detail.camera.position.map((v, i) => (v - position[i]) / scale[i]),
          focalPoint: event.detail.camera.focalPoint.map((v, i) => (v - position[i]) / scale[i])
        };

        referenceViewport.setCamera({
          ...event.detail.camera,
          ...referenceValues
        });
        
        referenceViewport.render();
      }
    });

    element.addEventListener(Enums.Events.VOLUME_NEW_IMAGE, ({ detail: { viewportId } }) => {
      const viewport = this.renderingEngine.getViewport(viewportId);

      if (!viewport) return;

      const { actor: acquiredActor } = viewport.getActor(this.acquiredVolumeId) ?? {};
      const { actor: referenceActor } = viewport.getActor(this.referenceVolumeId) ?? {};

      if (acquiredActor && !deepEquals(this.lastAcquiredZRange, acquiredActor.getZRange())) {
        this.lastAcquiredZRange = acquiredActor.getZRange();

        acquiredActor && setActorOpacity(acquiredActor, this.blendValue ?? INITIAL_BLEND);
      }

      if (referenceActor && !deepEquals(this.lastReferenceZRange, referenceActor.getZRange())) {
        this.lastReferenceZRange = referenceActor.getZRange();

        referenceActor && setActorOpacity(referenceActor, 1 - (this.blendValue ?? INITIAL_BLEND));
      }
    });

    element.addEventListener(Enums.Events.IMAGE_RENDERED, () => {
      this.annotateView();
    });
  }

  async initialize() {
    // If we're in a sticky table, don't render or initialize.
    if (this.loading || this.loaded || this.closest('table[sticky]')) {
      // temporary. we'll need to figure out the actual blend for the real stuff so the table heights stay accurate
      this.hasBlend = true;
      return;
    }

    this.loading = true;

    const { planSer, fraction, groupIndex, dicomGroupData, paired, simple } = this;

    this.listenForBroadcasts();

    try {
      if (dicomGroupData.acquiredImages[0].type === ImageTypes.TwoD) {
        if (!paired && !dicomGroupData.isReferenceAngleValid) {
          console.error('DRR not at same angle as acquired 2D image');

          // noinspection ExceptionCaughtLocallyJS
          throw new ReferenceAngleInvalidException();
        }

        if (paired && !dicomGroupData.isPairedReferenceAngleValid) {
          console.error('paired DRR not at same angle as paired 2D image');

          // noinspection ExceptionCaughtLocallyJS
          throw new ReferenceAngleInvalidException();
        }
      }

      const {
        type, acquiredVolumeId, acquiredImageIds, referenceImageIds, referenceVolumeId,
        pairedAcquiredImageId, pairedReferenceImageId, structureId, renderingEngine, pairedLayers,
        spatialRegistrationImageId, userOrigin, contours, structurePromises, layers,
        imageVOI, referenceImageVOI, pairedImageVOI, pairedReferenceImageVOI,
        isoCenter
      } = await initializeDicomGroup(planSer, fraction, groupIndex, dicomGroupData, simple);
      
      this.renderingEngine = renderingEngine;

      if (paired) {
        this.acquiredImageIds = [pairedAcquiredImageId].filter(Boolean);
        this.referenceImageIds = [pairedReferenceImageId].filter(Boolean);
        this.type = ImageTypes.TwoD;
        this.userOrigin = userOrigin;
        this.layers = this.buildLayers(pairedLayers);
        this.loadedStructures = this.layers.map(({ number }) => number);
        this.pairedImageVOI = pairedImageVOI;
        this.pairedReferenceImageVOI = pairedReferenceImageVOI; 
        // assign registration to the pair to apply setUserMatrix/setPosition within adjustFrameOfReference.
        this.spatialRegistrationImageId = spatialRegistrationImageId;
      } else {
        Object.assign(this, {
          type, acquiredVolumeId, referenceVolumeId,
          acquiredImageIds, referenceImageIds, structureId, spatialRegistrationImageId, userOrigin, contours,
          imageVOI, referenceImageVOI, isoCenter
        });
        
        if (type === ImageTypes.TwoD && !simple) {
          this.layers = this.buildLayers(layers);
          this.loadedStructures = this.layers.map(({ number }) => number);
        }

        if (type === ImageTypes.ThreeD && !simple) {
          this.referenceVolumeVOI = dicomGroupData.referenceImageVolumeVOI;
          this.acquiredVolumeVOI = dicomGroupData.acquiredImageVolumeVOI; 
        }
      }

      this.hasBlend = this.referenceImageIds.length && this.acquiredImageIds.length;
      await this.configureViewport();

      if (type === ImageTypes.ThreeD) {
        this.setInitialCameraPosition();
      }

      await this.configureToolGroup();
      
      // Must come after configureToolGroup()
      Object.entries(structurePromises || {}).forEach(([structureId, promise]) => {
        promise.then(this.finalizeSegmentation(structureId));
      });

      if (contours && !simple) {
        await this.addSegmentationConfig();
      }

      await this.addVOISynchronizer();
      await this.addZoomPanSynchronizer();
      await this.renderViewport();
      await this.startResizeObserver();

      this.loading = false;
      this.loaded = true;
    } catch (err) {
      console.error('dicom-image error:', err);
      this.hasError = true;
      if (err instanceof RegistrationNotFoundException) {
        this.errorDisplayMessage = err.message; 
      } else {
        this.errorDisplayMessage = 'Image unavailable.';
      }

      this.sendBroadcast(BROADCAST_ERROR, { errorDisplayMessage: this.errorDisplayMessage });
    }
  }

  listenForBroadcasts = () => {
    document.addEventListener(BROADCAST_LEVELS, this.handleBroadcast);
    document.addEventListener(BROADCAST_BLEND, this.handleBroadcast);
    document.addEventListener(BROADCAST_ERROR, this.handleBroadcast);
    document.addEventListener(BROADCAST_INITIALZOOM, this.handleBroadcast);
  };

  handleBroadcast = ({ type, detail: { sender, planSer, fraction, groupIndex, value } }) => {
    // don't react to something we broadcast by us or sent by a different group
    if (sender === this
      || (planSer !== this.planSer || fraction !== this.fraction || groupIndex !== this.groupIndex)) return;

    // can handle error before loaded
    if (type === BROADCAST_ERROR) {
      this.hasError = true;
      this.errorDisplayMessage = value.errorDisplayMessage;
    }

    if (type === BROADCAST_INITIALZOOM) {
      this.handleInitialZoom({ detail: { ...value, broadcast: true } });
    }

    if (!this.loaded) return;
    // only handle blend and level after loaded
    if (type === BROADCAST_BLEND) {
      this.handleBlendChange({ detail: { ...value, broadcast: true } });
    } else if (type === BROADCAST_LEVELS) {
      if (this.type === ImageTypes.ThreeD) {
        if (value.viewType === 'acquired') {
          this.handleLevelsChange({ detail: { ...value, broadcast: true } });
        } else if (value.viewType === 'reference') {
          this.handleReferenceLevelsChange({ detail: { ...value, broadcast: true } });
        }
      }
      // disable linked levels for 2D
    }
  };

  sendBroadcast(type, value) {
    const { planSer, fraction, groupIndex } = this;

    document.dispatchEvent(new CustomEvent(type, {
      detail: { sender: this, planSer, fraction, groupIndex, value },
      composed: true,
      bubbles: true
    }));
  }

  render() {
    const { blendControl, levelsControl, loading, hasBlend, simple,
      blendValue, type, hasError, errorDisplayMessage } = this;

    if (hasError) {
      return html`
        <div id="container" class="error">
          <span>
            ${errorDisplayMessage}
          </span>
        </div>
      `;
    }
   
    return html`
      ${fontAwesome}
      ${h(loading, this.renderLoading)}
      <div id="container" ?has-blend=${hasBlend}>
        ${h(blendControl, this.renderBlendControl)}
        ${h(!simple && levelsControl, this.renderLevelsControl)}
        ${h(!simple, this.renderStructureControl)}
        ${h(!simple, this.renderLabels)}
        <image-area reference
          ?hide=${this.type === ImageTypes.ThreeD}
          @contextmenu=${eatEvent}
          style=${styleMap({ opacity: `${1 - (blendValue ?? INITIAL_BLEND)}` })}
        ></image-area>
        <image-area main @contextmenu=${eatEvent}></image-area>
        <overlay>
          <canvas id="kramer"></canvas>
          ${h(type === ImageTypes.TwoD, html`<canvas id="structures"></canvas>`)}
        </overlay>
      </div>
    `;
  }

  renderLoading = () => {
    return html`
      <div loading>
        <loading class="fad fa-spinner"></loading>
        Loading...
      </div>
    `;
  }

  renderLabels = () => {
    // Default will apply for 2D
    const { orientation, type, cameraFocalPoint, userOrigin, showKeyboardControls } = this;
    const { labels = ORIENTATIONS.CORONAL_HFS.labels } = orientation || {};
    const sign = orientation.dimension.flip ? -1 : 1;
    
    return html`
      <div labels>
        <span top>${labels.top}</span>
        <span left>${labels.left}</span>
        <span right>${labels.right}</span>
        ${h(type === ImageTypes.ThreeD && cameraFocalPoint, () => html`
          <span dimension>${orientation.dimension.label}: 
            ${toPrecision(
              ((cameraFocalPoint[orientation.dimension.index] 
                - userOrigin[orientation.dimension.index]) ?? 0) * sign / 10, 
              2
            )}cm</span>
        `)}
        ${h(showKeyboardControls, () => html`<span controls>
          <ul>
            <li>Right-click + Drag: Zoom</li>
            <li>Wheel Drag: Pan</li>
            <li>Left Arrow: Previous Image</li>
            <li>Right Arrow: Next Image</li>
            <li>Shift + Left Arrow: Previous Day</li>
            <li>Shift + Right Arrow: Next Day</li>
          </ul>
        </span>`)}
      </div>
    `;
  }

  renderStructureControl = () => {
    const { structures, loadedStructures } = this;

    return html`
       <structure-toggle
         .structures=${structures}
         .loadedStructures=${loadedStructures}
         .toolGroupId=${this.toolGroupId}
         .type=${this.type}
         @change=${this.handleStructureChange}
       ></structure-toggle>
     `;
  };

  handleStructureChange({ detail: structures }) {
    this.structures = structures;
  }

  renderBlendControl = () => {
    const { blendControlBelow, hasBlend, simple } = this;

    if (!hasBlend) return;

    return html`
      <number-slider
        ?below=${blendControlBelow}
        .value=${this.blendValue ?? INITIAL_BLEND}
        left-label=${!simple ? 'Ref' : undefined}
        right-label=${!simple ? 'Acq' : undefined}
        @change=${this.handleBlendChange}
      ></number-slider>
    `;
  }

  renderLevelsControl = () => {
    const { minLevel, maxLevel, referenceMinLevel, referenceMaxLevel, referenceImageIds, loaded } = this;

    const { referenceVOI, acquiredVOI } = this.imageVOIs; 
    const { referenceVolumeVOI, acquiredVolumeVOI } = this; 

    const initialRef = this.type === ImageTypes.TwoD
      ? (referenceVOI ?? INITIAL_VOI)
      : (referenceVolumeVOI ?? INITIAL_LEVELS);
    const initialAcq = this.type === ImageTypes.TwoD
      ? (acquiredVOI ?? INITIAL_VOI)
      : (acquiredVolumeVOI ?? INITIAL_LEVELS);

    const ref = this.type === ImageTypes.TwoD ? (referenceVOI ?? VOI) : LEVELS;
    const acq = this.type === ImageTypes.TwoD ? (acquiredVOI ?? VOI) : LEVELS;

    return html`
      ${h(!loaded || referenceImageIds?.length, () => html`<range-slider
        class="left"
        minLabel=${`${referenceMinLevel ?? initialRef.min} ${h(this.type === ImageTypes.ThreeD, 'HU')}`}
        maxLabel=${`${referenceMaxLevel ?? initialRef.max} ${h(this.type === ImageTypes.ThreeD, 'HU')}`}
        label="L"
        @change=${this.handleReferenceLevelsChange}
        min=${valueToPercentage(referenceMinLevel ?? initialRef.min, ref.min, ref.max)}
        max=${valueToPercentage(referenceMaxLevel ?? initialRef.max, ref.min, ref.max)}
      ></range-slider>`)}
      <range-slider
        class="right"
        minLabel=${`${minLevel ?? initialAcq.min} ${h(this.type === ImageTypes.ThreeD, 'HU')}`}
        maxLabel=${`${maxLevel ?? initialAcq.max} ${h(this.type === ImageTypes.ThreeD, 'HU')}`}
        label="L"
        @change=${this.handleLevelsChange}
        min=${valueToPercentage(minLevel ?? initialAcq.min, acq.min, acq.max)}
        max=${valueToPercentage(maxLevel ?? initialAcq.max, acq.min, acq.max)}
      ></range-slider>
    `;
  }

  handleReferenceLevelsChange({ detail: { min, max, broadcast } }) {
    this.updateVoi('reference', min, max);
    if (!broadcast) {
      this.sendBroadcast(BROADCAST_LEVELS, { min, max, viewType: 'reference' });
    }
  }

  handleLevelsChange({ detail: { min, max, broadcast } }) {
    this.updateVoi('acquired', min, max);
    if (!broadcast) {
      this.sendBroadcast(BROADCAST_LEVELS, { min, max, viewType: 'acquired' });
    }
  }

  // viewType is 'acquired' or 'reference'
  updateVoi(viewType, min, max) {
    const { type, viewportId, acquiredVolumeId, referenceVolumeId, referenceViewportId } = this;
    const viewport = this.renderingEngine.getViewport(viewportId);

    if (type === ImageTypes.ThreeD) {
      const minValue = toValue(min);
      const maxValue = toValue(max);
      const volumeId = viewType === 'acquired' ? acquiredVolumeId : referenceVolumeId;

      if (!volumeId) return;

      const { actor } = viewport.getActor(volumeId);

      if (viewType === 'acquired') {
        this.minLevel = minValue;
        this.maxLevel = maxValue;
      } else if (viewType === 'reference') {
        this.referenceMinLevel = minValue;
        this.referenceMaxLevel = maxValue;
      }

      actor
        .getProperty()
        .getRGBTransferFunction(0)
        .setMappingRange(minValue, maxValue);
    } else {
      const { referenceVOI, acquiredVOI } = this.imageVOIs;  

      if (viewType === 'acquired') {
        const minValue = percentageToValue(min, acquiredVOI.min, acquiredVOI.max);
        const maxValue = percentageToValue(max, acquiredVOI.min, acquiredVOI.max);

        this.minLevel = minValue;
        this.maxLevel = maxValue;

        viewport.setVOI({
          lower: minValue,
          upper: maxValue
        });
      } else if (viewType === 'reference' && referenceViewportId) {
        const minValue = percentageToValue(min, referenceVOI.min, referenceVOI.max);
        const maxValue = percentageToValue(max, referenceVOI.min, referenceVOI.max);

        this.referenceMinLevel = minValue;
        this.referenceMaxLevel = maxValue;

        const referenceViewport = this.renderingEngine.getViewport(referenceViewportId);
        
        referenceViewport.setVOI({
          lower: minValue,
          upper: maxValue
        });

        referenceViewport.render();
      }
    }

    viewport.render();
  }

  handleBlendChange(event) {
    event.stopPropagation?.();

    const { detail: { value, broadcast } } = event;
    this.blendValue = value;

    if (this.type === ImageTypes.ThreeD) {
      const { viewportId, acquiredVolumeId, referenceVolumeId } = this;
      const viewport = this.renderingEngine.getViewport(viewportId);

      const { actor: referenceActor } = viewport.getActor(referenceVolumeId);
      const { actor: acquiredActor } = viewport.getActor(acquiredVolumeId);

      if (!referenceActor && !acquiredActor) return;

      referenceActor && setActorOpacity(referenceActor, 1 - value);
      acquiredActor && setActorOpacity(acquiredActor, value);

      viewport.render();
    } else {
      const { referenceElement } = this;

      referenceElement.style.opacity = `${1 - value}`;
    }

    if (!broadcast) {
      this.sendBroadcast(BROADCAST_BLEND, { value });
    }
  }

  handleInitialZoom(event) {
    // eslint-disable-next-line no-console
    console.log('handling zoom', this.type, this.orientation?.name);
    if (this.type === ImageTypes.ThreeD) {
      const viewport = this.renderingEngine.getViewport(this.viewportId);
      const orientationName = this.orientation?.name;
      if (orientationName.includes('axial')) {
        const { parallelScale } = viewport.initialCamera;

        // eslint-disable-next-line no-console
        console.log('setting zoom', parallelScale, event.detail.parallelScale,
          parallelScale / event.detail.parallelScale, this.loading, this.loaded);
        viewport.setZoom(parallelScale / event.detail.parallelScale, true);
      }
    }
  }

  annotateView() {
    const { renderingEngine, simple, viewportId } = this;

    if (simple) return; // don't annotate simple

    const viewport = renderingEngine.getViewport(viewportId);
    const camera = viewport.getCamera();
    const overlay = this.shadowRoot.querySelector('overlay');
    const canvases = [...overlay.querySelectorAll('canvas')];

    canvases.forEach(canvas => {
      canvas.width = overlay.offsetWidth;
      canvas.height = overlay.offsetHeight;
    });

    if (!this.kramer) {
      this.kramer = createKramer(overlay.querySelector('canvas#kramer'));
    }

    // @TODO: Only update if camera changes.
    this.renderKramer(camera);
    this.renderStructures();
  }

  renderKramer(camera) {
    const { type, kramer, orientation } = this;

    if (type === ImageTypes.TwoD) {
      kramer.render({ viewPlaneNormal: orientation.viewPlaneNormal, viewUp: orientation.viewUp, viewAngle: 90 },
        orientation.sourceAngle, orientation.couchAngle);
    } else {
      kramer.render(camera, orientation.sourceAngle, orientation.couchAngle);
    }
  }

  buildGraticule() {
    const { referenceImageIds } = this;

    const angle = metaDataManager.get('radGraticuleAngle', referenceImageIds[0]);
    const refData = metaDataManager.get('radSpatial', referenceImageIds[0]);
    const xSize = refData.Columns;
    const ySize = refData.Rows;

    const graticuleCenter = [
      // TODO: not apparent to me why this is true
      (xSize - 1) / 2,
      (ySize - 1) / 2
    ];

    return buildGraticule(graticuleCenter, angle * -1 * Math.PI / 180.0);
  }

  buildLayers = layers => [
    {
      number: 0,
      label: 'Graticule',
      lines: this.buildGraticule(),
      color: GRATICULE_COLOR,
      visible: true,
      type: 'CHARTCHECK'
    },
    ...(layers?.map(({ number, label, data, type }) => ({
      number,
      label,
      lines: [data.map(({ x, y }) => [x, y])],
      color: this.getColorFor2DStructure(label),
      visible: true,
      type
    })) || [])
  ];

  getColorFor2DStructure(structureLabel) {
    const { dicomGroupData, paired } = this;

    const color = (paired ? dicomGroupData.pairedReferenceStructure2Ds : dicomGroupData.referenceStructure2Ds)
      ?.find(({ label }) => label === structureLabel)
      ?.color;

    if (!color) {
      console.warn(`No color for ${structureLabel}, using random color.`);
      return randomColor();
    }

    return color;
  }

  renderStructures() {
    const { type, renderingEngine, referenceViewportId } = this;

    const canvas = this.shadowRoot?.querySelector('overlay canvas#structures');

    if (type !== ImageTypes.TwoD || !canvas) return;

    const viewport = renderingEngine.getViewport(referenceViewportId);

    const structures = [
      // Testing cross right through 0,0
      // { color: '#FF0000', lines: [[[-50, 0], [50, 0]], [[0, -50], [0, 50]]] },
      ...this.structures.filter(({ visible }) => visible)
    ];

    drawStructures2D(canvas, viewport, structures);
  }

  async configureViewport() {
    const { element, viewportId, orientation, type, acquiredVolumeId, referenceVolumeId,
      renderingEngine, acquiredImageIds, referenceImageIds,
      referenceViewportId } = this;

    const viewportInput = {
      viewportId,
      volumeId: type === ImageTypes.ThreeD ? acquiredVolumeId : undefined,
      initialVOIRange: type === ImageTypes.TwoD ? { lower: INITIAL_VOI.min, upper: INITIAL_VOI.max } : undefined,
      element,
      type: type === ImageTypes.ThreeD ? ViewportType.ORTHOGRAPHIC : ViewportType.STACK,
      defaultOptions: { orientation }
    };
    renderingEngine.enableElement(viewportInput);
    
    if (type === ImageTypes.ThreeD) {
      const { referenceVolumeVOI, acquiredVolumeVOI } = this; 
      // order volumeIds so the ref is first, acq is second --> zoom/pan tool normal for 3D.
      const viewportVolumes = [
        referenceVolumeId && {
          volumeId: referenceVolumeId,
          callback: ({ volumeActor }) => {
            volumeActor
              .getProperty()
              .getRGBTransferFunction(0)
              .setMappingRange(referenceVolumeVOI.min ?? INITIAL_LEVELS.min,
                referenceVolumeVOI.max ?? INITIAL_LEVELS.max);
          }
        },
        acquiredVolumeId && {
          volumeId: acquiredVolumeId,
          callback: ({ volumeActor }) => {
            volumeActor
              .getProperty()
              .getRGBTransferFunction(0)
              .setMappingRange(acquiredVolumeVOI.min ?? INITIAL_LEVELS.min,
                acquiredVolumeVOI.max ?? INITIAL_LEVELS.max);
          }
        }
      ].filter(Boolean);

      viewportVolumes.length && await setVolumesForViewports(
        renderingEngine,
        viewportVolumes,
        [viewportId]
      );

      const viewport = renderingEngine.getViewport(viewportId);
      viewport.setBlendMode(Enums.BlendModes.MAXIMUM_INTENSITY_BLEND);
      if (this.orientation?.name.includes('sagittal')) {
        this.sendBroadcast(BROADCAST_INITIALZOOM, { parallelScale: viewport.initialCamera.parallelScale });
      }
    } else {
      const viewport = renderingEngine.getViewport(viewportId);

      await viewport.setStack(acquiredImageIds, 0);

      let { referenceVOI, acquiredVOI } = this.imageVOIs; 
      if (!acquiredVOI) acquiredVOI = LEVELS; // for single CT slice in thumbnail view
      viewport.setProperties({ voiRange: { lower: acquiredVOI.min, upper: acquiredVOI.max } });

      if (this.referenceImageIds.length) {
        const referenceViewportInput = {
          ...viewportInput,
          viewportId: referenceViewportId,
          element: this.referenceElement
        };

        renderingEngine.enableElement(referenceViewportInput);

        const referenceViewport = renderingEngine.getViewport(referenceViewportId);

        await referenceViewport.setStack(referenceImageIds, 0);
        referenceViewport.setProperties({ voiRange: { lower: referenceVOI.min, upper: referenceVOI.max } });
      }
    }
  }

  async configureToolGroup() {
    const { viewportId, toolGroupId, simple } = this;
    const toolGroupType = simple ? ToolGroupTypes.Simple : ToolGroupTypes.Primary;

    const toolGroup = await createToolGroup(toolGroupId, toolGroupType);
    toolGroup.addViewport(viewportId);
    toolGroup.activate();
  }

  finalizeSegmentation(structureIdNumber) {
    const { toolGroupId } = this;

    return async contour => {
      const existingSegmentations = segmentation.state.getSegmentations();

      if (!existingSegmentations.find(({ segmentationId }) => segmentationId === structureIdNumber)) {
        await this.addSegmentation(structureIdNumber);

        const segmentationsRepresentationUID = await turnOnSegmentation(structureIdNumber, toolGroupId);

        await this.setSegmentationColor(segmentationsRepresentationUID, contour.number);
        contour.structureIdNumber = structureIdNumber;
        contour.segmentationsRepresentationUID = segmentationsRepresentationUID;

        // re-render after we add a segment so it shows up
        await this.renderViewport();
      }

      this.loadedStructures = [...this.loadedStructures, contour.number];
    };
  }

  async addSegmentation(structureIdNumber) {
    // one labelmap for each structureIdNumber.
    segmentation.addSegmentations([
      {
        segmentationId: structureIdNumber,
        representation: {
          // The type of segmentation
          type: csToolsEnums.SegmentationRepresentations.Labelmap,
          data: {
            volumeId: structureIdNumber // not segmentationId
          }
        }
      }
    ]);
  }

  async setSegmentationColor(representationUID, segmentIndex) {
    // sets the colorLUT index to use for the segmentation representation
    segmentation.config.color.setColorLUT(this.toolGroupId, representationUID, this.colorLUTIndex);
  }

  async addSegmentationConfig() {
    await this.addToolGroupSpecificConfig();
    await this.addColorLUTtoConfig();
  }

  async addToolGroupSpecificConfig() {
    let config = segmentation.config.getToolGroupSpecificConfig(this.toolGroupId);
    
    if (config === undefined || config.representations === undefined) {
      config = {
        renderInactiveSegmentations: true,
        representations: {
          LABELMAP: {
            fillAlpha,
            fillAlphaInactive: fillAlpha,
            outlineWidthActive: outlineWidth,
            outlineWidthInactive: outlineWidth,
            renderFill: true,
            renderFillInactive: true,
            renderOutline: true
          }
        }
      };

      segmentation.config.setToolGroupSpecificConfig(this.toolGroupId, config);
    }
  }

  async addColorLUTtoConfig() {
    // https://www.cornerstonejs.org/docs/concepts/cornerstone-tools/segmentation/config#color-api
    // https://www.cornerstonejs.org/api/tools/namespace/segmentation/
    const existingColorLUT = segmentation.state.getColorLUT(this.colorLUTIndex);
    
    if (existingColorLUT === undefined && this.contours.length) {
      const backgroundColorForSegmentIndexZero = [0, 0, 0, 0];
      const maxROInumber = Math.max(...this.contours.map(contour => contour.number));
      const colorLUT = new Array(maxROInumber+1).fill(backgroundColorForSegmentIndexZero);

      this.contours.forEach(contour => {
        colorLUT[contour.number] = [...contour.color, 255];
      });
    
      // add color LUT for use with a segmentation representation
      segmentation.config.color.addColorLUT(colorLUT, this.colorLUTIndex);
    }
  }

  async addVOISynchronizer() {
    if (this.type !== ImageTypes.ThreeD) return;

    const { viewportId, voiSynchronizerId, referenceViewportId } = this;

    let synchronizer = SynchronizerManager.getSynchronizer(voiSynchronizerId);

    if (!synchronizer) {
      createVOISynchronizer(voiSynchronizerId);
      synchronizer = SynchronizerManager.getSynchronizer(voiSynchronizerId);
    }

    synchronizer.add({ renderingEngineId, viewportId });
    referenceViewportId && synchronizer.add({ renderingEngineId, viewportId: referenceViewportId });
  }

  async addZoomPanSynchronizer() {
    const { viewportId, zoomPanSynchronizerId } = this;

    let synchronizer = SynchronizerManager.getSynchronizer(zoomPanSynchronizerId);

    if (!synchronizer) {
      createZoomPanSynchronizer(zoomPanSynchronizerId);
      synchronizer = SynchronizerManager.getSynchronizer(zoomPanSynchronizerId);
    }

    synchronizer.add({ renderingEngineId, viewportId });
  }

  async renderViewport() {
    const { renderingEngine, viewportId } = this;

    renderingEngine.renderViewport(viewportId);
  }

  async resizeIfNecessary() {
    const { simple } = this;

    const dicomCanvas = this.shadowRoot?.querySelector('image-area[main] canvas');
    const kramerCanvas = this.shadowRoot?.querySelector('overlay canvas#kramer');
    const structuresCanvas = this.shadowRoot?.querySelector('overlay canvas#structures');
    const referenceCanvas = this.shadowRoot?.querySelector('image-area[reference] canvas');
   
    // Resize overlay first so it'll get redrawn when the dicom redraws
    if (kramerCanvas && !simple && this.kramer && kramerCanvas && shouldResizeCanvas(kramerCanvas)) {
      kramerCanvas.width = kramerCanvas.offsetWidth;
      kramerCanvas.height = kramerCanvas.offsetHeight;

      clearTimeout(this.kramerResizeTimeoutId);

      this.kramerResizeTimeoutId = setTimeout(() => {
        this.kramer.resize();
      });
    }

    if (structuresCanvas && !simple && shouldResizeCanvas(structuresCanvas)) {
      structuresCanvas.width = structuresCanvas.offsetWidth;
      structuresCanvas.height = structuresCanvas.offsetHeight;
    }

    if (dicomCanvas && shouldResizeCanvas(dicomCanvas)) {
      dicomCanvas.width = dicomCanvas.offsetWidth;
      dicomCanvas.height = dicomCanvas.offsetHeight;

      clearTimeout(this.resizeTimeoutId);

      // eslint-disable-next-line no-console
      console.log('resizing dicom canvas');
      // Wait a moment to resize in case it is going to trigger repeatedly.
      this.resizeTimeoutId = setTimeout(() => {
        this.renderingEngine.resize();
        this.kramer?.resize();
        if (this.orientation?.name.includes('sagittal')) {
          this.sendBroadcast(BROADCAST_INITIALZOOM,
            { parallelScale: this.renderingEngine.getViewport(this.viewportId).initialCamera.parallelScale });
        }

        if (this.type === ImageTypes.ThreeD) {
          this.setInitialCameraPosition();
        }
      }, 50);
    }

    if (referenceCanvas && shouldResizeCanvas(referenceCanvas)) {
      referenceCanvas.width = referenceCanvas.offsetWidth;
      referenceCanvas.height = referenceCanvas.offsetHeight;

      clearTimeout(this.resizeTimeoutId);
      // Wait a moment to resize in case it is going to trigger repeatedly.
      this.resizeTimeoutId = setTimeout(() => {
        this.renderingEngine.resize();
        this.kramer?.resize();
      }, 50);
    }
  }

  async startResizeObserver() {
    const resizeObserver = new ResizeObserver(() => {
      if (!this.isConnected) return; // don't worry about resizing if we aren't connected

      this.resizeIfNecessary();
    });

    resizeObserver.observe(this.shadowRoot.querySelector('image-area canvas'));
  }

  setInitialCameraPosition() {
    const viewport = this.renderingEngine.getViewport(this.viewportId);
    if (!viewport) return;
    const camera = viewport.getCamera();
    const diff = [];
    const newFocalPoint = [];
    const newPosition = [];
    for (let i = 0; i <= 2; i++) {
      diff[i] = this.isoCenter[i] - camera.focalPoint[i];
      newFocalPoint[i] = camera.focalPoint[i] + diff[i];
      newPosition[i] = camera.position[i] + diff[i];
    }
    // let the camera modified event set these
    // this.cameraFocalPoint = newFocalPoint;
    // this.cameraPosition = newPosition;

    const storeAsInitialCamera = true;
    viewport.setCamera({ parallelScale: camera.parallelScale, focalPoint: newFocalPoint, position: newPosition },
      storeAsInitialCamera);

    // save this for when resize puts the initial camera back
    this.initialFocalPoint = newFocalPoint;
    // console.log({ 'setting camera': this.orientation?.name, diff: diff, newFocalPoint:
    // newFocalPoint, newPosition: newPosition, storeInitial: storeAsInitialCamera, savedFP: this.initialFocalPoint});
  }
}

customElements.define('dicom-image', DicomImage);
