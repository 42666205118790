import fromDateString from './fromDateString';

const toDateObject = date => {
  if (typeof date === 'string') {
    return fromDateString(date);
  }

  if (!(date instanceof Date)) {
    throw new Error('Unable to convert date to Date object: ' + date);
  }

  return date;
};

export default toDateObject;