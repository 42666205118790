﻿import { store } from '../store';
import createSelectorBase from '../../utils/redux/createSelector';

const createSelector = (path, defaultValue) =>
  createSelectorBase(path, defaultValue);

export const verifyDisabled = createSelector('app.verifyDisabled');

export const pageSelector = createSelector('app.page');
export const organizationSelector = createSelector('app.params.organization');
export const patientIdSelector = createSelector('app.params.patient');
export const patientSerSelector = createSelector('app.patient.ser');

export const configSelector = createSelector('app.config', {});
export const meSelector = createSelector('app.me');
export const settingsSelector = createSelector('app.settings');
export const settingsLoadedSelector = createSelector('app.settingsLoaded');

export const documentIdSelector = createSelector('app.params.documentId');
export const documentTypeSelector = createSelector('app.params.documentType');

export const settingsPageSelector = createSelector('app.params.page', 'main');

export const showOverlaySelector = createSelector('app.showOverlay');

export const organizationIdSelector = (state = store.getState()) => {
  const me = meSelector(state);
  const { id } = ((me || { organizations: [] })
    .organizations.find(({ slug }) => organizationSelector(state) === slug) || {});

  if (id) return id;
  if (me) return me.organizations[0].id;
};

export const organizationSlugSelector = (state = store.getState()) => {
  const me = meSelector(state);
  const { slug } = ((me || { organizations: [] })
    .organizations.find(({ slug }) => organizationSelector(state) === slug) || {});

  if (slug) return slug;

  if (me) return me.organizations[0].slug;
};