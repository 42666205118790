import getPixelCoordinates from './getPixelCoordinates.js';

const createSegmentation = (segmentationVolume, contour) => {
  const { scalarData, dimensions, direction, spacing, origin } = segmentationVolume;
  const { number, data } = contour;
  
  const structureNumber = number;
  const vertices = new Array(dimensions[2]).fill(0).map(() => []);

  if (data.length === 1 && data[0].contourData.length === 3) {
    // handle a single point
    const [{ contourData }] = data;
    const { k } = getPixelCoordinates(contourData[0], contourData[1], contourData[2], spacing, direction, origin);
    
    // fill the center of the 5x5
    const voxelIndex = getVoxelIndex(2, 2, k, dimensions);
    scalarData[voxelIndex] = structureNumber;

    // fill the top / btm / left / right of the inner 3x3:
    const voxelIndex1 = getVoxelIndex(2, 1, k, dimensions);
    const voxelIndex2 = getVoxelIndex(2, 3, k, dimensions);
    const voxelIndex3 = getVoxelIndex(1, 2, k, dimensions);
    const voxelIndex4 = getVoxelIndex(3, 2, k, dimensions);
    scalarData[voxelIndex1] = structureNumber;
    scalarData[voxelIndex2] = structureNumber;
    scalarData[voxelIndex3] = structureNumber;
    scalarData[voxelIndex4] = structureNumber;

    // fill the center of the slices above & below
    const voxelIndex5 = getVoxelIndex(2, 2, k - 1, dimensions);
    const voxelIndex6 = getVoxelIndex(2, 2, k + 1, dimensions);
    scalarData[voxelIndex5] = structureNumber;
    scalarData[voxelIndex6] = structureNumber;
  } else {
    for (let segment = 0; segment < data.length; segment++) {
      const { contourData } = data[segment];
      const [slice, verticesOnSegment] =
        getVerticesFromSegment(contourData, spacing, direction, origin);

      vertices[slice].push(verticesOnSegment);
    }

    paintStructure(dimensions, spacing, origin, direction, scalarData, vertices, structureNumber);
  }
};

export default createSegmentation;

function getVerticesFromSegment(contourData, spacing, direction, origin) {
  const verticesOnSegment = [];
  let kFirst;

  for (let index = 0; index < contourData.length; index += 3) {
    const x = contourData[index];
    const y = contourData[index + 1];
    const z = contourData[index + 2];
    
    if (index === 0) {
      const { k } = getPixelCoordinates(x, y, z, spacing, direction, origin);
      kFirst = k;
    }

    verticesOnSegment.push(x);
    verticesOnSegment.push(y);
  }

  return [kFirst, verticesOnSegment];
}

function getVoxelIndex(i, j, k, dimensions) {
  // i,j,k are zero-based e.g. 0-511 for 512 pixels.
  return i + (j * dimensions[0]) + (k * dimensions[0] * dimensions[1]);
}

/* adapted from Alan's linesweep algorithm */ 
/* https://drive.google.com/file/d/1NQ6Ey5SyfyPqzo6gfQoPGxVHMawothwY/view?usp=sharing */ 

// eslint-disable-next-line max-params
function paintStructure(dimensions, spacing, origin, direction, scalarData, vertices, structureNumber) {
  const orderedVertices = new Array(dimensions[2]);
  // eslint-disable-next-line prefer-destructuring
  const zSize = dimensions[2];

  // loop over all the slices --> get segments on each slice 
  for (let z = 0; z < zSize; z++) {
    if (vertices[z]) {
      const inPlaneContours = vertices[z];
      orderedVertices[z] = getOrderedVertices(inPlaneContours);
    }
  }

  paintMask3D(dimensions, spacing, origin, direction, scalarData, orderedVertices, structureNumber);
}

// eslint-disable-next-line max-params
function paintMask3D(dimensions, spacing, origin, direction, scalarData, orderedVertices, structureNumber) {
  const [, ySize] = dimensions;
  const [xDir] = direction;
  
  for (let z = 0; z < orderedVertices.length; z++) {
    if (!orderedVertices[z] || !orderedVertices[z].length) continue;
    
    const yIntersectPixels = getYIntersectPixels(orderedVertices[z], dimensions, spacing, origin, direction);
    
    for (let x = 0; x < yIntersectPixels.yIntersects.length; x++) {
      for (let i = 1; i < yIntersectPixels.yIntersects[x].length; i += 2) {
        for (
          let y = Math.max(0, yIntersectPixels.yIntersects[x][i - 1]);
          y < ySize && y < yIntersectPixels.yIntersects[x][i];
          y++
        ) {
          const voxelIndex = getVoxelIndex(yIntersectPixels.startXIndex + (x * xDir), y, z, dimensions);

          scalarData[voxelIndex] = structureNumber;
        }
      }
    }
  }
}

function getYIntersectPixels(orderedVertices, dimensions, spacing, origin, direction) {
  const delta = 1e-11;
  const [xSize] = dimensions;
  const [xOrigin, yOrigin] = origin;
  const [xRes, yRes] = spacing;
  const [xDir] = direction;
  // eslint-disable-next-line prefer-destructuring
  const yDir = direction[4];

  const startXIndex = Math.ceil((orderedVertices[0].x - xOrigin) / (xRes * xDir));
  const currentIntersectedLineSegments = [];
  const yIntersectData = {
    startXIndex: startXIndex,
    yIntersects: []
  };

  let currentVertexIndex = 0;
  let currentX = xOrigin + 0.00000001371 + (startXIndex * xRes * xDir);

  for (let x = startXIndex; x >= 0 && x < xSize; x += xDir) {
    while (currentVertexIndex < orderedVertices.length && orderedVertices[currentVertexIndex].x < currentX) {
      // noinspection EqualityComparisonWithCoercionJS
      if (orderedVertices[currentVertexIndex] == orderedVertices[currentVertexIndex].Parent.P2) {
        currentIntersectedLineSegments
          .splice(currentIntersectedLineSegments.indexOf(orderedVertices[currentVertexIndex].Parent), 1);
      } else {
        currentIntersectedLineSegments.push(orderedVertices[currentVertexIndex].Parent);
      }

      currentVertexIndex++;
    }

    const currentIntersectedLineSegmentsCount = currentIntersectedLineSegments.length;

    if (currentIntersectedLineSegmentsCount === 0) {
      if (currentVertexIndex >= orderedVertices.length) return yIntersectData;

      yIntersectData.yIntersects.push([]);
      currentX += xRes;
      continue;
    }

    const yIntersects = [];
    for (let i = 0; i < currentIntersectedLineSegmentsCount; i++) {
      const jCoordinate = (currentIntersectedLineSegments[i].getYIntersect(currentX) - yOrigin) / (yRes * yDir);
      yIntersects.push(jCoordinate);
    }

    yIntersects.sort(compareNumbers);
    
    const yIntersectPixelList = [];

    yIntersectPixelList.push(Math.ceil(yIntersects[0]));

    for (let i = 1; i < currentIntersectedLineSegmentsCount; i++) {
      if (Math.abs(yIntersects[i - 1] - yIntersects[i]) < delta) continue;

      yIntersectPixelList.push(Math.ceil(yIntersects[i]));
    }
    
    yIntersectData.yIntersects.push(yIntersectPixelList);
    currentX += xRes;
  }

  return yIntersectData;
}

function compareNumbers(a, b) {
  return a - b;
}

// this version takes about 400ms for a External contour - Quicksort takes the longest
function getOrderedVertices(inPlaneContours) {
  const verticesList = [];

  // looping over different disconnected segments on the same slice.
  for (let ix = 0; ix < inPlaneContours.length; ix++) {
    let extraLineSegmentRequired = false;
    const contour = inPlaneContours[ix];

    if (contour.length < 2) continue;

    // add first point to end of contour list - dont add this between disconnected segments on same slice
    // noinspection EqualityComparisonWithCoercionJS
    if (contour[0] != contour[contour.length - 2] && contour[1] != contour[contour.length - 1]) {
      extraLineSegmentRequired = true;
      contour.push(contour[0]);
      contour.push(contour[1]);
    }

    for (let i = 3; i < contour.length; i += 2) {
      // noinspection EqualityComparisonWithCoercionJS
      if (contour[i - 3] == contour[i - 1]) {
        // ignore vertical lines as y intersects don't make sense for them
        continue;
      }

      const lineSegment = new LineSegment(contour[i - 3], contour[i - 2], contour[i - 1], contour[i]);

      verticesList.push(lineSegment.P1);
      verticesList.push(lineSegment.P2);
    }

    if (extraLineSegmentRequired) {
      // remove the added vertex from the end - was just needed for the vertex list. 
      contour.pop();
      contour.pop();
    }
  }

  // quicksort AFTER putting on multiple segments onto verticesList.
  quicksort(verticesList, 0, verticesList.length - 1); // quicksort only needed if we insert it in unsorted order
  return verticesList;
}

class LineSegment {
  constructor(x1, y1, x2, y2) {
    if (x1 < x2) {
      this.P1 = new Vertex(this, x1, y1);
      this.P2 = new Vertex(this, x2, y2);
    } else {
      this.P1 = new Vertex(this, x2, y2);
      this.P2 = new Vertex(this, x1, y1);
    }
  }

  getYIntersect = x => {
    const distRatio = (x - this.P1.x) / (this.P2.x - this.P1.x);

    return this.P1.y + (distRatio * (this.P2.y - this.P1.y));
  };

  valueOf() {
    return this.P1.x;
  }

  toString() {
    return `|x1:${this.P1}=>${this.P2}|`;
  }
}

class Vertex {
  constructor(parent, x, y) {
    this.Parent = parent;
    this.x = x;
    this.y = y;
  }

  valueOf() {
    return this.x;
  }

  toString() {
    return `(${this.x},${this.y})`;
  }
}

function quicksort(list, L, R) {
  let index;

  if (list.length > 1) {
    index = qsPartition(list, L, R);
    if (L < index - 1) quicksort(list, L, index - 1);
    if (index < R) quicksort(list, index, R);
  }

  return list;
}

function qsSwap(list, L, R) {
  const temp = list[L];
  list[L] = list[R];
  list[R] = temp;
}

function qsPartition(list, L, R) {
  const pivot = list[Math.floor((R + L) / 2)];
  let i = L;
  let j = R;

  while (i <= j) {
    while (list[i] < pivot) i++;

    while (list[j] > pivot) j--;

    if (i <= j) {
      qsSwap(list, i, j);
      i++;
      j--;
    }
  }

  return i;
}