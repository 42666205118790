import toDateObject from '../toDateObject';
import toDay from './toDay';

// YYYY/{shortDate} - 2020/03/14
const toDate = date => {
  const dateObj = toDateObject(date);

  return `${dateObj.getFullYear()}/${toDay(dateObj)}`;
};

export default toDate;