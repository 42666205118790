import PassState from '../../../../PassState';
import getWorstPassState from './resolveData/getWorstPassState';
import countPassStates from './countPassStates';

const updateErrorCounts = patient => ({
  ...patient,
  courses: patient.courses.map(course => ({
    ...course,
    ...buildCourseErrorCounts(course, patient.verifications),
    plans: course.plans.map(plan => ({
      ...plan,
      ...buildPlanErrorCounts(plan, patient.verifications)
    }))
  }))
});

const buildCourseErrorCounts = (course, verifications) => {
  const plans = Object.keys(countPassStates(course.plans, verifications, ['initialTreatmentChecks',
    'referencePointDoses', 'treatments', 'imagings', 'referencePointDoses', 'totalErrors', 'fields', 'errorCounts',
    'passStates', 'imagingAngleLines', 'imagingCouchLines', 'treatmentAngleLines', 'treatmentCouchLines'], {},
  { includeVerify: true })
  );

  const prescriptions = {
    passState: getWorstPassState([
      ...Object.keys(countPassStates(course.prescriptions, verifications, ['plans'], {}, { includeVerify: true })),
      ...plans
    ])
  };

  const documentation = buildWorstPassState(course.documentations, verifications, [], { includeVerify: true });
  const eotChecks = buildWorstPassState(course.endOfTreatmentChecks, verifications, [],
    { key: course.key, parentField: 'endOfTreatmentChecks', includeVerify: true });
  const initial = buildWorstPassStateFromArray(course.plans, verifications, plan => plan.initialTreatmentChecks, [],
    { key: course.key, parentField: 'initialTreatmentChecks', includeVerify: true });

  return {
    totalErrors: {
      passState: getWorstPassState([prescriptions, documentation, eotChecks, initial].map(o => o.passState))
    },
    errorCounts: { prescriptions, documentation, eotChecks, initial }
  };
};

const buildPlanErrorCounts = (plan, verifications) => {
  const details = buildWorstPassStateFromArray(plan.treatments, verifications, t => t, ['fields'],
    { includeVerify: true });
  const fields = {
    passState: getWorstPassState(plan.treatments.flatMap(treatment =>
      treatment.fields.flatMap(field =>
        Object.keys(countPassStates(field, verifications, ['fieldsMatchPlan'], {}, { includeVerify: true })))
    ))
  };

  const imaging = buildWorstPassState(plan.imagings, verifications, [], { includeVerify: true });
  const refpts = buildWorstPassState(plan.referencePointDoses, verifications, [],
    { key: plan.key, parentField: 'referencePointDoses', includeVerify: true });

  return {
    totalErrors: { passState: getWorstPassState([details, fields, imaging, refpts].map(o => o.passState)) },
    errorCounts: { details, fields, imaging, refpts }
  };
};

const buildWorstPassState = (obj, verifications, excludes = [], { key, parentField, includeVerify } = {}) => ({
  passState: passIfBlank(getWorstPassState(
    Object.keys(countPassStates(obj, verifications, excludes, {}, { key, parentField, includeVerify }))
  ))
});

const buildWorstPassStateFromArray = (obj, verifications, getter, excludes = [],
    { key, parentField, includeVerify } = {}) => ({
  passState: passIfBlank(getWorstPassState(obj.flatMap(child =>
    Object.keys(countPassStates(getter(child), verifications, excludes, {},
      { key: key ?? child.key, parentField, includeVerify }))
  )))
});

const passIfBlank = passState =>
  ([PassState.NA, PassState.NO_RX].includes(passState) || !passState)
    ? PassState.PASS
    : passState;

export default updateErrorCounts;