const getOrientationLabels2D = (axial, top, sourceAngle) => {
  const { left, right } = getLeftRightLabels(axial, sourceAngle);
  return { top, left, right };
};

function getLeftRightLabels(axial, sourceAngle) {
  const { top, left, right } = axial.labels;
  const bottom = getBottomLabel(top);
  sourceAngle = Math.round(sourceAngle) === 360.0 ? 0.0 : Math.round(sourceAngle); // 360 is 0;

  if (sourceAngle >= 0.0 && sourceAngle < 45.0) {
    // console.log('is between 0 and 45');
    return { left, right };
  }

  if (sourceAngle === 45.0) {
    // console.log('is at 45');
    // noinspection JSSuspiciousNameCombination
    return { left, right: bottom };
  }

  if (sourceAngle > 45.0 && sourceAngle < 135.0) {
    // console.log('is between 45 and 135');
    // noinspection JSSuspiciousNameCombination
    return { left: top, right: bottom };
  }

  if (sourceAngle === 135.0) {
    // console.log('is 135');
    // noinspection JSSuspiciousNameCombination
    return { left: top, right: left };
  }

  if (sourceAngle > 135.0 && sourceAngle < 225.0) {
    // console.log('is between 135 and 225');
    return { left: right, right: left };
  }

  if (sourceAngle === 225.0) {
    // console.log('is 225');
    // noinspection JSSuspiciousNameCombination
    return { left: right, right: top };
  }

  if (sourceAngle > 225.0 && sourceAngle < 315.0) {
    // console.log('is between 225 and 315');
    // noinspection JSSuspiciousNameCombination
    return { left: bottom, right: top };
  }

  if (sourceAngle === 315.0) {
    // console.log('is 315');
    // noinspection JSSuspiciousNameCombination
    return { left: bottom, right };
  }

  if (sourceAngle > 315.0 && sourceAngle < 360.0) {
    // console.log('is between 315 and 360');
    return { left, right };
  }
}

function getBottomLabel(top) {
  switch (top) {
    case 'L':
      return 'R';
    case 'R':
      return 'L';
    case 'P':
      return 'A';
    case 'A':
    default: 
      return 'P';
  }
}

export default getOrientationLabels2D; 
