import getPopoutOpenedKey from './getPopoutOpenedKey.js';
import getIndexedDBValue from './getIndexedDBValue.js';

const getPopoutOpened = async name => {
  const asString = await getIndexedDBValue(getPopoutOpenedKey(name));

  if (!asString) return null;

  return new Date(Number.parseInt(asString));
};

export default getPopoutOpened;