import macro from '../../macros.js';

function vtkRenderPass(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkRenderPass');

  publicAPI.getOperation = function () {
    return model.currentOperation;
  };

  publicAPI.setCurrentOperation = function (val) {
    model.currentOperation = val;
    model.currentTraverseOperation = "traverse".concat(macro.capitalize(model.currentOperation));
  };

  publicAPI.getTraverseOperation = function () {
    return model.currentTraverseOperation;
  }; // by default this class will traverse all of its
  // preDelegateOperations, then call its delegate render passes
  // the traverse all of its postDelegateOperations
  // any of those three arrays can be empty


  publicAPI.traverse = function (viewNode) {
    var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    if (model.deleted) {
      return;
    } // we just render our delegates in order


    model.currentParent = parent;
    model.preDelegateOperations.forEach(function (val) {
      publicAPI.setCurrentOperation(val);
      viewNode.traverse(publicAPI);
    });
    model.delegates.forEach(function (val) {
      val.traverse(viewNode, publicAPI);
    });
    model.postDelegateOperations.forEach(function (val) {
      publicAPI.setCurrentOperation(val);
      viewNode.traverse(publicAPI);
    });
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  delegates: [],
  currentOperation: null,
  preDelegateOperations: [],
  postDelegateOperations: [],
  currentParent: null
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Build VTK API

  macro.obj(publicAPI, model);
  macro.get(publicAPI, model, ['currentOperation']);
  macro.setGet(publicAPI, model, ['delegates', 'currentParent', 'preDelegateOperations', 'postDelegateOperations']); // Object methods

  vtkRenderPass(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkRenderPass'); // ----------------------------------------------------------------------------

var vtkRenderPass$1 = {
  newInstance: newInstance,
  extend: extend
};

export { vtkRenderPass$1 as default, extend, newInstance };
