/**
 * Rounds the number to the given precision. If precision is not a number, the value will be returned unrounded.
 * Precision can be a negative number as well.
 *
 * Note: If the given number has fewer digits than the precision calls for, it will NOT pad them as a number is
 *  returned and not a string.
 *
 * @param {number} value
 * @param {number} precision=
 * @return {number} The rounded number.
 */
const toPrecision = (value, precision) =>
  typeof precision !== 'number'
    ? value
    : Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);

export default toPrecision;