import distinct from './distinct';
import mapByKey from './mapByKey';
import removeValue from './removeValue';
import removeValueMatching from './removeValueMatching';
import replaceValue from './replaceValue';
import splice from './splice';

export {
  distinct,
  mapByKey,
  removeValue,
  removeValueMatching,
  replaceValue,
  splice
};