import PassState from '../../../../../PassState';
import {
  SHOW_FOR_EMPTY_VALUE,
  SHOW_NON_EMPTY_VALUE,
  SHOW_FOR_NO_PASS_STATE
} from '../../../../common/pass-state-table';
import getInactive from './getInactive';
import getPassState from './getPassState';
import getValue from './getValue';
import hasValue from './hasValue';
import isEmpty from './isEmpty';
import h from '../../../../../utils/h';
import isActive from './isActive';

export {
  getInactive,
  getPassState,
  getValue,
  hasValue,
  isEmpty,
  isActive
};

const resolveData = ({
  verifyKey, verifyField, unverifiable, verifications = [], verifyEach, verifyIndexed, verifyIndex,
  data, field, unit, showIcon = true, showValue = false, htmlClass = undefined, defaultPrecision = 1
}) => {
  try {
    let value;
    let rawValue;
    let passCheck;
    let passState;
    let inactive = false;
    let isPassStateValue = false;
    let tooltip;
    let showTooltipIcon;
    let overrideApprover;

    if (Array.isArray(field)) {
      [field, passCheck] = field;
    }

    if (data && Array.isArray(data[field])) {
      const items = data[field].map((singleField, index) => {
        const itemVerifyKey = singleField.verifyKey || (Array.isArray(verifyKey) ? verifyKey[index] : verifyKey);
        const itemVerifyField = singleField.verifyField
          || (Array.isArray(verifyField) ? verifyField[index] : verifyField);
        const itemUnverifiable = singleField.unverifiable
          || (Array.isArray(unverifiable) ? unverifiable[index] : unverifiable);
        const itemVerifyIndex = singleField.verifyIndex || index;

        return resolveData({
          data: { data: singleField },
          field: passCheck ? ['data', passCheck] : 'data',
          unit,
          showIcon,
          showValue,
          defaultPrecision,
          verifyKey: itemVerifyKey,
          verifyField: itemVerifyField,
          unverifiable: itemUnverifiable,
          verifications,
          verifyIndexed,
          verifyEach,
          verifyIndex: itemVerifyIndex
        });
      });

      return {
        isPassStateValue: items.some(i => i.isPassStateValue),
        class: htmlClass,
        isArray: true,
        unverifiable,
        verified: !verifyEach && verifications.find(v => v.key === verifyKey && v.field === verifyField),
        verifyKey,
        verifyField,
        verifyEach,
        verifyIndexed,
        inactive: Boolean(items.length
          && items.filter(i => i.isPassStateValue).length
          && items.filter(i => i.isPassStateValue).every(i => i.inactive)),
        items,
        rawValue: !verifyEach && (data?.[field]?.value || data?.[field])
      };
    }

    if (typeof field === 'function') {
      const result = field(data);

      return resolveData({
        data: { result }, field: 'result', unit, showIcon, showValue, htmlClass, defaultPrecision,
        verifyKey, verifyField, unverifiable, verifications, verifyEach, verifyIndexed, verifyIndex
      });
    } else if (data && data[field] && typeof data[field] === 'object') {
      ({ value, isInactive: inactive, rawValue } = data[field]);
      isPassStateValue = true;
      tooltip = data[field]?.tooltipText;
      showTooltipIcon = data[field]?.showTooltipIcon;
      overrideApprover = data[field]?.overrideApprover;

      if (!passCheck && Object.prototype.hasOwnProperty.call(data[field], 'passState')) {
        ({ passState } = data[field]);
      }
    } else {
      value = data ? data[field] : field;
    }

    if (typeof passCheck === 'function') {
      passState = passCheck(data);
    } else if (passCheck) {
      passState = data[passCheck] && data[passCheck].passState;
    }

    const verified = verifications.find(v =>
      v.key === verifyKey
      && v.field === verifyField
      && (!verifyIndexed || v.index?.toString() === verifyIndex?.toString())
    );

    if (verified) {
      passState = PassState.VERIFIED;
    }

    rawValue = rawValue ?? value;
    value = value === 0 ? value.toFixed(defaultPrecision) : value;

    const finalValue = (value !== '' && value !== null && value !== undefined)
      ? `${value}${unit ? ` ${unit}` : ''}`
      : (
        showValue === SHOW_NON_EMPTY_VALUE && !([].concat(showIcon).includes(SHOW_FOR_EMPTY_VALUE) && !passState)
          ? ''
          : (showValue === SHOW_FOR_NO_PASS_STATE && passState ? '' : '-')
      );

    return {
      isPassStateValue,
      passState,
      inactive,
      tooltip,
      unverifiable,
      verified,
      verifyKey,
      verifyField,
      verifyIndexed,
      verifyIndex,
      showInfoIcon: Boolean(showTooltipIcon && tooltip),
      class: htmlClass,
      showIcon: showIcon,
      showValue: Boolean(showValue || !showIcon || (passState !== PassState.PASS && value)),
      value: `${finalValue}${h(passState?.toLowerCase() === 'override' && overrideApprover, ` (${overrideApprover})`)}`,
      rawValue // for passing to verification
    };
  } catch (err) {
    console.error(err, data, field, unit, showIcon, showValue);
    throw err;
  }
};

export default resolveData;