import { ORIENTATIONS } from '../constants.js';

export const getOrientations = orientation => {
  switch (orientation) {
    case 'HFP':
      return { axial: ORIENTATIONS.AXIAL_HFP, sagittal: ORIENTATIONS.SAGITTAL_HFP, coronal: ORIENTATIONS.CORONAL_HFP };
    case 'FFS':
      return { axial: ORIENTATIONS.AXIAL_FFS, sagittal: ORIENTATIONS.SAGITTAL_FFS, coronal: ORIENTATIONS.CORONAL_FFS };
    case 'FFP':
      return { axial: ORIENTATIONS.AXIAL_FFP, sagittal: ORIENTATIONS.SAGITTAL_FFP, coronal: ORIENTATIONS.CORONAL_FFP };
    case 'HFS':
    default:
      return { axial: ORIENTATIONS.AXIAL_HFS, sagittal: ORIENTATIONS.SAGITTAL_HFS, coronal: ORIENTATIONS.CORONAL_HFS };
  }
};

export const applyCoordinateSystem = (axial, sagittal, coronal, coordinateSystem, usePatientDicomCoordinates) => {
  for (let index = 0; index < coordinateSystem.length; index++) {
    const value = coordinateSystem[index];

    // noinspection EqualityComparisonWithCoercionJS
    if (value != 0) {
      applyLabelToOrientation(index, axial, sagittal, coronal);
      if (!usePatientDicomCoordinates) {
        applySignChangeToOrientation(index, value, axial, sagittal, coronal, usePatientDicomCoordinates);
      }
    }
  }
};

function applySignChangeToOrientation(index, value, axial, sagittal, coronal) {
  switch (index) {
    case 0:
    case 3:
    case 6:
      // flip left to right
      if (value * sagittal.viewPlaneNormal[0] === 1) {
        sagittal.dimension.flip = true;
      }

      return;
    case 1:
    case 4:
    case 7:
      // flip post to ant
      if (value * coronal.viewPlaneNormal[1] === -1) {
        coronal.dimension.flip = true;
      }

      return;
    case 2:
    case 5:
    case 8:
      // flip sup to inf
      if (value * axial.viewPlaneNormal[2] === -1) {
        axial.dimension.flip = true;
      }

      return;
  }
}

function applyLabelToOrientation(index, axial, sagittal, coronal) {
  switch (index) {
    case 0:
      sagittal.dimension.label = 'X';
      return;
    case 1:
      coronal.dimension.label = 'X';
      return;
    case 2:
      axial.dimension.label = 'X';
      return;
    case 3:
      sagittal.dimension.label = 'Y';
      return;
    case 4:
      coronal.dimension.label = 'Y';
      return;
    case 5:
      axial.dimension.label = 'Y';
      return;
    case 6:
      sagittal.dimension.label = 'Z';
      return;
    case 7:
      coronal.dimension.label = 'Z';
      return;
    case 8:
      axial.dimension.label = 'Z';
      return;
  }
}
