import { html, css, LitElement } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import clamp from '../../utils/math/clamp.js';
import h from '../../utils/h.js';
import eatEvent from '../../utils/event/eatEvent.js';

/*
 * The value given by the range slider is always between 0 and 1.
 * The minValue and maxValue indicate where the handles are currently, and should
 *   be given as a value between 0 and 1.
 */
class RangeSlider extends LitElement {
  static styles = css`
    :host {
      --width: 8px;
      --handle-height: 8px;
      --handle-width: 12px;
      --range-color: #FFF;
      --label-color: #FFF;

      margin-top: 20px;
      margin-bottom: 20px;
      margin-right: 20px;
      
      user-select: none;
      
      font-weight: 600;
    }
    
    div#container {
      width: var(--width);
      height: 100%;
      background: var(--neutral-6);
    }
    
    handle, bar {
      position: absolute;
      display: block;
      background: var(--primary-color);
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
    
    handle:hover, bar:hover {
      background: var(--primary-2);
    }
    
    bar {
      width: var(--width);
    }
    
    handle {
      width: var(--handle-width);
      height: var(--handle-height);
    }
    
    /* Shift the upper handle up and the lower handle down so they can sit right next to one another without overlap */
    handle#upper {
      transform: translate(-50%, calc(-100% + 1px));
    }
    
    handle#lower {
      transform: translate(-50%, -1px);
    }
    
    span {
      position: absolute;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      color: var(--range-color);
      font-size: 12px;
      white-space: nowrap;
    }
    
    span[upper] {
      top: -22px;
    }
    
    span[lower] {
      bottom: -22px;
    }
    
    span[middle] {
      top: 50%;
      text-align: right;
      right: -10px;
      left: unset;
      transform: translateY(-50%);
      color: var(--label-color);
    }
  `;

  static properties = {
    min: { type: Number },
    max: { type: Number },
    minLabel: { type: String },
    maxLabel: { type: String }
  }

  constructor() {
    super();

    this.min = .25;
    this.max = .75;
  }

  render() {
    const { min, max, minLabel, maxLabel } = this;

    return html`
      ${h(maxLabel, html`<span upper>${maxLabel}</span>`)}
      ${h(minLabel, html`<span lower>${minLabel}</span>`)}
      <div id="container" @click=${eatEvent}>
        <bar 
          style=${styleMap({ top: `${(1 - max) * 100}%`, height: `${(max - min) * 100}%` })}
          @mousedown=${this.handleBarMouseDown}
        ></bar>
        <handle 
          id="upper" 
          style=${styleMap({ top: `${(1 - max) * 100}%` })}
          @mousedown=${this.handleHandleMouseDown}
        ></handle>
        <handle 
          id="lower" 
          style=${styleMap({ top: `${(1 - min) * 100}%` })}
          @mousedown=${this.handleHandleMouseDown}
        ></handle>
      </div>
    `;
  }

  handleBarMouseDown(event) {
    document.addEventListener('mousemove', this.handleBarMouseMove);
    document.addEventListener('mouseup', this.handleBarStop);
    document.addEventListener('blur', this.handleBarStop);
  }

  handleBarStop = event => {
    document.removeEventListener('mousemove', this.handleBarMouseMove);
    document.removeEventListener('mouseup', this.handleBarStop);
    document.removeEventListener('blur', this.handleBarStop);
  }

  handleHandleMouseDown(event) {
    this.currentHandle = event.currentTarget;

    document.addEventListener('mousemove', this.handleHandleMouseMove);
    document.addEventListener('mouseup', this.handleHandleStop);
    document.addEventListener('blur', this.handleHandleStop);
  }

  handleHandleStop = event => {
    document.removeEventListener('mousemove', this.handleHandleMouseMove);
    document.removeEventListener('mouseup', this.handleHandleMouseMove);
    document.removeEventListener('blur', this.handleHandleStop);
  };

  handleHandleMouseMove = event => {
    event.stopPropagation();

    const { currentHandle } = this;

    const { movementY } = event;
    const { offsetTop, id } = currentHandle;
    const height = currentHandle.parentNode.offsetHeight;

    const newValue = clamp((offsetTop + movementY) / height, 0, 1);

    if (id === 'upper') {
      this.max = clamp(1 - newValue, this.min, 1);
    } else {
      this.min = clamp(1 - newValue, 0, this.max);
    }

    this.dispatchChange();
  };

  handleBarMouseMove = event => {
    event.stopPropagation();

    const bar = this.shadowRoot.querySelector('bar');
    const { movementY } = event;
    const { offsetTop } = bar;
    const height = bar.parentNode.offsetHeight;

    const newMax = 1 - clamp((offsetTop + movementY) / height, 0, 1 - (this.max - this.min));

    this.min += newMax - this.max;
    this.max = newMax;

    this.dispatchChange();
  }

  dispatchChange() {
    const { min, max } = this;

    this.dispatchEvent(new CustomEvent('change', { detail: { min, max } }));
  }
}

customElements.define('range-slider', RangeSlider);