import normalize from './normalize';

const calculate = (lines, pointIndex, steps = 10, subSteps = 5) => {
  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;

  lines.forEach(({ points }) => {
    points.forEach(point => {
      min = Math.min(min, point[pointIndex]);
      max = Math.max(max, point[pointIndex]);
    });
  });

  return normalize({ axis: {}, min, max, steps, subSteps });
};

export default calculate;