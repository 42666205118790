import { html, css, LitElement } from 'lit';
import h from '../../../utils/h';
import commonStyles from '../../styles-common';
import getDocumentUrl from '../../../utils/getDocumentUrl';
import './tiff-viewer';
import openInPopout from '../../../utils/openInPopout';
import getDocumentViewUrl from '../../../utils/getDocumentViewUrl';
import { toDateTime } from '../../../utils/date/format';

const knownTypes = ['pdf', 'word', 'image', 'tiff'];

class DocumentViewer extends LitElement {
  static styles = [
    commonStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 100%;
        overflow: hidden;
      }
      
      #header {
        background: rgb(50, 54, 57);
        display: grid;
        grid-gap: 8px;
        padding: 20px 0 8px;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        overflow: auto;
      }
      
      #header .row {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
      
      :host(:not([no-buttons])) #header {
        padding-top: 16px;
      }
      
      #header ul {
        padding: 0;
        list-style: none;
        font-size: 0.8rem;
        padding-right: 24px;
        margin: 20px 0 8px;
      }
      
      #header li {
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        justify-items: center;
        gap: 4px;
        margin-bottom: 4px;
      }
      
      #header span:first-child {
        font-weight: bold;
        flex: 0 0 108px;
        display: inline-block;
        text-align: right;
        color: var(--light-color);
      }
      
      #header span:first-child:after {
        content: ": ";
      }
      
      #header span:nth-child(2) {
        flex: 1;
        border: 1px solid #333;
        background: var(--light-color);
        padding: 2px 4px;
      }
      
      #header span:nth-child(2) {
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;
      }
  
      #header span::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
      
      #header [buttons] {
        white-space: nowrap;
        position: absolute;
        top: 0;
        right: 10px;
      }
      
      #header button {
        color: #FFF;
        background: none;
        border: 0;
        line-height: 2.25;
        font-size: 1rem;
        padding: 0;
        margin: 0 16px 0 0;
        outline: 0;
        cursor: pointer;
        z-index: 10000;
      }
      
      #header button:hover {
        color: #CCC;
      }
      
      #header button:after {
        font-family: 'Font Awesome 5 Pro';
        font-weight: bold;
        position: relative;
      }
      
      #header button[close]:after {
        content: '\uf00d';
        top: 2px;
      }
      
      #header button[popout]:after {
        content: '\uf08e';
        top: 1px;
      }
  
      pdf-view,
      image-view,
      unknown-view,
      tiff-viewer {
        flex: 1;
        position: relative;
        display: block;
      }
      
      pdf-view div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      
      pdf-view iframe {
        width: 100%;
        height: 100%;
      }
      
      image-view {
        overflow: auto;
      }
      
      image-view img {
        cursor: pointer;
      }
      
      image-view img:not([zoomed]) {
        max-width: 100%;
      }
      
      unknown-view div {
        margin: 16px auto;
        padding: 16px;
        background: #FFF;
        text-align: center;
        border-radius: 4px;
        max-width: 400px;
      }
      
      unknown-view a {
        display: inline-block;
        background: var(--primary-3);
        color: #FFF;
        padding: 8px 16px;
        border: 0;
        margin: 32px auto 0;
        cursor: pointer;
        text-decoration: none;
      }
      
      unknown-view a:hover {
        background: var(--primary-2);
      }
      
      .nowrap {
        white-space: nowrap;
      }
      
      spinner-wrapper {
        position: relative;
        flex: 1;
      }
      
      spinner {
        animation: spin 2s steps(8, end) infinite;
        position: absolute;
        top: calc(50% - 32.5px);
        left: calc(50% - 32.5px);
        width: 65px;
        height: 65px;
      }
      
      spinner:after {
        font-family: 'Font Awesome 5 Pro';
        content: '\uf110';
        font-size: 60px;
        line-height: 60px;
        position: absolute;
        top: calc(50% + 2px);
        left: calc(50% + 0px);
        transform: translate(-50%, -50%);
        width: 60px;
        height: 60px;
      }
  
      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    `
  ].flat();

  static properties = {
    id: { type: String },
    type: { type: String },
    data: { type: Object },
    dataUrl: { type: String },
    meta: { type: String },
    imageZoomed: { type: Boolean },
    noButtons: { type: Boolean, attribute: 'no-buttons' },
    loaded: { type: Boolean }
  };

  get url() {
    const { id } = this;

    return getDocumentUrl(id);
  }

  get viewUrl() {
    const { id, type } = this;
    
    return getDocumentViewUrl(id, type);
  }

  async updated(changed) {
    if (['id', 'type'].some(v => changed.has(v)) && this.id && this.type) {
      const oldDataUrl = this.dataUrl;

      this.data = undefined;
      this.dataUrl = undefined;
      this.meta = undefined;
      this.loaded = false;

      await this.load();

      // Wait until the new one is loaded to revoke the old.
      if (oldDataUrl) {
        URL.revokeObjectURL(oldDataUrl);
      }
    }
  }

  render() {
    const { id, type, dataUrl, meta, handleDocumentClose, handleDocumentPopout, noButtons, loaded } = this;
    const { authoredByUserId, supervisedByUserId, enteredByUserId, dateEntered, signedByUserId, dateSigned,
      approvedByUserId, dateApproved, dateOfService, documentType, accountNumber, templateName } = meta ?? {};

    if (!type || !id) return;

    return html`
      <div id="header">
        <div class="row">
          <ul>
            <li>
              <span>Authored By</span>
              <span>${authoredByUserId || html`&nbsp;`}</span>
            </li>
            <li>
              <span>Supervised By</span>
              <span>${supervisedByUserId || html`&nbsp;`}</span>
            </li>
            <li>
              <span>Entered By</span>
              <span>${enteredByUserId || html`&nbsp;`}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Date Entered</span>
              <span>${h(dateEntered, () => toDateTime(dateEntered), html`&nbsp;`)}</span>
            </li>
            <li>
              <span>Signed By</span>
              <span>${signedByUserId || html`&nbsp;`}</span>
            </li>
            <li>
              <span>Date Signed</span>
              <span>${h(dateSigned, () => toDateTime(dateSigned), html`&nbsp;`)}</span>
            </li>
          </ul>
        </div>
        <div class="row">
          <ul>
            <li>
              <span>Approved By</span>
              <span>${approvedByUserId || html`&nbsp;`}</span>
            </li>
            <li>
              <span>Date Approved</span>
              <span>${h(dateApproved, () => toDateTime(dateApproved), html`&nbsp;`)}</span>
            </li>
            <li>
              <span>Date of Service</span>
              <span>${h(dateOfService, () => toDateTime(dateOfService), html`&nbsp;`)}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Document Type</span>
              <span>${documentType || html`&nbsp;`}</span>
            </li>
            <li>
              <span>Account Number</span>
              <span>${accountNumber || html`&nbsp;`}</span>
            </li>
            <li>
              <span>Template Name</span>
              <span>${templateName || html`&nbsp;`}</span>
            </li>
          </ul>
        </div>
        ${h(!noButtons, html`
          <div buttons>
            <button popout @click=${handleDocumentPopout}></button>
            <button close @click=${handleDocumentClose}></button>
          </div>
        `)}
      </div>
      ${h(!loaded, () => html`
        <spinner-wrapper>
          <spinner></spinner>
        </spinner-wrapper>
      `)}
      ${h(loaded && dataUrl, () => html`
        ${h(type === 'pdf' || type === 'word', () => this.renderPdf())}
        ${h(type === 'image', () => this.renderImage())}
        ${h(type === 'tiff', () => this.renderTiff())}
        ${h(!knownTypes.includes(type), () => this.renderUnknown())}
      `)}
    `;
  }

  renderPdf = () => {
    const { dataUrl } = this;

    return html`
      <pdf-view>
        <div>
          <iframe src=${dataUrl}></iframe>
        </div>
      </pdf-view>
    `;
  };

  renderImage = () => {
    const { dataUrl, imageZoomed, handleImageZoom } = this;

    return html`
      <image-view>
        <img src=${dataUrl} @click=${handleImageZoom} ?zoomed=${imageZoomed} />
      </image-view>
    `;
  };

  renderTiff = () => {
    const { data } = this;

    return html`
      <tiff-viewer .data=${data}></tiff-viewer>
    `;
  }

  renderUnknown = () => {
    const { url, id } = this;

    return html`
      <unknown-view>
        <div>
          <h1>Unknown file type</h1>
          <p>
            The viewer doesn't support this type of file. You can try to download the file and view it locally.
          </p>
          <a href=${url} download=${id}>Download File</a>
        </div>
      </unknown-view>
    `;
  };

  handleDocumentClose() {
    this.dispatchClose();
  }

  handleDocumentPopout() {
    const { viewUrl } = this;

    openInPopout(viewUrl);

    this.dispatchClose();
  }

  handleImageZoom() {
    this.imageZoomed = !this.imageZoomed;
  }

  dispatchClose() {
    this.dispatchEvent(new CustomEvent('document-close', { bubbles: true, composed: true }));
  }

  async load() {
    const { url } = this;

    const response = await fetch(url);
    const blob = await response.blob();
    this.data = blob;
    this.dataUrl = URL.createObjectURL(blob);
    this.meta = JSON.parse(response.headers.get('x-document-meta') || '{}');
    this.loaded = true;
  }
}

customElements.define('document-viewer', DocumentViewer);