import { html, LitElement } from 'lit';
import ResizeObserver from 'resize-observer-polyfill';
import applyAttributes from '../../../directives/ApplyAttributesDirective';
import tableStyles from './styles';
import h from '../../../utils/h';
import renderChart, { updateCharts } from './renderChart';
import { ifDefined } from 'lit/directives/if-defined';
import PassState from '../../../PassState';
import '../verify-check-box';
import verifiedByMessageGetter from '../../../utils/verifiedByMessageGetter';
import splitHtmlLines from '../../../utils/html/splitHtmlLines.js';

export const INFO_ICON_TAG = '%INFO-ICON%';

const unverifiablePassStates = [PassState.PASS, PassState.NA];

const tags = {
  [INFO_ICON_TAG]: html`<span class="info-icon"></span>`
};

// Split string on each tag, keeping the tag, then replace each tag with its value.
// End up with an array of of string and html elements
const resolveTags = valueString =>
  valueString.split(/(%[^%]+%)/g)
    .map(p => tags[p] || p);

const getRowProperties = row => Object.entries(row)
  .filter(([key]) => isNaN(parseInt(key)))
  .reduce((result, [key, value]) => Object.assign(result, { [key]: value }), {});

const groupByCollapsible = (acc, row) => {
  const props = getRowProperties(row);

  if (props['collapse-header'] || !acc.length) {
    acc.push([]);
  }

  acc[acc.length - 1].push(row);

  return acc;
};

/*
  data must be an even array

  If you have cells which span multiple rows or columns, then the cell which they are overriding
  should have a colspan or rowspan of 0.

  Known unhandled issues (won't fix unless needed):
  - If a colspan or rowspan crosses a frozen row, weird things may happen
  - Column groups are based on the first row colspans, jagged colspans will cause weird things
  - If chart is in the frozen space, weird things may happen
  - If chart startColumn is before a frozen space, weird things may happen
 */
const dissectTable = table => {
  const rows = Array.from(table.querySelectorAll('tr'));
  const cells = rows.map(row => Array.from(row.querySelectorAll('td, th')));

  return { table, rows, cells };
};

// @TODO Move to utils
const camelCaseToHyphens = str =>
  str.replace(/([A-Z])/g, v => `-${v.toLowerCase()}`);

// @TODO Move to utils
const toTitleCase = str =>
  `${str.substr(0, 1).toUpperCase()}${str.substr(1)}`;

// @TODO Move to utils, update to my code style
const getScrollParent = node => {
  const regex = /(auto|scroll)/;
  const parents = (_node, ps) => {
    if (!_node || (!_node.parentNode && !_node.host)) return ps;

    return parents(_node.host || _node.parentNode, _node instanceof ShadowRoot ? ps : ps.concat([_node]));
  };

  const style = (_node, prop) => getComputedStyle(_node, null).getPropertyValue(prop);
  const overflow = _node => style(_node, 'overflow') + style(_node, 'overflow-y') + style(_node, 'overflow-x');
  const scroll = _node => regex.test(overflow(_node));

  /* eslint-disable consistent-return */
  const scrollParent = _node => {
    if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
      return;
    }

    const ps = parents(_node.host || _node.parentNode, []);

    for (let i = 0; i < ps.length; i += 1) {
      if (scroll(ps[i])) {
        return ps[i];
      }
    }

    return document.scrollingElement || document.documentElement;
  };

  return scrollParent(node);
  /* eslint-enable consistent-return */
};

export default class RadTable extends LitElement {
  static styles = tableStyles;

  static properties = {
    headerRows: { type: Number },
    headerColumns: { type: Number },
    freezeRows: { type: Number },
    freezeColumns: { type: Number },
    data: { type: Array },
    even: { type: Boolean },
    collapsedBodies: { type: Object }
  };

  static cellDefaults = {
    class: '',
    value: '',
    colspan: 1,
    rowspan: 1
  };

  get scrollParent() {
    return getScrollParent(this);
  }

  get isVisible() {
    const bounds = this.getBoundingClientRect();
    const parentBounds = this.scrollParent.getBoundingClientRect();

    return bounds.x < parentBounds.x + parentBounds.width
      && bounds.x + bounds.width > parentBounds.x
      && bounds.y < parentBounds.y + parentBounds.height
      && bounds.y + bounds.height > parentBounds.y;
  }

  constructor() {
    super();

    const { handleResize } = this;

    Object.assign(this, {
      headerRows: 1,
      headerColumns: 1,
      freezeRows: 0,
      freezeColumns: 0
    });

    this.resizeObserver = new ResizeObserver(handleResize);
    this.resizeObserver.observe(this);
    this.collapsedBodies = {};

    this.renderCell = this.renderCell.bind(this);
  }
  
  async updated(changed) {
    const { updateDynamic, lastScrollParent, handleWheel, scrollParent } = this;

    if (lastScrollParent !== scrollParent) {
      this.resizeObserver.observe(scrollParent);
      lastScrollParent && this.resizeObserver.unobserve(lastScrollParent);
      const scrollTarget = scrollParent instanceof HTMLHtmlElement ? window : scrollParent;
      scrollTarget.addEventListener('wheel', handleWheel, { passive: false });
      lastScrollParent && lastScrollParent.removeEventListener('scroll', updateDynamic);
      scrollTarget.addEventListener('scroll', updateDynamic);

      this.lastScrollParent = scrollTarget;
    }

    if (changed.has('data')) {
      const { collapsedBodies } = this;
      const newCollapsedBodies = {};
      let index = 0;

      this.data.filter(r => r?.['collapse-header']).forEach(row => {
        const { collapsed, 'collapse-header': collapseHeader, collapseOnce } = getRowProperties(row);

        if (collapseHeader) {
          newCollapsedBodies[index] = collapsed !== undefined && !collapseOnce
            ? collapsed
            : (collapsedBodies[index] === undefined ? collapsed : collapsedBodies[index]);
          index++;
        }
      });

      this.collapsedBodies = newCollapsedBodies;
    }

    updateDynamic();
  }

  render() {
    const { data, freezeRows, freezeColumns, renderTable, renderStickyRows, renderStickyColumns,
      renderStickyCorner } = this;

    return html`
      <div wrapper class=${[
        data?.[0].length <= 7 ? `fixed-${data[0].length-1}` : 'fluid',
        freezeRows > 0 ? 'freeze-rows' : '',
        `freeze-columns-${freezeColumns}`
      ].join(' ')}>
        ${renderTable({ main: true })}
        ${renderStickyColumns()}
        ${renderStickyRows()}
        ${renderStickyCorner()}
      </div>
    `;
  }

  renderTable = (attributes, rowLimit = -1, columnLimit = -1) => {
    const { data, headerRows, collapsedBodies, renderRow, mapKeysToAttributes } = this;

    return html`
      <table>
        ${applyAttributes(mapKeysToAttributes(attributes))}
        <colgroup>
          ${data[0].map(({ colspan = 1 }) => html`
            <col span=${colspan}>
          `)}
        </colgroup>
        ${h(headerRows, html`
          <thead>
            ${data.slice(0, Math.min(headerRows, rowLimit === -1 ? data.length : rowLimit))
              .map((row, index, arr) => renderRow(row, index, arr, columnLimit))
            }
          </thead>
        `)}
        ${h(rowLimit === -1 || rowLimit > headerRows, 
          data.slice(headerRows, headerRows + (rowLimit === -1 ? data.length : rowLimit - headerRows))
            .reduce(groupByCollapsible, [])
            .map((group, index) => {
              const { 'collapse-header': collapsible } = getRowProperties(group[0]);
              
              if (collapsible) {
                return html`
                  <tbody ?collapsed-header=${collapsedBodies[index]}>
                    ${renderRow(group[0], headerRows, [group[0]], columnLimit)}
                  </tbody>
                  <tbody ?collapsed=${collapsedBodies[index]} .index=${index}>
                    ${group.slice(1).map((row, index, arr) => renderRow(row, index + headerRows, arr, columnLimit))}
                  </tbody>
                `;
              } else {
                return html`
                  <tbody>
                    ${group.map((row, index, arr) => renderRow(row, index + headerRows, arr, columnLimit))}
                  </tbody>
                `;
              }
            })
        )}
      </table>
    `;
  }

  renderRow = (row, rowNumber, _, columnLimit = -1) => {
    const { headerRows, headerColumns, handleToggleCollapse } = this;

    if (row.chart) {
      return renderChart(row, columnLimit);
    }

    const rowProperties = getRowProperties(row);

    return html`
      <tr 
        ?no-column-header=${!headerColumns}
        ?collapse-header=${rowProperties['collapse-header']}
        @click=${rowProperties['collapse-header'] && handleToggleCollapse}
      >
        ${applyAttributes(rowProperties)}
        ${row.slice(0, columnLimit === -1 ? undefined : columnLimit)
          .map((cell, columnNumber) => this.renderCell(
            cell,
            headerRows > rowNumber || headerColumns > columnNumber || rowProperties['flex-header'],
            row[columnNumber - 1] && row[columnNumber - 1].rowspan === 0 
          ))
        }
      </tr>
    `;
  };

  renderCell = (cell, isHeader, isSpanned) => {
    if (cell?.contents) {
      return isHeader
        ? html`<th ?active=${cell?.active}>${cell.contents}</th>`
        : html`<td ?active=${cell?.active}>${cell.contents}</td>`;
    }

    const items = cell?.isArray
      ? cell.items.map(item => this.normalizeCell(item))
      : [this.normalizeCell(cell)];

    const [{ colspan, rowspan } = {}] = items || [];
    const cellAttributes = {};

    if (colspan === 0 || rowspan === 0) {
      return;
    } else if (colspan > 1 || rowspan > 1) {
      Object.assign(
        cellAttributes,
        rowspan > 1 ? { rowspan } : {},
        colspan > 1 ? { colspan } : {}
      );
    }

    // verifiable controls if the box shows up (false means it shows up)
    // verified means the box is checked (or false if not)
    const verifiable = cell && !cell.unverifiable
      && ((!cell.isArray && cell.passState && !unverifiablePassStates.includes(cell.passState))
        || (cell.isArray && cell.items.some(item => item.passState && item.passState !== PassState.PASS)));
    const verified = cell?.verified;
    const verifiedBy = cell?.verifiedBy ?? verifiedByMessageGetter;
    const key = cell?.verifyKey;
    const field = cell?.verifyField;
    const index = cell?.verifyIndex;
    const indexed = cell?.verifyIndexed;
    const verifyEach = cell?.verifyEach;
    const passState = cell?.passState;
    const rawValue = cell?.rawValue;
    const tooltip = cell?.tooltip;

    const verificationData = {
      key,
      field,
      index,
      passState,
      value: rawValue,
      tooltip
    };

    cell && cell.isArray && cell.title && Object.assign(cellAttributes, { title: splitHtmlLines(cell.title) });
    cell && cell.class && Object.assign(cellAttributes, { class: cell.class });
    isSpanned && Object.assign(cellAttributes, { spanned: true });
    const phi = cell?.phi;

    const renderedValue = cell?.isArray && cell?.items[0]?.isArray
      // 2D array of items, always treat each row as inline and each line as block.
      ? cell?.items.map(({ items: children }) => html`
        <li>
          ${children.map(child => {
            const { value, ...attributes } = this.normalizeCell(child);
            
            const childVerifiable = !attributes.unverifiable 
              && attributes['pass-state'] 
              && !unverifiablePassStates.includes(attributes['pass-state']);
            const childVerified = attributes?.['.verified'];
            const childKey = attributes?.['verify-key'];
            const childField = attributes?.['verify-field'];
            const childIndex = attributes?.['verify-index'];
            const childIndexed = attributes?.['?verify-indexed'];
            const childTooltip = attributes?.['tooltip'] ?? attributes?.['title'];
            const childPassState = attributes?.['pass-state'];
            const childRawValue = attributes?.['raw-value'] ?? attributes?.['.raw-value'];

            const cleanLines = (value && String(value).split(/\\n/g)) || [];
            
            const childVerificationData = {
              key: childKey,
              field: childField,
              index: childIndex,
              tooltip: childTooltip,
              passState: childPassState,
              value: childRawValue
            };
            
            const linesRendered = cleanLines.map((line, index, arr) => html`
                  ${resolveTags(line)}${h(index < arr.length - 1, html`<br>`)}
            `);
            
            return html`
              <span child inline>
                ${applyAttributes(attributes)}
                <span ?verifiable=${childVerifiable} value title=${ifDefined(splitHtmlLines(attributes.title))}>
                  ${h(phi, html`<phi-value>${linesRendered}</phi-value>`)}
                  ${h(!phi, linesRendered)}
                  ${h(verifyEach, () => this.renderVerified(childVerifiable, childVerified, 
                    childVerificationData, childIndexed, cell, child))}
                </span>
              </span>
            `;
          })}
        </li>
      `)
      : items.map(({ value, ...attributes }) => {
        const itemVerifiable = !attributes.unverifiable
          && attributes?.['pass-state'] && !unverifiablePassStates.includes(attributes?.['pass-state']);
        const itemVerified = attributes?.['.verified'];
        const itemKey = attributes?.['verify-key'];
        const itemField = attributes?.['verify-field'];
        const itemIndex = attributes?.['verify-index'];
        const itemIndexed = attributes?.['?verify-indexed'];
        const itemTooltip = attributes?.['tooltip'] ?? attributes?.['title'];
        const itemPassState = attributes?.['pass-state'];
        const itemRawValue = attributes?.['raw-value'] ?? attributes?.['.raw-value'];

        const cleanLines = (value && String(value).split(/\n/g)) || [];

        const itemVerificationData = {
          key: itemKey,
          field: itemField,
          index: itemIndex,
          tooltip: itemTooltip,
          passState: itemPassState,
          value: itemRawValue
        };

        delete attributes.class;

        const linesRendered = cleanLines.map((line, index, arr) => html`
            ${resolveTags(line)}${h(index < arr.length - 1, html`<br>`)}
        `);

        const inner = html`
          <span item ?verifiable=${itemVerified} value title=${ifDefined(splitHtmlLines(attributes.title))}>
            ${h(phi, html`<phi-value>${linesRendered}</phi-value>`)}
            ${h(!phi, linesRendered)}
            ${h(verifyEach, () => this.renderVerified(itemVerifiable, itemVerified,
              itemVerificationData, itemIndexed, cell, { value, ...attributes }))}
          </span>
        `;

        if (cell?.isArray && !cell?.inline) {
          return html`
            <li>
              ${applyAttributes(attributes)}
              ${inner}
            </li>
          `;
        }

        if (!cell?.inline) {
          return html`
            <div>
              ${applyAttributes(attributes)}
              ${inner}            
            </div>
          `;
        }

        return html`
          <span inline>
            ${applyAttributes(attributes)}
            ${inner}
          </span>
        `;
      });

    const renderedInner = cell && cell.isArray && (!cell.inline || cell.items[0]?.isArray)
      ? html`
        <div ?verifiable=${verifiable}>
          <ul>${renderedValue}</ul>
          ${h(!verifyEach, () => this.renderVerified(verifiable, verified, verificationData, indexed, cell, null))}
        </div>
      `
      : html`
        <div ?verifiable=${verifiable}>
          ${renderedValue}
          ${h(!verifyEach, () => this.renderVerified(verifiable, verified, verificationData, indexed, cell, null))}
        </div>
      `;

    if (isHeader) {
      return html`
        <th>
          ${applyAttributes(cellAttributes)}
          ${renderedInner}
          ${h(!verifyEach, () => this.renderVerifiedBy(verifiedBy, verified))}
          ${h(verifyEach, () =>
            this.renderVerifiedByEach(
              cell?.isArray && cell?.items[0]?.isArray ? cell?.items : items, 
              verifiedBy,
              verified
            ))}
        </th>
      `;
    }

    return html`
      <td>
        ${applyAttributes(cellAttributes)}
        ${renderedInner}
        ${h(!verifyEach, () => this.renderVerifiedBy(verifiedBy, verified))}
        ${h(verifyEach, () =>
          this.renderVerifiedByEach(
            cell?.isArray && cell?.items[0]?.isArray ? cell?.items : items, 
            verifiedBy,
            verified
          ))}
      </td>
    `;
  }

  // eslint-disable-next-line max-params
  renderVerified = (verifiable, verified, verificationData, indexed, cell, child) => {
    if (!verifiable) return;

    const { key, field } = verificationData;

    if (!key || !field) {
      console.error(`Verifiable cell does not provide a key and/or field. Provided: ${key} | ${field}`, cell, child);
      return;
    }

    return html`
      <verify-check-box
        .verification=${verificationData}
        ?indexed=${indexed}
        ?checked=${verified}
        @change=${this.handleVerifiedChange}
        title="Check to verify"
      ></verify-check-box>
    `;
  }

  renderVerifiedBy(verifiedByGetter, verification) {
    if (!verifiedByGetter || !verification) {
      return;
    }

    const message = verifiedByGetter(verification);

    if (!message) return;

    return html`<span class="verified-by">
      ${message}
    </span>`;
  }

  renderVerifiedByEach(children, verifiedBy, verified) {
    return children.map((child, index) => {
      if (child?.items) return this.renderVerifiedByEach(child.items, verifiedBy, verified);

      const childVerified = child['.verified'] ?? child.verified;

      return this.renderVerifiedBy(
        Array.isArray(verifiedBy) ? verifiedBy[index] : verifiedBy,
        childVerified ?? verified
      );
    });
  }

  handleVerifiedChange(event) {
    const { detail: { checked }, currentTarget } = event;
    const { verification: { key, field, index, passState, value, tooltip } } = currentTarget;

    this.dispatchEvent(new CustomEvent(
      'verified-change',
      {
        detail: { key, field, checked, index, passState, value,
          tooltip, indexed: currentTarget.hasAttribute('indexed') },
        bubbles: true,
        composed: true
      }
    ));
  }

  renderStickyCorner = () => {
    const { freezeRows, freezeColumns, renderTable } = this;

    if (!freezeRows || !freezeColumns) return;

    return renderTable({ sticky: true, corner: true });
  }

  renderStickyRows = () => {
    const { freezeRows, renderTable } = this;

    if (!freezeRows) return;

    return renderTable({ sticky: true, rows: true });
  };

  renderStickyColumns = () => {
    const { freezeColumns, renderTable } = this;

    if (!freezeColumns) return;

    return renderTable({ sticky: true, columns: true });
  };

  normalizeCell(cell) {
    return this.mapKeysToAttributes({
      ...this.constructor.cellDefaults,
      ...(typeof cell !== 'object'
        ? { value: cell }
        : cell
      )
    });
  }

  mapKeysToAttributes = obj => {
    const { mapKeyToAttribute } = this;

    return Object.entries(obj).reduce((result, [key, value]) => {
      const mappedKey = mapKeyToAttribute(key, value, obj);

      if (!mappedKey) return result;

      return Object.assign(result, { [mappedKey]: value });
    }, {});
  };

  mapKeyToAttribute(key, value, obj) {
    if (['colspan', 'rowspan'].includes(key) && value === 1) return undefined;

    if (/^[a-z]/.test(key)) {
      if (typeof value === 'boolean') return `?${camelCaseToHyphens(key)}`;
      if (!['string', 'number'].includes(typeof value)) return `.${camelCaseToHyphens(key)}`;
    }

    return camelCaseToHyphens(key);
  }

  handleToggleCollapse = ({ target }) => {
    const { collapsedBodies } = this;
    const bodyIndex = target.closest('tbody').nextElementSibling.index;

    this.collapsedBodies = { ...collapsedBodies, [bodyIndex]: !collapsedBodies[bodyIndex] };
  }

  handleWheel = event => {
    // @TODO Figure out how to make this work how I want
    // const { scrollParent, updateDynamic } = this;
    //
    // event.preventDefault();
    // event.stopPropagation();
    //
    // scrollParent.scrollTop += event.deltaY;
    // updateDynamic();
  };

  updateDynamic = () => {
    if (!this.isVisible) return;

    const { freezeRows, freezeColumns, shadowRoot, updateStickyRows,
      updateStickyColumns, updateStickyCorner } = this;

    const mainTable = shadowRoot.querySelector('table[main]');

    if (!mainTable) return;

    const { table, rows, cells } = dissectTable(mainTable);

    freezeRows && freezeColumns && updateStickyCorner(table, rows, cells);
    freezeRows && updateStickyRows(table, rows, cells);
    freezeColumns && updateStickyColumns(table, rows, cells);

    const charts = Array.from(shadowRoot.querySelectorAll('[chart]'));

    charts.length && updateCharts(charts);
  };

  updateStickyCorner = table => {
    const { shadowRoot, syncTableStyle } = this;

    const { table: stickyTable } =
      dissectTable(shadowRoot.querySelector('table[sticky][corner]'));

    syncTableStyle(table, stickyTable, 'height', 'top');
    syncTableStyle(table, stickyTable, 'width', 'left');
  };
  
  updateStickyRows = table => {
    const { shadowRoot, syncTableStyle } = this;

    const { table: stickyTable } =
      dissectTable(shadowRoot.querySelector('table[sticky][rows]'));

    syncTableStyle(table, stickyTable, 'height', 'top');
  };
  
  updateStickyColumns = table => {
    const { shadowRoot, syncTableStyle } = this;

    const { table: stickyTable } =
      dissectTable(shadowRoot.querySelector('table[sticky][columns]'));

    syncTableStyle(table, stickyTable, 'width', 'left');
  };

  syncTableStyle = (sourceTable, stickyTable, dimensionKey, positionKey) => {
    const { scrollParent } = this;
    const scrollParentOffset = scrollParent.getBoundingClientRect()[positionKey];
    const scrollParentScroll = scrollParent[`scroll${toTitleCase(positionKey)}`];
    const bounds = sourceTable.getBoundingClientRect();
    const maxPosition = bounds[dimensionKey];

    const minPosition = -bounds[positionKey] + (scrollParentOffset + scrollParentScroll === 0
      ? 0
      : scrollParentOffset);

    const newValue = Math.min(maxPosition, Math.max(0, minPosition));

    Object.assign(stickyTable.style, {
      [positionKey]: `${newValue}px`,
      [positionKey === 'top' ? 'bottom' : 'right']: `-${newValue}px`
    });
  };

  handleResize = () => {
    const { updateDynamic } = this;

    setTimeout(async () => {
      updateDynamic();
    }, 10);
  };
}

customElements.define('rad-table', RadTable);