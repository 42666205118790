import PassState from '../../../../PassState';
import h from '../../../../utils/h';

const mapEpisodicPatientData = episodicPatientData => {
  if (!episodicPatientData) return null;

  const { episodes, ...patientData } = episodicPatientData;
  const allPlans = patientData.courses.flatMap(c => c.plans.map(p => {
    p.course = c;
    return p;
  }));

  allPlans.forEach(p => {
    p.treatments = [];
    p.imagings = [];
  });

  const findPlanById = planId => planId && allPlans.find(p => p.id === planId);
  const findPlanBySer = planSer => planSer && allPlans.find(p => p.ser === planSer);

  episodes.forEach(({ treatment, imaging, documentation, dateTime }) => {
    if (treatment || imaging) {
      const plan = findPlanBySer(treatment?.planSer) || findPlanBySer(imaging?.planSer) || findPlanById(treatment?.id);

      if (plan) {
        plan.course.documentations = plan.course.documentations || [];

        if (treatment) {
          plan.treatments.push(treatment);
          treatment.episodeDateTime = dateTime;
          treatment.fields?.forEach(f => f.episodeDateTime = dateTime);
        }

        if (imaging) {
          plan.imagings.push(imaging);
          imaging.episodeDateTime = dateTime;
        }

        if (documentation) {
          plan.course.documentations.push(documentation);
          documentation.episodeDateTime = dateTime;
        }

        patientData.courses.forEach(c => {
          c.errorCounts.documentation += documentation?.totalErrors ?? 0;
        });

        return;
      }
    }

    if (documentation) {
      // orphan, add to all courses
      patientData.courses.forEach(c => {
        c.documentations = c.documentations || [];
        c.documentations.push(documentation);
        c.errorCounts.documentation += documentation?.totalErrors ?? 0;
      });
    }
  });

  allPlans.forEach(p => {
    delete p.course;

    p.errorCounts = {
      details: p.treatments?.reduce((total, treatment) => total + treatment?.totalErrors ?? 0, 0) ?? 0,
      fields: p.treatments?.reduce((total, treatment) => total
        + treatment.fields?.reduce((subtotal, field) => subtotal + field?.totalErrors, 0) ?? 0, 0) ?? 0,
      imaging: p.imagings?.reduce((total, imaging) => total + imaging?.totalErrors ?? 0, 0) ?? 0,
      refpts: p.referencePointDoses?.reduce((total, refpt) => total + h(refpt?.passState === 'FAIL', 1, 0), 0) ?? 0
    };

    // @TODO Temporary until APC-538 is complete
    p.fields = p.fields || [];

    // Apply treatment and imaging hides
    p.skipTreatmentTimes = episodes.filter(({ hideEpisodeDateForPlanSers, treatment }) =>
      treatment && hideEpisodeDateForPlanSers?.includes(p.ser)).map(({ treatment }) => treatment.episodeDateTime);
    p.skipImagingTimes = episodes.filter(({ hideEpisodeDateForImagingPlanSers, imaging }) =>
      imaging && hideEpisodeDateForImagingPlanSers?.includes(p.ser)).map(({ imaging }) => imaging.episodeDateTime);
  });

  patientData.courses.flatMap(c => c.prescriptions)
    .forEach(rx => rx.plans = allPlans.filter(p => p.prescriptionSer === rx.ser));

  // @TODO Temporary until APC-521 and APC-522 are done
  patientData.courses.forEach(c => {
    c.endOfTreatmentChecks = c.endOfTreatmentChecks || [];
    c.initialTreatmentChecks = [];

    const initialChecks = [];

    c.plans.forEach(p => {
      p.initialTreatmentChecks.forEach(itc => {
        if (initialChecks[itc.id]) {
          const initialCheckExists = initialChecks[itc.id].some(initial => initial.passState === itc.passState);
          if (!initialCheckExists) {
            initialChecks[itc.id].push(itc);
          }
        } else {
          initialChecks[itc.id] = [itc];
        }
      });
    });

    for (const key in initialChecks) {
      if (!Object.hasOwnProperty.call(initialChecks, key)) continue;

      const failInitialCheck = initialChecks[key].find(ic => ic.passState === PassState.FAIL);
      const conditionInitialCheck = initialChecks[key].find(ic => ic.passState === PassState.CONDITION);
      const passInitialCheck = initialChecks[key].find(ic => ic.passState === PassState.PASS);

      if (failInitialCheck) {
        c.initialTreatmentChecks.push(failInitialCheck);
      } else if (conditionInitialCheck) {
        c.initialTreatmentChecks.push(conditionInitialCheck);
      } else if (passInitialCheck) {
        c.initialTreatmentChecks.push(passInitialCheck);
      }
    }

    // Sort Rxs by the earliest treatment date in its plans.
    c.prescriptions.sort((a, b) => {
      const [earliestA] = a.planSers.map(ser =>
        c.plans.find(p => p.ser === ser)?.treatments
          .map(t => t.dateTime).sort()[0]
      ).sort();

      const [earliestB] = b.planSers.map(ser =>
        c.plans.find(p => p.ser === ser)?.treatments
          .map(t => t.dateTime).sort()[0]
      ).sort();

      return earliestA < earliestB ? -1 : earliestA === earliestB ? 0 : 1;
    });

    // Sorts plans by their treatment date.
    c.plans.sort((a, b) => {
      const [earliestA] = a.treatments.map(t => t.dateTime).sort();
      const [earliestB] = b.treatments.map(t => t.dateTime).sort();

      return earliestA < earliestB ? -1 : earliestA === earliestB ? 0 : 1;
    });

    // Apply documentation hides
    c.skipDocumentationTimes = episodes.filter(({ hideEpisodeDateForDocumentations, documentation }) =>
      hideEpisodeDateForDocumentations && documentation).map(({ documentation }) => documentation.episodeDateTime);
  });

  patientData.settingsRevision = episodicPatientData.settingsRevision;

  return patientData;
};

export default mapEpisodicPatientData;