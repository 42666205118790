import { html, css, LitElement } from 'lit';
import sidebarStyle from './styles/sidebar';
import fontAwesome from '../../../utils/font-awesome';
import { filterPatients, sortByStatus } from './utils';
import { FilterModes, statusFilters, statuses, DateOptions, stateFilters, states } from './common';
import daysAgo from '../../../utils/date/daysAgo';
import '../../common/check-box';
import removeValue from '../../../utils/immutable/array/removeValue';
import '../../common/phi-value';
import { toIsoDate, toLocaleDate } from '../../../utils/date/format';
import { isEndOfTreatmentStatus } from './common';
import h from '../../../utils/h';
import '../../common/rad-multi-dropdown';
import { store } from '../../../redux/store';
import { connect } from '../../../utils/redux';
import '../../rad-overlay';
import { organizationSlugSelector, showOverlaySelector } from '../../../redux/app/selectors';
import preventDefault from '../../../utils/event/preventDefault.js';

const dateOptions = [
  DateOptions.THIS_WEEK,
  DateOptions.LAST_TWO_WEEKS,
  DateOptions.THIS_MONTH,
  DateOptions.CUSTOM
];

class PatientsSidebar extends connect(store)(LitElement) {
  static styles = [
    sidebarStyle,
    css`    
      h4 {
        margin-bottom: 8px;
      }
      
      #filters {
        position: absolute;
        width: 680px;
        left: 234px;
        top: 84px;
        background: var(--light-color);
        border-radius: 5px;
        z-index: 10000;
        padding: 24px;
        box-shadow: 0 4px 16px var(--neutral-5);
      }
      
      #filters li.divider {
        line-height: 0;
        border: 0;
        border-bottom: 1px solid var(--neutral-6);
        height: 0;
        margin: 0 0 8px;
        padding: 0;
      }
      
      #filters #columns {
        display: flex;
        flex-direction: row;
        gap: 18px;
      }
      
      #filters #columns .col {
        flex: 1;
      }
      
      #filters ul {
        margin: 0;
      }
      
      #filters .close {
        position: absolute;
        color: var(--neutral-4);
        top: 0px;
        right: 0px;
        line-height: 1;
        cursor: pointer;
        padding: 22px 16px;
        background: 0px center;
        border-width: 0px;
        border-style: initial;
        border-color: initial;
        border-image: initial;
        outline: 0px;
        transition: all .2s;
      }
  
      #filters .close:hover {
        color: var(--neutral-2);
        transition: all .2s;
      }
      
      #filters h3 {
        margin: 4px 0 12px;
        font-size: 18px;
        letter-spacing: .33px;
        text-transform: uppercase;
        color: var(--neutral-2);
      }
      
      #filters li {
        font-weight: normal;
        cursor: default;
        font-size: 14px;
        line-height: 21px;
        position: relative;
        padding: 4px 8px;
      }
      
      #filters li check-box,
      #filters li check-box label {
        cursor: pointer;
        display: block !important;
      }
  
      #filters li check-box.five-frac {
        margin: 2px 0 2px 12px;
        display: inline-block !important;
      }
      
      #filters li li {
        padding-right: 0;
      }
      
      
      #filters li li input {
        width: 34px;
      }

      #filters li li input[type=number]::-webkit-inner-spin-button,
      #filters li li input[type=number]::-webkit-outer-spin-button {
        opacity: 1;
      }
      
      #filters li check-box label {
        display: block;
        cursor: pointer;
        padding: 6px 12px;
      }
      
      #filters li label input {
        margin: -2px 4px 0 0;
      }
      
      #filters li[disabled] {
        pointer-events: none;
        color: #AAA;
      }
      
      #filters li[disabled] input {
        border: #AAA;
      }
      
      #filters hr {
        margin: 16px 0 20px;
        border-style: solid;
        border-width: .5px;
        border-color: var(--neutral-6);
      }
      
      #filters select {
        width: 100%;
        background: var(--lighter-color);
        height: 35px;
        font-size: 14px;
        color: var(--neutral-3);
        border-radius: 5px;
        margin: 0px 0px 16px 0;
      }
      
      h2 {
        border-bottom: 0;
        margin-bottom: 0;
      }
      
      header {
        position: relative;
        margin: 0 -16px 16px;
        padding: 0 16px 8px;
      }    
      
      header ul {
        display: flex;
        flex-direction: row;
      }
      
      header li {
        flex: 1;
        color: var(--neutral-5);
        font-size: 18px;
        line-height: 24px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--neutral-6);
        transition: .25s;
      }
      
      header li:hover,
      header li[active]:hover {
        background: var(--light-color) !important;
        transition: .25s;
      }
      
      header li:not([active]):hover {
        flex: 1;
        color: var(--neutral-4);
        border-bottom: 2px solid var(--neutral-6);
      }
      
      header li[active] {
        color: var(--neutral-2);
        border-bottom: 2px solid var(--primary-color);
      }
  
      .patient-search-input {
        position: relative;
        margin-bottom: 16px;
      }
  
      .patient-search-input input,
      .custom-range input {
        padding: 12px;
        border: 1px solid var(--neutral-5);
        border-radius: 5px;
        font-size: 14px;
        font-family: "Segoe UI", Helvetica, sans-serif;
        color: var(--neutral-3);
        width: 100%;
      }
  
      .custom-range {
        padding: 4px 16px;
        margin-bottom: 8px;
      }
  
      .custom-range input {
        margin: 4px 0;
        padding: 6px 12px;
      }
  
      #date-header select:hover,
      #date-header select:focus,
      .patient-search-input input:hover,
      .custom-range input:hover {
        border: 1px solid var(--neutral-3);
        outline: none;
        cursor: pointer;
      }
  
      .patient-search-input input:hover {
        cursor: text;
      }
  
      .patient-search-input input:focus,
      .custom-range input:focus {
        outline: none;
        border: 1px solid var(--primary-color);
      }
  
      .patient-search-input i {
        position: absolute;
        right: 16px;
        top: 16px;
        color: var(--primary-color);
        pointer-events: none;
      }
  
      #date-header {
        padding: 0;
        margin: 0 -16px;
        border-bottom: 1px solid var(--neutral-6);
      }
      
      #date-header .top-row {
        display: flex;
      }
      
      #date-header select {
        flex: 3;
        height: 43px;
        border-radius: 3px;
        font-size: 14px;
        color: var(--neutral-3);
        background: var(--lighter-color);
        margin: 0 0 16px 16px;
      }
      
      #date-header input {
        display: block;
        width: 100%;
      }
      
      button {
        cursor: pointer;
      }
      
      #filter-buttons {
        position: absolute;
        right: 16px;
        top: 14px;
      }
      
      #filter-buttons i {
        margin-left: 5px;
        font-size: 1.1em;
        cursor: pointer;
      }
      
      .date {
        padding: 8px 16px;
        color: var(--blue-2);
        background: var(--neutral-6);
        font-weight: 600;
        letter-spacing: .25px;
        font-size: 16px;
      }
      
      #filters-button {
        display: block;
        text-align: center;
        cursor: pointer;
        padding: 4px 8px 3px;
        text-decoration: none;
        color: var(--neutral-3);
        font-weight: lighter;
      }
  
      #filters-button i {
        font-size: 1.3rem;
        font-weight: lighter;
        padding: 8px 8px 6px;
        border-radius: 5px;
      }
      
      #filters-button:hover i,
      #filters-button:active i,
      #filters-button:focus i {
        color: var(--neutral-1);
        background: var(--blue-7);
      }
      
      #filter-section {
        margin: -16px -16px 16px;
      }
      
      .filter {
        box-sizing: border-box;
        transition: height .5s, padding .5s;
        overflow: hidden;
      }
      
      .filter:not([show]) {
        height: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      
      #search {
        background: var(--side-border);
        padding: 16px;
        height: 51px;
      }
      
      #search input,
      #search button {
        display: inline-block;
      }
      
      #search input {
        box-sizing: border-box;
        border-radius: 100px;
        border: 1px solid #333;
        max-width: 160px;
        padding: 0 16px;
      }
      
      #search button {
        background: 0;
        border: 0;
      }
      
      #patients-search li {
        font-weight: normal;
      }
      
      li.nested,
      li.divider {
        margin-bottom: 1em;
      }
      
      li.divider,
      hr {
        cursor: auto;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1px;
        margin-bottom: .5em;
      }
      
      li.divider hr {
        margin: 0 -16px;
        border-style: solid;
        border-width: .5px;
        border-color: var(--neutral-6);
      }
      
      li.divider:hover {
        background: inherit !important;
      }
      
      .status-header {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        cursor: default;
        margin: 8px 0 4px;
      }
  
      .status-header h3,
      .status-header span {
        font-size: 11px;
        letter-spacing: .33px;
        text-transform: uppercase;
        color: var(--neutral-2);
      }
  
      .status-header span {
        letter-spacing: -.3px;
      }
      
      [eot-passing],
      [needs-review], 
      [review-accepted] {
        position: relative;
        padding-right: 31px;
      }
  
      [eot-passing],
      [needs-review] {
        font-weight: bold !important;
      }
      
      /* [needs-review]:before {
        content: '\uF621';
        font-family: 'Font Awesome 5 Pro';
        position: absolute;
        top: 6px;
        left: 5px;
        font-weight: 900;
        font-size: .5em;
        color: var(--blue-2);
      } */
  
      [eot-passing]:not([review-accepted]):hover:after {
        content: 'Chart Updated';
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        color: var(--blue-2);
        padding: 4px 16px 0 0;
        transition: .25s;
        text-transform: uppercase;
        font-family: "Segoe UI", Helvetica, sans-serif;
        font-size: 10px;
        font-weight: 600;
        text-align: right;
        line-height: 12px;
      }
  
      [needs-review]:not([review-accepted]):hover:after {
        content: 'Needs Review';
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        color: var(--blue-2);
        padding: 4px 16px 0 0;
        transition: .25s;
        text-transform: uppercase;
        font-family: "Segoe UI", Helvetica, sans-serif;
        font-size: 10px;
        font-weight: 600;
        text-align: right;
        line-height: 12px;
      }
      
      [review-accepted]:after {
        content: '\uF00C';
        font-family: 'Font Awesome 5 Pro';
        color: var(--neutral-2);
        position: absolute;
        top: 6px;
        right: 16px;
        font-weight: 900;
      }
  
      [current] {
        color: var(--neutral-1);
        font-weight: 600;
      }
      
      select {
        margin: 0 0 8px 0;
      }
      
      [no-patients] .status-header {
        color: #999;
      }
  
      li ul li[current] > span {
        color: var(--neutral-1);
      }
      
      fancy-multi {
        width: 100vw;
        height: 100vh;
        top: -61px; /* height of the header */
      }
      
      #filters .header h3,
      #filters .header check-box {
        display: inline-block;
      }
      
      #filters .header check-box {
        margin-left: 40px;
        position: relative;
        top: -2px;
      }
    `
  ].flat();

  static properties = {
    currentPatientId: { type: String },
    patients: { type: Array },
    patientsByStatus: { type: Object },
    total: { type: Object },
    filters: { type: Object },
    showFilters: { type: Boolean },
    machines: { type: Array },
    hospitals: { type: Array },
    departments: { type: Array },
    minSearchLength: { type: Number },
    searchTerm: { type: String },
    showOverlay: { type: Boolean },
    organizationSlug: { type: String }
  };

  get statesAll() {
    const { filters: { states } = {} } = this;

    return stateFilters.every(p => states.includes(p));
  }

  get statesPartial() {
    const { filters: { states } = {}, statesAll } = this;

    return Boolean(states.length) && !statesAll;
  }

  get statusesAll() {
    const { filters: { statuses, moreThanFive } = {} } = this;

    return statusFilters.every(s => statuses.includes(s)) && moreThanFive;
  }

  get statusesPartial() {
    const { filters: { statuses } = {}, statusesAll } = this;

    return Boolean(statuses.length) && !statusesAll;
  }

  constructor() {
    super();

    this.machines = [];
    this.hospitals = [];
    this.departments = [];
  }

  updated(changed) {
    const { patients, showFilters, handleBodyClick, handleBodyKeyUp } = this;

    if (changed.has('patients')) {
      this.patientsByStatus = sortByStatus(patients);
    }

    if (changed.has('showFilters')) {
      if (showFilters) {
        document.body.addEventListener('click', handleBodyClick);
        document.body.addEventListener('keyup', handleBodyKeyUp);
      } else {
        document.body.removeEventListener('click', handleBodyClick);
        document.body.removeEventListener('keyup', handleBodyKeyUp);
      }
    }
  }

  render() {
    const { patientsByStatus, filters: { patientId, mode, statuses: statusFilters }, renderStatusSection,
      renderDateHeader, renderPatientHeader, handleToggleMode, showFilters, renderFilters, renderPatient,
      patients, showOverlay } = this;

    if (!patientsByStatus) {
      return;
    }

    return html`
      ${fontAwesome}
      <header>
        <ul>
          <li
            .mode=${FilterModes.DATE} 
            ?active="${mode === FilterModes.DATE}"
            @click=${handleToggleMode}
          >
            Date
          </li>
          <li
            .mode=${FilterModes.PATIENT} 
            ?active="${mode === FilterModes.PATIENT}"
            @click=${handleToggleMode}
          >
            Patient
          </li>
        </ul>
      </header>
      ${mode === FilterModes.DATE ? renderDateHeader() : ''}
      ${mode === FilterModes.PATIENT ? renderPatientHeader() : ''}
      ${mode === FilterModes.DATE && !statusFilters.length ? html`
        <p>Enable at least 1 status in filters to see results.</p>
      ` : ''}
      ${mode !== FilterModes.PATIENT && statusFilters.length ? html`
        <ul>
          ${Object.entries(statuses).filter(([status]) => statusFilters.includes(status))
      .map(([status, title]) => renderStatusSection(status, title))}
        </ul>
      ` : ''}
      ${mode === FilterModes.PATIENT && patientId ? html`
        <ul>
          <li>
            <ul>
              ${renderPatient(patients.find(p => p.id === patientId))}
            </ul>
          </li>
        </ul>
      `: ''}
      ${mode === FilterModes.DATE && showFilters ? renderFilters() : ''}
      ${h(showOverlay, () => html`<rad-overlay></rad-overlay>`)}
    `;
  }

  renderHospitalSelect = () => {
    const {
      filters: { hospitalIds = [] }, hospitals = [],
      handleHospitalChange
    } = this;

    const hospitalOptions = hospitals.map(({ id }) => id);

    return html`
      <rad-multi-dropdown
        .selectAllText="All Hospitals"
        .label=${!hospitalIds.length || hospitalIds.length === hospitalOptions.length
          ? 'All Hospitals'
          : hospitalIds.length > 1
            ? `${hospitalIds.length ?? 0} Hospitals`
            : hospitalIds[0]}
        .options=${hospitalOptions}
        .selected=${hospitalIds}
        @change=${handleHospitalChange}
      ></rad-multi-dropdown>
    `;
  }

  renderDepartmentSelect = () => {
    const {
      filters: { departmentSers = [], hospitalIds = [] }, departments = [], hospitals = [],
      handleDepartmentChange
    } = this;

    const departmentOptions = departments
      .filter(({ hospitalSer }) => !hospitalSer || !hospitalIds.length
        || hospitalIds.includes(hospitals.find(({ ser }) => hospitalSer === ser)?.id))
      .map(({ departmentName, departmentSer, hospitalSer }) => ({
        text: `${departmentName}`,
        value: departmentSer,
        group: hospitals?.find(({ ser }) => ser === hospitalSer)?.id
      })) ?? [];

    return html`
      <rad-multi-dropdown
        .selectAllText="All Departments"
        .label=${!departmentSers.length || departmentSers.length === departmentOptions.length
          ? 'All Departments'
          : departmentSers.length > 1
            ? `${departmentSers.length} Departments`
            : departments.find(({ departmentSer }) => departmentSer === departmentSers[0])?.departmentName
              ?? '1 Department'}
        .options=${departmentOptions}
        .selected=${departmentSers}
        @change=${handleDepartmentChange}
      ></rad-multi-dropdown>
    `;
  }

  renderMachineSelect = () => {
    const {
      filters: { hospitalIds = [], machineIds = [], departmentSers = {} }, machines = [], departments = [],
      hospitals = [], handleMachineChange
    } = this;

    const machineOptions = machines
      .filter(({ hospitalSer, departmentSer }) =>
        (!hospitalSer || !hospitalIds.length
          || hospitalIds.includes(hospitals.find(({ ser }) => ser === hospitalSer)?.id))
        && (!departmentSer || !departmentSers.length || departmentSers.includes(departmentSer))
      ).map(({ hospitalId, hospitalSer, departmentSer, id }) => {
        const departmentName = departmentSer 
          && departments.find(d => d.departmentSer === departmentSer)?.departmentName;

        return {
          id,
          text: id,
          value: id + '|' + departmentSer + '|' + hospitalId,
          group: h(hospitalSer,
            `${hospitals.find(({ ser }) => hospitalSer === ser)?.id}${h(departmentName, ` - ${departmentName}`)}`
          )
        };
      });

    return html`
      <rad-multi-dropdown
        .selectAllText=${'All Machines'}
        .label=${!machineIds.length || machineOptions.every(({ value }) => machineIds.includes(value))
          ? 'All Machines'
          : machineIds.length > 1
            ? `${machineIds.length ?? 0} Machines`
            : machineIds[0].split('|')[0]}
        .options=${machineOptions}
        .selected=${machineIds}
        @change=${handleMachineChange}
      ></rad-multi-dropdown>
    `;
  };

  handleHospitalChange = event => {
    const { hospitals, departments, machines, filters: { departmentSers = [], machineIds = [] } } = this;
    const { detail: { selected } } = event;
    const hospitalIds = selected;

    this.dispatchFilterChange({
      hospitalIds,
      departmentSers: departmentSers.filter(ser => {
        const department = departments.find(({ departmentSer }) => departmentSer === ser);

        return !department?.hospitalSer || !hospitalIds.length
          || hospitalIds.includes(hospitals.find(({ ser }) => ser === department?.hospitalSer)?.id);
      }),
      machineIds: machineIds.filter(machineId => {
        const machine = machines.find(({ id }) => id === machineId);

        return (
          !hospitalIds.length
          || !machine?.hospitalSer
          || hospitalIds.includes(hospitals.find(({ ser }) => ser === machine?.hospitalSer)?.id)
        ) && (
          !departmentSers.length
          || !machine?.departmentSer
          || departmentSers.includes(machine?.departmentSer)
        );
      })
    });
  };

  handleDepartmentChange = event => {
    const { filters: { machineIds = [] }, machines = [] } = this;
    const { detail: { selected } } = event;

    const departmentSers = selected;

    this.dispatchFilterChange({
      departmentSers,
      machineIds: machineIds.filter(machineId => {
        const machine = machines.find(({ id }) => id === machineId);

        return !departmentSers.length || !machine?.departmentSer || departmentSers.includes(machine?.departmentSer);
      })
    });
  };

  handleMachineChange = event => {
    const { detail: { selected } } = event;

    this.dispatchFilterChange({ machineIds: selected });
  };

  renderDateHeader = () => {
    const { filters: { dateOption, start, end }, handleToggleFilters, handleToChange, handleFromChange,
      handleDateOptionChange } = this;

    return html`
      <div id="date-header">
        <div class="top-row">
          <select
            @change=${handleDateOptionChange}
          >
            ${dateOptions.map(option => html`
              <option 
                ?selected=${dateOption === option}
                .value=${option.key}
              >
                ${option.text}
              </option>
            `)}
          </select>
          <span
            id="filters-button"            
            @click=${handleToggleFilters}
          >
            <i class="fa fa-tasks" title="Filters"></i>
          </span>
        </div>
        ${dateOption === DateOptions.CUSTOM ? html`
          <div class="custom-range">
            <input type="date" value=${toIsoDate(start)} @change=${handleToChange}>
            <span>to</span>
            <input type="date" value=${toIsoDate(end)} @change=${handleFromChange}>  
          </div>
        `: html`
          <div class="date">
            ${toLocaleDate(start)} - ${toLocaleDate(end)}
          </div>
        `}
      </div>
    `;
  };

  handleBodyClick = event => {
    const ignores = Array.from(this.shadowRoot.querySelectorAll('#filters, #filters-button'));

    if (!event.composedPath().some(el => ignores.includes(el))) {
      this.showFilters = false;
    }
  };

  handleBodyKeyUp = event => {
    if (event.key === 'Escape') {
      this.showFilters = false;
    }
  };

  handleToggleFilters = () => {
    this.showFilters = !this.showFilters;
  };

  dispatchFilterChange = update => {
    const { filters } = this;

    this.dispatchEvent(new CustomEvent('filters-change', {
      bubbles: true,
      detail: {
        filters: {
          ...filters,
          ...update
        }
      }
    }));
  };

  handleDateOptionChange = event => {
    const dateOption = DateOptions[event.currentTarget.value];
    let { filters: { start, end } } = this;

    if (!dateOption.custom) {
      start = daysAgo(-dateOption.start);
      end = daysAgo(-dateOption.end);
    }

    this.dispatchFilterChange({
      dateOption,
      start,
      end
    });
  };

  handleToChange = event => {
    const date = new Date(event.currentTarget.value);
    date.setMinutes(date.getTimezoneOffset()); // force it to midnight of the current timezone so we aren't off by a day

    if (!isNaN(date) && `${date.getFullYear()}`.length === 4) {
      this.dispatchFilterChange({ start: date });
    }
  };

  handleFromChange = event => {
    const date = new Date(event.currentTarget.value);
    date.setMinutes(date.getTimezoneOffset()); // force it to midnight of the current timezone so we aren't off by a day

    if (!isNaN(date) && `${date.getFullYear()}`.length === 4) {
      this.dispatchFilterChange({ end: date });
    }
  };

  renderPatientHeader = () => {
    const { patients, filters: { patientId }, searchTerm,
      handleSelectPatient, handleUpdateSearchTerm } = this;

    return html`
      <form class="patient-search-input">
        <input
          type="text"
          placeholder="Search by name"
          value=${searchTerm || ''}
          @change=${handleUpdateSearchTerm}
          @keyup=${handleUpdateSearchTerm}
        >
        <i class="fa fa-search"></i>
      </form>
      ${!patientId && searchTerm && patients.length ? html`
        <ul id="patients-search">
          ${this.filterPatients().map(({ firstName, lastName, id }) => html`
              <li
                .patientId=${id}
                @click=${handleSelectPatient}
              >
                <phi-value>${lastName}, ${firstName} (${id})</phi-value>
              </li>
            `)
    }
        </ul>
      ` : ''}
      ${!patientId && (!searchTerm || !searchTerm.length) ? html`
        No results.
      ` : ''}
    `;
  };

  filterPatients = () => {
    const { searchTerm, patients } = this;

    const searchTerms = (searchTerm ?? '').toLowerCase().split(/[\s,]+/g).map(t => t.trim()).filter(Boolean);

    return patients.filter(p =>
      searchTerms.every(term =>
        p.firstName?.toLowerCase().includes(term)
          || p.lastName?.toLowerCase().includes(term)
          || p.id?.toLowerCase().includes(term)
          || `${p.ser || ''}`?.toLowerCase().includes(term)
      )
    );
  }

  handleUpdateSearchTerm = event => {
    this.searchTerm = event.currentTarget.value;
    this.dispatchFilterChange({
      patientId: null
    });
  };

  handleSelectPatient = event => {
    this.dispatchFilterChange({ patientId: event.currentTarget.patientId });
  };

  renderStatusSection = (status, title) => {
    const { renderStatusHeader, renderPatient, patientsByStatus, filters } = this;
    const patients = filterPatients(patientsByStatus[status], filters);

    return html`
      ${status === 'completed' ? html`
        <li class="divider" ?no-patients=${!patients.length}>
        </li>
      ` : ''}
      <li class="nested no-hover" ?no-patients=${!patients.length}>
        ${renderStatusHeader(status, title, patients.length)}
        <ul>
          ${patients.map(renderPatient)}
        </ul>
      </li>
    `;
  };

  renderStatusHeader = (status, title, numPatients) => {
    const { totals } = this;

    return html`
      <div class="status-header">
        <h3>${title}</h3>
        <span>${numPatients} / ${totals[status]}</span>
      </div>
    `;
  };

  renderPatient = ({ lastName, firstName, id, reviewAccepted, needsReview, eotPassing, status, fractionsToReview }) => {
    const { currentPatientId, organizationSlug,
      handlePatientClick, filters: { initialFractionsCount, weeklyFractionsCount } } = this;

    return html`
      <li
        .patientId=${id}
        ?current=${currentPatientId === id}
        @click=${handlePatientClick}
        ?needs-review=${h(!isEndOfTreatmentStatus(status) 
          && (needsReview 
            || (status === 'initial' && fractionsToReview >= initialFractionsCount)
            || (status === 'weekly' && fractionsToReview >= weeklyFractionsCount)
          )
        )}
        ?eot-passing=${h(isEndOfTreatmentStatus(status) && eotPassing)}
        ?review-accepted=${reviewAccepted}        
      >
        <a @click=${preventDefault} href=${`/weekly-check/${organizationSlug}/patient/${id}`}>
          <phi-value>${lastName}, ${firstName} <span>(${id})</span></phi-value>
        </a>
      </li>
    `;
  };

  renderFilters = () => {
    const { filters, statesAll, statesPartial, statusesAll, statusesPartial,
      handleStatusFilterChange, handleWeeklyFractionsCountChange,
      handleWeeklyMoreThanChange, handleInitialFractionsCountChange,
      handleInitialMoreThanChange, handleBillingEligibleChange,
      handleToggleFilters, handleAllChange,
      handleSelectAllStatusesChange, handleSelectAllStatesChange, handleStateFilterChange } = this;

    const { statuses: filteredStatuses, initialMoreThanFractions, weeklyMoreThanFractions,
      initialFractionsCount, weeklyFractionsCount, all, states: filteredStates,
      billingEligible } = filters;

    return html`
      <div id="filters">
        <div class="header">
          <h3>Filters</h3>
          <check-box
            ?checked=${all}
            @change=${handleAllChange}
          >
            Show All Treatments
          </check-box>
        </div>
        <div 
          class="close"
          @click=${handleToggleFilters}
        ><i class="fas fa-times fa-lg"></i></div>
        <div id="columns">
          <div class="col">
            <div>
              <h4>Hospitals</h4>
              ${this.renderHospitalSelect()}
            </div>
            <div>
              <h4>Departments</h4>
              ${this.renderDepartmentSelect()}
            </div>
            <div>
              <h4>Machines</h4>
              ${this.renderMachineSelect()}
            </div>
          </div>          
          <div class="col">
            <h4>Treatment Phase</h4>
            <ul>
              <li>
                <check-box
                  @change=${handleSelectAllStatusesChange}
                  ?partial=${statusesPartial}
                  ?checked=${statusesAll}
                >
                  Select All
                </check-box>
              </li>
              <li class="divider"></li>
              ${statusFilters.map(status => html`
                <li>
                  <check-box  
                    ?checked=${filteredStatuses.includes(status)}
                    .status=${status}
                    @change=${handleStatusFilterChange}
                  >
                    ${statuses[status]}
                  </check-box>
                  ${status === 'weekly' ? html`
                    <ul>
                      <li ?disabled=${!filteredStatuses.includes(status)}>
                        <check-box
                          class="five-frac"
                          ?disabled=${!filteredStatuses.includes(status)} 
                          ?checked=${weeklyMoreThanFractions}
                          @change=${handleWeeklyMoreThanChange}
                        ></check-box>
                        &GreaterEqual;
                        <input
                          type="number"
                          min="1"
                          max="5"
                          value=${weeklyFractionsCount}
                          @change=${handleWeeklyFractionsCountChange}
                        >
                        fractions only
                      </li>
                      <li ?disabled=${!filteredStatuses.includes(status)}>
                        <check-box
                          class="five-frac"
                          ?disabled=${!filteredStatuses.includes(status)}
                          ?checked=${billingEligible}
                          @change=${handleBillingEligibleChange}
                        ></check-box>
                        New treatment block
                      </li>
                    </ul>
                  `: ''}
                  ${status === 'initial' ? html`
                    <ul>
                      <li ?disabled=${!filteredStatuses.includes(status)}>
                        <check-box
                          class="five-frac"
                          ?disabled=${!filteredStatuses.includes(status)} 
                          ?checked=${initialMoreThanFractions}
                          @change=${handleInitialMoreThanChange}
                        ></check-box>
                        &GreaterEqual;
                        <input
                          type="number"
                          min="1"
                          max="5"
                          value=${initialFractionsCount}
                          @change=${handleInitialFractionsCountChange}
                        >
                        fractions only
                      </li>
                    </ul>
                  `: ''}
                </li>
              `)}
            </ul>
          </div>
          <div class="col">
            <h4>Check Results</h4>
            <ul>
              <li>
                <check-box
                  @change=${handleSelectAllStatesChange}
                  ?partial=${statesPartial}
                  ?checked=${statesAll}
                >
                  Select All
                </check-box>
              </li>
              <li class="divider"></li>
              ${stateFilters.map(state => html`
                <li>
                  <check-box
                    @change=${handleStateFilterChange}
                    ?checked=${filteredStates.includes(state)}
                    .state=${state}
                  >
                    ${state === 'pass' ? 'All Passing' : states[state]}
                  </check-box>
                </li>
              `)}
            </ul>
          </div>
        </div>
      </div>
    `;
  };

  handleSelectAllStatesChange = () => {
    const { statesAll } = this;

    this.dispatchFilterChange({ states: statesAll ? [] : stateFilters });
  };

  handleSelectAllStatusesChange = () => {
    const { statusesAll } = this;

    this.dispatchFilterChange({
      statuses: statusesAll ? [] : statusFilters,
      moreThanFive: !statusesAll
    });
  };

  handleStateFilterChange = event => {
    const { filters: { states } } = this;
    const { state } = event.currentTarget;

    this.dispatchFilterChange({
      states: !states.includes(state)
        ? [...states, state]
        : removeValue(states, state)
    });
  };

  handleStatusFilterChange = event => {
    const { filters: { statuses } } = this;
    const { status } = event.currentTarget;

    this.dispatchFilterChange({
      statuses: !statuses.includes(status)
        ? [...statuses, status]
        : removeValue(statuses, status)
    });
  };

  handleAllChange = event => {
    this.dispatchFilterChange({ all: event.detail.checked });
  };

  handleBillingEligibleChange = event => {
    this.dispatchFilterChange({ billingEligible: event.detail.checked });
  };

  handleWeeklyMoreThanChange = event => {
    this.dispatchFilterChange({ weeklyMoreThanFractions: event.detail.checked });
  };

  handleWeeklyFractionsCountChange = event => {
    this.dispatchFilterChange({ weeklyFractionsCount: parseInt(event.currentTarget.value) });
  };

  handleInitialMoreThanChange = event => {
    this.dispatchFilterChange({ initialMoreThanFractions: event.detail.checked });
  };

  handleInitialFractionsCountChange = event => {
    this.dispatchFilterChange({ initialFractionsCount: parseInt(event.currentTarget.value) });
  };

  handleToggleMode = event => {
    this.dispatchFilterChange({
      mode: event.currentTarget.mode
    });
  };

  handlePatientClick(event) {
    const { currentPatientId } = this;
    const { patientId } = event.currentTarget;

    if (patientId !== currentPatientId) {
      this.dispatchEvent(new CustomEvent('patient-change', {
        bubbles: true,
        detail: {
          patientId
        }
      }));
    }
  }

  stateChanged(state) {
    this.showOverlay = showOverlaySelector(state);
    this.organizationSlug = organizationSlugSelector(state);
  }
}

customElements.define('patients-sidebar', PatientsSidebar);