import { html, css, LitElement } from 'lit';
import patientStyles from '../styles/patient';
import { toDayTime } from '../../../../utils/date/format';
import '../../../common/pass-state-table';

class PatientChartCheckHistory extends LitElement {
  static styles = [
    patientStyles,
    css`    
      table tr {
        display: table-row;
      }
      
      thead th:first-child, 
      thead tr th:first-of-type,
      th:not(:first-of-type),
      tbody td {
        flex-basis: auto;
        width: auto;
      }
    `
  ].flat();

  static properties = {
    approvals: { type: Array },
    checkTemplates: { type: Array }
  };

  render() {
    const { buildData } = this;
    const data = buildData();

    return html`
      <section class="history">
        <h4>Chart Approvals</h4>
        <pass-state-table
          .data=${data}
          headerColumns="0"
        ></pass-state-table>        
      </section>
    `;
  }

  buildData = () => {
    const { approvals, checkTemplates } = this;
    const fromChartQA = 'From ChartQA';
    return [
      ['Date', 'Fractions checked', 'Check Template', 'Approver', 'Approval Comments'],
      ...(approvals?.flatMap(({ approver, comments, dateTime, fractions }) =>
        fractions.map(({ startFraction, fraction, planId, checkTemplateId, checkTemplateRevisionNumber }) => [
          toDayTime(dateTime),
          startFraction ? `${planId} (${startFraction} to ${fraction})` : `${planId} (${fraction})`,
          checkTemplates
            ?.find(t =>
              t.id === checkTemplateId && t.revisionNumber === checkTemplateRevisionNumber
            )?.name ?? (checkTemplateId?.indexOf(fromChartQA) > -1 ? fromChartQA : '(unknown)'),          
          approver,
          { value: comments, phi: true }
        ])
      ) ?? [])
    ];
  };
}

customElements.define('patient-chart-check-history', PatientChartCheckHistory);