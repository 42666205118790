import { LitElement, html, css } from 'lit';
import h from '../../../utils/h';
import openInPopout from '../../../utils/openInPopout.js';
import { toDateTime, toDay } from '../../../utils/date/format/index.js';
import getImageId from './utils/getImageId.js';
import { removeValue } from '../../../utils/immutable/array/index.js';
import Keys from '../../../utils/key/KeyCodes.js';

class ImageHeader extends LitElement {
  static styles = css`
    :host {
      --color: var(--neutral-10);
    }
    
    #header {
      background: rgb(50, 54, 57);
      display: block;
      position: relative;
      min-height: 37px;
    }

    #header [buttons] {
      white-space: nowrap;
      position: absolute;
      top: 0;
      right: 8px;
    }

    #header button {
      color: var(--color);
      background: none;
      border: 0;
      line-height: 2.25;
      font-size: 1rem;
      padding: 0;
      margin: 0 16px 0 0;
      outline: 0;
      cursor: pointer;
      z-index: 10000;
    }

    #header button:hover {
      color: #CCC;
    }

    #header button:after {
      font-family: 'Font Awesome 5 Pro';
      font-weight: bold;
      position: relative;
    }

    #header button[close]:after {
      content: '\uf00d';
      top: 2px;
    }

    #header button[popout]:after {
      content: '\uf08e';
      top: 1px;
    }
    
    #carousel ul {
      list-style: none;
      color: var(--color);
      text-align: center;
      margin: 0;
      padding: 0;
    }
    
    #carousel ul.day {
      margin: 0 auto;
      display: flex;
      max-width: 100%;
      justify-content: center;
    }
    
    #carousel .day > li {
      flex: 1;
      max-width: 10%;
      border: 1px solid #CCC;
    }
    
    #carousel .image > li {
      display: inline;
    }
    
    #carousel .image > li span {
      border: 1px solid var(--color);
      background: #333;
      border-radius: 100%;
      display: inline-block;
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
    
    #carousel .image > li[active] span {
      background: var(--color);
    }
    
    #arrows ul {
      color: var(--color);
      margin: 0 auto;
      text-align: center;
      display: flex;
      justify-content: center;
    }
    
    #arrows li {
      display: inline-block;
    }
    
    #arrows li:not(#image-id) {
      cursor: pointer;
      font-size: 1.2em;
      flex: 0 0 30px;
    }

    #arrows li:not(#image-id):hover {
      color: #999;
    }
    
    #arrows li#image-id {
      flex: 0;
      white-space: nowrap;
      line-height: 1.8;
      padding: 0 6px;
    }
    
    #arrows li:before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 500;
    }
    
    #arrows li[disabled] {
      color: #666;
    }
    
    #arrows #previous-day:before {
      content: '\uf100';
    }
    
    #arrows #previous-image:before {
      content: '\uf104';
    }
    
    #arrows #next-image:before {
      content: '\uf105';
    }
    
    #arrows #next-day:before {
      content: '\uf101';
    }
  `;

  static properties = {
    showButtons: { type: Boolean, attribute: 'show-buttons' },
    popoutUrl: { type: String },
    images: { type: Array },
    planSer: { type: Number },
    currentFraction: { type: Number },
    currentGroupIndex: { type: Number },
    currentDicomGroup: { type: Object }
  }

  constructor() {
    super();

    this.downKeys = [];
  }

  connectedCallback() {
    super.connectedCallback();

    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  render() {
    const { showButtons, images: imageDays, planSer, currentFraction, currentGroupIndex, currentDicomGroup } = this;
    const allFractions = imageDays?.flatMap(({ fraction }) => fraction) ?? [];
    const firstFraction = Math.min(...allFractions);
    const lastFraction = Math.max(...allFractions);
    const lastGroupIndex = imageDays?.find(({ fraction }) => fraction === lastFraction)?.images.length - 1;

    return html`
      <div id="header">
        <div id="carousel">
          ${h(imageDays?.length, () => html`
            <ul class="day" @click=${this.handleDicomChange}>
              ${imageDays.map(({ dateTime, fraction, images }) => html`
                <li>
                  ${toDay(dateTime)}
                  <ul class="image">
                    ${images.map((imageGroup, groupIndex) => {
                      const imageId = getImageId(imageGroup);
  
                      return html`
                      <li ?active=${fraction === currentFraction && groupIndex === currentGroupIndex}>
                        <span
                          image
                          .planSer=${planSer}
                          .fraction=${fraction}
                          .groupIndex=${groupIndex}
                          .dicomGroupData=${imageGroup.value}
                          title=${`Fraction: ${fraction}${imageId ? `, Image ID: ${imageId}` : ''}`}
                        ></span>
                      </li>
                    `;
                    })}
                  </ul>
                </li>
              `)}
            </ul>
          `)}
        </div>
        <div id="arrows">
          <ul>
            <li 
              id="previous-day" 
              ?disabled=${currentFraction === firstFraction} 
              @click=${this.handlePreviousDay}
            ></li>
            <li 
              id="previous-image" 
              ?disabled=${currentFraction === firstFraction && currentGroupIndex === 0}
              @click=${this.handlePreviousImage}
            ></li>
            <li id="image-id">
              ${getImageId({ value: currentDicomGroup })}
              ${currentDicomGroup?.acquiredDateTime ? ` - ${toDateTime(currentDicomGroup?.acquiredDateTime)}` : ''}
            </li>
            <li 
              id="next-image"
              ?disabled=${currentFraction === lastFraction && currentGroupIndex === lastGroupIndex}
              @click=${this.handleNextImage}
            ></li>
            <li 
              id="next-day" 
              ?disabled=${currentFraction === lastFraction}
              @click=${this.handleNextDay}
            ></li>
          </ul>
        </div>
        ${h(showButtons, () => html`
          <div buttons>
            <button popout @click=${this.handlePopout}></button>
            <button close @click=${this.handleClose}></button>
          </div>
        `)}
      </div>
    `;
  }

  handleKeyDown = event => {
    if (this.downKeys.includes(event.key)) return;

    this.downKeys.push(event.key);

    if (event.key === Keys.ArrowLeft) {
      if (event.shiftKey) {
        this.handlePreviousDay();
      } else {
        this.handlePreviousImage();
      }
    } else if (event.key === Keys.ArrowRight) {
      if (event.shiftKey) {
        this.handleNextDay();
      } else {
        this.handleNextImage();
      }
    }
  };

  handleKeyUp = event => {
    this.downKeys = removeValue(this.downKeys, event.key);
  };

  handleClose() {
    this.close();
  }

  handlePopout() {
    const { popoutUrl } = this;

    openInPopout(popoutUrl);

    this.close();
  }

  handleDicomChange(event) {
    if (!event.target.matches('[image]')) return;

    this.dispatchDicomChange(event.target);
  }

  handleNextImage() {
    this.dispatchDicomChange(this.getImageData().nextImage);
  }

  handlePreviousImage() {
    this.dispatchDicomChange(this.getImageData().previousImage);
  }

  handleNextDay() {
    this.dispatchDicomChange(this.getImageData().nextDay);
  }

  handlePreviousDay() {
    this.dispatchDicomChange(this.getImageData().previousDay);
  }

  dispatchDicomChange(next) {
    if (!next) return;

    const { planSer, fraction, groupIndex, dicomGroupData } = next;

    this.dispatchEvent(new CustomEvent('dicom-change', {
      detail: { planSer, fraction, groupIndex, dicomGroupData },
      composed: true,
      bubbles: true
    }));
  }

  getImageData() {
    const all = [...this.shadowRoot.querySelectorAll('#carousel [image]')];
    const active = this.shadowRoot.querySelector('#carousel [active] [image]');
    const index = all.indexOf(active);

    const previousDayImages = all.filter(({ fraction }) => active.fraction > fraction);
    const previousDayFraction = Math.max(...previousDayImages.map(({ fraction }) => fraction));
    const [previousDay] = previousDayImages.filter(({ fraction }) => fraction === previousDayFraction);

    const nextDayImages = all.filter(({ fraction }) => active.fraction < fraction);
    const nextDayFraction = Math.min(...nextDayImages.map(({ fraction }) => fraction));
    const [nextDay] = nextDayImages.filter(({ fraction }) => fraction === nextDayFraction);

    return {
      all,
      active,
      index,
      previousDay,
      nextDay,
      previousImage: index > 0 ? all[index - 1] : undefined,
      nextImage: index < all.length - 1 ? all[index + 1] : undefined
    };
  }

  close() {
    this.dispatchEvent(new CustomEvent('dicom-close', { bubbles: true, composed: true }));
  }
}

customElements.define('image-header', ImageHeader);