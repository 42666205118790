import { html, LitElement } from 'lit';
import { resolveData as resolveDataBase } from '../utils';
import patientStyles from '../styles/patient';
import { SHOW_NON_EMPTY_VALUE } from '../../../common/pass-state-table';

const resolveData = ({ data, field, unit = '', htmlClass = '', ...rest }) =>
  resolveDataBase({ data, field, unit, showIcon: true, showValue: SHOW_NON_EMPTY_VALUE, htmlClass, ...rest });

class PatientInitial extends LitElement {
  static styles = patientStyles;

  static properties = {
    checks: { type: Object },
    verifications: { type: Array },
    verifyKey: { type: String },
    verifyField: { type: String }
  };

  render() {
    const { buildData, checks } = this;

    if (!checks || !checks.length || !checks.some(c => !c.IsInactive)) return;

    const data = buildData();

    return html`
      <section class="checks">
        <h4>Initial Treatment Checks</h4>  
        <pass-state-table 
          even
          .data=${data}
          headerColumns="0"
        ></pass-state-table>  
      </section>
    `;
  }

  buildData = () => {
    const { checks, verifyKey, verifyField, verifications } = this;

    return [
      checks.map(({ id }) => id),
      checks.map(check => resolveData({
        data: { check },
        field: 'check',
        verifyKey,
        verifyField,
        verifyIndexed: true,
        verifyIndex: check.id,
        verifications
      }))
    ];
  };
}

customElements.define('patient-initial', PatientInitial);