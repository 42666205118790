﻿import {
  organizationIdSelector,
  organizationSelector,
  patientIdSelector,
  patientSerSelector
} from '../redux/app/selectors';

const getDefaultParam = paramName => {
  switch (paramName) {
    case 'organization':
      return organizationSelector();
    case 'patientId':
      return patientIdSelector();
    case 'patientSer':
      return patientSerSelector();
    case 'organizationId':
      return organizationIdSelector();
  }
};

export default getDefaultParam;