import { html, css, LitElement } from 'lit';
import patientStyles from '../styles/patient';
import './plan-details';
import './plan-fields';
import './plan-imaging';
import './plan-ref-points';
import { isImagingTableEmpty } from './plan-imaging';
import h from '../../../../utils/h';

class PatientPlan extends LitElement {
  static styles = [
    patientStyles,
    css`    
      [section] {
        display: block;
      }
    `
  ].flat();

  static properties = {
    plan: { type: Object },
    planNumber: { type: Number },
    courseNumber: { type: Number },
    treatmentDates: { type: Array },
    imagingDates: { type: Array },
    showPreTreatmentDates: { type: Boolean },
    verifications: { type: Array },
    currentDicom: { type: Object },
    patientId: { type: String },
    showAll: { type: Boolean }
  };

  render() {
    const { plan, planNumber, courseNumber, treatmentDates, imagingDates, showPreTreatmentDates, verifications,
      currentDicom, patientId, showAll } = this;
    const { id, imagings, imagingCouchLines, imagingAngleLines, imagingAngleMode, imagingCouchShow,
      imagingOrderFollowed, treatments, skipImagingTimes } = plan;
    return html`
      <h3><span>Plan:</span> ${id}</h3>
      <plan-ref-points
        section
        id=${`course${courseNumber}-plan${planNumber}-refpts`}
        .plan=${plan}
        .verifications=${verifications}
      ></plan-ref-points>
      ${h(treatments.length || imagings.length, html`
        <plan-details
          section
          id=${`course${courseNumber}-plan${planNumber}-details`}
          .plan=${plan}
          .treatmentDates=${treatmentDates}
          .verifications=${verifications}
        ></plan-details>
        ${h(imagings && !isImagingTableEmpty(plan, imagingDates), html`<plan-imaging
          ?showAll=${showAll}
          section 
          id=${`course${courseNumber}-plan${planNumber}-imaging`}
          planId=${id}
          .imagings=${imagings}
          .couchLines=${imagingCouchLines}
          .angleLines=${imagingAngleLines}
          .angleMode=${imagingAngleMode}
          .showCouchChart=${imagingCouchShow}
          .imagingDates=${imagingDates}
          .orderFollowed=${imagingOrderFollowed}
          .skipImagingTimes=${skipImagingTimes}
          .showPreTreatmentDates=${showPreTreatmentDates}
          .verifications=${verifications}
          .currentDicom=${currentDicom}
          .patientId=${patientId}
        ></plan-imaging>`)}
        <plan-fields
          section
          id=${`course${courseNumber}-plan${planNumber}-fields`}
          .plan=${plan}
          .treatmentDates=${treatmentDates}
          .verifications=${verifications}
        ></plan-fields>
      `)}
    `;
  }
}

customElements.define('patient-plan', PatientPlan);