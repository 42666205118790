import h from '../../../../utils/h';
import { buildDateHeaders, resolveData } from './index';

/**
 * @typedef {{}} LitElement A LitElement with properties
 * @typedef {{ times: { asString }[] }} DateDatum
 * @typedef {DateDatum[]} DateData Values returned from createDateData
 * @typedef {string|function(element:LitElement, source:{}):(string|{})} FieldDefinition
 * @typedef {{
 *    firstColumn:number,
 *    unitOfMeasurement:string,
 *    axis: ((function(element):{ label:string })|{ label:string }),
 *    data: function(element):{}[]|{}[]
 *  }} ChartDefinition
 * @typedef {{
 *  chart:ChartDefinition?,
 *  header:string,
 *  firstField:FieldDefinition?,
 *  firstProperties:{},
 *  field:FieldDefinition,
 *  skip:(function(element:LitElement, source:{}):boolean)?,
 *  showIcon:boolean = true,
 *  showValue:boolean = false,
 *  unit:string = '',
 *  htmlClass:string = undefined,
 *  defaultPrecision:number = 1,
 *  rowAttributes:{}
 * }} RowDefinition
 */
/**
 * @typedef BuildDatedTableDataOptions
 * @property {{}} element The LitElement with the properties related to this.
 * @property {DateData} dates Values returned from createDateData()
 * @property {RowDefinition[]} rows
 * @property {{}} [firstData] Source for used for the first field
 * @property {{}[]} data Sources used for every other field
 * @property {boolean} [showFractions=false]
 * @property {boolean} [showPlanned=false]
 * @property {{}} [rowDefaults={}] Defaults applied to all row data (except charts)
 * @property {{}} [firstDefaults={}] Defaults applied only to the first cell
 * @property {boolean} [showHeader=true] Indicates if it should include the date header, as well as fractions
 *  and planned (depending on their booleans. If this is false, showFractions and showPlanned do nothing.
 */
/**
 * @param {BuildDatedTableDataOptions} options
 * @return {{}[]}
 */
const buildDatedTableData = ({
  element, dates, rows, firstData, data, showFractions = false,
  showPlanned = false, rowDefaults = {}, firstDefaults = {},
  showHeader = true, verifications = []
}) => [
  ...(showHeader ? buildDateHeaders(dates) : []),
  showHeader && showFractions && [
    '',
    'Fraction',
    ...dates
      .flatMap(({ times }) => times)
      .map(({ asString }) => data.find(({ episodeDateTime }) => episodeDateTime === asString)?.fraction)
  ],
  showHeader && showPlanned && ['', 'Planned', ...dates.flatMap(({ times }) => times).map(_ => '')],
  ...rows
    .filter(({ skip }) => !skip?.(element))
    .map(row => {
      const { chart, header, firstField, field, showIcon = true, showValue = false,
        firstProperties = {}, htmlClass = undefined, defaultPrecision = 1, unit = '',
        rowAttributes = {}, unverifiable = false, customBuilder,
        verifyKey, verifyField, verifyEach, verifyIndexed, ...cellAttributes } = { ...rowDefaults, ...row };

      const resolveDataProps = { unit, defaultPrecision, htmlClass, showIcon, showValue };
      const firstProps = { ...rowDefaults, ...row, ...firstDefaults, ...firstProperties };

      if (customBuilder) {
        return customBuilder(data, dates, element, row);
      }

      return chart
        ? buildChart(chart, element, cellAttributes)
        : Object.assign([
          getSimpleValue(header, element),
          getResolvedValue(firstField || field, element, firstData,
            cellAttributes, { ...resolveDataProps, ...firstProps }),
          ...dates
            .flatMap(({ times }) => times)
            .map(({ asString }) => {
              const source = data.find(({ episodeDateTime }) => episodeDateTime === asString);

              const verifyKeyValue = verifyKey
                ? (typeof verifyKey === 'function' ? verifyKey(field, element, source) : verifyKey)
                : source?.key;
              const verifyFieldValue = verifyField
                ? (typeof verifyField === 'function' ? verifyField(field, element, source) : verifyField)
                : field;

              return getResolvedValue(field, element, source, cellAttributes, resolveDataProps,
                unverifiable, verifyKeyValue, verifyFieldValue, verifyEach, verifyIndexed, verifications);
            })
        ], rowAttributes);
    })
].filter(v => v && (!Array.isArray(v) || !v.some(c => c?.inactive)));

const buildChart = ({ axis, data, unitOfMeasurement, firstColumn }, element, cellAttributes) => ({
  chart: true,
  firstColumn: getSimpleValue(firstColumn, element),
  unitOfMeasurement: getSimpleValue(unitOfMeasurement, element),
  axis: getSimpleValue(axis, element),
  data: getSimpleValue(data, element),
  ...cellAttributes
});

const getSimpleValue = (field, element) =>
  typeof field === 'function'
    ? field(element)
    : field;

/* eslint-disable max-params */
const getResolvedValue = (field, element, source, cellAttributes, {
  unit, showIcon, showValue, htmlClass, defaultPrecision
}, unverifiable, verifyKey, verifyField, verifyEach, verifyIndexed, verifications) => {
/* eslint-enable max-params */
  let data = source;

  if (typeof field === 'function') {
    data = ({ field: field(element, source, verifications) });
    field = 'field';
  }

  return h(data, {
    ...resolveData({
      data, field, unit, showIcon,
      showValue, htmlClass, defaultPrecision, verifyKey, verifyField, verifications, verifyEach, verifyIndexed
    }),
    unverifiable,
    ...cellAttributes
  });
};

export default buildDatedTableData;