import { html, LitElement } from 'lit';
import patientStyles from '../styles/patient';
import { resolveData } from '../utils';
import '../../../common/pass-state-table';

class PatientEndOfTreatment extends LitElement {
  static styles = patientStyles;

  static properties = {
    endOfTreatmentChecks: { type: Object },
    verifications: { type: Array },
    verifyKey: { type: String },
    verifyField: { type: String }
  };

  render() {
    const { buildData, endOfTreatmentChecks } = this;

    if (!endOfTreatmentChecks || !endOfTreatmentChecks.length || !endOfTreatmentChecks.some(c => !c.isInactive)) return;

    const data = buildData();

    return html`
      <section class="eot">
        <h4>End of Treatment Checks</h4>     
        <pass-state-table
          even
          .data=${data}
          headerColumns="0"
        ></pass-state-table> 
      </section>
    `;
  }

  buildData = () => {
    const { endOfTreatmentChecks, verifyKey, verifyField, verifications } = this;

    return [
      endOfTreatmentChecks.map(({ id }) => id),
      endOfTreatmentChecks.map(check => resolveData({
        data: { check },
        field: 'check',
        verifyKey,
        verifyField,
        verifyIndexed: true,
        verifyIndex: check.id,
        verifications
      }))
    ];
  };
}

customElements.define('patient-end-of-treatment', PatientEndOfTreatment);