import { RenderingEngine } from '@cornerstonejs/core';
import initDemo from './demo/initDemo';
import {
  addTool,
  CrosshairsTool,
  PanTool, SegmentationDisplayTool,
  StackScrollMouseWheelTool,
  ZoomTool
} from '@cornerstonejs/tools';
import singleFile from '../../../../utils/singleFile.js';

export const renderingEngineId = 'radRenderingEngine';

let renderingEngine;
let initialized = false;

export const getRenderingEngine = () => {
  if (!initialized) throw new Error('Cannot get RenderingEngine before initializing.');

  return renderingEngine;
};

export const loadCornerstone = singleFile(
  async () => {
    if (!initialized) {
      initialized = true;

      await initDemo();
      // cache.setMaxCacheSize(4 * 1024 * 1024 * 1024); // 4GB

      addTool(ZoomTool);
      addTool(PanTool);
      addTool(CrosshairsTool);
      addTool(StackScrollMouseWheelTool);
      addTool(SegmentationDisplayTool);

      renderingEngine = new RenderingEngine(renderingEngineId);
    }

    return { renderingEngine };
  }, 'cornerstone-loaded'
);