const key = 'disableImagePreview';

const imagePreview = {
  enable: () => {
    localStorage.removeItem(key);
  },
  disable: () => {
    localStorage.setItem(key, '1');
  },
  toggle: () => {
    imagePreview.isEnabled() ? imagePreview.disable() : imagePreview.enable();
  },
  isEnabled: () =>
    !localStorage.getItem(key)
};

export default imagePreview;