const clone = (obj, deep = true) => {
  if (typeof obj !== 'object' || !obj || obj === '') {
    return obj; // primitive or function, can't clone
  }

  if (Symbol.iterator in obj) {
    return Array.prototype.map.call(obj, value => clone(value, deep));
  }

  const newObj = {};

  if (obj.clone && typeof obj.clone === 'function') {
    return obj.clone();
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] = deep ? clone(obj[key], deep) : obj[key];
    }
  }

  return newObj;
};

export default clone;