import toDateObject from '../toDateObject';
import toDay from './toDay';

// {shortDate}/YYYY - 03/14/2020
// @TODO Localize
const toLocaleDate = date => {
  const dateObj = toDateObject(date);

  return `${toDay(dateObj)}/${dateObj.getFullYear()}`;
};

export default toLocaleDate;