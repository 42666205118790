const deepEquals = (a, b) =>
  a === b
    || (
      typeof a === 'object'
        && typeof b === 'object'
        && Object.keys(a).length === Object.keys(b).length
        && Object.entries(a).every(([key, aValue]) => deepEquals(aValue, b[key]))
    )
;

export default deepEquals;