import { randomString } from '../../../string';
import { ADD_TOAST, CLEAR_TOAST, UPDATE_TOAST } from '../types';
import { TOAST_FADE_IN_DURATION, TOAST_FADE_OUT_DURATION,
  ToastStates } from '../../../../components/common/toasts-container';

const addToast = (message, { color = '#27AE60', timeout = 5, id = randomString(24) } = {}) =>
  dispatch => {
    setTimeout(() => {
      dispatch({
        type: UPDATE_TOAST,
        id,
        state: ToastStates.STATIC
      });
    }, 1);

    setTimeout(() => {
      dispatch({
        type: UPDATE_TOAST,
        id,
        state: ToastStates.FADE_OUT
      });
    }, (TOAST_FADE_IN_DURATION + timeout) * 1000);

    setTimeout(() => {
      dispatch({
        type: CLEAR_TOAST,
        id
      });
    }, (timeout + TOAST_FADE_IN_DURATION + TOAST_FADE_OUT_DURATION) * 1000);

    dispatch({
      type: ADD_TOAST,
      id,
      message,
      color,
      state: ToastStates.FADE_IN
    });
  };

export default addToast;