/**
 * @external Viewport
 * @see @cornerstone/core:Viewport
 */

import normalizeToRGBAString from '../../../../utils/color/normalizeToRGBAString.js';

/**
 * structures should be an objects with a color (either RGB/RGBA string, with or without the #, or array of 3/4 ints)
 *   and points, which is a nested array, with each single line being an array of x/y points:
 *
 *   [
 *     [[0,0],[50,50]], // line 1
 *     [[0,50],[50,0]] // line 2
 *   ]
 *
 * All points should be in millimeters.
 *
 * @param {HTMLCanvasElement} canvas
 * @param {Viewport} viewport
 * @param {[{ color:string, lines:[[[int, int]]] }]} structures
 */
const drawStructures2D = (canvas, viewport, structures) => {
  // console.log(viewport);
  const ctx = canvas.getContext('2d');
  const worldToCanvas = viewport.worldToCanvas.bind(viewport);

  structures.forEach(({ color, lines }) =>
    lines.forEach(drawLine(ctx, worldToCanvas, color))
  );
};

const drawLine = (ctx, worldToCanvas, color) => points => {
  ctx.strokeStyle = normalizeToRGBAString(color);

  ctx.beginPath();
  points.forEach(([worldX, worldY], index) => {
    const [canvasX, canvasY] = worldToCanvas([worldX, worldY, 0]);

    if (index === 0) {
      ctx.moveTo(canvasX, canvasY);
    } else {
      ctx.lineTo(canvasX, canvasY);
    }
  });
  ctx.closePath();
  ctx.stroke();
};

export default drawStructures2D;