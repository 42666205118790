// Recursively walks through all properties of the object and grabs any keys named "passState"
// excludes is an array of keys to not traverse or count
import PassState from '../../../../PassState';

// Leaving console.debug in here, but on only conditionally, since this is so finicky and we may need it again.
// Should never be committed with this value set to "true".
/* eslint-disable no-console */
const debug = false;

const findVerification = (verifications, field, key, index) =>
  verifications.find(v =>
    v.key === key
       && v.field === field
       && (v.index === undefined || index === v.index)
  );

// There are PassStateValues that don't have a passState, but for the purposes of
//  this method, it only matters if they have a passState or not.
const isPassStateValue = obj =>
  Object.prototype.hasOwnProperty.call(obj, 'passState');

// eslint-disable-next-line max-params
const getEffectivePassState = (passStateValue, verifications, field, key, index, includeVerify) => {
  const verification = findVerification(verifications, field, key, index);

  debug && console.debug(field, verification ? 'has verification' : 'no verification',
    { field, key, index, verifications });

  return verification ? (includeVerify ? PassState.VERIFIED : PassState.PASS) : passStateValue.passState;
};

// eslint-disable-next-line max-params
const addPassState = (passStateValue, verifications, field, key, index, counts, includeVerify) => {
  const passState = getEffectivePassState(passStateValue, verifications, field, key, index, includeVerify);
  debug && console.debug(field, passState);

  counts[passState] = (counts[passState] ?? 0) + 1;

  return counts;
};

const countPassStates = (obj, verifications, excludes = [], counts = {},
    { key = undefined, parentField = undefined, index = undefined, includeVerify = false } = {}) => {
  // Either null or a primitive, so just return any counts we were given.
  if (!obj || typeof obj !== 'object') return counts;

  if (isPassStateValue(obj)) {
    debug && console.debug(parentField, 'is a passState', obj);
    return addPassState(obj, verifications, parentField, key, index, counts, includeVerify);
  }

  if (Array.isArray(obj)) {
    debug && console.debug(parentField, 'is an array', obj);
    return obj.reduce((valueAcc, value, index) => {
      debug && console.debug('value in array', value);
      return countPassStates(value, verifications, excludes, counts,
        // value.value?.id is for referencePointDoses
        { key, index: value.id ?? value.value?.id ?? index, parentField, includeVerify });
    }, counts);
  }

  // It is some other object, so we'll iterate over its fields.
  return Object.entries(obj)
    .filter(([field]) => !excludes.includes(field))
    .reduce((acc, [field, value]) =>
      countPassStates(value, verifications, excludes, counts,
        { key: obj.key ?? key, parentField: field, includeVerify }), counts);
};

export default countPassStates;