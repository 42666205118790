const getQueryParams = () =>
  location.search.length
    ? location.search.slice(1)
      .split(/&/g)
      .map(v => v.split(/=/))
      .reduce(
        (obj, [key, value]) =>
          // if value is undefined, it means it's a boolean parameter, so we'll just put true
          Object.assign(obj, { [key]: value ?? true }),
        {}
      )
    : {}
;

export default getQueryParams;