﻿import { html, css, LitElement } from 'lit';
import h from '../../utils/h';

export default class RadConfirm extends LitElement {
  static styles = css`      
    :host {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .5);
      z-index: 10000;
      --min-width: 240px;
    }
    
    #container {
      background: var(--light-color);
      padding: 24px;
      border-radius: 5px;
      position: absolute;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 24%;
      min-width: var(--min-width);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    h3 {
      margin: 0 auto;
    }
    
    button {
      font-size: 15px;
      font-weight: 400;
      display: inline-block;
      padding: 12px 24px;
      background: var(--primary-color);
      color: var(--neutral-8);
      border: 0;
      border-radius: 5px;
      margin: 16px 8px 0 0;
      min-width: 96px;
      transition: all .25s; 
    }

    button:hover {
      background: var(--blue-2);
    }

    button:focus {
      outline: none;
    }

    button[cancel] {
      color: var(--neutral-2);
      border: 1px solid var(--neutral-5);
      background: var(--light-color);
    }
    
    button[cancel]:hover {
      background: var(--neutral-7);
    }
  `;

  static properties = {
    title: { type: String },
    confirmText: { type: String },
    cancelText: { type: String }
  };

  constructor() {
    super();

    this.confirmText = 'Yes';
    this.cancelText = 'No';
  }

  updated(_) {
    this.updateComplete.then(() => {
      this.shadowRoot.querySelector('button[confirm]')?.focus();
    });
  }

  render() {
    const { title, confirmText, cancelText, handleConfirm, handleCancel } = this;

    return html`
      <div id="container">
        ${title ? html`<h1>${title}</h1>` : ''}
        <div>
          <slot></slot>
        </div>
        <buttons>
          ${h(confirmText, html`<button confirm @click=${handleConfirm}>${confirmText}</button>`)}
          ${h(cancelText, html`<button cancel @click=${handleCancel}>${cancelText}</button>`)}
        </buttons>
      </div>
    `;
  }

  handleConfirm = event => {
    event.stopPropagation();
    this.dispatchEvent(new CustomEvent('confirm', { composed: true }));
  };

  handleCancel = event => {
    event.stopPropagation();
    this.dispatchEvent(new CustomEvent('cancel', { composed: true }));
  }
}

customElements.define('rad-confirm', RadConfirm);