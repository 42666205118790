const filterForSkippedDates = skipTimes => (acc, dateObj) => {
  const times = dateObj.times.filter(({ asString }) => !skipTimes.includes(asString));

  if (times.length) {
    acc.push({ ...dateObj, times });
  }

  return acc;
};

export default filterForSkippedDates;