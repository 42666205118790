import translatePointBy from './translatePointBy.js';

/**
 * Given an array of points (expressed as [x, y]), translates each point.
 * @param {[Number, Number][]} points
 * @param {Number} x
 * @param {Number} y
 * @return {[Number, Number][]} New array with the translated points.
 */
const translatePoints = (points, x, y) =>
  points.map(translatePointBy(x, y));

export default translatePoints;