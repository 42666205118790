// Micro-framework for only allowing one call to a function, with a set of parameters, to be processed at once.
// If multiples are found, they will wait until the original is completed.
import deepEquals from './object/deepEquals.js';

const singleFile = (method, readyEventName, argsFilter = () => true) => {
  /** Array of arrays of parameters used to initiated method already. */
  const initializing = [];

  const wait = args => new Promise(resolve => {
    const eventListener = ({ detail: { args: readyArgs, result } }) => {
      if (!deepEquals(readyArgs, args)) return;

      document.removeEventListener(readyEventName, eventListener);
      resolve(result);
    };

    document.addEventListener(readyEventName, eventListener);
  });

  return async (...args) => {
    const keyArgs = args.filter(argsFilter);

    if (initializing.find(params => deepEquals(params, keyArgs))) {
      return await wait(keyArgs);
    }

    initializing.push(keyArgs);

    const result = await method(...args);

    document.dispatchEvent(new CustomEvent(readyEventName, { detail: { args: keyArgs, result } }));
    initializing.splice(initializing.findIndex(params => deepEquals(params, keyArgs)), 1);

    return result;
  };
};

export default singleFile;