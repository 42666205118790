import getOrCreateElement from './getOrCreateElement';

const updateMetadata = ({ title, description }) => {
  title && setTitle(title);
  description && setDescription(description);
};

const setTitle = title => {
  getOrCreateElement(document.head, 'title').innerText = title;

  Object.assign(
    getOrCreateElement(document.head, 'meta[property="og:title"]', 'meta'),
    { property: 'og:title', content: title }
  );
};

const setDescription = description => {
  Object.assign(
    getOrCreateElement(document.head, 'meta[property="og:description"]', 'meta'),
    { property: 'og:description', content: description }
  );
};

export default updateMetadata;