// eslint-disable-next-line max-params
const getPixelCoordinates = (x, y, z, spacing, direction, origin) => {
  // TODO: handle direction for decubitus
  const i = Math.round((x - origin[0]) / (spacing[0] * direction[0]));
  const j = Math.round((y - origin[1]) / (spacing[1] * direction[4]));
  const k = Math.round((z - origin[2]) / (spacing[2] * direction[8]));

  return { i, j, k };
};

export default getPixelCoordinates;
