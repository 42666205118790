import { html, LitElement } from 'lit';
import patientStyles from '../styles/patient';
import { angleAxisTransform } from '../utils';
import buildDatedTableData from '../utils/buildDatedTableData';
import { SHOW_NON_EMPTY_VALUE } from '../../../common/pass-state-table';
import filterForSkippedDates from '../utils/filterForSkippedDates';
import isDatedTableEmpty from '../utils/isDatedTableEmpty';

export const isPlanDetailTableEmpty = (plan, treatmentDates) =>
  isDatedTableEmpty(rows, { plan }, treatmentDates, plan.treatments);

const couchYAxis = {
  increment: 1,
  label: 'Δ Position [cm]'
};

const angleYAxis = {
  increment: 0.5,
  label: 'Δ Position [deg]'
};

const rows = [
  {
    header: 'Dose Limit Override',
    field: 'doseOverride',
    showIcon: false,
    skip: ({ plan: { treatments = [] } }) =>
      !treatments.some(t => t.doseOverride)
  },
  {
    header: 'Manual Treatment',
    field: 'manual',
    showIcon: false,
    skip: ({ plan: { treatments = [] } }) =>
      !treatments.some(t => t.manual)
  },
  {
    header: 'Machine',
    field: 'machineId',
    showIcon: true,
    showValue: SHOW_NON_EMPTY_VALUE
  },
  {
    header: 'Treatment Frequency Order Followed',
    field: 'treatmentFrequencyOrderFollowed',
    showIcon: true,
    showValue: SHOW_NON_EMPTY_VALUE
  },
  {
    header: 'Bolus Frequency Order Followed',
    field: 'bolusFrequencyOrderFollowed',
    showValue: false
  },
  {
    header: 'Couch Positions Match for All Fields',
    field: 'couchPositionsMatchForAllFields',
    showValue: false
  },
  {
    chart: {
      firstColumn: 2,
      unitOfMeasurement: 'cm',
      axis: couchYAxis,
      data: ({ plan: { treatmentCouchLines } }) => ['Couch Vrt', 'Couch Lng', 'Couch Lat']
        .map((header, index) => ({ header, ...treatmentCouchLines[index] }))
    },
    skip: ({ plan: { treatmentCouchShow } }) => !treatmentCouchShow
  },
  {
    chart: {
      chart: true,
      firstColumn: 2,
      unitOfMeasurement: '°',
      axis: ({ plan: { treatmentAngleMode } }) =>
        ({ ...angleYAxis, transform: angleAxisTransform(treatmentAngleMode) }),
      data: ({ plan: { treatmentAngleLines } }) => ['Couch Rtn', 'Pitch Angle', 'Roll Angle']
        .map((header, index) => ({ header, ...treatmentAngleLines[index] }))
    },
    skip: ({ plan: { treatmentAngleMode } }) => treatmentAngleMode === 'skip'
  },
  {
    header: 'Treatment Initials',
    field: 'treatingRtts'
  },
  {
    header: 'Treatment Notes',
    field: 'treatingNotes',
    phi: true
  }
];

class PlanDetails extends LitElement {
  static styles = patientStyles;

  static properties = {
    plan: { type: Object },
    treatmentDates: { type: Array },
    verifications: { type: Array }
  };

  render() {
    const { plan, treatmentDates, verifications } = this;

    if (!plan || isPlanDetailTableEmpty(plan, treatmentDates)) return;

    const { id } = plan;

    const data = buildDatedTableData({
      element: this,
      dates: treatmentDates
        .reduce(filterForSkippedDates(plan.skipTreatmentTimes), []),
      rows,
      firstData: plan,
      data: plan.treatments,
      showFractions: true,
      showPlanned: true,
      rowDefaults: { showValue: true },
      firstDefaults: { showValue: true },
      verifications
    });

    return html`
      <section class="treatment">
        <h4>Plan - ${id}</h4>
        <pass-state-table
          even
          .data=${data}
          headerRows="5"
          freezeRows="5"
          headerColumns="2"
          freezeColumns="2"
        ></pass-state-table>
      </section>
    `;
  }
}

customElements.define('plan-details', PlanDetails);