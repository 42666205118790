import { Directive, directive } from 'lit/directive';
import { distinct } from '../utils/immutable/array';
import splitHtmlLines from '../utils/html/splitHtmlLines.js';

export class ApplyAttributesDirective extends Directive {
  update(part, [attributes]) {
    if (!attributes) return;

    const attributeKeys = Object.keys(attributes);

    part.parentNode.appliedAttributes ??= [];

    // Remove each attribute that was applied before that isn't being applied now.
    part.parentNode.appliedAttributes.filter(key => !attributeKeys.includes(key))
      .forEach(key => {
        const attribute = /^[a-z]/i.test(key) ? key : key.slice(1);

        if (key[0] === '.') {
          delete part.parentNode[attribute];
        } else if (key[0] === '@') {
          // @TODO if needed
        } else {
          part.parentNode.removeAttribute(attribute);
        }
      });

    Object.entries(attributes).filter(([_, value]) => value !== undefined)
      .forEach(([key, value]) => {
        const attribute = /^[a-z]/i.test(key) ? key : key.slice(1);

        if (key[0] === '?') {
          if (value) {
            part.parentNode.setAttribute(attribute, '');
          }
        } else if (key[0] === '.') {
          part.parentNode[attribute] = value;
        } else if (key[0] === '@') {
          throw new Error('Not implemented.');
          // there is trickiness with avoiding overapplying and removing
          // not currently needed, but placeholder added for if it is used
        } else {
          part.parentNode.setAttribute(attribute, splitHtmlLines(value));
        }

        part.parentNode.appliedAttributes.push(key);
      });

    part.parentNode.appliedAttributes = distinct(part.parentNode.appliedAttributes);

    return this.render();
  }

  render() {
    return '';
  }
}

const applyAttributes = directive(ApplyAttributesDirective);

export default applyAttributes;