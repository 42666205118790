/* global UTIF */
import { html, css, LitElement } from 'lit';
import 'utif/UTIF.js';
import h from '../../../utils/h';

class TiffViewer extends LitElement {
  static styles = css`
    :host {
      display: block;
      min-height: 100%;
      position: relative;
      overflow: auto;
    }
    
    #loading {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem;
    }
    
    a {
      display: inline-block;
      background: var(--primary-3);
      color: #FFF;
      padding: 8px 16px;
      border: 0;
      margin: 32px auto 0;
      cursor: pointer;
      text-decoration: none;
    }
    
    img {
      cursor: pointer;
    }
    
    img:not([zoomed]) {
      max-width: 100%;
    }
  `;

  static properties = {
    data: { type: String },
    loading: { type: Boolean },
    zoomed: { type: Boolean }
  };

  get image() {
    return this.shadowRoot.querySelector('img');
  }

  updated(changed) {
    if (changed.has('data')) {
      this.error = false;
      this.load();
    }
  }

  render() {
    const { error, url, zoomed, handleImageZoom } = this;

    return html`
      ${h(error, html`
        <div id="error">
          <p>There was an error rendering this TIFF file.</p>
          <p>It may require features that this viewer does not support, or is too large to view in your browser.</p>
          <p>You can try downloading the file and viewing it on your computer.</p>
          <a href=${url} download=${url}>Download</a>
        </div>
      `)}
      <img ?zoomed=${zoomed} @click=${handleImageZoom} />
    `;
  }

  handleImageZoom() {
    this.zoomed = !this.zoomed;
  }

  async load() {
    const { data } = this;

    const buff = await data.arrayBuffer();

    try {
      const ifds = UTIF.decode(buff);
      let vsns = ifds;
      let ma = 0;
      let [page] = vsns;

      if (ifds[0].subIFD) {
        vsns = vsns.concat(ifds[0].subIFD);
      }

      for (let i = 0; i < vsns.length; i++) {
        const img = vsns[i];

        if (img['t258'] == null || img['t258'].length < 3) continue;

        const ar = img['t256'] * img['t257'];

        if (ar > ma) {
          ma = ar;
          page = img;
        }
      }

      UTIF.decodeImage(buff, page, ifds);

      const rgba = UTIF.toRGBA8(page);
      const w = page.width;
      const h = page.height;

      const canvas = document.createElement('canvas');
      canvas.width = w;
      canvas.height = h;

      const ctx = canvas.getContext('2d');
      const imageData = ctx.createImageData(w, h);

      for (let i = 0; i < rgba.length; i++) {
        imageData.data[i] = rgba[i];
      }

      ctx.putImageData(imageData, 0, 0);

      const { image } = this;
      image.setAttribute('src', canvas.toDataURL());
      this.dispatchEvent(new CustomEvent('load', { composed: true, bubbles: true }));
    } catch {
      this.error = true;
    }
  }
}

customElements.define('tiff-viewer', TiffViewer);