import { LitElement, css } from 'lit';

class RadOverlay extends LitElement {
  static styles = css`
    :host {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .5);
    }
  `;
}

customElements.define('rad-overlay', RadOverlay);