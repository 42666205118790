import * as THREE from 'three';

const kramerColors = {
  red: 0xff0000,
  aqua: 0x0000ff,
  skyBlue: 0x7ec0ee,
  green: 0x00ff00,
  white: 0xffffff
};

// [origin, size, color] where a and b are opposite corners of a cube
const parts = [
  [[0, 0, 0], [50, 50, 50], kramerColors.skyBlue], // torso
  [[0, 0, 35], [30, 30, 30], kramerColors.skyBlue], // head
  [[0, -18, 35], [7, 7, 7], kramerColors.white], // nose
  [[42, 0, 15], [15.3, 15.3, 15.3], kramerColors.red], // LHand
  [[33, 0, 11], [15.1, 15.1, 15.1], kramerColors.skyBlue], // LElbow
  [[25, 0, 7], [15.2, 15.2, 15.2], kramerColors.skyBlue], // LShoulder
  [[-42, 0, 15], [15.3, 15.3, 15.3], kramerColors.white], // RHand
  [[-33, 0, 11], [15.1, 15.1, 15.1], kramerColors.skyBlue], // RElbow
  [[-25, 0, 7], [15.2, 15.2, 15.2], kramerColors.skyBlue], // RShoulder
  [[15, 0, -30], [18, 18, 18], kramerColors.skyBlue], // LThigh
  [[20, 0, -39], [16, 16, 16], kramerColors.skyBlue], // LKnee
  [[24, 0, -49], [15, 15, 15], kramerColors.skyBlue], // LFoot
  [[24, -15, -49], [15, 15, 15], kramerColors.red], // LToe
  [[-15, 0, -30], [18, 18, 18], kramerColors.skyBlue], // RThigh
  [[-20, 0, -39], [16, 16, 16], kramerColors.skyBlue], // RKnee
  [[-24, 0, -49], [15, 15, 15], kramerColors.skyBlue], // RFoot
  [[-24, -15, -49], [15, 15, 15], kramerColors.white] // RToe
];

const createKramer = canvas => {
  const { width, height } = canvas;
  const scene = new THREE.Scene();
  const camera = new THREE.OrthographicCamera(width / - 2, width / 2, height / 2, height / - 2, 1, 1000);
  const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });

  let group = new THREE.Group();
  const innerGroup = new THREE.Group();

  innerGroup.scale.set(.5, .5, .5);
  innerGroup.add(...parts.map(buildCube));
  innerGroup.rotateX(-Math.PI / 2);

  group.add(innerGroup);

  const box = new THREE.Box3().setFromObject(group);

  // Position in the corner
  group.translateX((-width / 2) + ((box.max.x - box.min.x) / 2) + 10);
  group.translateY((-height / 2) + ((box.max.y - box.min.y) / 2) + 10);

  scene.add(group);

  camera.position.z = 500;

  // light
  const skyColor = 0xFFFFFF; // light gray
  const groundColor = 0x000000; // black
  const intensity = 1.5;
  const light = new THREE.HemisphereLight(skyColor, groundColor, intensity);
  light.target = group;
  light.translateY((-height / 2) + ((box.max.y - box.min.y) / 2) + 10);

  scene.add(light);

  renderer.render(scene, camera);

  const render = (csCamera, sourceAngle, couchAngle) => {
    const { viewPlaneNormal, viewUp } = csCamera;
    const [x, y, z] = viewPlaneNormal;
    const [, j, k] = viewUp;
    group.setRotationFromEuler(new THREE.Euler(0, 0, 0));
    
    sourceAngle = (360 - sourceAngle) === 360 ? 0 : 360 - sourceAngle; // 360 is 0;

    const isProne = y === 1 || (x !== 0 && x === k) || j === 1;
    const flipToProne = isProne ? 180 : 0;
    group.rotateY((Math.round(sourceAngle, 1) + flipToProne) * Math.PI / 180);

    const isFootFirst = k === -1 || z === 1;
    const flipToFootFirst = isFootFirst ? 180 : 0;
    group.rotateZ((Math.round(couchAngle, 1)+flipToFootFirst) * Math.PI / 180);

    const isAxial = z !== 0;
    const rotateToAxialPlane = isAxial
      ? isFootFirst
        ? (j * -90)
        : (j * 90)
      : 0;
    group.rotateX(rotateToAxialPlane * Math.PI / 180);
    
    renderer.setSize(canvas.width, canvas.height);
    renderer.render(scene, camera);
  };

  const resize = () => {
    const { width, height } = canvas;

    renderer.setSize(canvas.width, canvas.height);

    Object.assign(camera, { left: width / -2, right: width / 2, top: height / 2, bottom: height / -2 });
    camera.updateProjectionMatrix();

    scene.remove(group);
    group = new THREE.Group();
    group.add(innerGroup);
    scene.add(group);

    const box = new THREE.Box3().setFromObject(group);
    // Position in the corner

    group.translateX((-width / 2) + ((box.max.x - box.min.x) / 2) + 10);
    group.translateY((-height / 2) + ((box.max.y - box.min.y) / 2) + 10);
  };

  return { resize, render };
};

const buildCube = ([origin, size, color]) => {
  const material = new THREE.MeshPhongMaterial({ color });
  const geometry = new THREE.BoxGeometry(...size);
  geometry.translate(...origin);

  return new THREE.Mesh(geometry, material);
};

export default createKramer;