import { newInstance as newInstance$1, vtkDebugMacro as vtkDebugMacro$1 } from '../../macros.js';
import vtkViewNode from '../SceneGraph/ViewNode.js';
import { registerOverride } from './ViewNodeFactory.js';

// import { mat4, vec3 }     from 'gl-matrix';
var vtkDebugMacro = vtkDebugMacro$1; // ----------------------------------------------------------------------------
// vtkOpenGLPixelSpaceCallbackMapper methods
// ----------------------------------------------------------------------------

function vtkOpenGLPixelSpaceCallbackMapper(publicAPI, model) {
  model.classHierarchy.push('vtkOpenGLPixelSpaceCallbackMapper');

  publicAPI.opaquePass = function (prepass, renderPass) {
    model.openGLRenderer = publicAPI.getFirstAncestorOfType('vtkOpenGLRenderer');
    model._openGLRenderWindow = model.openGLRenderer.getParent();
    var aspectRatio = model.openGLRenderer.getAspectRatio();
    var camera = model.openGLRenderer ? model.openGLRenderer.getRenderable().getActiveCamera() : null;
    var tsize = model.openGLRenderer.getTiledSizeAndOrigin();
    var texels = null;

    if (model.renderable.getUseZValues()) {
      var zbt = renderPass.getZBufferTexture();
      var width = Math.floor(zbt.getWidth());
      var height = Math.floor(zbt.getHeight());

      var gl = model._openGLRenderWindow.getContext();

      zbt.bind(); // Here we need to use vtkFramebuffer to save current settings (bindings/buffers)

      var fb = renderPass.getFramebuffer();

      if (!fb) {
        vtkDebugMacro('No framebuffer to save/restore');
      } else {
        // save framebuffer settings
        fb.saveCurrentBindingsAndBuffers();
      }

      var framebuffer = gl.createFramebuffer();
      gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
      gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, zbt.getHandle(), 0);

      if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) === gl.FRAMEBUFFER_COMPLETE) {
        texels = new Uint8Array(width * height * 4);
        gl.viewport(0, 0, width, height);
        gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, texels);
      } // Now we need to restore framebuffer bindings/buffers


      if (fb) {
        fb.restorePreviousBindingsAndBuffers();
      }

      gl.deleteFramebuffer(framebuffer);
    }

    model.renderable.invokeCallback(model.renderable.getInputData(), camera, aspectRatio, tsize, texels);
  };

  publicAPI.queryPass = function (prepass, renderPass) {
    if (prepass) {
      if (model.renderable.getUseZValues()) {
        renderPass.requestDepth();
      }
    }
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Inheritance

  vtkViewNode.extend(publicAPI, model, initialValues); // Object methods

  vtkOpenGLPixelSpaceCallbackMapper(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = newInstance$1(extend, 'vtkOpenGLPixelSpaceCallbackMapper'); // ----------------------------------------------------------------------------

var vtkPixelSpaceCallbackMapper = {
  newInstance: newInstance,
  extend: extend
}; // Register ourself to OpenGL backend if imported

registerOverride('vtkPixelSpaceCallbackMapper', newInstance);

export { vtkPixelSpaceCallbackMapper as default, extend, newInstance };
