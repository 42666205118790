const defer = () => {
  let res;
  let rej;

  const promise = new Promise((resolve, reject) => {
    res = resolve;
    rej = reject;
  });

  promise.resolved = false;

  promise.resolve = (...args) => {
    promise.resolved = true;
    res(...args);
  };

  promise.reject = rej;

  return promise;
};

export default defer;