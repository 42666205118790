import { html, css, LitElement } from 'lit';
import { DICOM_POPOUT_TARGET } from './image-view/utils/getPopoutUrl.js';
import getPopoutUrlKey from '../../utils/popout/getPopoutUrlKey.js';

class BlankImageView extends LitElement {
  static styles = css`
    :host {
      background: rgb(50, 54, 57);
      color: #999;
    }
    
    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `;

  connectedCallback() {
    super.connectedCallback();

    // This will keep the other window informed that we're still open.
    const worker = new Worker(new URL('../../workers/dicom-image.js', import.meta.url), { type: 'module' });
    worker.postMessage(DICOM_POPOUT_TARGET);

    window.addEventListener('storage', this.updateUrl);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('storage', this.updateUrl);
  }

  updateUrl = ({ key, newValue }) => {
    if (key !== getPopoutUrlKey(DICOM_POPOUT_TARGET)) return;
    if (newValue === document.location.href) return;

    document.location = newValue;
    window.focus(); // try to make the window blink on the taskbar... probably won't work
  };

  render() {
    return html`
      <div class="blank-image">
        <h1>Select an image to view.</h1>
      </div>
    `;
  }
}

customElements.define('blank-image-view', BlankImageView);
