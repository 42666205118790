import { html, css, LitElement } from 'lit';
import patientStyles from '../styles/patient';
import fontAwesome from '../../../../utils/font-awesome';
import { partitionRx } from '../utils';

class PatientRxMatchModal extends LitElement {
  static styles = [
    patientStyles,
    css`    
      :host {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .65);
        z-index: 9999;
      }
      
      #modal {
        position: absolute;
        display: flex;
        flex-direction: column;
        height: 95%;
        width: 60%;
        min-width: 600px;
        min-height: 450px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--light-color);
        padding: 24px;
        border-radius: 5px;
      }
  
      #modal h2 {
        margin: -8px 0 -4px;
      }
  
      #modal > p {
        margin: 0 0 24px;
      }
      
      #wrapper {
        display: flex;
        flex-direction: row;
        flex: 1;
        overflow: hidden;
      }
      
      legend {
        color: var(--neutral-3);
        padding: 0 12px;
        position: relative;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .3px;
      }
  
      legend i {
        margin: 2px 8px auto auto;
        color: var(--blue-3);
      }
      
      #rx,
      #plans {
        flex: 1;
        overflow: auto;
      }
      
      fieldset {
        flex: 1 0 64px;
        position: relative;
        border-style: solid;
        border-color: var(--neutral-6);
        border-width: 2px;
        margin-inline-start: 0;
        margin-inline-end: 0;
        border-radius: 5px;
        box-sizing: border-box;
        transition: all .2s;
        overflow: visible;
      }
  
      fieldset.drag-over:before {
        content: "RELEASE TO ASSIGN PLAN";
        position: absolute;
        font-size: 14px;
        font-weight: 600;
        bottom: 16px;
        width: 100%;
        text-align: center;
        color: var(--blue-5);
        margin-left: -12px;
        transition: all .2s;
      }
  
      fieldset#plans.drag-over:before {
        content: "RELEASE TO UNASSIGN PLAN";
        color: var(--neutral-6);
      }
  
      fieldset.drag-over  {
        border-style: dashed;
        border-color: var(--blue-3);
        transition: all .2s;
      }
  
      fieldset#plans.drag-over {
        border-color: var(--neutral-4);
      }
      
      #rx {
        display: flex;
        flex-direction: column;
        padding-right: 12px;
      }
      
      #rx fieldset:first-of-type {
        margin-bottom: 16px;
      }
      
      #plans {        
        margin-left: 8px;
      }
      
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      
      li {
        display: block;
        font-size: 14px;
        line-height: 2;
        border: 1px solid var(--neutral-4);
        background: var(--light-color);
        color: var(--neutral-2);
        margin: 8px 0;
        padding: 4px 8px 2px;
        cursor: pointer;
        transition: all .2s;
        border-radius: 2px;
      }
      
      li:hover {
        border: 1px solid var(--neutral-1);
        box-shadow: 0 2px 4px var(--neutral-8);
        transition: all .2s;
      }
      
      li:active {
        border: 1px solid var(--primary-color);
        box-shadow: 0 4px 8px var(--neutral-6);
        transition: all .2s;
      }
      
      li[draggable]:hover {
        box-shadow: 0 4px 8px var(--neutral-6);
        transition: all .2s;
        cursor: move;
      }
      
      .dragging li {
        box-shadow: 0 4px 8px var(--neutral-6);
        transition: all .2s;
        cursor: move;
      }
      
      li:before {
        content: '\uf58e';
        font-family: 'Font Awesome 5 Pro';
        color: var(--neutral-4);
        font-weight: 900;
        margin-right: 8px;
        transition: all .2s;
      }
  
      li:hover:before {
        color: var(--neutral-2);
        transition: all .2s;
      }
      
      li[disabled] {
        pointer-events: none;
        background: var(--neutral-7);
        border: 1px solid var(--neutral-6);
        color: var(--neutral-4);
        transition: all .2s;
        cursor: default;
      }
  
      #buttons button {
        margin: 24px 16px auto 0;
        border-radius: 5px;
        min-width: 96px;
      }
  
      #modal .close {
        position: absolute;
        color: var(--neutral-4);
        top: 0;
        right: 0;
        padding: 20px 24px;
        background: 0;
        border: 0;
        line-height: 1;
        outline: 0;
        cursor: pointer;
        transition: all .2s;
      }
  
      #modal .close:hover,
      #modal .content p {
        color: var(--neutral-2);
        transition: all .2s;
      }
  
      button.cancel {
        color: var(--neutral-2);
        border: 1px solid var(--neutral-5);
        background: var(--light-color);
      }
      
      button.cancel:hover {
        background: var(--neutral-7);
      }
    `
  ].flat();

  static properties = {
    plans: { type: Array },
    prescriptions: { type: Array },
    links: { type: Object },
    unlinkedPlans: { type: Array },
    title: { type: String },
    showCloseConfirm: { type: Boolean },
    changed: { type: Boolean }
  }

  constructor() {
    super();

    this.links = [];
    this.unlinkedPlans = [];

    this.canvas = document.createElement('canvas');

    const dragImage = new Image();
    dragImage.style.position = 'absolute';
    dragImage.style.top = 0;
    dragImage.style.left = 0;
    dragImage.style.zIndex = 100000;
    dragImage.style.top = '-999999px';
    dragImage.style.left = '-999999px';
    dragImage.style.pointerEvents = 'none';
    dragImage.opacity = 1;
    document.body.appendChild(dragImage);

    this.dragImage = dragImage;
  }

  connectedCallback() {
    const { handleBodyKeyUp, handleBodyClick } = this;

    super.connectedCallback();

    document.body.addEventListener('click', handleBodyClick);
    document.body.addEventListener('keyup', handleBodyKeyUp);
  }

  disconnectedCallback() {
    const { handleBodyKeyUp, handleBodyClick } = this;

    super.disconnectedCallback();

    document.body.removeEventListener('click', handleBodyClick);
    document.body.removeEventListener('keyup', handleBodyKeyUp);
  }

  updated(changed) {
    if (changed.has('prescriptions') || changed.has('plans')) {
      const { prescriptions, plans } = this;

      const { links, unlinkedPlans } = partitionRx(prescriptions, plans);
      this.links = links;
      this.unlinkedPlans = unlinkedPlans;
      this.changed = false;
    }
  }

  render() {
    const { links, unlinkedPlans, prescriptions, title, showCloseConfirm,
      handleCancel, handleSave, handleDrop, handleDragOver, handleDragEnter,
      handleDragLeave, handleCancelClose, handleConfirmClose,
      renderPlan } = this;

    return html`
      ${fontAwesome}
      <div id="modal">
        <i class="close fas fa-times fa-lg" @click=${handleCancel}></i>
        <h2 id="course1">${title}</h2>
        <p>Drag and drop unmatched plans to a prescription to match them.</p>
        <div id="wrapper">
          <div id="rx">
            ${links.map(({ rxSer, plans }, rxIndex) => html`
              <fieldset
                .rxIndex=${rxIndex}
                @drop=${handleDrop}
                @dragover=${handleDragOver}
                @dragenter=${handleDragEnter}
                @dragleave=${handleDragLeave}
              >
                <legend><i class="fas fa-prescription fa-lg"></i>
                  ${prescriptions.find(({ ser }) => parseInt(ser) === parseInt(rxSer)).id}
                </legend>
                <ul>
                  ${plans.map(plan => renderPlan(rxIndex, plan))}
                </ul>
              </fieldset>               
            `)}
          </div>
          <fieldset 
            id="plans"
            @drop=${handleDrop}
            @dragover=${handleDragOver}
            @dragenter=${handleDragEnter}
            @dragleave=${handleDragLeave}
          >
            <legend>Unmatched Plans</legend>
            <ul>
              ${unlinkedPlans.map(plan => renderPlan(undefined, plan))}
            </ul>
          </fieldset>
        </div>
        <div id="buttons">
          <button @click=${handleSave}>Save</button>
          <button class="cancel" @click=${handleCancel}>Cancel</button>
        </div>
      </div>
      ${showCloseConfirm ? html`
        <rad-confirm
          confirmText="Yes"
          cancelText="No"
          @confirm=${handleConfirmClose}
          @cancel=${handleCancelClose}        
        >
          <h3>Are you sure you want to cancel?</h3>
          <p>Your changes will not be saved.</p>
        </rad-confirm>
      ` : ''}
    `;
  }

  renderPlan = (rxIndex, { id, linkLocked }) => {
    const { handleDragStart, handleDragEnd, handleDrag } = this;

    return html`
      <li
        draggable="true"
        .planId=${id}
        .rxIndex=${rxIndex}
        @dragstart=${handleDragStart}
        @dragend=${handleDragEnd}
        @drag=${handleDrag}
        ?disabled=${linkLocked}
      >
        ${id}
      </li>
    `;
  };

  handleBodyClick = event => {
    if (event.composedPath()[0] === this) {
      this.handleCancel();
    }
  };

  handleBodyKeyUp = event => {
    if (event.key === 'Escape') {
      this.handleCancel();
    }
  };

  handleCancel = () => {
    const { changed } = this;

    if (changed) {
      this.showCloseConfirm = true;
    } else {
      this.cancel();
    }
  };

  handleConfirmClose = () => {
    this.showCloseConfirm = false;
    this.cancel();
  };

  handleCancelClose = event => {
    event.stopPropagation();
    this.showCloseConfirm = false;
  };

  handleDrag = event => {
    const { dragImage } = this;
    const { x, y } = dragImage.dragOffset;

    this.dragImage.style.top = `${event.clientY - y}px`;
    this.dragImage.style.left = `${event.clientX - x}px`;
  };

  handleDragStart = event => {
    this.shadowRoot.querySelector('#wrapper').classList.add('dragging');

    const { currentTarget } = event;
    const { x, y } = currentTarget.getBoundingClientRect();
    this.dragImage.dragOffset = { x: event.clientX - x, y: event.clientY - y };
    this.buildDragImage(currentTarget);

    event.dataTransfer.setDragImage(this.dragImage, -9999, -9999);
    event.dataTransfer.dropEffect = 'move';
    event.dataTransfer.setData('application/json', JSON.stringify({
      planId: event.target.planId,
      rxIndex: event.target.rxIndex
    }));

    return false;
  };

  handleDragOver = event => {
    event.dataTransfer.dropEffect = 'move';
    event.preventDefault();
  };

  handleDragEnter = event => {
    const { classList } = event.currentTarget;
    classList.add('drag-over', 'drag-entered');

    // So we don't remove it when entering a child of the fieldset
    setTimeout(() => classList.remove('drag-entered'), 0);
  };

  handleDragLeave = event => {
    const { classList } = event.currentTarget;

    if (!classList.contains('drag-entered')) {
      event.currentTarget.classList.remove('drag-over');
    }
  };

  handleDragEnd = () => {
    const { dragImage } = this;

    this.shadowRoot.querySelector('#wrapper').classList.remove('dragging');

    dragImage.style.top = '-999999px';
    dragImage.style.left = '-999999px';
  };

  handleDrop = event => {
    event.preventDefault();
    event.currentTarget.classList.remove('drag-over');

    const targetRxIndex = event.currentTarget.rxIndex;
    const { planId, rxIndex } = JSON.parse(event.dataTransfer.getData('application/json'));

    if (targetRxIndex === rxIndex) {
      return; // same, nothing to do
    }

    const { links, unlinkedPlans } = this;
    this.changed = true;

    if (rxIndex !== undefined && targetRxIndex === undefined) { // plan => unlinked
      const planIndex = links[rxIndex].plans.findIndex(({ id: rxPlanId }) => rxPlanId === planId);

      this.unlinkedPlans = [
        ...unlinkedPlans,
        links[rxIndex].plans.find(({ id: rxPlanId }) => rxPlanId === planId)
      ];

      this.links = [
        ...links.slice(0, rxIndex),
        {
          rxSer: links[rxIndex].rxSer,
          plans: [
            ...links[rxIndex].plans.slice(0, planIndex),
            ...links[rxIndex].plans.slice(planIndex + 1)
          ]
        },
        ...links.slice(rxIndex + 1)
      ];
    } else if (rxIndex !== undefined) { // plan => plan
      const planIndex = links[rxIndex].plans.findIndex(({ id: rxPlanId }) => rxPlanId === planId);
      const plan = links[rxIndex].plans[planIndex];

      const linksIntermediate = [
        ...links.slice(0, rxIndex),
        {
          rxSer: links[rxIndex].rxSer,
          plans: [
            ...links[rxIndex].plans.slice(0, planIndex),
            ...links[rxIndex].plans.slice(planIndex + 1)
          ]
        },
        ...links.slice(rxIndex + 1)
      ];

      this.links = [
        ...linksIntermediate.slice(0, targetRxIndex),
        {
          rxSer: links[targetRxIndex].rxSer,
          plans: [
            ...links[targetRxIndex].plans,
            plan
          ]
        },
        ...linksIntermediate.slice(targetRxIndex + 1)
      ];
    } else { // unlinked => plan
      const planIndex = unlinkedPlans.findIndex(({ id }) => id === planId);
      const plan = unlinkedPlans[planIndex];

      this.unlinkedPlans = [
        ...unlinkedPlans.slice(0, planIndex),
        ...unlinkedPlans.slice(planIndex + 1)
      ];

      this.links = [
        ...links.slice(0, targetRxIndex),
        {
          rxSer: links[targetRxIndex].rxSer,
          plans: [
            ...links[targetRxIndex].plans,
            plan
          ]
        },
        ...links.slice(targetRxIndex + 1)
      ];
    }
  };

  buildDragImage = element => {
    const { canvas } = this;
    canvas.width = element.offsetWidth;
    canvas.height = element.offsetHeight;

    const ctx = canvas.getContext('2d');
    const style = getComputedStyle(element);

    ctx.fillStyle = style.backgroundColor;
    ctx.strokeStyle = style.borderColor;

    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.strokeRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = style.color;
    ctx.font = `900 ${style.fontSize} 'Font Awesome 5 Pro'`;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'left';

    const paddingLeft = parseInt(style.paddingLeft.substr(0, style.paddingLeft.length - 2))
      + parseInt(style.borderRightWidth.substr(0, style.borderRightWidth.length - 2));
    const borderTop = parseInt(style.borderTop.substr(0, style.borderTop.length - 2));
    const extraPadding = 8; // padding-right of ::before

    ctx.fillText('\uf58e', paddingLeft, (canvas.height / 2) + borderTop);
    const handleWidth = ctx.measureText('\uf58e').width;

    ctx.font = style.font;
    ctx.fillText(element.innerText, paddingLeft + extraPadding + handleWidth, (canvas.height / 2) + borderTop);

    this.dragImage.src = canvas.toDataURL();
  };

  handleSave = () => {
    const { links } = this;

    this.dispatchEvent(new CustomEvent('save', {
      bubbles: true,
      detail: {
        links: links.reduce((result, { rxSer, plans }) => ({
          [rxSer]: plans.map(({ ser }) => ser),
          ...result
        }), {})
      }
    }));
  };

  cancel = () => {
    this.dispatchEvent(new CustomEvent('cancel', { bubbles: true }));
  };
}

customElements.define('patient-rx-match-modal', PatientRxMatchModal);