import { html } from 'lit';
import h from '../../../utils/h';
import { styleMap } from 'lit/directives/style-map.js';
import { normalize } from '../../../utils/charts';
import range from '../../../utils/range';
import getSlope from '../../../utils/math/getSlope';
import getDistanceBetweenTwoPoints from '../../../utils/math/getDistanceBetweenTwoPoints';

const checkAllLinesHaveEqualPoints = data => {
  if (!data.every(({ points }) => points.length === data[0].points.length)) {
    throw new Error('Table chart has uneven number of points. If you want to omit points, put a null in the array.');
  }
};

const renderFiller = (headerColumnIndex, columnLimit) => {
  return h(columnLimit <= 0 && headerColumnIndex > 1, html`
    <td colspan=${Math.min(headerColumnIndex - 1, columnLimit > -1 ? columnLimit : Number.MAX_SAFE_INTEGER)}>
      &nbsp;
    </td>
  `);
};

const renderHeaders = (headerColumnIndex, data, columnLimit) => {
  const height = 1 / data.length;

  return h(columnLimit === -1 || columnLimit > headerColumnIndex, html`
    <th headers>
      <ul>
        ${data.map(({ header, color }) => html`
          <li style=${styleMap({ 
            color,
            height: `${height * 100}%`
          })}>
            <span>${header}</span>
          </li>
        `)}
      </ul>
    </th>
  `);
};

const buildBackground = (height, steps) => {
  const canvas = document.createElement('canvas');
  canvas.height = height;
  canvas.width = 200; // mostly arbitrary value, must be at least as wide as a step column

  const ctx = canvas.getContext('2d');

  ctx.fillStyle = '#FFF';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.strokeStyle = '#666';
  ctx.lineWidth = 1;

  let y = .5;
  for (let i = 0; i < steps - 1; i++) {
    y += height / steps;

    ctx.beginPath();
    ctx.moveTo(0, y);
    ctx.lineTo(canvas.width, y);
    ctx.closePath();
    ctx.stroke();
  }

  return canvas.toDataURL();
};

const renderAxis = (axisColumnIndex, { label, min, max, increment, transform = v => v }, columnLimit) => {
  const steps = [];

  for (let i = max; i >= min; i -= increment) {
    steps.push(i);
  }

  return h(columnLimit === -1 || columnLimit > axisColumnIndex, html`
    <td axis>
      <span>${label}</span>
      <ul>
        ${steps.map(step => html`
          <li>${transform(step)}</li>
        `)}
      </ul>
    </td>
  `);
};

const renderLines = (data, unitOfMeasurement, { min, max, steps, transform = v => v }, columnLimit, background) => {
  return h(columnLimit === -1, range(0, data[0].points.length - 1).map(index => html`
    <td step>
      <div style=${styleMap({
        background: `url(${background})`
      })}>
        ${data.map(({ points, color }, lineIndex) => 
          renderPoint({ unitOfMeasurement, lineIndex, point: points[index], min, max, 
            valueTransform: transform, color, includeLine: index < data[0].points.length - 1 })
        )}
      </div>
    </td>
  `));
};

const renderPoint = ({ unitOfMeasurement, lineIndex, point, min, max, valueTransform, color, includeLine }) => {
  if (!point) return;

  const { value, passState, tooltipText } = point;
  const top = `${(1 - ((value - min) / (max - min))) * 100}%`;

  return html`
    <span point
      line-index=${lineIndex}
      pass-state=${passState}
      title=${tooltipText || `${valueTransform(value)} ${unitOfMeasurement}`}
      style=${styleMap({ 
        background: color,
        top
      })}
    >
      <span value>
        ${tooltipText ? html`${tooltipText}` : html`${valueTransform(value)} ${unitOfMeasurement}`}
      </span>
    </span>
    ${h(includeLine, html`
      <span line
        line-index=${lineIndex}
        style=${styleMap({ 
          background: color,
          top
        })}
      ></span>
    `)}
  `;
};

const renderChart = ({ firstColumn, axis, data, unitOfMeasurement }, columnLimit = -1) => {
  checkAllLinesHaveEqualPoints(data);

  const headerColumnIndex = firstColumn - 2;
  const axisColumnIndex = firstColumn - 1;
  const allValues = data.flatMap(({ points } = {}) => points)
    .filter(Boolean)
    .map(({ value }) => value);

  const normalizedAxis = normalize({
    axis,
    dataMin: Math.min(...allValues),
    dataMax: Math.max(...allValues)
  });

  const rowHeight = 38; // should match line-height of [chart] [axis] li
  const background = buildBackground(
    (rowHeight * normalizedAxis.steps) + Math.round((rowHeight / 2) - Math.floor(rowHeight / 2)),
    normalizedAxis.steps
  );

  return html`
    <tr chart>
      ${renderFiller(headerColumnIndex, columnLimit)}
      ${renderHeaders(headerColumnIndex, data, columnLimit)}
      ${renderAxis(axisColumnIndex, normalizedAxis, columnLimit)}
      ${renderLines(data, unitOfMeasurement, normalizedAxis, columnLimit, background)}
    </tr>
  `;
};

const findPoints = line => {
  const lineIndex = line.getAttribute('line-index');
  const selector = `[point][line-index="${lineIndex}"]`;
  const start = line.closest('td').querySelector(selector);
  let current = line.closest('td').nextElementSibling;

  while (current) {
    const end = current.querySelector(selector);

    if (end) return { start, end };

    current = current.nextElementSibling;
  }
};

const getMathPoint = pointElement => {
  const { x, y, width, height } = pointElement.getBoundingClientRect();

  return { x: x + (width / 2), y: y + (height / 2) };
};

const updateChart = chart => {
  const lines = Array.from(chart.querySelectorAll('[line]'));

  if (!lines.length) return;

  lines.forEach(line => {
    const { start, end } = findPoints(line) || {};

    if (!end) {
      line.style.display = 'none';
    } else {
      const startPoint = getMathPoint(start);
      const endPoint = getMathPoint(end);
      const angle = Math.atan(getSlope(startPoint, endPoint)) / Math.PI * 180;
      const width = getDistanceBetweenTwoPoints(startPoint, endPoint);
      line.style.transform = `translate(-50%, -1px) rotate(${angle}deg) translateX(50%)`;
      line.style.width = `${width}px`;
    }
  });
};

export const updateCharts = charts => {
  charts.forEach(updateChart);
};

export default renderChart;