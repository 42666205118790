import { css } from 'lit';

const commonStyles = css`
  :host {
    --blue-1: #184664;
    --blue-2: #266FA0;
    --blue-3: #3498DB;
    --blue-4: #62B6ED;
    --blue-5: #9CD1F4;
    --blue-6: #D8ECFA;
    --blue-7: #EAF5FD;

    --neutral-1: #1E252A;
    --neutral-2: #313C43;
    --neutral-3: #42525C;
    --neutral-4: #6B8393;
    --neutral-5: #A2B2BC;
    --neutral-6: #DAE0E4;
    --neutral-7: #ECF0F2;
    --neutral-8: #F6F8F9;
    --neutral-9: #F9FAFB;
    --neutral-10: #FFFFFF;

    --green-1: #12502C;
    --green-2: #1D7F46;
    --green-3: #27AE60;
    --green-4: #61C48B;
    --green-5: #9CDAB6;
    --green-6: #D7F0E2;
    --green-7: #EBF7F0;

    --orange-1: #522900;
    --orange-2: #a54700;
    --orange-3: #FF9900;
    --orange-4: #FFBB00;
    --orange-5: #FFCC33;
    --orange-6: #FFDD66;
    --orange-7: #FFEE99;

    --primary-1: var(--blue-1);
    --primary-2: var(--blue-2);
    --primary-3: var(--blue-3);
    --primary-4: var(--blue-4);
    --primary-5: var(--blue-5);
    --primary-6: var(--blue-6);
    --primary-7: var(--blue-7);

    --primary-color: var(--primary-3);
    --secondary-color: var(--neutral-4);
    --side-background: var(--neutral-8);
    --side-border: var(--neutral-6);
    --hover-color: var(--neutral-7);
    --good-color: var(--green-3);
    --bad-color: #F00;
    --light-color: #FFF;
    --dark-color: var(--neutral-3);
    --darker-color: var(--neutral-1);
    --selected-color: var(--primary-color);
    --button-hover-color: var(--blue-2);
  }

  :host * {
    box-sizing: border-box;
  }

  button {
    font-size: 15px;
    font-weight: 400;
    padding: 12px 24px;
    transition: all .25s;
  }

  button:focus {
    outline: none;
  }

  section {
    margin: 0 16px 24px 0;
    box-shadow: 0 4px 6px var(--neutral-6);
    border-radius: 5px;
    display: inline-block;
    min-width: 100%;
  }

  section header h4,
  section,
  section > table,
  section > table > thead {
    background: var(--light-color);
  }

  section > table > thead {
    background: var(--neutral-8);
  }
`;

export default commonStyles;