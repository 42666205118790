import { LitElement, html, css } from 'lit';
import { connect } from '../../utils/redux';
import { toastsSelector } from '../../utils/redux/toasts/selectors';
import { styleMap } from 'lit/directives/style-map.js';
import { getStore } from '../../utils/redux/store';

// Should match the transition time(s) of <toasts-container>
export const TOAST_FADE_IN_DURATION = .33;
export const TOAST_FADE_OUT_DURATION = .33;

export const ToastStates = {
  FADE_IN: 'fade-in',
  STATIC: 'static',
  FADE_OUT: 'fade-out'
};

// Requires initializing with the store before the custom element will be available.
export class ToastsContainer extends connect(getStore)(LitElement) {
  static styles = css`    
    :host {
      position: fixed;
      display: flex;
      flex-direction: column-reverse;
      bottom: 8px;
      right: 0;
      top: 0;
      left: 0;
      z-index: 9999;
      align-items: flex-end;
      pointer-events: none;
    }

    toast-banner {
      position: relative;
      text-align: center;
      width: 100%;
      padding: 12px 16px;
      margin: 8px 16px;
      max-width: 360px;
      opacity: 0;
      color: #FFFFFF;
      background: #333;
      font-size: 13px;
      border-radius: 5px;
      box-shadow: 0 8px 16px rgba(49,60,67,.24);
      transition: .33s opactiy;
    }
    
    toast-banner.static {
      opacity: 1;
    }
  `;

  static properties = {
    toasts: { type: Array }
  };

  render() {
    const { toasts = [] } = this;

    return html`
    ${toasts.map(({ message, state, color }) => html`
      <toast-banner class=${state} style=${styleMap({ background: color })}>
        <b>
          ${message}
        </b>
      </toast-banner>
    `)}
  `;
  }

  stateChanged(state) {
    this.toasts = toastsSelector(state);
  }
}

customElements.define('toasts-container', ToastsContainer);