const anonymize = (patients, patient) => {
  let anonPatient;
  const anonPatients = patients.map(p => {
    const a = {
      ...p,
      firstName: `FirstName${Math.floor(Math.random() * 10000000)}`,
      lastName: `LastName${Math.floor(Math.random() * 100000000)}`,
      ser: `Ser${Math.floor(Math.random() * 100000000)}`,
      id: `Id${Math.floor(Math.random() * 100000000)}`
    };

    if (patient === p) {
      anonPatient = a;
    }

    return a;
  });

  return { __patients: anonPatients, __patient: anonPatient };
};

export default anonymize;