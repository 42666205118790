import { setCookie } from '../cookie';
import { COOKIE_NAME, EVENT_NAME } from './constants';

const hidePhi = mode => {
  setCookie(COOKIE_NAME, mode);
  window.dispatchEvent(new CustomEvent(EVENT_NAME));

  return mode;
};

export default hidePhi;