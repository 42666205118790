import { ImageTypes } from './initializeDicomGroup.js';
import getOrientationLabels2D from './getOrientationLabels2D.js';

const getCoronal2DOrientations = (type, axial, coronal, dicomGroupData) => {
  if (type !== ImageTypes.TwoD) return {};

  const labels2D = type === ImageTypes.TwoD
    ? getOrientationLabels2D(axial, coronal.labels.top, dicomGroupData.gantryAngle)
    : undefined;

  const labels2DPaired = dicomGroupData.pairedAcquiredImage
    ? getOrientationLabels2D(axial, coronal.labels.top, dicomGroupData.pairedGantryAngle)
    : undefined;

  const coronal2D = { ...coronal, labels: labels2D };
  const coronal2DPaired = { ...coronal, labels: labels2DPaired };

  return {
    coronal2D,
    coronal2DPaired
  };
};

export default getCoronal2DOrientations;