// @TODO Clean / remove this file, from CS tools examples
/* eslint-disable */

import { imageLoader, volumeLoader } from '@cornerstonejs/core';
import {
  cornerstoneStreamingImageVolumeLoader,
  sharedArrayBufferImageLoader,
} from '@cornerstonejs/streaming-image-volume-loader';
import radSharedArrayBufferImageLoader from '../radSharedArrayBufferImageLoader.js';

export default function initVolumeLoader() {
  volumeLoader.registerUnknownVolumeLoader(
    cornerstoneStreamingImageVolumeLoader
  );
  volumeLoader.registerVolumeLoader(
    'cornerstoneStreamingImageVolume',
    cornerstoneStreamingImageVolumeLoader
  );

  imageLoader.registerImageLoader(
    'streaming-wadors',
    sharedArrayBufferImageLoader
  );

  imageLoader.registerImageLoader(
    'rad-streaming-wadors',
    radSharedArrayBufferImageLoader
  );

  imageLoader.registerImageLoader(
    'rad-file',
    radSharedArrayBufferImageLoader
  );
}
