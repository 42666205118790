import { statusFilters } from '../common';
import { sortBy } from '../../../../utils/array';

const sortByStatus = patients => {
  const sortByFunc = sortBy('reviewAccepted', 'lastName', 'firstName');

  const patientsByStatus = patients.reduce((bySection, patient) => {
    return ({
      ...bySection,
      [(patient.status && patient.status.toLowerCase()) || undefined]:
        [...(bySection[(patient.status && patient.status.toLowerCase()) || undefined] || []), patient]
    });
  }, {});

  const asObject = statusFilters.reduce((result, key) => ({
    ...result,
    [key]: (patientsByStatus[key.toLowerCase()] || [])
      .sort(sortByFunc)
  }), {});

  asObject['weekly'].push(...(patientsByStatus[undefined] || []));
  asObject['weekly'].sort(sortByFunc);

  return asObject;
};

export default sortByStatus;