import { LOADING, QUEUED, READY } from './statuses.js';
import { createSelector } from '../../../../utils/redux/index.js';
import createKey from './createKey.js';
import { store } from '../../../../redux/store.js';

const dicomsSelector = createSelector('dicom.dicoms', {});

// status doesn't actually matter, we just care if it is in here at all
export const isQueued = ({ planSer, fraction, groupIndex }, state = store.getState()) =>
  Boolean(dicomsSelector(state)?.[createKey(planSer, fraction, groupIndex)]);

export const isReadyToLoad = ({ planSer, fraction, groupIndex }, state = store.getState()) => {
  const dicoms = dicomsSelector(state);

  // If we haven't indicated they can start loading.
  if (!dicoms.canLoad) return false;

  const noneCurrentlyLoading = !Object.values(dicoms)
    .find(({ status }) => status === LOADING);

  const next = Object.entries(dicoms)
    .filter(([, { status }]) => status === QUEUED)
    .reduce((acc, [key, { priority }]) => priority < acc.priority ? ({ key, priority }) : acc,
      { priority: Number.MAX_SAFE_INTEGER }
    );

  return noneCurrentlyLoading
    && next.key === createKey(planSer, fraction, groupIndex);
};

export const isLoading = ({ planSer, fraction, groupIndex }, state = store.getState()) =>
  dicomsSelector(state)?.[createKey(planSer, fraction, groupIndex)]?.status === LOADING;

export const isReady = ({ planSer, fraction, groupIndex }, state = store.getState()) =>
  dicomsSelector(state)?.[createKey(planSer, fraction, groupIndex)]?.status === READY;