import { html, css, LitElement } from 'lit';
import commonStyles from '../../styles-common';
import { distinct, removeValue } from '../../../utils/immutable/array';
import h from '../../../utils/h';

const normalizeOptions = options => options.map(option =>
  typeof option !== 'object'
    ? { value: option, text: option }
    : { value: option.value, text: option.text || option.value, group: option.group }
);

class RadMultiDropdown extends LitElement {
  static styles = [
    commonStyles,
    css`
      :host {
        display: block;
        position: relative;
      }
      
      label {
        display: block;
        width: 100%;
        background: var(--lighter-color);
        font-size: 14px;
        color: var(--neutral-3);
        border-radius: 5px;
        margin: 0 0 16px;
        border: 1px solid var(--side-border);
        line-height: 35px;
        padding: 0 4px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
      }
      
      label[open] {
        border-color: var(--neutral-1);
        border-radius: 4px 4px 0 0;
      }

      label:after {
        font-family: 'Font Awesome 5 Pro';
        content: '\uf107';
        position: absolute;
        right: 4px;
        font-weight: 900;
        font-size: 1.1em;
        top: 1px;
      }
        
      dropdown {
        position: absolute;
        top: 100%;
        border: 1px solid var(--neutral-1);
        padding: 8px 0;
        background: #FFF;
        z-index: 10000;
        width: 100%;
        border-radius: 0 0 4px 4px;
        margin-top: -1px;
        max-height: 400px;
        overflow: auto;
      }
        
      dropdown ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
        
      dropdown li {
        padding: 0 12px 0 42px;
        position: relative;
        line-height: 31px;
        cursor: pointer;
      }
        
      li[item]:before {
        font-family: 'Font Awesome 5 Pro';
        content: '\uf0c8';
        position: absolute;
        left: 12px;
        font-weight: 500;
        color: var(--neutral-4);
        font-size: 22px;
        top: 1px;
      }
        
      li[item][selected]:before {
        content: '\uf14a';
        font-weight: 900;
      }
        
      li[group] {
        font-weight: bold;
        padding-left: 12px;
        background: var(--neutral-7);
      }
    `
  ].flat();

  static properties = {
    hideSelectAll: { type: Boolean },
    options: { type: Array },
    selected: { type: Array },
    open: { type: Boolean },
    label: { type: String },
    selectAllText: { type: String }
  };

  get normalOptions() {
    return normalizeOptions(this.options);
  }

  render() {
    const { label, open, handleToggleOpen } = this;

    return html`      
      <label
        ?open=${open}
        @click=${handleToggleOpen}
      >
        ${label}
      </label>
      ${this.renderDropdown()}
    `;
  }

  renderDropdown() {
    const { hideSelectAll, normalOptions, open, selectAllText, selected, options,
      handleSelect } = this;

    if (!open) return;

    const groups = distinct(normalOptions.map(({ group }) => group));
    const groupedOptions = normalOptions.reduce((acc, { group, ...option }) => {
      if (!acc[group]) {
        acc[group] = [];
      }

      acc[group].push(option);

      return acc;
    }, {});

    return html`
      <dropdown @click=${handleSelect}>
        <ul>
          ${h(!hideSelectAll, html`
            <li item select-all 
              ?selected=${selected.length === options.length}
            >${selectAllText || 'Select All'}</li>`)}
          ${groups.length > 1 ? this.renderGroupedOptions(groups, groupedOptions) : this.renderOptions1(normalOptions)}
        </ul>
      </dropdown>
    `;
  }
  
  renderGroupedOptions(groups, groupedOptions) {
    return groups.map(group => html`
      <li group>${group}</li>
      ${this.renderOptions1(groupedOptions[group])}
    `);
  }
  
  renderOptions1(normalOptions) {
    const { selected } = this;

    return normalOptions.map(({ value, text }) => html`
      <li .itemValue=${value} item ?selected=${selected.includes(value)}>${text}</li>
    `);
  }

  handleSelect(event) {
    event.stopPropagation();

    const { selected, normalOptions } = this;
    const { target } = event;

    if (target.hasAttribute('select-all')) {
      this.dispatchChange(selected.length === normalOptions.length ? [] : normalOptions.map(({ value }) => value));
    } else if (target.hasAttribute('item')) {
      const { itemValue: value } = target;

      this.dispatchChange(selected.includes(value) ? removeValue(selected, value) : [...selected, value]);
    }
  }

  handleToggleOpen(event) {
    const { open } = this;

    if (open) {
      this.handleClose(event);
      return;
    }

    this.open = true;

    // In a timeout or it'll fire immediately after.
    setTimeout(() => document.addEventListener('click', this.handleClose), 1);
  }

  handleClose = event => {
    event.stopPropagation();

    this.open = false;
    document.removeEventListener('click', this.handleClose);
  };

  dispatchChange(selected) {
    this.dispatchEvent(new CustomEvent('change', { detail: { selected }, bubbles: true, composed: true }));
  }
}

customElements.define('rad-multi-dropdown', RadMultiDropdown);