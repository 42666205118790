import TID1500 from "./TID1500/index.js";
import TID300 from "./TID300/index.js";
import message from "./Message.js";
import addAccessors from "./addAccessors.js";

const utilities = {
    TID1500,
    TID300,
    message,
    addAccessors
};

export default utilities;
