﻿import { css, html, LitElement } from 'lit';
import { configSelector, pageSelector } from '../../../redux/app/selectors';
import fontAwesome from '../../../utils/font-awesome';
import { generate } from '../../../router';
import { Routes } from '../../../routes';
import '../../common/company-info-modal';
import '../../common/rad-link';
import adminStyles from './styles-admin';
import h from '../../../utils/h';
import applyAttributes from '../../../directives/ApplyAttributesDirective';

const MODAL_FADE = 1000; // milliseconds to from starting fade to complete

class AuthHeader extends LitElement {
  static styles = [
    adminStyles,
    css`
      nav {
        display: flex;
        align-items: center;
        background: var(--primary-color);
        font-size: 24px;
        padding: 8px;
        z-index: 9999;
        box-shadow: 4px 4px 4px rgba(0,0,0,.1);
      }
  
      a, rad-link {
        color: var(--light-color);
        margin-right: 5px;
      }
      
      #save button.save {
        border: 0;
        padding: 5px 8px 4px;
        background: 0;
        color: var(--blue-6);
        font-size: inherit;
        cursor: pointer;
        min-width: unset;
      }
      
      #save button:hover {
        color: var(--light-color);
      }
      
      div {
        flex: 1;
      }
      
      #tabs {
        text-align: center;
        position: relative;
        flex-basis: 400px;
      }
      
      #tabs rad-link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        background: none;
        border: none;
        display: inline-block;
        color: var(--light-color);
        cursor: pointer;
        padding: 8px 12px 7px;
        font-size: 1rem;
        margin: 0 16px;
        box-sizing: border-box;
        text-decoration: none;
        border: 2px solid transparent;
      }
      
      #tabs rad-link:hover {
        border: 2px solid var(--primary-4);
        border-radius: 8px;
      }
      
      #tabs rad-link[active] {
        border: 2px solid var(--light-color);
        border-radius: 8px;
      }
      
      #extra {
        text-align: right;
      }
      
      #extra a,
      #extra button {
        background: 0;
        padding: 5px 8px 4px;
        border: 0;
        color: var(--primary-7);
        margin-left: 10px;
        font-size: inherit;
        cursor: pointer;
      }
      
      #extra a:hover,
      #extra button:hover {
        color: var(--light-color);
      }
      
      #saved-indicator {
        opacity: 0;
        position: absolute;
        z-index: 9999;
        background: var(--primary-2);
        border-radius: 5px;
        padding: 7px 16px 6px;
        width: auto;
        top: 10px;
        left: 56px;
        transition: opacity ${MODAL_FADE}ms ease-in-out;
      }
      
      #saved-indicator[active] {
        transition: opacity .2s ease-in-out;
        opacity: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      
      #saved-indicator p {
        font-size: 14px;
        border-radius: 5px;
        margin: 0;
        color: white;
      }
    `
  ].flat();

  static properties = {
    hideSave: { type: Boolean },
    showInfoModal: { type: Boolean },
    showSaved: { type: Boolean },
    savedMessage: { type: String }
  };

  updated(changed) {
    if (changed.has('showSaved') && this.showSaved) {
      setTimeout(() => {
        this.dispatchEvent(new CustomEvent('saved-complete', { bubbles: true, composed: true }));
      }, 3000);
    }
  }

  render() {
    const { hideSave, showInfoModal, showSaved, savedMessage, toggleInfoModal, handleSave } = this;
    const currentPage = pageSelector();
    const { logoutUrl } = configSelector();

    return html`
      ${fontAwesome}
      <nav>
        ${h(!hideSave, html`
          <div id="save">
            <button class="save" 
              @click=${handleSave}>
              <i class="far fa-save"></i>
            </button>
          </div>
        `)} 
        <div id="tabs">
          <rad-link 
            href=${generate(Routes.AdminPermissions)} 
            class="weekly" 
            ?active=${currentPage === Routes.AdminPermissions}
          >Permissions</rad-link>          
          <rad-link 
            href=${generate(Routes.AdminUsers)} 
            class="settings"
            ?active=${currentPage === Routes.AdminUsers}
          >Users</rad-link>
          <rad-link 
            href=${generate(Routes.AdminOrganizations)} 
            class="settings"
            ?active=${currentPage === Routes.AdminOrganizations}
          >Organizations</rad-link>
        </div>
        <div id="extra">
          <button @click=${toggleInfoModal}><i class="fas fa-info"></i></button>
          <a href=${logoutUrl}><i class="fas fa-sign-out-alt"></i></a>
        </div>
      </nav>
      <company-info-modal 
        class="info-modal" 
        ?active=${showInfoModal} 
        @close=${toggleInfoModal}      
      >
        ${applyAttributes(configSelector())}
      </company-info-modal>
      <div id="saved-indicator" ?active=${showSaved}>
        <p>${savedMessage}</p>
      </div>
    `;
  }

  handleSave = () => {
    this.dispatchEvent(new CustomEvent('save', { bubbles: true, composed: true }));
  };

  toggleInfoModal = () => {
    this.showInfoModal = !this.showInfoModal;
  };
}

customElements.define('auth-header', AuthHeader);