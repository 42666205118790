import range from '../../../../utils/range';

const buildDateHeaders = (dateData, padding = 1) => [
  [
    ...range(0, padding - 1).map(() => ''),
    { value: 'Date', rowspan: 2, class: 'align-right' },
    ...dateData.map(({ dayOfWeek, times }) => ({ value: dayOfWeek, colspan: times.length }))
  ],
  [
    ...range(0, padding - 1).map(() => ''),
    { rowspan: 0 },
    ...dateData.map(({ date, times }) =>
      ({ value: date, colspan: times.length, class: padding === 0 ? 'no-border' : '' }))
  ],
  [
    ...range(0, padding - 1).map(() => ''),
    { value: 'Time', class: 'align-right' },
    ...dateData.flatMap(({ times }) => times.map(({ time }) => time))
  ]
];

export default buildDateHeaders;