import { store } from './redux/store';
import { navigate } from './redux/app/actions';
import routes from './routes';
import getDefaultParam from './utils/getDefaultParam';
import { getQueryParams } from './utils/query';
import queryToString from './utils/rad-http/utils/queryToString.js';

const router = new class {
  constructor() {
    this.baseUri = document.baseURI.replace(/\/?$/, '');
  }

  pause() {
    this.paused = true;
  }

  resume() {
    this.paused = false;
    this.resolve();
  }

  navigate(path) {
    if (!this.beforeCallback) {
      this.doNavigate(path);
      return;
    }

    this.beforeCallback((resolve = true) => {
      resolve && this.doNavigate(path);
    });
  }

  doNavigate(path) {
    history.pushState('', '', path);
    !this.paused && this.resolve();
  }

  generate(routeName, params) {
    const route = this.routes.find(({ as }) => as === routeName);

    if (!route) return undefined;

    return Object.entries(params)
      .reduce((str, [key, value]) => str.replace(new RegExp(`:${key}(/?)`, 'g'), `${value}$1`), route.route);
  }

  resolve() {
    const path = document.location.href.replace(this.baseUri, '').replace(/\/?([#?].*)?$/, '') || '/';
    const route = this.routes.find(({ pattern }) => pattern.test(path));

    if (!route) {
      this.notFoundCallback();
      return;
    }

    const { pattern, uses } = route;
    const { groups: params } = path.match(pattern);
    const query = getQueryParams();

    uses(params, query);
  }

  on(routes) {
    this.routes = Object.entries(routes).map(([route, { as, uses }]) => ({
      route,
      pattern: new RegExp(`^${route.replace(/\//, '\\/')
        .replace(/:([^/]+)!/g, '(?<$1>.+)')
        .replace(/:([^/]+)/g, '(?<$1>[^\\/]+)')}$`),
      as,
      uses
    }));

    return this;
  }

  notFound(notFoundCallback) {
    this.notFoundCallback = notFoundCallback;
    return this;
  }

  hooks({ before }) {
    this.beforeCallback = before;
    return this;
  }
}();

export const generate = (routeName, givenParams = {}) => {
  const { route } = routes[routeName];
  const params = [...route.matchAll(/:([^/]+)/g)]
    .map(a => a[1])
    .reduce((result, paramName) => ({
      ...result,
      [paramName]: givenParams[paramName] ?? getDefaultParam(paramName)
    }), {});

  return `.${router.generate(routeName, params)}`;
};

const changePageUses = (page, filePath) => (params, query) => {
  store.dispatch(navigate(page, filePath, params, query));
};

const forwardPageUses = forward => (params, query) => {
  const queryString = query ? queryToString(query) : '';

  router.navigate(`${generate(forward, params)}${queryString}`);
};

const changePage = (page, filePath) => ({
  as: page,
  uses: changePageUses(page, filePath)
});

const forwardPage = (page, forward) => ({
  as: page,
  uses: forwardPageUses(forward)
});

const buildRoutes = routes => Object.entries(routes)
  .filter(([, { notFound }]) => !notFound)
  .reduce((result, [routeName, { view, route, forward }]) => ({
    ...result,
    [route]: view
      ? changePage(routeName, view)
      : forwardPage(routeName, forward)
  }), {});

const notFoundRoute = Object.entries(routes).find(([, { notFound }]) => notFound);

const hooks = {
  before: doneCallback => {
    const done = (goToNext = true) => {
      doneCallback(goToNext);
    };

    const event = new CustomEvent('beforenav', { cancelable: true, detail: { done } });

    if (window.dispatchEvent(event)) {
      done();
    }
  }
};

router.on(buildRoutes(routes))
  .notFound(() => changePageUses(notFoundRoute[0], notFoundRoute[1].view))
  .hooks(hooks)
  .resolve();

export default router;