import { CheckBox } from './check-box';
import { connect } from '../../utils/redux';
import { store } from '../../redux/store';
import { verifyDisabled } from '../../redux/app/selectors';

class VerifyCheckBox extends connect(store)(CheckBox) {
  static properties = {
    ...CheckBox.properties,
    verifyDisabled: { type: Boolean }
  }

  get isDisabled() {
    return this.disabled || this.readOnly || this.verifyDisabled;
  }

  get isLabelDisabled() {
    return this.verifyDisabled || this.disabled;
  }

  stateChanged(state) {
    this.verifyDisabled = verifyDisabled(state);
  }
}

customElements.define('verify-check-box', VerifyCheckBox);