const getImageId = imageGroup => {
  const acquired = imageGroup
    ? imageGroup?.value?.acquiredImages
      .concat(imageGroup?.value?.referenceImages)[0]
      .displayDicomId ?? ''
    : '';

  const paired = imageGroup?.value?.pairedAcquiredImage?.displayDicomId;

  return paired ? acquired + ' - ' + paired : acquired;
};

export default getImageId;