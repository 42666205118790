/**
 * Enchances the contrast of a 2D image.
 * @param {Number[]} data An array of data, stored Y-first.
 * @param {int} width The width of the image.
 * @param {int} height The hiehgt of the image.
 * @param {Object.<int, int>} histogram= A histogram. Will be generated if not provided.
 * @return {Number[]} The transformed data. The type of array will be the same as the input.
 */
import buildHistogram from './buildHistogram.js';

const applyContrastEnhanceFilter = (data, width, height, histogram = undefined) => {
  histogram ??= buildHistogram(data, width, height);

  const range = 65535;

  let sum = histogram.getValue(0)
    + histogram.getValue(histogram.max);

  for (let i = 1; i < histogram.max; i++) {
    sum += 2 * histogram.getWeightedValue(i);
  }

  const scale = range / sum;
  const lut = new Array(range + 1);
  lut[0] = 0;

  sum = histogram.getValue(0);

  for (let i = 1; i < histogram.max; i++) {
    const delta = histogram.getWeightedValue(i);
    sum += delta;
    lut[i] = Math.round(sum * scale);
    sum += delta;
  }

  lut[histogram.max] = range;

  for (let i = 0; i < data.length; i++) {
    data[i] = lut[data[i]];
  }
};

export default applyContrastEnhanceFilter;