import toDateObject from '../toDateObject';
import toDate from './toDate';
import toTime from './toTime';

// {fullDate} {time} - 2020/03/14 01:14 PM
const toDateTime = date => {
  const dateObj = toDateObject(date);

  return `${toDate(dateObj)} ${toTime(dateObj)}`;
};

export default toDateTime;