
import clamp from '../math/clamp.js';
import percentageToValue from '../math/percentageToValue.js';

const to255Integer = value =>
  clamp(percentageToValue(value, 0, 255), 0, 255);

/**
 * Converts color to an array with 4 numbers, from 0 to 255, for RGBA. E.g., [255, 0, 0, 255]
 *
 * If the input is missing alpha information, it is assumed to be 255.
 *
 * Supported inputs:
 *  - RGB string - #FF0000 or FF0000
 *  - RGBA string - #FF0000FF or FF0000FF => #FF0000FF
 *  - Array of 3 int values, from 0 to 255 - [255, 0, 0]
 *  - Array of 4 int values, from 0 to 255 - [255, 0, 0, 255]
 *  - Array of 3 decimal values, from 0 to 1 - [1, 0, 0]
 *  - Array of 4 decimal values, from 0 to 1 - [1, 0, 0, 1]
 *
 * @param {string|[int, int, int]|[int, int, int, int]}color
 * @return {Number[]}
 */
const toComponentArray = color => {
  if (typeof color === 'string') return parseString(color);

  if (!Array.isArray(color)) throw new Error(`Cannot parse color: ${color}`);

  // Integer array
  if (Number.isSafeInteger(color[0])) {
    return [
      color[0],
      color[1],
      color[2],
      color[3] ?? 255
    ];
  }

  // Decimal array
  return [
    to255Integer(color[0]),
    to255Integer(color[1]),
    to255Integer(color[2]),
    to255Integer(color[3]) ?? 255
  ];
};

const parseString = color => {
  const [, r, g, b, a = 'FF'] = color.match(/^#?([\dA-F]{2})([\dA-F]{2})([\dA-F]{2})([\dA-F]{2})?$/i);

  return [r, g, b, a].map(v => Number.parseInt(v, 16));
};

export default toComponentArray;