const POPOUT_DB_NAME = 'popout';
const OBJECT_STORE_NAME = 'popout-os';
const VERSION = 2;

const getIndexedDBObjectStore = async () => new Promise((resolve, reject) => {
  const openRequest = indexedDB.open(POPOUT_DB_NAME, VERSION);

  openRequest.onupgradeneeded = event => {
    const db = event.target.result;
    db.createObjectStore(OBJECT_STORE_NAME);
  };

  openRequest.onerror = reject;
  openRequest.onsuccess = () => {
    /** @type {IDBDatabase} */
    const db = openRequest.result;

    resolve(db.transaction(OBJECT_STORE_NAME, 'readwrite').objectStore(OBJECT_STORE_NAME));
  };
});

export default getIndexedDBObjectStore;