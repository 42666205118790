import { html } from 'lit';
import RadTable from '../rad-table';
import PassState from '../../../PassState';
import passStateTableStyles from './styles';
import fontAwesome from '../../../utils/font-awesome';
import { settingsSelector } from '../../../redux/app/selectors';
import { styleMap } from 'lit/directives/style-map.js';

export const SHOW_FOR_EMPTY_VALUE = Symbol('show-for-empty-value');
export const SHOW_FOR_NON_PASS = Symbol('non-pass-only');
export const SHOW_NON_EMPTY_VALUE = Symbol('non-empty-value');
export const SHOW_FOR_NO_PASS_STATE = Symbol('show-for-no-pass-state');

export default class PassStateTable extends RadTable {
  static styles = [passStateTableStyles].flat();

  static properties = {
    ...RadTable.properties,
    colors: { type: Object }
  }

  static cellDefaults = {
    ...super.cellDefaults,
    passState: null,
    showIcon: false,
    showValue: true
  }

  constructor() {
    super();

    this.colors = settingsSelector().colors;
  }

  render() {
    return html`
      ${fontAwesome}
      ${super.render()}
    `;
  }

  renderVerifiedBy(verifiedByGetter, verification) {
    const verifiedBy = super.renderVerifiedBy(verifiedByGetter, verification);

    if (!verifiedBy) return;

    return html`<span style=${styleMap({ color: this.colors.verified })}>
      ${verifiedBy}
    </span>`;
  }

  normalizeCell(cell) {
    const { colors } = this;

    if (!cell || !cell.passState || !colors[cell.passState.toLowerCase()]) {
      return super.normalizeCell(cell);
    }

    return {
      ...super.normalizeCell(cell),
      style: `color: ${colors[cell.passState.toLowerCase()]}`
    };
  }

  mapKeyToAttribute(key, value, obj) {
    if (key === 'tooltip' || key === 'tooltipText') {
      return 'title';
    }

    if (key === 'showIcon' && Array.isArray(value)) {
      if ((value.includes(SHOW_FOR_NON_PASS) && obj.passState !== PassState.PASS)
        || (value.includes(SHOW_FOR_EMPTY_VALUE) && !obj.value)) {
        return '?show-icon';
      }

      return undefined;
    }

    if (key === 'showIcon' && value === SHOW_FOR_NON_PASS) {
      if (obj.passState && obj.passState !== PassState.PASS) return '?show-icon';

      return undefined;
    }

    if (key === 'showValue') {
      if (value === SHOW_FOR_NON_PASS) {
        if (obj.passState !== PassState.PASS) return '?show-value';

        return undefined;
      }

      if (value === SHOW_NON_EMPTY_VALUE) {
        if (obj.value) return '?show-value';

        return undefined;
      }

      if (value === SHOW_FOR_NO_PASS_STATE) {
        if (!obj.passState) {
          return '?show-value';
        }

        return undefined;
      }
    }

    return super.mapKeyToAttribute(key, value, obj);
  }
}

customElements.define('pass-state-table', PassStateTable);