import { html, LitElement } from 'lit';
import patientStyles from '../styles/patient';
import h from '../../../../utils/h';
import '../../../common/pass-state-table';
import buildDatedTableData from '../utils/buildDatedTableData';
import filterForSkippedDates from '../utils/filterForSkippedDates';
import isDatedTableEmpty from '../utils/isDatedTableEmpty';
import { SHOW_FOR_NO_PASS_STATE } from '../../../common/pass-state-table';

export const isDocumentationTableEmpty = (dates, documentations = [], skipData) =>
  isDatedTableEmpty(rows, skipData, dates, documentations);

const rows = [
  {
    header: 'Pre-Treatment Timeout Complete',
    firstField: 'plannedPreTreatmentTimeouts',
    field: 'preTreatmentTimeout',
    showValue: SHOW_FOR_NO_PASS_STATE
  },
  {
    header: 'Treatment Encounters',
    field: 'encounters',
    firstField: 'plannedEncounters',
    showValue: SHOW_FOR_NO_PASS_STATE
  },
  {
    header: 'Procedure Notes',
    field: 'procedureNotes',
    firstField: 'plannedProcedureNotes',
    showValue: SHOW_FOR_NO_PASS_STATE
  },
  {
    header: 'Journal Entries',
    field: 'journalNotesAdded',
    phi: true
  },
  {
    header: 'Documents Added',
    field: 'documentsAdded'
  },
  {
    header: 'Documents Modified',
    field: 'documentsModified'
  },
  {
    header: 'Alerts',
    field: 'alerts',
    phi: true
  },
  {
    chart: {
      firstColumn: 2,
      unitOfMeasurement: ({ weightUnitOfMeasure }) => weightUnitOfMeasure,
      axis: ({ weightUnitOfMeasure }) => ({
        increment: 1,
        label: `Weight [${weightUnitOfMeasure}]`
      }),
      data: ({ weightLine }) => ['Weight']
        .map((header, index) => ({ header, ...weightLine[index] }))
    },
    skip: ({ weightShow }) => !weightShow
  }
];

class PatientDocumentation extends LitElement {
  static styles = patientStyles;

  static properties = {
    course: { type: Object },
    documentations: { type: Object },
    dates: { type: Array },
    weightLine: { type: Array },
    weightShow: { type: Boolean },
    weightUnitOfMeasure: { type: String },
    showPreTreatmentDates: { type: Boolean },
    verifications: { type: Array }
  };

  static styles = patientStyles;

  render() {
    const { dates, course, documentations, showPreTreatmentDates, verifications, handleTogglePreTreatmentDates } = this;

    if (!dates?.length || isDocumentationTableEmpty(dates, documentations, this)) return;

    const data = buildDatedTableData({
      element: this,
      dates: dates.reduce(filterForSkippedDates(course.skipDocumentationTimes), []),
      rows,
      firstData: course,
      data: documentations,
      showPlanned: true,
      verifications
    });

    return html`
      <!-- <button
        @click=${handleTogglePreTreatmentDates}
      >
        ${h(showPreTreatmentDates, html`Hide`, html`Show`)} Pre-Treatment Dates
      </button> -->
      <section class="documentation">
        <h4>Documentation</h4>
        <pass-state-table
          even
          .data=${data}
          headerRows="4"
          freezeRows="4"
          headerColumns="2"
          freezeColumns="2"
        ></pass-state-table>
      </section>
    `;
  }

  handleTogglePreTreatmentDates = () => {
    this.dispatchEvent(new CustomEvent('toggle-pre-treatment-dates', { bubbles: true, composed: true }));
  };
}

customElements.define('patient-documentation', PatientDocumentation);