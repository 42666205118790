const filterInactive = row => {
  if (!Array.isArray(row)) return true; // a chart

  const passStateCells = row.filter(cell => cell?.isPassStateValue);

  // There are no passState cells in the entire row, so it is entirely informational and we'll display it
  if (!passStateCells.length) return true;

  // If there are passState cells, but they're all inactive, we won't show the row.
  return Boolean(passStateCells.filter(cell => cell.inactive !== true).length);
};

export default filterInactive;