import { html, css, LitElement } from 'lit';
import patientStyles from '../styles/patient';
import { SHOW_FOR_NON_PASS } from '../../../common/pass-state-table';
import resolveDataBase, { getValue, getPassState, hasValue, getInactive } from '../utils/resolveData';
import { INFO_ICON_TAG } from '../../../common/rad-table';

const resolveData = ({
  data, field, unit = '', htmlClass = '', showIcon = SHOW_FOR_NON_PASS, showValue = true, ...rest
}) =>
  resolveDataBase({ data, field, unit, showIcon, showValue, htmlClass, defaultPrecision: 0, ...rest });

const subValues = {
  actualDose: 'actualDose',
  plannedDose: 'plannedDose',
  dosePerFraction: 'dosePerFraction',
  id: 'id',
  hasDoseCorrection: 'hasDoseCorrection'
};

const getSubValue = (passStateValue, subValueKey) =>
  (getValue(passStateValue) || {})[subValueKey];

const hasSubValue = (passStateValue, subValueKey) =>
  (hasValue(passStateValue)) && getSubValue(passStateValue, subValueKey);

class PlanRefPoints extends LitElement {
  static styles = [
    patientStyles,
    css`    
      table tr {
        display: table-row;
      }
      
      thead th:first-child, 
      thead tr th:first-of-type,
      th:not(:first-of-type),
      tbody td {
        flex-basis: auto;
        width: auto;
      }
    `
  ].flat();

  static properties = {
    plan: { type: Object },
    verifications: { type: Array }
  };

  render() {
    const { plan, buildData } = this;
    const data = buildData();

    if (!data) return;

    return html`
      <section>
        <h4>Ref Points - ${plan.id}</h4>
        <pass-state-table
          .data=${ data }
          headerColumns = "0"
        ></pass-state-table> 
      </section>
    `;
  }

  buildData = () => {
    const { referencePointDoses, treatments, key } = this.plan;

    if (!referencePointDoses?.length || getInactive(referencePointDoses[0]) || !treatments.length) return;

    return [
      ['Reference point', 'Planned dose', 'Delivered / Total dose'],
      ...referencePointDoses.map((refPointDose, index) =>
        [
          getSubValue(refPointDose, subValues.id),
          getSubValue(refPointDose, subValues.dosePerFraction),
          {
            ...resolveData({
              data: refPointDose,
              field: refPointDose =>
                hasSubValue(refPointDose, subValues.actualDose) || hasSubValue(refPointDose, subValues.plannedDose)
                  ? [
                    {
                      value: refPointDose.tooltipText
                        ? getSubValue(refPointDose, subValues.actualDose) + INFO_ICON_TAG
                        : getSubValue(refPointDose, subValues.actualDose),
                      rawValue: getSubValue(refPointDose, subValues.actualDose),
                      passState: getPassState(refPointDose),
                      isInactive: getInactive(refPointDose),
                      verifyIndex: getSubValue(refPointDose, subValues.id),
                      tooltipText: refPointDose.tooltipText,
                      tooltip: refPointDose.tooltipText
                    },
                    '/ ',
                    getSubValue(refPointDose, subValues.plannedDose)
                  ] : '-',
              verifyKey: key,
              verifyEach: true,
              verifyIndexed: true,
              verifyField: 'referencePointDoses',
              verifications: this.verifications
            }),
            inline: true
          }
        ]
      )
    ];
  };
}

customElements.define('plan-ref-points', PlanRefPoints);