/* This is the standalone page view. For the embeddable element, use document-viewer */
import { html, css, LitElement } from 'lit';
import { connect } from '../../../utils/redux';
import { store } from '../../../redux/store';
import { documentIdSelector, documentTypeSelector } from '../../../redux/app/selectors';
import './document-viewer';

class DocumentView extends connect(store)(LitElement) {
  static styles = css`
    :host {
      display: flex !important;
      flex-flow: column;
      background: #ecf0f2;
    }
    
    document-viewer {
      flex: 1;
    }
  `;

  static properties = {
    documentId: { type: String },
    documentType: { type: String }
  };

  render() {
    const { documentId, documentType } = this;

    return html`
      <document-viewer no-buttons id=${documentId} type=${documentType}></document-viewer>
    `;
  }

  stateChanged(state) {
    this.documentId = documentIdSelector(state);
    this.documentType = documentTypeSelector(state);
  }
}

customElements.define('document-view', DocumentView);