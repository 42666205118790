import { html, css, LitElement } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';

class ColorSquare extends LitElement {
  static styles = css`
    :host {
      width: 20px;
      height: 20px;
      display: inline-block;
    }
    
    div {
      display: block;
      width: 100%;
      height: 100%;
    }
  `;

  static properties = {
    color: { type: String }
  };
  
  render() {
    return html`
      <div style=${styleMap({ backgroundColor: this.color })}></div>
    `;
  }
}

customElements.define('color-square', ColorSquare);
