import { LitElement, html, css } from 'lit';
import { hidePhi, showPhi } from '../utils/phi';
import http from '../utils/redux/http';
import { padBefore } from '../utils/string';
import { download, downloadCsv } from '../utils/browser';
import { addToast } from '../utils/redux/toasts/actions';
import { connect } from '../utils/redux';
import fontAwesome from '../utils/font-awesome';
import h from '../utils/h';
import '../components/common/company-info-modal';
import { configSelector, patientIdSelector, patientSerSelector } from '../redux/app/selectors';
import { store } from '../redux/store';
import { getCookie } from '../utils/cookie';
import applyAttributes from '../directives/ApplyAttributesDirective';
import getGpu from '../utils/getGpu.js';
import imagePreview from '../utils/imagePreview.js';

const truncateIfNeeded = gpuName => {
  if (gpuName.toLowerCase().includes('vulkan')) {
    return 'Google Vulcan Driver'.toUpperCase();
  }

  if (gpuName.toLowerCase().includes('microsoft')) {
    return 'Microsoft Basic Renderer Driver'.toUpperCase();
  }

  return gpuName;
};

class CompanyInfoModalWithSupport extends connect(store)(LitElement) {
  static styles = css`
    #exporting {
      position: fixed;
      z-index: 20000;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, .5);
    }

    #exporting p {
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      font-size: 2em;
      transform: translate(-50%, -50%);
      color: #FFF;
    }

    #exporting spinner {
      animation: spin 2s steps(8, end) infinite;
      font-family: "Font Awesome 5 Pro";
      margin-right: 8px;
      display: inline-block;
    }

    #exporting spinner:before {
      content: '\uf110';
    }

    support button,
    gpu-info button,
    gpu-tutorial button {
      display: block;
      background: #3498DB;
      color: #FFF;
      padding: 8px 16px;
      border: 0;
      margin: 8px auto;
      cursor: pointer;
    }

    support button:hover,
    gpu-info button:hover,
    gpu-tutorial button:hover {
      background: #266FA0;
    }

    support blur {
      display: flex;
      flex-direction: row;
    }

    support blur button {
      flex: 1;
      margin: 0 4px;
    }

    support blur button:not([active]) {
      background: var(--neutral-5);
    }
    
    gpu-info {
      border: 1px solid var(--neutral-3);
      font-size: .8rem;
      line-height: 1.2rem;
      padding: 12px 8px 0;
      margin: 0 8px 1em;
      display: block;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      max-height: 314px; /* if modifying, this needs to be adjusted */
      transition: max-height .5s ease-in-out;
    }
    
    gpu-info.closed {
      max-height: 29px;
    }
    
    gpu-info.good {
      background-color: var(--green-7);
    }
    
    gpu-info.bad {
      background-color: #FFEEEE;
    }
    
    gpu-info:before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;    
      position: absolute;
      left: 14px;
      font-size: 36px;
      top: 12px;
      pointer-events: none;
    }
    
    gpu-info:after {
      pointer-events: none;
      font-family: "Font Awesome 5 pro";
      font-weight: 900;
      position: absolute;
      right: 14px;
      top: 12px;
      font-size: 36px;
      cursor: pointer;
    }
    
    gpu-info header {
      font-weight: bold;
      display: flex;
      padding: 12px 50px 12px 60px;
      margin: -12px -8px;
      cursor: pointer;
    }
    
    gpu-info.closed header .gpu {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    gpu-info header .gpu {
      flex: 1;
    }
    
    gpu-info header .tier {
      flex-wrap: nowrap;
      white-space: nowrap;
      flex: 0;
    }
    
    gpu-info.open:after {
      content: "\uf106";
    }
    
    gpu-info.closed:after {
      content: "\uf107";
    }
    
    gpu-info p,
    gpu-info button {
      margin-bottom: 12px;
    }
    
    gpu-info.good:before {
      content: "\uf00c";
      color: var(--green-2);
    }
    
    gpu-info.bad:before {
      content: "\uf00d";
      color: var(--bad-color);
    }
    
    gpu-tutorial {
      content: "";
      position: fixed;
      inset: 0;
      background: rgba(0, 0, 0, .65);
    }

    gpu-tutorial .content {
      z-index: 20000;
      border-radius: 4px;
      position: absolute;
      overflow: auto;
      background: var(--light-color);
      top: 50%;
      left: 50%;
      min-width: 600px;
      max-height: 80%;
      transform: translate(-50%, -50%);
      display: block;
      padding: 20px 16px;
    }
    
    gpu-tutorial img {
      max-width: 100%;
    }
    
    gpu-tutorial .header {
      display: flex;
      flex-direction: row;
    }
    
    gpu-tutorial a {
      flex: 1;
    }
    
    gpu-tutorial h1 {
      font-size: 1.8em;
      text-align: center;
      margin-top: 0;
    }
    
    gpu-tutorial a.close {
      text-align: right;
      font-size: 18px;
      line-height: 1;
      margin-right: 8px;
      color: var(--neutral-4);
    }
    
    gpu-tutorial a {
      cursor: pointer;
    }
    
    gpu-tutorial a.back:before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      content: "\uf104 ";
    }
    
    gpu-tutorial a.close:before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      content: "\uf00d";
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }

    rad-confirm input,
    rad-confirm textarea {
      line-height: 1.5;
      width: 100%;
    }
  `;

  static properties = {
    active: { type: Boolean },
    sending: { type: Boolean },
    downloading: { type: Boolean },
    hidePhiMode: { type: String },
    showCorrelatedModal: { type: Boolean },
    sendingCorrelated: { type: Boolean },
    showReasonModal: { type: Boolean },
    gpuInfo: { type: Object },
    showGpuPopup: { type: Boolean },
    gpuInfoOpen: { type: Boolean },
    gpuPopupState: { type: Number },
    imagePreviewEnabled: { type: Boolean }
  };

  constructor() {
    super();

    this.hidePhiMode = getCookie('HIDE_PHI');
    this.initializeGpuInfo();
    this.imagePreviewEnabled = imagePreview.isEnabled();

    window.addEventListener('hide-phi', () => {
      this.hidePhiMode = getCookie('HIDE_PHI');
    });
  }

  async initializeGpuInfo() {
    this.gpuInfo = await getGpu();
  }

  render() {
    const { active, downloading, sending, hidePhiMode, showCorrelatedModal, showReasonModal,
      handleShowPhi, handleHidePhi, handleSend, handleDownload,
      handleCorrelatedErrorStart, handleCorrelatedErrorConfirm, handleCorrelatedErrorCancel,
      handleAuditLogDownload, auditDownloading, gpuInfo, showGpuPopup } = this;
    const patientId = patientIdSelector();

    return html`
      ${fontAwesome}
      ${h(downloading || sending || auditDownloading, html`
        <div id="exporting">
          <p>
            <spinner></spinner>
            ${h(downloading, html`Exporting for download...`)}
            ${h(sending, html`Exporting and sending...`)}
            ${h(auditDownloading, html`Downloading audit logs...`)}
          </p>
        </div>
      `)}
      <company-info-modal ?active=${active}>
        ${applyAttributes(configSelector())}
        ${h(gpuInfo, this.renderGpuInfo)}
        <support>
          <blur>
            <button 
              @click=${handleShowPhi} 
              title="Shows PHI normally"
              ?active=${!hidePhiMode}
            >Show PHI</button>
            <button 
              @click=${handleHidePhi} 
              .mode=${'blur'} 
              ?active=${hidePhiMode === 'blur'} 
              title="Blur PHI so you can take screenshots"
            >Blur PHI</button>
            <button 
              @click=${handleHidePhi} 
              .mode=${'redact'} 
              ?active=${hidePhiMode === 'redact'} 
              title="Redact PHI so you can take screenshots"
            >Redact PHI</button>
          </blur>
          ${h(patientId, () => html`
            <button @click=${handleSend}>Anonymize and export current patient to Radformation</button>
            <button @click=${handleDownload}>Anonymize and download current patient</button>
          `)}
          <button @click=${handleCorrelatedErrorStart}>Submit Radformation requested data</button>
          ${h(showCorrelatedModal, html`
            <rad-confirm
              style="--min-width: 600px"
              confirmText="Send Data"
              cancelText="Cancel"
              @confirm=${handleCorrelatedErrorConfirm}
              @cancel=${handleCorrelatedErrorCancel}
            >
              <p>Please enter data request number:</p>
              <input>
            </rad-confirm>
          `)}           
          <button @click=${handleAuditLogDownload}>Download audit log</button>
          ${h(showReasonModal, html`
            <rad-confirm
              style="--min-width: 600px"
              confirmText="Send Anonymized Patient"
              cancelText="Cancel"
              @confirm=${this.handleConfirmReasonModal}
              @cancel=${this.handleCancelReasonModal}
            >
              <p>Please provide a description of the problem:</p>
              <textarea rows="8"></textarea>
            </rad-confirm>
          `)}  
        </support>
      </company-info-modal>
      ${h(showGpuPopup, this.renderGpuPopup)}
    `;
  }

  renderGpuInfo = () => {
    const { gpuInfo, imagePreviewEnabled, gpuInfoOpen } = this;

    return html`
      <gpu-info class=${`${gpuInfo.tier >= 3 ? 'good' : 'bad'} ${gpuInfoOpen ? 'open' : 'closed'}`}>
        <div @click=${this.handleGpuInfoToggle}>
          <header>
            <span class="gpu">
              GPU: ${truncateIfNeeded(gpuInfo.gpu.toUpperCase())}
            </span>
            <span class="tier">
              (Cornerstone Tier: ${gpuInfo.tier})
            </span>
          </header>
        </div>
        <div>
          ${h(gpuInfo.tier >= 3, html`
            <p>You are using a discrete graphics card, which is the recommendation.</p>
          `)}
          ${h(gpuInfo.tier === 2, html`
            <p>You appear to be using an integrated GPU which may not be powerful enough to display DICOM images.</p>
          `)}
          ${h(gpuInfo.tier === 1, html`
            <p>You appear to not have a GPU, which means DICOM images will struggle to display.</p>
          `)}
          ${h(gpuInfo.tier <= 2, html`
            <p>
              We strongly recommend using a discrete GPU. If your machine has a discrete GPU, 
              you can follow the instructions below to enable it for your specific GPU.
            </p>
            <button @click=${this.handleShowGpuPopup}>
              Instructions to enable GPU
            </button>
          `)}
          <p>
            If you are experiencing problems caused by image previews, you can disable them.
          </p>
          <p>
            Image Previews are <strong>${imagePreviewEnabled ? 'enabled' : 'disabled'}</strong>
          </p>
          <button @click=${this.handleToggleImagePreview}>
            ${imagePreviewEnabled ? 'Disable' : 'Enable'} image previews
          </button>
          <p>Refresh the browser after changing this for the changes to take effect.</p>
        </div>
      </gpu-info>
    `;
  }

  renderGpuPopup = () => {
    const { gpuPopupState: state } = this;

    // States:
    // 0 - Main page
    // 1 - Device Manager
    // 2 - Nvidia
    // 3 - Intel or AMD

    const commonInstructions = html`
      <li>
        Enable hardware acceleration in Chrome
        <ul>
          <li>
            In Chrome, in a new tab, go to "chrome://settings/system"</a>
            <img src="images/gpu-tutorial/chrome-settings.png">
          </li>
          <li>Enable "Use hardware acceleration when available" (1) if it isn't already.</li>
        </ul>
      </li>
      <li>
        Enable GPU in Windows Graphics Settings
        <ul>
          <li>Click the Start button or press the Windows key</li>
          <li>
            Start typing "Graphics Settings" until it appears at the top of the list, then press Enter
            <img src="images/gpu-tutorial/graphics-settings-chrome.png">
          </li>
          <li>
            Click Browse (1), then navigate to chrome.exe. If it was installed in the default location, it should be
            in
            C:\Program Files (x86)\Google\Chrome\Application\chrome.exe
          </li>
          <li>
            Click Chrome (2), then click Options (3)
            <img src="images/gpu-tutorial/graphics-settings-options.png">
          </li>
          <li>Select High Performance (1) and Save (2)</li>
          <li>You can now close the Windows Graphics Settings</li>
        </ul>
      </li>
    `;

    return html`
      <gpu-tutorial @click=${this.handleGpuPopupClickOut}>
        <div class="content">
          <div class="header">
            ${h(state > 0, html`<a class="back" @click=${this.handleGpuPopupBack}>Back</a>`)}
            <a class="close" @click=${this.handleGpuPopupClose}></a>
          </div>
          ${h(state === 0, html`
            <h1>Enable GPU Help</h1>
            <div @click=${this.handleGpuChangeState}>
              <button .state=${1}>How do I determine what I have?</button>
              <button .state=${2}>How do I enable Nvidia GPU?</button>
              <button .state=${3}>How do I enable Intel or AMD GPU?</button>
            </div>
          `)}
            ${h(state === 1, html`
            <h1>How do I determine what I have?</h1>
            <ul>
              <li>
                Open Device Manager
                <ul>
                  <li>Click the Start button or press the Windows key</li>
                  <li>Start typing "Device Manager" until it appears at the top, then press Enter</li>
                </ul>
              </li>
              <li>
                Find and expand the "Display Adapters" section
                <img src="images/gpu-tutorial/display-adapters.png">
                <ul>
                  <li>There may be multiple display adapters listed.</li>
                  <li>
                    If you see one that mentions Nvidia, you have an Nvidia discrete GPU.
                    <button .state=${2} @click=${this.handleGpuChangeState}>
                      How do I enable Nvidia GPU?
                    </button>
                  </li>
                  <li>
                    If you see one that mentions AMD Radeon, you have an AMD discrete GPU.
                    <button .state=${3} @click=${this.handleGpuChangeState}>
                      How do I enable AMD GPU?
                    </button>
                  </li>
                  <li>
                    If you only see one that mentions AMD, but does not have Radeon (and perhaps has Ryzen or Athlon), 
                    you have an AMD embedded GPU.
                    <button .state=${3} @click=${this.handleGpuChangeState}>
                      How do I enable AMD GPU?
                    </button>
                  </li>
                  <li>
                    If you only see one that mentions Intel, you have an Intel embedded GPU.
                    <button .state=${3} @click=${this.handleGpuChangeState}>
                      How do I enable Intel GPU?
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          `)}
            ${h(state === 2, html`
            <h1>How do I enable Nvidia GPU?</h1>
            <ul>
              ${commonInstructions}
              <li>
                Enable Nvidia for Chrome
                <ul>
                  <li>Click the Start button or press the Windows key.</li>
                  <li>
                    Start typing "Nvidia Control Panel" until it appears at the top of the list, then press Enter
                    <img src="images/gpu-tutorial/nvidia-control-panel.png">
                  </li>
                  <li>Go to "Manage 3D Settings" (1)</li>
                  <li>Go to the "Program Settings" tab (2)</li>
                  <li>Select "Chrome" from the program dropdown (3)</li>
                  <li>Change the "OpenGL rendering GPU" to your graphics card (the option that isn't "Auto-Select")</li>
                  <li>You can now close the Control Panel</li>
                </ul>
              </li>
              <li>
                Finally, restart Chrome. If all is well, the info popup should now show Nvidia and a green check.
              </li>
            </ul>
          `)}
            ${h(state === 3, html`
            <h1>How do I enable Intel or AMD GPU?</h1>
            <ul>
              ${commonInstructions}
              <li>
                Finally, restart Chrome. If all is well, the info popup should now show your AMD or Intel GPU.
                <br/>
                If you have a discrete AMD GPU, it should now also be green and show a checkmark. If you enabled
                an embedded AMD or Intel GPU, it will still be red, because a discrete GPU is strongly recommended.
              </li>
            </ul>
          `)}
        </div>
      </gpu-tutorial>
    `;
  };

  handleGpuInfoToggle = () => {
    this.gpuInfoOpen = !this.gpuInfoOpen;
  }

  handleToggleImagePreview = () => {
    imagePreview.toggle();

    this.imagePreviewEnabled = imagePreview.isEnabled();
  };

  handleGpuChangeState = event => {
    const { target: { state } } = event;

    event.stopPropagation();
    this.gpuPopupState = state;
  }

  handleGpuPopupBack = event => {
    event.stopPropagation();
    this.gpuPopupState = 0;
  }

  handleGpuPopupClickOut = event => {
    if (!event.composedPath()[0].matches('gpu-tutorial')) {
      return;
    }

    this.showGpuPopup = false;
    // don't stop propagation
  }

  handleGpuPopupClose = event => {
    event.stopPropagation();
    this.showGpuPopup = false;
  }

  handleShowGpuPopup = event => {
    event.stopPropagation();
    this.showGpuPopup = true;
    this.gpuPopupState = 0;
  }

  handleConfirmReasonModal(event) {
    this.showReasonModal = false;

    const reason = event.target.querySelector('textarea').value;

    this.doSend(reason);
  }

  handleCancelReasonModal() {
    this.showReasonModal = false;
  }

  handleCorrelatedErrorStart() {
    this.showCorrelatedModal = true;
  }

  handleCorrelatedErrorCancel() {
    this.showCorrelatedModal = false;
  }

  handleCorrelatedErrorConfirm({ currentTarget }) {
    this.showCorrelatedModal = false;
    this.sendingCorrelated = true;
    const correlationId = currentTarget.querySelector('input').value;

    this.correlatedRequestName =
      http.post(`./weekly-check/api/support/send/composition-failure/{organization}/${correlationId}`);
  }

  handleShowPhi = () => {
    showPhi();
  };

  handleHidePhi = ({ currentTarget: { mode } }) => {
    hidePhi(mode);
  };

  handleDownload = () => {
    this.downloading = true;
    this.sending = false;
    this.auditDownloading = false;

    this.downloadRequestName = http.get('./weekly-check/api/support/export/{organization}/{patientSer}');
  };

  handleSend = () => {
    this.showReasonModal = true;
  };

  doSend = reason => {
    this.showReasonModel = false;
    this.downloading = false;
    this.sending = true;
    this.auditDownloading = false;

    this.sendRequestName = http.post('./weekly-check/api/support/send/{organization}/{patientSer}', {
      type: 'json',
      body: { reason }
    });
  };

  handleAuditLogDownload = () => {
    this.downloading = false;
    this.sending = false;
    this.auditDownloading = true;

    this.downloadAuditRequestName = 
      http.get('./weekly-check/api/support/export/{organization}/audit', { type: 'text' }, true, { dataType: 'blob' } );
  };

  triggerDownload(data) {
    const date = new Date();
    const dateString = `${date.getFullYear()}-${padBefore(date.getMonth() + 1)}-${padBefore(date.getDate())}`;
    /* eslint-disable-next-line max-len */
    const timeString = `${padBefore(date.getHours())}-${padBefore(date.getMinutes())}-${padBefore(date.getSeconds())}-${date.getTimezoneOffset()}`;

    download(
      data,
      `anonymized-patient-${patientSerSelector()}-${dateString}-${timeString}.json`);
  }

  stateChanged(state) {
    const { downloading, sending, sendingCorrelated, auditDownloading } = this;

    if (downloading) {
      const request = http.getRequest(this.downloadRequestName, state);

      if (request?.completed) {
        if (request?.ok) {
          this.triggerDownload(request.data);
        } else {
          store.dispatch(addToast('There was a problem downloading the patient.', { color: 'var(--bad-color)' }));
        }

        this.downloading = false;
        http.flush(request);
      }
    }

    if (sending) {
      const request = http.getRequest(this.sendRequestName, state);

      if (request?.completed) {
        if (request?.ok) {
          store.dispatch(addToast('Patient sent to Radformation support.'));
        } else {
          store.dispatch(addToast('There was a problem sending to Radformation.', { color: 'var(--bad-color)' }));
        }

        this.sending = false;
        http.flush(request);
      }
    }

    if (sendingCorrelated) {
      const request = http.getRequest(this.correlatedRequestName, state);

      if (request?.completed) {
        if (request?.ok) {
          store.dispatch(addToast('Data sent to Radformation successfully.'));
        } else {
          store.dispatch(addToast('There was a problem sending the data to Radformation',
            { color: 'var(--bad-color)' }));
        }

        this.sendingCorrelated = false;
        http.flush(request);
      }
    }

    if (auditDownloading) {
      const request = http.getRequest(this.downloadAuditRequestName, state);

      if (request?.completed) {
        if (request?.ok) {
          downloadCsv(
            request.data,
            'audit.csv');
        } else {
          store.dispatch(addToast('There was a problem downloading audit logs.', { color: 'var(--bad-color)' }));
        }

        this.auditDownloading = false;
        http.flush(request);
      }
    }
  }
}

customElements.define('company-info-modal-with-support', CompanyInfoModalWithSupport);