const randomValue = () =>
  Math.floor(Math.random() * 256);

/**
 * Generates a random color in RGBA component array format
 * @param {Boolean} randomAlpha=false If alpha should be randomized. Default will give full alpha
 * @return {[int, int, int, int]}
 */
const randomColor = (randomAlpha = false) => [
  randomValue(),
  randomValue(),
  randomValue(),
  randomAlpha ? randomValue() : 255
];

export default randomColor;