import getPixelCoordinates from './getPixelCoordinates.js';
import { volumeLoader } from '@cornerstonejs/core';

const upSampleRate = localStorage.getItem('upSampleRate') ?? 1;
// eslint-disable-next-line no-console
console.info('Using upSampleRate:', upSampleRate);

const createSegmentationVolume = async (structureIdNumber, contour, referenceVolume) => {
  const [{ contourData }] = contour.data;
  const structureExtent = {
    xMin: contourData[0], yMin: contourData[1], zMin: contourData[2],
    xMax: contourData[0], yMax: contourData[1], zMax: contourData[2]
  };
  getStructureExtent(contour, structureExtent, referenceVolume);
  const { direction, metadata, spacing, origin } = referenceVolume;
 
  getNormalizedStructureExtent(structureExtent, origin, spacing, direction);
  

  // include one extra slice top & bottom to get the contours to look closed in frontal/sagittal
  const structureOrigin = [
    structureExtent.xMin,
    structureExtent.yMin,
    structureExtent.zMin - (spacing[2]*direction[8]) // extra slice at min
  ];

  let { i, j, k } = getPixelCoordinates(structureExtent.xMax, structureExtent.yMax,
    structureExtent.zMax, spacing, direction, structureOrigin);

  // set single pixel at least 5x5x5
  if (i < 4) i = 4; 
  if (j < 4) j = 4;
  if (k < 3) k = 3;

  const structureDimensions = [i + 1, j + 1, k + 2]; // extra slice at max
  
  const localVolumeOptions = {
    scalarData: new Uint8Array(
      structureDimensions[0] * upSampleRate * structureDimensions[1] * upSampleRate * structureDimensions[2]
    ),
    metadata: metadata,
    dimensions: [
      structureDimensions[0] * upSampleRate,
      structureDimensions[1] * upSampleRate,
      structureDimensions[2]
    ],
    spacing: [
      spacing[0] / upSampleRate,
      spacing[1] / upSampleRate,
      spacing[2]
    ],
    origin: structureOrigin,
    direction: direction
  };

  return volumeLoader.createLocalVolume(
    localVolumeOptions,
    structureIdNumber
  );
};

export default createSegmentationVolume;

function getNormalizedStructureExtent(structureExtent, origin, spacing, direction) {
  const iMin = (structureExtent.xMin - origin[0]) / (spacing[0] * direction[0]);
  structureExtent.xMin = (Math.floor(iMin) * spacing[0] * direction[0]) + origin[0];
  const jMin = (structureExtent.yMin - origin[1]) / (spacing[1] * direction[4]);
  structureExtent.yMin = (Math.floor(jMin) * spacing[1] * direction[4]) + origin[1];
  const kMin = (structureExtent.zMin - origin[2]) / (spacing[2] * direction[8]);
  structureExtent.zMin = (Math.floor(kMin) * spacing[2] * direction[8]) + origin[2];

  const iMax = (structureExtent.xMax - origin[0]) / (spacing[0] * direction[0]);
  structureExtent.xMax = (Math.ceil(iMax) * spacing[0] * direction[0]) + origin[0];
  const jMax = (structureExtent.yMax - origin[1]) / (spacing[1] * direction[4]);
  structureExtent.yMax = (Math.ceil(jMax) * spacing[1] * direction[4]) + origin[1];
  const kMax = (structureExtent.zMax - origin[2]) / (spacing[2] * direction[8]);
  structureExtent.zMax = (Math.ceil(kMax) * spacing[2] * direction[8]) + origin[2];
}

function getStructureExtent(contour, structureExtent, referenceVolume) {
  const { data } = contour;
  for (let segment = 0; segment < data.length; segment++) {
    const { contourData } = data[segment];
    getExtentFromSegment(contourData, structureExtent, referenceVolume);
  }
}

function getExtentFromSegment(contourData, structureExtent, referenceVolume) {
  /* eslint-disable prefer-destructuring */
  const dirX = referenceVolume.direction[0];
  const dirY = referenceVolume.direction[4];
  const dirZ = referenceVolume.direction[8];
  /* eslint-enable prefer-destructuring */

  for (let index = 0; index < contourData.length; index += 3) {
    const x = contourData[index];
    const y = contourData[index + 1];
    const z = contourData[index + 2];
    if (dirX > 0) {
      if (x < structureExtent.xMin) structureExtent.xMin = x;
      if (x > structureExtent.xMax) structureExtent.xMax = x;
    } else {
      if (x > structureExtent.xMin) structureExtent.xMin = x;
      if (x < structureExtent.xMax) structureExtent.xMax = x;
    }

    if (dirY > 0) {
      if (y < structureExtent.yMin) structureExtent.yMin = y;
      if (y > structureExtent.yMax) structureExtent.yMax = y;
    } else {
      if (y > structureExtent.yMin) structureExtent.yMin = y;
      if (y < structureExtent.yMax) structureExtent.yMax = y;
    }

    if (dirZ > 0) {
      if (z < structureExtent.zMin) structureExtent.zMin = z;
      if (z > structureExtent.zMax) structureExtent.zMax = z;
    } else {
      if (z > structureExtent.zMin) structureExtent.zMin = z;
      if (z < structureExtent.zMax) structureExtent.zMax = z;
    }
  }
}
