import _defineProperty from '@babel/runtime/helpers/defineProperty';
import macro from '../../macros.js';
import PropertyConst from './Property/Constants.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var Representation = PropertyConst.Representation,
    Interpolation = PropertyConst.Interpolation;

function notImplemented(method) {
  return function () {
    return macro.vtkErrorMacro("vtkProperty::".concat(method, " - NOT IMPLEMENTED"));
  };
} // ----------------------------------------------------------------------------
// vtkProperty methods
// ----------------------------------------------------------------------------


function vtkProperty(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkProperty');

  publicAPI.setColor = function (r, g, b) {
    if (Array.isArray(r)) {
      if (model.color[0] !== r[0] || model.color[1] !== r[1] || model.color[2] !== r[2]) {
        model.color[0] = r[0];
        model.color[1] = r[1];
        model.color[2] = r[2];
        publicAPI.modified();
      }
    } else if (model.color[0] !== r || model.color[1] !== g || model.color[2] !== b) {
      model.color[0] = r;
      model.color[1] = g;
      model.color[2] = b;
      publicAPI.modified();
    }

    publicAPI.setDiffuseColor(model.color);
    publicAPI.setAmbientColor(model.color);
    publicAPI.setSpecularColor(model.color);
  };

  publicAPI.computeCompositeColor = notImplemented('ComputeCompositeColor');

  publicAPI.getColor = function () {
    // Inline computeCompositeColor
    var norm = 0.0;

    if (model.ambient + model.diffuse + model.specular > 0) {
      norm = 1.0 / (model.ambient + model.diffuse + model.specular);
    }

    for (var i = 0; i < 3; i++) {
      model.color[i] = norm * (model.ambient * model.ambientColor[i] + model.diffuse * model.diffuseColor[i] + model.specular * model.specularColor[i]);
    }

    return [].concat(model.color);
  };

  publicAPI.addShaderVariable = notImplemented('AddShaderVariable');

  publicAPI.setInterpolationToFlat = function () {
    return publicAPI.setInterpolation(Interpolation.FLAT);
  };

  publicAPI.setInterpolationToGouraud = function () {
    return publicAPI.setInterpolation(Interpolation.GOURAUD);
  };

  publicAPI.setInterpolationToPhong = function () {
    return publicAPI.setInterpolation(Interpolation.PHONG);
  };

  publicAPI.getInterpolationAsString = function () {
    return macro.enumToString(Interpolation, model.interpolation);
  };

  publicAPI.setRepresentationToWireframe = function () {
    return publicAPI.setRepresentation(Representation.WIREFRAME);
  };

  publicAPI.setRepresentationToSurface = function () {
    return publicAPI.setRepresentation(Representation.SURFACE);
  };

  publicAPI.setRepresentationToPoints = function () {
    return publicAPI.setRepresentation(Representation.POINTS);
  };

  publicAPI.getRepresentationAsString = function () {
    return macro.enumToString(Representation, model.representation);
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  color: [1, 1, 1],
  ambientColor: [1, 1, 1],
  diffuseColor: [1, 1, 1],
  specularColor: [1, 1, 1],
  edgeColor: [0, 0, 0],
  ambient: 0,
  diffuse: 1,
  specular: 0,
  specularPower: 1,
  opacity: 1,
  interpolation: Interpolation.GOURAUD,
  representation: Representation.SURFACE,
  edgeVisibility: false,
  backfaceCulling: false,
  frontfaceCulling: false,
  pointSize: 1,
  lineWidth: 1,
  lighting: true,
  shading: false,
  materialName: null
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Build VTK API

  macro.obj(publicAPI, model);
  macro.setGet(publicAPI, model, ['lighting', 'interpolation', 'ambient', 'diffuse', 'specular', 'specularPower', 'opacity', 'edgeVisibility', 'lineWidth', 'pointSize', 'backfaceCulling', 'frontfaceCulling', 'representation']);
  macro.setGetArray(publicAPI, model, ['ambientColor', 'specularColor', 'diffuseColor', 'edgeColor'], 3); // Object methods

  vtkProperty(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkProperty'); // ----------------------------------------------------------------------------

var vtkProperty$1 = _objectSpread({
  newInstance: newInstance,
  extend: extend
}, PropertyConst);

export { vtkProperty$1 as default, extend, newInstance };
