import toDateObject from '../toDateObject';
import toDay from './toDay';
import toTime from './toTime';

// {day} {time}
const toDayTime = date => {
  const dateObj = toDateObject(date);

  return `${toDay(dateObj)} ${toTime(dateObj)}`;
};

export default toDayTime;