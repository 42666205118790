/* This is the standalone page view. For the embeddable element, use image-viewer */
import { html, css, LitElement } from 'lit';
import { connect } from '../../../utils/redux';
import { store } from '../../../redux/store';
import './image-viewer';
import { patientIdSelector } from '../../../redux/app/selectors';
import radHttp from '../../../utils/rad-http/index.js';
import { DICOM_POPOUT_TARGET, getPopoutUrl } from './utils/getPopoutUrl.js';
import getPopoutUrlKey from '../../../utils/popout/getPopoutUrlKey.js';
import getQueryParam from '../../../utils/query/getQueryParam.js';

class ImageView extends connect(store)(LitElement) {
  static styles = css`
    :host {
      display: flex !important;
      flex-flow: column;
      background: #ecf0f2;
    }
    
    document-viewer {
      flex: 1;
    }
  `;

  static properties = {
    patientId: { type: String },
    planSer: { type: String },
    fraction: { type: String },
    groupIndex: { type: String },
    patientData: { type: Object },
    dicomGroupData: { type: Object },
    showAll: { type: Object }
  };

  connectedCallback() {
    super.connectedCallback();

    // This will keep the other window informed that we're still open.
    const worker = new Worker(new URL('../../../workers/dicom-image.js', import.meta.url), { type: 'module' });
    worker.postMessage(DICOM_POPOUT_TARGET);

    window.addEventListener('storage', this.updateUrl);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('storage', this.updateUrl);
  }

  updateUrl = ({ key, newValue }) => {
    if (key !== getPopoutUrlKey(DICOM_POPOUT_TARGET)) return;
    if (newValue === document.location.href) return;

    document.location = newValue;
    window.focus(); // try to make the window blink on the taskbar... probably won't work
  };

  updated(changed) {
    const { patientId, planSer, fraction, groupIndex } = this;

    if (['patientId', 'planSer', 'fraction', 'groupIndex'].some(::changed.has)
      && [patientId, planSer, fraction, groupIndex].every(v => v !== undefined && v !== null)) {
      this.load();
    }
  }

  async load() {
    const { patientId, planSer, fraction, groupIndex, showAll } = this;

    const patientData = (await radHttp.get(`./weekly-check/api/{organization}/patient/{patientId}?showAll=${showAll}`, {
      params: { patientId }
    })).data;

    this.patientData = patientData;
    const allImagings = patientData?.episodes
      ?.map(({ imaging = {} }) => imaging);

    this.dicomGroupData = allImagings
      .find(imaging => `${imaging.planSer}` === planSer && `${imaging.fraction}` === fraction)
      ?.displayDicoms?.[groupIndex]
      ?.value;

    // we didn't find the image in our filtered set, so we'll redirect to the first we did get
    if (!this.dicomGroupData) {
      this.changeUrl(allImagings[0].planSer, allImagings[0].fraction, 0);
    }
  }

  render() {
    const { patientData, planSer, fraction, groupIndex, dicomGroupData } = this;

    if (!dicomGroupData) return;

    return html`
      <image-viewer
        planSer=${planSer}
        fraction=${fraction}
        groupIndex=${groupIndex}
        .patientData=${patientData}
        .dicomGroupData=${dicomGroupData}
        @dicom-change=${this.handleDicomChange}
      ></image-viewer>
    `;
  }

  handleDicomChange = ({ detail: { planSer, fraction, groupIndex } }) => {
    this.changeUrl(planSer, fraction, groupIndex);
  };

  changeUrl = (planSer, fraction, groupIndex) => {
    document.location =
      getPopoutUrl(this.patientId, planSer, fraction, groupIndex, parseInt(getQueryParam('showAll') ?? 0));
  };

  stateChanged(state) {
    this.patientId = patientIdSelector(state);

    Object.assign(this, state.app.params);

    this.showAll = parseInt(getQueryParam('showAll') ?? 0);
  }
}

customElements.define('image-view', ImageView);