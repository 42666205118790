import { html } from 'lit';

const createCanAccess = store => method => baseElement => class extends baseElement {
  render() {
    if (method(store)) {
      return super.render();
    } else {
      return html`
          <div>Access denied</div>
      `;
    }
  }
};

export default createCanAccess;