export const orientation = {
  AXIAL: {
    viewPlaneNormal: [0, 0, -1],
    viewUp: [0, -1, 0]
  },
  SAGITTAL: {
    viewPlaneNormal: [1, 0, 0],
    viewUp: [0, 0, 1]
  },
  CORONAL: {
    viewPlaneNormal: [0, 1, 0],
    viewUp: [0, 0, 1]
  }
};

// eslint-disable-next-line no-unused-vars
const { AXIAL, SAGITTAL, CORONAL } = orientation;

// 3D
export const LEVELS = { min: -1000, max: 2000 }; 
export const INITIAL_LEVELS = { min: -180, max: 220 };

// 2D
export const VOI = { min: 0, max: 130000 };
export const INITIAL_VOI = { min: 0, max: 130000 }; 

export const INITIAL_BLEND = .5;

export const SOP_INSTANCE_UID = '00080018';
export const SERIES_INSTANCE_UID = '0020000E';
export const MODALITY = '00080060';

export const FRAME_OF_REFERENCE_UID = '00200052';
export const REG_SEQUENCE = '00700308';

export const CURVE_LABEL = '50XX2500';
export const CURVE_DATA = '50XX3000';

export const ORIENTATIONS = {
  AXIAL_HFS: { // AXIAL
    ...AXIAL,
    name: 'axial_hfs',
    labels: {
      top: 'A',
      left: 'R',
      right: 'L'
    },
    dimension: {
      index: 2,
      label: 'Z',
      flip: false
    }
  },
  SAGITTAL_HFS: { // SAGITTAL_FLIP
    name: 'sagittal_hfs',
    viewPlaneNormal: [-1, 0, 0],
    viewUp: [0, 0, 1],
    labels: {
      top: 'S',
      left: 'P',
      right: 'A'
    },
    dimension: {
      index: 0,
      label: 'X',
      flip: false
    }
  },
  CORONAL_HFS: { // CORONAL_FLIP
    name: 'coronal_hfs',
    viewPlaneNormal: [0, -1, 0],
    viewUp: [0, 0, 1],
    labels: {
      top: 'S',
      left: 'R',
      right: 'L'
    },
    dimension: {
      index: 1,
      label: 'Y',
      flip: false
    }
  },
  AXIAL_HFP: {
    name: 'axial_hfp',
    viewPlaneNormal: [0, 0, -1],
    viewUp: [0, 1, 0],
    labels: {
      top: 'P',
      left: 'L',
      right: 'R'
    },
    dimension: {
      index: 2,
      label: 'Z',
      flip: false
    }
  },
  SAGITTAL_HFP: {
    name: 'sagittal_hfp',
    viewPlaneNormal: [1, 0, 0],
    viewUp: [0, 0, 1],
    labels: {
      top: 'S',
      left: 'A',
      right: 'P'
    },
    dimension: {
      index: 0,
      label: 'X',
      flip: false
    }
  },
  CORONAL_HFP: {
    name: 'coronal_hfp',
    viewPlaneNormal: [0, 1, 0],
    viewUp: [0, 0, 1],
    labels: {
      top: 'S',
      left: 'L',
      right: 'R'
    },
    dimension: {
      index: 1,
      label: 'Y',
      flip: false
    }
  },
  AXIAL_FFS: {
    name: 'axial_ffs',
    viewPlaneNormal: [0, 0, 1],
    viewUp: [0, -1, 0],
    labels: {
      top: 'A',
      left: 'L',
      right: 'R'
    },
    dimension: {
      index: 2,
      label: 'Z',
      flip: false
    }
  },
  SAGITTAL_FFS: {
    name: 'sagittal_ffs',
    viewPlaneNormal: [1, 0, 0],
    viewUp: [0, 0, -1],
    labels: {
      top: 'I',
      left: 'P',
      right: 'A'
    },
    dimension: {
      index: 0,
      label: 'X',
      flip: false
    }
  },
  CORONAL_FFS: {
    name: 'coronal_ffs',
    viewPlaneNormal: [0, -1, 0],
    viewUp: [0, 0, -1],
    labels: {
      top: 'I',
      left: 'L',
      right: 'R'
    },
    dimension: {
      index: 1,
      label: 'Y',
      flip: false
    }
  },
  AXIAL_FFP: {
    name: 'axial_ffp',
    viewPlaneNormal: [0, 0, 1],
    viewUp: [0, 1, 0],
    labels: {
      top: 'P',
      left: 'R',
      right: 'L'
    },
    dimension: {
      index: 2,
      label: 'Z',
      flip: false
    }
  },
  SAGITTAL_FFP: {
    name: 'sagittal_ffp',
    viewPlaneNormal: [-1, 0, 0],
    viewUp: [0, 0, -1],
    labels: {
      top: 'I',
      left: 'A',
      right: 'P'
    },
    dimension: {
      index: 0,
      label: 'X',
      flip: false
    }
  },
  CORONAL_FFP: {
    name: 'coronal_ffp',
    viewPlaneNormal: [0, 1, 0],
    viewUp: [0, 0, -1],
    labels: {
      top: 'I',
      left: 'R',
      right: 'L'
    },
    dimension: {
      index: 1,
      label: 'Y',
      flip: false
    }
  }
};