import { css, unsafeCSS } from 'lit';
import tableStyle from '../rad-table/styles';
import PassState from '../../../PassState';

const PASS = unsafeCSS(PassState.PASS);
const VERIFIED = unsafeCSS(PassState.VERIFIED);
const FAIL = unsafeCSS(PassState.FAIL);
const CONDITION = unsafeCSS(PassState.CONDITION);
const OVERRIDE = unsafeCSS(PassState.OVERRIDE);
const INDETERMINATE = unsafeCSS(PassState.INDETERMINATE);
const EXCEPTION = unsafeCSS(PassState.EXCEPTION);

const passStateTableStyles = [
  tableStyle,
  css`  
    .strong-left {
      border-left: 2px solid #dae0e4;
    }
    
    .align-right {
      text-align: right;
    }
    
    .no-border {
      border: 0 !important;
    }
    
    tr.field-start th, 
    tr.field-start td {
      background: #f6f8f9;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      color: #6b8393;
      border-top: 1px solid #dae0e4;
      border-bottom: 1px solid #dae0e4;
      padding-top: 8px;
    }
    
    tr.field-start[collapse-header] > th:first-of-type span:before,
    table[rows] tr.field-start[collapse-header] > th:last-of-type:before,
    table[main] tr.field-start[collapse-header] > th:last-of-type:before {
      content: " ";
      display: inline;
      border: solid #6b8393;
      border-width: 0 2px 2px 0;
      width: 7px;
      height: 7px;
      float: right;
      top: 6px;
      margin-top: 2px;
      transform: rotate(225deg);
      position: relative;
    }
    
    [collapsed-header] tr.field-start[collapse-header] > th:first-of-type span:before,
    table[rows] [collapsed-header] tr.field-start[collapse-header] > th:last-of-type:before,
    table[main] [collapsed-header] tr.field-start[collapse-header] > th:last-of-type:before {
      transform: rotate(45deg);
      top: 2px;
    }
    
    tr.field-start[collapse-header]:hover > th:first-of-type span:before,
    table[rows] tr.field-start[collapse-header] > th:first-of-type:hover:before,
    table[main] tr.field-start[collapse-header] > th:first-of-type:hover:before {
      border: solid #3498DB;
      border-width: 0 2px 2px 0;
    }
    
    // ATTEMPT TO USE ACTIVE STATE TO INVERT CARET
    tr.field-start[collapse-header]:focus > th:first-of-type span:before,
    table[rows] tr.field-start[collapse-header] > th:first-of-type:focus:before,
    table[main] tr.field-start[collapse-header] > th:first-of-type:focus:before {
      border: solid #3498DB;
      border-width: 2px 0 0 2px;
    }
    
    :not([show-value])>[value] {
      display: none;
    }
    
    [show-icon]:before,
    li[pass-state]:before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 500;
    }
    
    li {
      list-style: none;
    }
    
    li[pass-state]:before {
      text-align: center;
    }
  
    [show-icon][pass-state=${FAIL}]:before,
    li[show-icon][pass-state=${FAIL}]:before {
      content: '\uF00D';
      font-size: 24px;
      font-weight: 300;
      vertical-align: middle;
      margin-right: 4px;
    }
  
    [show-icon][pass-state=${PASS}]:before,
    li[show-icon][pass-state=${PASS}]:before,
    [show-icon][pass-state=${VERIFIED}]:before,
    li[show-icon][pass-state=${VERIFIED}]:before{
      content: '\uF00C';
      font-size: 22px;
      margin-right: 4px;
    }
  
    [show-icon][pass-state=${CONDITION}]:before,
    li[show-icon][pass-state=${CONDITION}]:before {
      content: '\uF2EC';
      font-size: 16px;
      font-weight: 500;
      margin-right: 4px;
    }
  
    [show-icon][pass-state=${OVERRIDE}]:before,
    li[show-icon][pass-state=${OVERRIDE}]:before {
      content: '\uF12A';
      font-size: 16px;
      margin-right: 4px;
    }
    
    [show-icon][pass-state=${INDETERMINATE}]:before,
    li[show-icon][pass-state=${INDETERMINATE}]:before {
      content: '\uf128';
      font-size: 16px;
      margin-right: 4px;
    }
  
    [show-icon][pass-state=${EXCEPTION}]:before,
    li[show-icon][pass-state=${EXCEPTION}]:before {
      content: '\uf06a';
      font-size: 16px;
      margin-right: 4px;
      font-weight: 900;
      color: #F00;
    }
    
    [show-icon][pass-state=${EXCEPTION}],
    li[show-icon][pass-state=${EXCEPTION}] {
      color: #F00;
    }
    
    ul {
      position: relative;
    }
    
    li[show-icon][pass-state] {
      list-style: none;
      min-height: 2em;
      --left-padding: 26px;
      padding-left: var(--left-padding);
    }
    
    div[show-icon][pass-state] {
      padding-left: 24px;
      min-height: 2em;
    }
    
    div[show-icon][pass-state]:before {
      margin-left: -23px;
      position: absolute;
    }
    
    li[show-icon][pass-state]:before {
      position: absolute;
      width: var(--left-padding); /* may need to change if size of icon changes, based on checkmark width */
      left: 9px;
      transform: translateX(-50%);
      margin-right: 0 !important;
    }
    
    [pass-state=${FAIL}]:not(li) + [pass-state=${FAIL}]:before,
    [pass-state=${PASS}]:not(li) + [pass-state=${PASS}]:before,
    [pass-state=${VERIFIED}]:not(li) + [pass-state=${VERIFIED}]:before,
    [pass-state=${OVERRIDE}]:not(li) + [pass-state=${OVERRIDE}]:before,
    [pass-state=${CONDITION}]:not(li) + [pass-state=${CONDITION}]:before,
    [pass-state=${INDETERMINATE}]:not(li) + [pass-state=${INDETERMINATE}]:before,
    [pass-state=${EXCEPTION}]:not(li) + [pass-state=${EXCEPTION}]:before {
      content: '';
    }
    
    [chart] [point][pass-state=${OVERRIDE}] {
      width: 20px;
      height: 20px;
      z-index: 5;
    }
    
    [chart] [point][pass-state=${OVERRIDE}]:before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 700;
      content: '\uF12A';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      text-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;
      font-size: 12px;
      line-height: 16px;
      top: 3px;
      color: rgb(255, 255, 255);
    }
  
    [chart] [point][pass-state=${INDETERMINATE}] {
      width: 20px;
      height: 20px;
      z-index: 5;
    }
  
    [chart] [point][pass-state=${INDETERMINATE}]:before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 700;
      content: '\uf128';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      text-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;
      font-size: 12px;
      line-height: 16px;
      top: 3px;
      color: rgb(255, 255, 255);
    }
  
    [chart] [point][pass-state=${EXCEPTION}] {
      width: 20px;
      height: 20px;
      z-index: 5;
    }
  
    [chart] [point][pass-state=${EXCEPTION}]:before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 500;
      content: '\uf06a';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      text-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;
      font-size: 12px;
      line-height: 16px;
      top: 3px;
      color: #FFF;
    }
    
    dicom-image, [dicom-placeholder] {
      width: 100%;
      height: 80px;
    }
    
    td[active] {
      background: #CCC;
    }

    [dicom-placeholder] {
      background: #000;
      color: #FFF;
      text-align: center;
      line-height: 80px;
      cursor: pointer;
    }
  `
];

export default passStateTableStyles;