import getWadoImageId from './getWadoImageId.js';
import radHttp from '../../../../utils/rad-http/index.js';
import dcmjs from 'dcmjs';
import { FRAME_OF_REFERENCE_UID, MODALITY, CURVE_DATA, CURVE_LABEL } from '../constants.js';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import metaDataManager from './metaDataManager.js';
import WADORSHeaderProvider from './demo/WADORSHeaderProvider.js';
import getPixelSpacingInformation from './demo/getPixelSpacingInformation.js';
import { utilities } from '@cornerstonejs/core';
import getVolumeId from './getVolumeId.js';
import getStructureId from './getStructureId.js';
import { loadCornerstone } from './cornerstone.js';
import singleFile from '../../../../utils/singleFile.js';
import createSegmentation from './createSegmentation.js';
import createSegmentationVolume from './createSegmentationVolume';
import createTransformedImage from './createTransformedImage'; 
import createImageVolume from './createImageVolume.js';
import { RegistrationNotFoundException } from './registrationNotFoundException';
import applyContrastEnhanceFilter from './applyContrastEnhanceFilter.js';
const targetFrameOfReference = 'TARGET_FRAME_OF_REFERENCE';
const { imageIdToURI } = utilities;

export const ImageTypes = {
  ThreeD: 'ctimage',
  TwoD: 'rtimage'
};

// In sort order
const contourTypes = ['PTV', 'CTV', 'GTV'];

// Load from an FileSystemHandle or a function
const fileHandles = {};

const getWadoImageIdFromDicomData = (filePrefix = '', simple = false) => (dicomImage, index) => {
  // eslint-disable-next-line no-undef
  
  // eslint-disable-next-line no-undef
  if (dicomImage instanceof FileSystemHandle || typeof dicomImage === 'function') {
    const imageId = `rad-file://${filePrefix}-${index}`;
    fileHandles[imageId] = dicomImage;
    return imageId;
  }
  
  return dicomImage
    ? getWadoImageId(dicomImage.type, dicomImage.displayDicomUID, dicomImage.sliceIndex, simple)
    : null;
};

/*
 * If the group has already been loaded for the given data,
 * it will be returned out of the cache and nothing will happen.
 * @return {LoadedDicomGroupMetaData}
 */
const initializeDicomGroup = singleFile(
  async (planSer, fraction, groupIndex, dicomGroup, simple) =>
    initialize(planSer, fraction, groupIndex, dicomGroup, simple),
  'dicom-group-loaded',
  (_, i) => i !== 3);

const initialize = async (planSer, fraction, groupIndex, dicomGroup, simple = false) => {
  const { acquiredImageIds, referenceImageIds, pairedAcquiredImageId, pairedLayers,
    pairedReferenceImageId, spatialRegistrationImageId, contours, layers,
    imageVOI, referenceImageVOI, pairedImageVOI, pairedReferenceImageVOI,
    volumeScalarDataReference, volumeScalarDataAcquired
  } = await loadImages(dicomGroup, simple);

  const { renderingEngine } = await loadCornerstone();

  const is3D = dicomGroup.is3D
    || ((dicomGroup.acquiredImages?.[0] ?? dicomGroup.referenceImages?.[0])?.type === ImageTypes.ThreeD
      && (acquiredImageIds.length > 1 || referenceImageIds.length > 1));

  const hasStructures = dicomGroup.referenceStructureSet;

  const acquiredVolumeId = is3D && acquiredImageIds.length
    ? getVolumeId('acquired', planSer, fraction, groupIndex)
    : null;

  const referenceVolumeId = is3D && referenceImageIds.length
    ? getVolumeId('reference', planSer, fraction, groupIndex)
    : null;

  const structureId = hasStructures
    ? getStructureId(planSer, fraction, groupIndex)
    : null;

  let referenceVolume;
  
  if (is3D) {
    if (referenceVolumeId && !simple) {
      referenceVolume =
        await createImageVolume({
          volumeId: referenceVolumeId,
          volumeMetadata: dicomGroup.imageVolumeMetaData,
          volumeDetails: dicomGroup.imageVolumeDetails,
          volumeScalarData: volumeScalarDataReference
        });
    }

    if (acquiredVolumeId && !simple) {
      await createImageVolume({
        volumeId: acquiredVolumeId,
        volumeMetadata: dicomGroup.imageVolumeMetaData,
        volumeDetails: dicomGroup.imageVolumeDetails,
        volumeScalarData: volumeScalarDataAcquired
      });
    }
  }
  
  let structurePromises;
  if (hasStructures && !simple) {
    structurePromises = await loadStructures(structureId, referenceVolumeId, contours, referenceVolume);
  }

  const userOrigin = [
    dicomGroup.referenceUserOriginX ?? 0,
    dicomGroup.referenceUserOriginY ?? 0,
    dicomGroup.referenceUserOriginZ ?? 0
  ];

  const isoCenter = [
    dicomGroup.referenceIsocenterX ?? 0,
    dicomGroup.referenceIsocenterY ?? 0,
    dicomGroup.referenceIsocenterZ ?? 0
  ];

  return {
    renderingEngine,
    type: is3D ? ImageTypes.ThreeD : ImageTypes.TwoD,
    acquiredVolumeId,
    referenceVolumeId,
    acquiredImageIds,
    referenceImageIds,
    pairedAcquiredImageId,
    pairedReferenceImageId,
    structureId,
    spatialRegistrationImageId,
    userOrigin,
    contours,
    layers,
    pairedLayers,
    structurePromises, 
    imageVOI,
    referenceImageVOI,
    pairedImageVOI,
    pairedReferenceImageVOI,
    isoCenter
  };
};

const getMatrixFromRegistration = (spatialRegistrationImageId, imageId) => {
  const { RegistrationSequence: sequences } = metaDataManager.get('radSpatial', spatialRegistrationImageId) || {};

  // imageId --> acquiredImageId for 3D, referenceImageId for 2D
  // noinspection JSUnresolvedVariable
  return sequences?.find(({ FrameOfReferenceUID: uid }) =>
    uid === metaDataManager.get('radFrameOfRef', imageId)
  )?.MatrixRegistrationSequence[0]
    ?.MatrixSequence
    ?.FrameOfReferenceTransformationMatrix;
};

const loadImages = async (dicomGroup, simple) => {
  const { acquiredImages, referenceImages, patientOrientation,
    acquiredImageVolumeID, referenceImageVolumeID } = dicomGroup;

  const acquiredImageIds = (simple
    ? acquiredImages
      ?.slice(Math.floor(acquiredImages.length / 2))
      ?.slice(0, 1)
    : acquiredImages)
    ?.map(getWadoImageIdFromDicomData('acquired', simple)) ?? [];

  const referenceImageIds = (simple
    ? []
    : referenceImages)
    ?.map(getWadoImageIdFromDicomData('reference')) ?? [];

  // acquiredStructureSet is technically there too, but we'd never use it even if it existed.
  const structureSetImageId = !simple
    && getWadoImageIdFromDicomData('structure-set')(dicomGroup.referenceStructureSet);
  const pairedAcquiredImageId = !simple
    && getWadoImageIdFromDicomData('paired-acquired')(dicomGroup.pairedAcquiredImage);
  const pairedReferenceImageId = !simple
    && getWadoImageIdFromDicomData('paired-reference')(dicomGroup.pairedReferenceImage);
  const spatialRegistrationImageId =
    getWadoImageIdFromDicomData('spatial-registration')(dicomGroup.spatialRegistration);

  // handle 2D vs 3D vs simple 
  const acquiredImageVolumeId = !simple && dicomGroup.acquiredImageVolumeID;
  const referenceImageVolumeId = !simple && dicomGroup.referenceImageVolumeID; 
  const acquiredImageId2DOnly = (acquiredImages[0].type === ImageTypes.TwoD) ? acquiredImageIds : []; 
  const acquiredImageIds3DSimpleOnly = (acquiredImages[0].type === ImageTypes.ThreeD && simple)
    ? acquiredImageIds : [];
  const referenceImageIds2DOr3DSimpleOnly = (referenceImages[0].type === ImageTypes.TwoD || simple)
    ? referenceImageIds : [];
  const spatialRegistration2DOnly = (acquiredImages[0].type === ImageTypes.TwoD && !simple)
    && spatialRegistrationImageId; 

  if (acquiredImageVolumeId && !simple && !dicomGroup.spatialRegistration) {
    throw new RegistrationNotFoundException();
  } 

  const allImageIds = [
    spatialRegistration2DOnly, // not needed for imageVolumes
    ...(referenceImageIds2DOr3DSimpleOnly || []), // no imageVolumes
    // put the single CT slice only for simple - it cannot be processed like a 2D
    ...(acquiredImageIds3DSimpleOnly || []),
    structureSetImageId,
    pairedReferenceImageId
  ].filter(Boolean);

  const acquiredImageIdsToTransform = [
    ...(acquiredImageId2DOnly || []),
    pairedAcquiredImageId
  ].filter(Boolean);

  const imageVolumes = [ 
    acquiredImageVolumeId,
    referenceImageVolumeId 
  ].filter(Boolean);

  let contours; 
  let layers;
  let pairedLayers;
  let imageVOI;
  let referenceImageVOI;
  let pairedImageVOI;
  let pairedReferenceImageVOI;
  let volumeScalarDataReference;
  let volumeScalarDataAcquired; 

  if (acquiredImageVolumeId && referenceImageVolumeId) {
    const imageVolumeBuffers =
      await getImageVolumeBuffers(imageVolumes, dicomGroup, acquiredImageVolumeId, referenceImageVolumeId);

    if (!imageVolumeBuffers.acquired) {
      console.error(`Unable to create image volume: ${acquiredImageVolumeID}`);
    } else if (!imageVolumeBuffers.reference) {
      console.error(`Unable to create image volume: ${referenceImageVolumeID}`);
    } else {
      volumeScalarDataAcquired =
        new Float32Array(imageVolumeBuffers.acquired, 0, imageVolumeBuffers.acquired.byteLength / 4);
      volumeScalarDataReference =
        new Float32Array(imageVolumeBuffers.reference, 0, imageVolumeBuffers.reference.byteLength / 4);
    }
  }
    
  await Promise.all(allImageIds.map(async imageId => {
    if (metaDataManager.has(imageId)) return; // already loaded

    const image = await loadImage(imageId);

    const dicomData = dcmjs.data.DicomMessage.readFile(image);

    const dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(
      dicomData.dict
    );

    dataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(
      dicomData.meta
    );

    dataset.imageId = imageId;
    const [modality] = dicomData.dict[MODALITY].Value;

    if (modality === 'CT') {
      processCT(imageId, dicomData, dataset);
    } else if (modality === 'RTIMAGE') {
      processRT(imageId, dicomData, dataset);

      if (imageId === referenceImageIds[0]) {
        layers = processLayers(imageId, dicomData, dataset);
        referenceImageVOI = getImageVOI(dataset); 
      }

      if (imageId === pairedReferenceImageId) {
        pairedLayers = processLayers(imageId, dicomData, dataset);
        pairedReferenceImageVOI = getImageVOI(dataset);
      }
    } else if (modality === 'RTSTRUCT') {
      contours = processStructure(imageId, dicomData, dataset);
    } else if (modality === 'REG') {
      processRegistration(imageId, dicomData, dataset);
    } else {
      console.error(`Unknown modality: ${modality}`);
    }
  }));

  // load acquired 2D images after reg file and reference image file have been loaded
  await Promise.all(acquiredImageIdsToTransform.map(async imageId => {
    if (metaDataManager.has(imageId)) return; // already loaded

    // get the acq data (this image)
    const image = await loadImage(imageId);
    const dicomData = dcmjs.data.DicomMessage.readFile(image);

    // get the ref data appropriate for this image
    let refData = [];
    let refImageId;
    if (imageId === acquiredImageIds[0]) {
      // use referenceImageIds[0] for the reference dataset
      refData = metaDataManager.get('radSpatial', referenceImageIds[0]);
      [refImageId] = referenceImageIds;
    }

    if (imageId === pairedAcquiredImageId) {
      // use pairedReferenceImageId for the reference dataset
      refData = metaDataManager.get('radSpatial', pairedReferenceImageId);
      refImageId = pairedReferenceImageId;
    }

    const matrix = getMatrixFromRegistration(spatialRegistrationImageId, refImageId);
    
    if (!matrix && !simple) {
      throw new RegistrationNotFoundException();
    }

    // only transform the image if all required data is present
    if (refData && Object.keys(refData).length && !simple) {    
      // get the original data from the buffer
      const [originalBuffer] = dicomData.dict['7FE00010'].Value;
      const originalView = new Uint16Array(originalBuffer, 0, originalBuffer.byteLength / 2);
      // make a copy to work with
      const originalScalarData = new Uint16Array(originalView.length);
      for (let index = 0; index < originalScalarData.length; index++) {
        originalScalarData[index] = originalView[index];
      }

      // initialize a scalar data for the transformed values
      const MIN_VALUE = 0;
      const transformedScalarData = new Uint16Array(refData.Rows * refData.Columns).fill(MIN_VALUE); 

      applyContrastEnhanceFilter(
        originalScalarData,
        metaDataManager.get('radWidth', acquiredImageIds[0]),
        metaDataManager.get('radHeight', acquiredImageIds[0])
      );

      // update newScalarData with transformed values.
      createTransformedImage({
        matrix,
        acqData: dicomData,
        refData, transformedScalarData,
        originalScalarData, MIN_VALUE,
        patientOrientation
      });

      // edit the original dataset of the acq data to match the ref data we have transformed it to.
      dicomData.dict['7FE00010'].Value[0] = transformedScalarData.buffer;
      dicomData.dict['00280010'].Value[0] = refData.Rows;
      dicomData.dict['00280011'].Value[0] = refData.Columns;
      dicomData.dict['30020011'].Value = refData.ImagePlanePixelSpacing;
    }

    const dataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(
      dicomData.dict
    );

    dataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(
      dicomData.meta
    );

    dataset.imageId = imageId;

    const [modality] = dicomData.dict[MODALITY].Value;

    if (modality === 'RTIMAGE') {
      processRT(imageId, dicomData, dataset);

      if (imageId === acquiredImageIds[0]) {
        imageVOI = getImageVOI(dataset);
      }

      if (imageId === pairedAcquiredImageId) {
        pairedImageVOI = getImageVOI(dataset);
      }
    } else {
      console.error(`Unknown modality: ${modality}`);
    }
  }));

  return { acquiredImageIds, referenceImageIds, pairedAcquiredImageId,
    pairedReferenceImageId, spatialRegistrationImageId, contours, layers, pairedLayers,
    imageVOI, referenceImageVOI, pairedImageVOI, pairedReferenceImageVOI,
    volumeScalarDataReference, volumeScalarDataAcquired
  };
};

const loadImage = async imageId => {
  if (/^rad-file:\/\//.test(imageId)) {
    const handle = fileHandles[imageId];

    // eslint-disable-next-line no-undef
    return handle instanceof FileSystemHandle
      ? (await fileHandles[imageId].getFile()).arrayBuffer()
      : await handle();
  }

  return (await radHttp.get(imageIdToURI(imageId), { type: 'blob' })).data.arrayBuffer();
};

const getImageVolumeBuffers = async (imageVolumes, dicomGroup, acquiredImageVolumeId, referenceImageVolumeId) => {
  const imageVolumeBuffers = {}; 
  await Promise.all(imageVolumes.map(async imageVolumeID => {
    if (imageVolumeID === acquiredImageVolumeId) {
      const imageVolumeBuffer = await getImageVolume(imageVolumeID);
      imageVolumeBuffers.acquired = imageVolumeBuffer;
    } else if (imageVolumeID === referenceImageVolumeId) {
      const { startIndex, endIndex } = dicomGroup.imageVolumeDetails; 
      const imageVolumeBuffer = await getImageVolume(imageVolumeID, startIndex, endIndex);
      imageVolumeBuffers.reference = imageVolumeBuffer;
    } else {
      console.error(`Unknown image volume: ${imageVolumeID}`);
    }
  }));

  if (!imageVolumeBuffers.acquired || !imageVolumeBuffers.reference) {
    const dicomGroupRevised = await handleImageVolume(dicomGroup);
    await Promise.all(imageVolumes.map(async imageVolumeID => {
      if (imageVolumeID === acquiredImageVolumeId) {
        const imageVolumeBuffer = await getImageVolume(imageVolumeID);
        imageVolumeBuffers.acquired = imageVolumeBuffer;
      } else if (imageVolumeID === referenceImageVolumeId) {
        const { startIndex, endIndex } = dicomGroupRevised.imageVolumeDetails;
        const imageVolumeBuffer = await getImageVolume(imageVolumeID, startIndex, endIndex);
        imageVolumeBuffers.reference = imageVolumeBuffer;
      } else {
        console.error(`Unknown image volume: ${imageVolumeID}`);
      }
    }));
  }

  return imageVolumeBuffers; 
};

const processCT = (imageId, dicomData, dataset) => {
  dataset.RealFrameOfReferenceUID = dataset.FrameOfReferenceUID;

  dataset.FrameOfReferenceUID = targetFrameOfReference;
  dicomData.dict[FRAME_OF_REFERENCE_UID] = targetFrameOfReference;

  cornerstoneWADOImageLoader.wadors.metaDataManager.add(
    imageId,
    dicomData.dict
  );

  metaDataManager.add(imageId, dataset);
  WADORSHeaderProvider.addInstance(imageId, dicomData.dict);

  const pixelSpacing = getPixelSpacingInformation(dataset);

  if (pixelSpacing) {
    utilities.calibratedPixelSpacingMetadataProvider.add(
      imageId,
      pixelSpacing.map(parseFloat)
    );
  }
};

const processRT = (imageId, dicomData, dataset) => {
  dataset.RealFrameOfReferenceUID = dataset.FrameOfReferenceUID;

  dataset.FrameOfReferenceUID = targetFrameOfReference;
  dicomData.dict[FRAME_OF_REFERENCE_UID] = targetFrameOfReference;

  cornerstoneWADOImageLoader.wadors.metaDataManager.add(
    imageId,
    dicomData.dict
  );

  metaDataManager.add(imageId, dataset);
  WADORSHeaderProvider.addInstance(imageId, dicomData.dict);

  const pixelSpacing = getPixelSpacingInformation(dataset);

  if (pixelSpacing) {
    utilities.calibratedPixelSpacingMetadataProvider.add(
      imageId,
      pixelSpacing.map(parseFloat)
    );
  }
};

const processRegistration = (imageId, dicomData, dataset) => {
  metaDataManager.add(imageId, dataset);
};

const processStructure = (imageId, dicomData, dataset) => {
  const rois = dataset.StructureSetROISequence.map(({ ROIName: label, ROINumber: number }) =>
    ({ label, number }));

  const observations = dataset.RTROIObservationsSequence
    .map(({ ROIObservationLabel: label, ReferencedROINumber: number, RTROIInterpretedType: type }) =>
      ({ label, number, type }));

  const nonEmptyContours = dataset.ROIContourSequence
    .filter(c => c.ContourSequence && c.ContourSequence.length);

  const contours = nonEmptyContours
    .map(({ ROIDisplayColor: color, ReferencedROINumber: number, ContourSequence: sequence }) => ({
      color,
      number,
      label: (rois.find(o => o.number === number)?.label || observations.find(o => o.number === number)?.label)
        ?? 'Contour' + number,
      data: sequence.map(({ ContourData: contourData }) => ({ contourData })),
      type: observations.find(o => o.number === number).type
      // NOTE: don't flatten; want to avoid connecting two unconnected
      // pieces on same slice - sequence.flatMap(({ ContourData } = {}) => ContourData)
    }));
  contours.sort((a, b) => {
    let aIndex = contourTypes.indexOf(a.type);
    let bIndex = contourTypes.indexOf(b.type);

    aIndex = aIndex === -1 ? 10000 : aIndex; // big number, doesn't matter what
    bIndex = bIndex === -1 ? 10000 : bIndex;

    if (aIndex === bIndex) {
      return a.label?.toLowerCase() < b.label?.toLowerCase()
        ? -1
        : a.label?.toLowerCase() > b.label?.toLowerCase()
          ? 1
          : 0;
    }

    return aIndex - bIndex;
  });
  return contours;
};

const processLayers = (imageId, dicomData, dataset) => {
  const layers = [];

  for (let i = 1; i <= 50; i++) {
    const group = (i - 1) * 2;
    const repeatingGroup = group.toString().padStart(2, '0');
    const curveLabel = CURVE_LABEL.replace('XX', repeatingGroup);
    const hasGroup = dicomData.dict[curveLabel];
    if (!hasGroup) {
      break;
    }

    processLayer(i, repeatingGroup, curveLabel, dicomData, layers);
  }

  return layers;
};

const loadStructures = async (structureId, referenceVolumeId, contours, referenceVolume) => {
  // each structure in its own label map in case contours overlap.
  const promises = contours.map(contour => {
    const structureIdNumber = structureId + '-' + contour.number;

    return {
      [structureIdNumber]: loadStructure(structureIdNumber, contour, referenceVolume)
        .then(() => contour) // returns the contour when the promise finishes
    };
  });

  Promise.all(promises).then(() => 'Loaded all structures');

  return Object.assign({}, ...promises);
};

const loadStructure = async (structureIdNumber, contour, referenceVolume) => {
  const segmentationVolume = await createSegmentationVolume(structureIdNumber, contour, referenceVolume);

  createSegmentation(segmentationVolume, contour);
};

const processLayer = (i, repeatingGroup, curveLabel, dicomData, layers) => {
  const curveData = CURVE_DATA.replace('XX', repeatingGroup);
  const decoder = new TextDecoder();
  const label = decoder.decode(dicomData.dict[curveLabel].Value[0]);
  const [layerArrayBuffer] = dicomData.dict[curveData].Value;
  const layer2D = new Float64Array(layerArrayBuffer, 0, layerArrayBuffer.byteLength / 8);

  const layerXY = [];
  for (let j = 0; j <= layer2D.length; j += 2) {
    layerXY.push({ x: layer2D[j], y: layer2D[j + 1] });
  }

  const existingLayer = layers.find(layer => layer.label === label);

  if (existingLayer) {
    existingLayer.data.push(...layerXY);
  } else {
    layers.push({ number: i, label, data: layerXY, type: 'LAYER' });
  }
};

const getImageVOI = dataset => {
  const windowCenter = dataset.WindowCenter;
  const windowWidthHalf = Math.floor(dataset.WindowWidth / 2);

  return {
    min: windowCenter - windowWidthHalf,
    max: windowCenter + windowWidthHalf
  };
};

const getImageVolume = async (imageId, startIndex, endIndex) => {
  const imageRootUrl = '/weekly-check/api/{organization}/dicom/imagevolume';
  const imageVolumeRequestURL = `${imageRootUrl}/${imageId}`;
  const query = {
    startIndex,
    endIndex
  }; 
  const answer = !endIndex
    ? (await radHttp.get(imageVolumeRequestURL, { type: 'blob' }))
    : (await radHttp.get(imageVolumeRequestURL, { query, type: 'blob' }));

  // noinspection EqualityComparisonWithCoercionJS
  if (answer.response.status == 404) return null;

  return answer.data.arrayBuffer();
};

const handleImageVolume = async dicomGroup => {
  const imageRootUrl = '/weekly-check/api/{organization}/dicom/imageVolume';
  const imageVolumeRequestURL = `${imageRootUrl}`;
  const answer = (await radHttp.put(imageVolumeRequestURL, { body: JSON.stringify(dicomGroup), type: 'json' }));

  return answer.data; // TODO: may need this dicomGroup for a different flow where we don't already have the dicomGroup.
};

export default initializeDicomGroup;
