import patientStyles from '../styles/patient';
import { css, html, LitElement } from 'lit';
import '../../../common/pass-state-table';
import { angleAxisTransform, resolveData } from '../utils';
import h from '../../../../utils/h';
import buildDatedTableData from '../utils/buildDatedTableData';
import { SHOW_FOR_NO_PASS_STATE } from '../../../common/pass-state-table';
import filterForSkippedDates from '../utils/filterForSkippedDates';
import isDatedTableEmpty from '../utils/isDatedTableEmpty';
import { isActive } from '../utils/resolveData';
import { ImageTypes } from '../../image-view/utils/initializeDicomGroup.js';
import '../../image-view/dicom-image.js';
import { ORIENTATIONS } from '../../image-view/constants.js';
import imagePreview from '../../../../utils/imagePreview.js';
import { getPopoutUrl, DICOM_POPOUT_TARGET } from '../../image-view/utils/getPopoutUrl.js';
import openPopoutIfNeeded from '../../../../utils/popout/openPopoutIfNeeded.js';

export const isImagingTableEmpty = (plan, imagingDates) =>
  isDatedTableEmpty(rows, {
    showCouchChart: plan.imagingCouchShow,
    angleMode: plan.imagingAngleMode,
    orderFollowed: plan.imagingOrderFollowed
  }, imagingDates, plan.imagings)
  && !plan.imagings.some(({ setupFields }) => setupFields.length
    && setupFields.some(field => isActive(field)))
    && !plan.imagings.some(({ displayDicoms }) => displayDicoms.length);

const couchYAxis = {
  increment: 1,
  label: 'Δ Position [cm]'
};

const angleYAxis = {
  increment: 0.5,
  label: 'Δ Position [deg]'
};

const disableImagePreview = !imagePreview.isEnabled();

if (disableImagePreview) {
  // eslint-disable-next-line no-console
  console.info('Disabling image preview');
}

const buildDicomImages = (imagings, dates, element) =>
  imagings.some(({ displayDicoms }) => displayDicoms.length)
    ? [
      '', // row header
      '', // planned
      ...dates
        .flatMap(({ times }) => times)
        .map(({ asString }, dateIndex) => {
          const { displayDicoms, fraction, planSer } =
            imagings.find(({ episodeDateTime }) => episodeDateTime === asString) || {};

          return {
            active: element.currentDicom?.planSer === planSer
              && element.currentDicom?.fraction === fraction,
            contents: h(displayDicoms?.length, () => html`
              ${displayDicoms.slice(0, 1).map(({ value: dicomGroupData }, groupIndex) => html`
                ${h(imagings.length > 5 || disableImagePreview, 
                  renderDicomPlaceholder(element, planSer, fraction, groupIndex, dicomGroupData))}
                ${h(imagings.length <= 5
                  && (dicomGroupData?.acquiredImages?.length || dicomGroupData?.referenceImages?.length), () => {
                  if ((dicomGroupData?.acquiredImages?.[0] || dicomGroupData?.referenceImages?.[0]).type
                    === ImageTypes.ThreeD) {
                    return html`
                      <dicom-image
                        .planSer=${planSer}
                        .fraction=${fraction}
                        .groupIndex=${groupIndex}
                        .dicomGroupData=${dicomGroupData}
                        .orientation=${ORIENTATIONS.AXIAL_HFS}
                        simple
                        @click=${::element.handleDicomClick}
                        .priority=${imagings.length - dateIndex}
                      ></dicom-image>
                    `;
                  }
  
                  return html`
                    <dicom-image
                      .planSer=${planSer}
                      .fraction=${fraction}
                      .groupIndex=${groupIndex}
                      .dicomGroupData=${dicomGroupData}
                      simple
                      @click=${::element.handleDicomClick}
                      ?active=${element.currentDicom?.planSer === planSer
                        && element.currentDicom?.fraction === fraction && element.currentDicom?.groupIndex}
                    ></dicom-image>
                  `;
                })}
              `)}
            `)
          };
        })
    ] : null;

const renderDicomPlaceholder = (element, planSer, fraction, groupIndex, dicomGroupData) => html`
  <div
    dicom-placeholder
    .planSer=${planSer}
    .fraction=${fraction}
    .groupIndex=${groupIndex}
    .dicomGroupData=${dicomGroupData}
    @click=${::element.handleDicomClick}
    ?active=${element.currentDicom?.planSer === planSer
      && element.currentDicom?.fraction === fraction && element.currentDicom?.groupIndex}
  >Click to View
  </div>
`;

const rows = [
  {
    dicom: true,
    customBuilder: buildDicomImages
  },
  {
    chart: {
      firstColumn: 2,
      unitOfMeasurement: 'cm',
      axis: couchYAxis,
      data: ({ couchLines }) => ['Vrt Shift', 'Lng Shift', 'Lat Shift']
        .map((header, index) => ({ header, ...couchLines[index] }))
    },
    skip: ({ showCouchChart }) => !showCouchChart
  },
  {
    chart: {
      firstColumn: 2,
      unitOfMeasurement: '°',
      axis: ({ angleMode }) => ({ ...angleYAxis, transform: angleAxisTransform(angleMode) }),
      data: ({ angleLines }) => ['Couch Rtn Shift', 'Pitch Angle Shift', 'Roll Angle Shift']
        .map((header, index) => ({ header, ...angleLines[index] }))
    },
    skip: ({ angleMode }) => angleMode === 'skip'
  },
  {
    header: 'MD Review Status',
    field: 'mdReviewStatus',
    showIcon: true,
    phi: true,
    showValue: SHOW_FOR_NO_PASS_STATE
  },
  {
    header: 'Approved Before Next Treatment',
    field: 'reviewedBeforeNextTreatment',
    showIcon: true,
    showValue: SHOW_FOR_NO_PASS_STATE
  },
  {
    header: 'Imaging Order Followed',
    firstField: ({ orderFollowed }) => orderFollowed,
    field: 'orderFollowed',
    skip: ({ orderFollowed }) => !orderFollowed
  }
];

class PlanImaging extends LitElement {
  static styles = [
    patientStyles,
    css`
      dicom-image {
        max-width: 116px;
      }
    `
  ];

  static properties = {
    planId: { type: String },
    skipImagingTimes: { type: Array },
    imagings: { type: Array },
    couchLines: { type: Array },
    angleLines: { type: Array },
    angleMode: { type: String },
    showCouchChart: { type: Boolean },
    setupFieldIds: { type: Array },
    orderFollowed: { type: String },
    showPreTreatmentDates: { type: Boolean },
    verifications: { type: Array },
    currentDicom: { type: Object },
    patientId: { type: String },
    showAll: { type: Boolean }
  };

  updated(changed) {
    this.columns = Array.prototype.map.call(
      this.shadowRoot.querySelectorAll('thead th'),
      element => ({ text: element.innerText, width: element.offsetWidth })
    );

    if (changed.has('imagings')) {
      this.setupFieldIds = this.imagings.flatMap(imaging => (imaging.setupFields || []).map(({ id }) => id))
        .reduce((result, id) => result.includes(id) ? result : [...result, id], [])
        .sort();
    }
  }

  render() {
    const { imagings, planId, imagingDates, showPreTreatmentDates, skipImagingTimes, verifications,
      handleTogglePreTreatmentDates } = this;

    if (!imagings.length
      || (
        isDatedTableEmpty(rows, this, imagingDates, imagings)
          && this.setupFieldsEmpty()
          && !imagings.some(({ displayDicoms }) => displayDicoms)
      )
    ) return;

    const data = buildDatedTableData({
      element: this,
      dates: imagingDates.reduce(filterForSkippedDates(skipImagingTimes), []),
      rows,
      data: imagings,
      showFractions: true,
      verifications
    }).concat(this.buildSetupFieldData());

    return html`
      <!-- <button
        @click=${handleTogglePreTreatmentDates}
      >
        ${h(showPreTreatmentDates, html`Hide`, html`Show`)} Pre-Treatment Dates
      </button> -->
      <section class="imaging">
        <h4>Imaging - ${planId}</h4>
        ${!imagings || !imagings.length ? html`No imaging details` : html`
          <pass-state-table
            even
            .data=${data}
            headerRows="4"
            freezeRows="4"
            headerColumns="2"
            freezeColumns="2"
          ></pass-state-table>
        `}
      </section>
    `;
  }

  handleTogglePreTreatmentDates = () => {
    this.dispatchEvent(new CustomEvent('toggle-pre-treatment-dates', { bubbles: true, composed: true }));
  };

  setupFieldsEmpty() {
    const { imagings, imagingDates, setupFieldIds = [] } = this;

    return !setupFieldIds.some(header =>
      imagingDates
        .flatMap(({ times }) => times)
        .some(({ asString }) => {
          const source = imagings.find(({ episodeDateTime }) => episodeDateTime === asString);

          return source && isActive(source?.setupFields?.find(({ id }) => id === header));
        })
    );
  }

  buildSetupFieldData() {
    const { setupFieldIds = [], imagingDates, imagings, skipImagingTimes, verifications } = this;

    return setupFieldIds.map(header => [
      header,
      '',
      ...imagingDates
        .reduce(filterForSkippedDates(skipImagingTimes), [])
        .flatMap(({ times }) => times)
        .map(({ asString }) => {
          const { setupFields = [] } = imagings.find(({ episodeDateTime }) => episodeDateTime === asString) || {};

          return h(setupFields,
            resolveData({
              data: setupFields.find(({ id }) => id === header) || {},
              field: 'value',
              verifyKey: imagings.key,
              verifyField: `setupFields[${header}]`,
              verifications
            })
          );
        })
    ]);
  }

  handleDicomClick(event) {
    const { showAll } = this;
    const { planSer, fraction, groupIndex } = event.currentTarget;

    // for pop out
    const popoutUrl = getPopoutUrl(this.patientId, planSer, fraction, groupIndex, showAll);
    openPopoutIfNeeded(DICOM_POPOUT_TARGET, popoutUrl);
    // window.openDicomPopout = openInPopout(popoutUrl, DICOM_POPOUT_TARGET);
    // for split-view
    // this.dispatchEvent(new CustomEvent('dicom-open', {
    //  detail: { dicomGroupData, planSer, fraction, groupIndex },
    //  bubbles: true,
    //  composed: true
    // }));
  }
}

customElements.define('plan-imaging', PlanImaging);