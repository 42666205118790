import { html, LitElement } from 'lit';
import patientStyles from '../styles/patient';
import '../../../common/pass-state-table';
import { getInactive, getPassState, getValue } from '../utils/resolveData';
import { SHOW_FOR_NON_PASS } from '../../../common/pass-state-table';
import buildDatedTableData from '../utils/buildDatedTableData';
import filterForSkippedDates from '../utils/filterForSkippedDates';
import isDatedTableEmpty from '../utils/isDatedTableEmpty';
import getWorstPassState from '../utils/resolveData/getWorstPassState';
import verifiedByMessageGetter from '../../../../utils/verifiedByMessageGetter';
import countPassStates from '../utils/countPassStates';

export const isFieldsTableEmpty = (plan, treatmentDates) =>
  !plan.fields
    .some(fields => {
      // Map the treatment date to the fields so we can look it up properly.
      const data = plan.treatments.map(({ episodeDateTime: dateTime, fraction, fields: treatmentFields }) =>
        ({ ...treatmentFields.find(({ id }) => fields.id === id), dateTime, fraction }));

      return !isDatedTableEmpty(fieldRows.slice(1), {}, treatmentDates, data);
    });

const fieldRows = [
  {
    header: 'Field %fieldName%',
    firstField: () => 'Planned',
    field: (_, field, verifications) =>
      ({ passState: getWorstPassState(
        Object.keys(countPassStates(field, verifications, ['fieldsMatchPlan'], {}, { includeVerify: true }))
      ) }),
    showIcon: true,
    showValue: false,
    rowAttributes: {
      class: 'field-start',
      'collapse-header': true,
      collapsed: true,
      collapseOnce: true, // if true, uses collapsed the first time data is rendered and not afterward
      'flex-header': 'field'
    },
    unverifiable: true
  },
  {
    header: 'Machine',
    field: 'machineId'
  },
  {
    header: 'MU',
    field: 'mu'
  },
  {
    header: 'Energy',
    field: 'energy'
  },
  {
    header: 'Dose Rate (MU/min)',
    field: 'doseRate'
  },
  {
    header: 'Time (min)',
    field: 'time'
  },
  {
    header: 'Gantry (deg)',
    field: 'gantry'
  },
  {
    header: 'Collimator (deg)',
    field: 'collimator'
  },
  {
    header: 'Couch Vrt (cm)',
    field: 'couchVrt'
  },
  {
    header: 'Couch Lng (cm)',
    field: 'couchLng'
  },
  {
    header: 'Couch Lat (cm)',
    field: 'couchLat'
  },
  {
    header: 'Couch Rtn (deg)',
    field: 'couchRotation'
  },
  {
    header: 'Couch Pitch (deg)',
    field: 'couchPitch'
  },
  {
    header: 'Couch Roll (deg)',
    field: 'couchRoll'
  },
  {
    header: 'Bolus',
    field: 'bolus'
  },
  {
    inline: true,
    header: 'X1 / X2 (cm)',
    firstField: (_, field) =>
      field?.x1?.map((x1, i) => `${getValue(x1)} / ${getValue(field.x2[i])}`),
    field: (_, field) => field?.x1 && field?.x2
      ? field.x1.map((x1, i) => [
        {
          value: getValue(x1),
          passState: getPassState(x1),
          isInactive: getInactive(x1),
          verifyKey: field.key,
          verifyField: 'x1',
          rawValue: field.x1
        },
        '/',
        {
          value: getValue(field.x2[i]),
          passState: getPassState(field.x2[i]),
          isInactive: getInactive(field.x2[i]),
          verifyKey: field.key,
          verifyField: 'x2',
          rawValue: field.x2
        }
      ]) : '-',
    verifyEach: true,
    verifiedBy: [
      verified => `X1 ${verifiedByMessageGetter(verified, true)}`,
      null,
      verified => `X2 ${verifiedByMessageGetter(verified, true)}`
    ]
  },
  {
    inline: true,
    header: 'Y1 / Y2 (cm)',
    firstField: (_, field) =>
      field?.y1?.map((y1, i) => `${getValue(y1)} / ${getValue(field.y2[i])}`),
    field: (_, field) => field?.y1 && field?.y2
      ? field.y1.map((y1, i) => [
        {
          value: getValue(y1),
          passState: getPassState(y1),
          isInactive: getInactive(y1),
          verifyKey: field.key,
          verifyField: 'y1',
          rawValue: field.y1
        },
        '/',
        {
          value: getValue(field.y2[i]),
          passState: getPassState(field.y2[i]),
          isInactive: getInactive(field.y2[i]),
          verifyKey: field.key,
          verifyField: 'y2',
          rawValue: field.y2
        }
      ]) : '-',
    verifyEach: true,
    verifiedBy: [
      verified => `Y1 ${verifiedByMessageGetter(verified, true)}`,
      null,
      verified => `Y2 ${verifiedByMessageGetter(verified, true)}`
    ]
  },
  {
    header: 'SSD (cm)',
    field: 'ssd'
  },
  {
    header: 'Tolerance Table',
    field: 'toleranceTable'
  },
  {
    header: 'Add-Ons',
    field: 'addOns'
  },
  {
    header: 'Setup Note',
    field: 'setupNote',
    phi: true
  }
];

class PlanFields extends LitElement {
  static styles = patientStyles;

  static properties = {
    plan: { type: Object },
    treatmentDates: { type: Array },
    verifications: { type: Array }
  };

  updated(_) {
    this.columns = Array.prototype.map.call(
      this.shadowRoot.querySelectorAll('thead th'),
      element => ({ text: element.innerText, width: element.offsetWidth })
    );
  }

  render() {
    const { plan, treatmentDates, verifications } = this;
    const { id, treatments = [] } = plan;

    if (!plan || !plan.fields?.length || isFieldsTableEmpty(plan, treatmentDates)) return;

    const data = plan.fields
      .sort((a, b) => a.id < b.id ? -1 : 1)
      .flatMap((fields, index) => {
        // Map the treatment date to the fields so we can look it up properly.
        const data = plan.treatments.map(({ episodeDateTime, fraction, fields: treatmentFields }) =>
          ({ ...treatmentFields.find(({ id }) => fields.id === id), episodeDateTime, fraction }));

        const rows = [
          {
            ...fieldRows[0],
            header: fieldRows[0].header.replace('%fieldName%', fields.id)
          },
          ...fieldRows.slice(1)
        ];

        return buildDatedTableData({
          element: this,
          showHeader: index === 0,
          dates: treatmentDates
            .reduce(filterForSkippedDates(plan.skipTreatmentTimes), []),
          firstData: fields,
          rows,
          data,
          showFractions: true,
          rowDefaults: { showValue: true, showIcon: SHOW_FOR_NON_PASS },
          verifications
        });
      });

    if (!data.length) return;
    
    return html`
      <section class="treatment">
        <h4>Field(s) - ${id}</h4>
        ${treatments.length ? html`
          <pass-state-table
            even
            .data=${data}
            headerRows="4"
            freezeRows="5"
            headerColumns="2"
            freezeColumns="2"
          ></pass-state-table>
        ` : html`<table><tr><td>No treatments yet</td></tr></table>` }
      </section> 
    `;
  }
}

customElements.define('plan-fields', PlanFields);