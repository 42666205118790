const metadata = {};

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.info('Dicom Meta available on "dicomMeta"');
  window.dicomMeta = metadata;
}

// Store the result of dcmjs.DicomMessage.naturalizeDataset() here
const add = (imageId, dataset) => {
  metadata[imageId] = dataset;
};

const has = imageId =>
  Boolean(metadata[imageId]);

const get = (type, imageId) => {
  switch (type) {
    case 'radPixelData':
      return metadata[imageId]?.PixelData[0];
    case 'radSpatial':
      return metadata[imageId] ?? {};
    case 'radFrameOfRef':
      return metadata[imageId]?.RealFrameOfReferenceUID ?? metadata[imageId]?.FrameOfReferenceUID;
    case 'radGraticuleAngle':
      return metadata[imageId]?.BeamLimitingDeviceAngle;
    case 'radGraticuleCenter':
      return metadata[imageId]?.IsocenterPosition;
    case 'radWidth':
      return metadata[imageId]?.Columns;
    case 'radHeight':
      return metadata[imageId]?.Rows;
  }
};

const metaDataManager = {
  add,
  get,
  has
};

export default metaDataManager;