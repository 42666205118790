import anonymize from './anonymize';

const buildErrorData = (latestError, allErrors) => {
  const radApp = document.querySelector('rad-app');
  const weeklyPlanCheck = radApp.shadowRoot.querySelector('weekly-plan-check-view');

  const anonData = anonymize(weeklyPlanCheck.__patients || [], weeklyPlanCheck.__patient);

  return {
    ...Object.keys(radApp)
      .filter(key => /^__/.test(key))
      .reduce((result, key) => Object.assign(result, { [key]: radApp[key] }), {}),
    ...Object.keys(weeklyPlanCheck)
      .filter(key => /^__/.test(key) && key !== 'errorData')
      .reduce((result, key) => Object.assign(result, { [key]: weeklyPlanCheck[key] }), {}),
    ...anonData,
    __patientId: anonData.__patient?.id || 'ANON',
    __filters: weeklyPlanCheck.__filters
      ? { ...weeklyPlanCheck.__filters, patientId: weeklyPlanCheck.__filters?.patientId }
      : undefined,
    latestError,
    allErrors
  };
};

export default buildErrorData;