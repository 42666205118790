import { html, css, LitElement } from 'lit';
import fontAwesome from '../../../utils/font-awesome';
import { configSelector, meSelector, organizationIdSelector, showOverlaySelector } from '../../../redux/app/selectors';
import { generate } from '../../../router';
import { Routes } from '../../../routes';
import '../../company-info-modal-with-support';
import '../../common/rad-link';
import h from '../../../utils/h';
import { connect } from '../../../utils/redux';
import { store } from '../../../redux/store';
import { SETTINGS_PERMISSION } from './common';
import { hasPermission } from './utils';
import http from '../../../utils/redux/http';
import getAgentStatus from '../../../utils/getAgentStatus';
import '../../rad-overlay';

class WeeklyCheckHeader extends connect(store)(LitElement) {
  static styles = css`
    :host(:not([overlaid])) {
      position: relative;
    }
    
    nav {
      display: flex;
      align-items: center;
      background: var(--primary-color);
      font-size: 24px;
      padding: 8px;
      z-index: 9999;
      box-shadow: 4px 4px 4px rgba(0,0,0,.1);
    }

    a, rad-link {
      color: var(--light-color);
      margin-right: 5px;
    }
    
    .menu {
      border: 0;
      padding: 5px 8px 4px;
      background: 0;
      color: var(--blue-6);
      font-size: inherit;
      cursor: pointer;
    }
    
    .menu:hover {
      color: var(--light-color);
    }
    
    div {
      flex: 1;
    }
    
    #tabs {
      text-align: center;
      position: relative;
    }
    
    #tabs rad-link {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      background: none;
      border: none;
      display: inline-block;
      color: var(--light-color);
      cursor: pointer;
      padding: 8px 12px 7px;
      font-size: 1rem;
      margin: 0 16px;
      box-sizing: border-box;
      text-decoration: none;
      border: 2px solid transparent;
    }
    
    #tabs rad-link:hover {
      border: 2px solid var(--blue-4);
      border-radius: 8px;
    }
    
    #tabs rad-link[active] {
      border: 2px solid var(--light-color);
      border-radius: 8px;
    }
    
    #extra {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
    }
    
    #extra a,
    #extra button,
    #extra span {
      background: 0;
      padding: 5px 8px 4px;
      border: 0;
      color: var(--blue-7);
      margin-left: 10px;
      font-size: inherit;
      cursor: pointer;
    }
    
    #extra a:hover,
    #extra button:hover {
      color: var(--light-color);
    }
    
    #extra span {
      font-size: .5em;
      cursor: default;
      display: inline-block;
      padding-top: 0;
      padding-bottom: 0px;
      position: relative;
      text-align: left;
      line-height: 1.4;
    }

    span#agentStatus {
      font-size: .8em;
      text-align: center;
      margin-top: -4px;
    }

    span#agentStatus i {
      border-radius: 50%;
      color: white;
      width: 13px;
      height: 13px;
      border: 2px solid rgb(38, 111, 160);
      padding: 0 4px 8px;
    }

    span#agentStatus i.fa-sync-alt {
      background: #DB432A;
    }

    span#agentStatus i.fa-sync-alt:before,
    span#agentStatus i.fa-check:before {
      font-size: 13px;
      position: relative;
      top: -1px;
    }

    span#agentStatus i.fa-check:before {
      top: -3px;
    }

    span#agentStatus i.fa-check {
      background: #61C48B;
      padding: 1px 3.5px 6px;
    }

    span#agentStatus i::after {
      color: white;
      background: #266FA0;
      position: absolute;
      left: -170px;
      width: 145px;
      border-radius: 3px;
      font-family: helvetica, sans-serif;
      font-weight: 400;
      font-size: 12px;
      cursor: default;
      text-align: right;
      padding: 6px 12px;
      line-height: 18px;
      transition: .2s;
      opacity: 0;
    }

    span#agentStatus:hover i::after {
      transition: .2s;
      opacity: 1;
    }

    span#agentStatus i.fa-sync-alt::after {
      content: "Treatment Data Outdated";
      top: -1px;
      text-align: center;
    }

    span#agentStatus i.fa-check::after {
      content: "Treatment Data Up to Date";
      top: 0;
    }

    span#agentStatus:hover {
      content: attr(data-tooltip);
    }
  `;

  static properties = {
    showInfoModal: { type: Boolean },
    me: { type: Object },
    organizationId: { type: String },
    lastCheckIn: { type: Date },
    isAgentAlive: { type: Boolean },
    agentHealthInterval: { type: Number },
    showOverlay: { type: Boolean }
  };

  constructor() {
    super();

    this.requestNames = {};

    this.loadAgentHealthInterval();
  }

  get canAccessSettings() {
    const { me, organizationId } = this;

    return hasPermission(SETTINGS_PERMISSION, me, organizationId);
  }

  render() {
    const { showInfoModal, showOverlay,
      toggleInfoModal, togglePatients, canAccessSettings, me, isAgentAlive, lastCheckIn } = this;
    const { logoutUrl } = configSelector();

    return html`
      ${fontAwesome}
      <nav>
        <div id="menu">
          <button 
            class="menu"
            @click=${togglePatients}
          ><i class="fas fa-bars"></i></button>
        </div>
        <div id="tabs">
          <rad-link 
            href=${generate(Routes.WeeklyCheckOrganization)} 
            class="weekly" 
            active
          >Weekly Checks</rad-link>
          ${h(canAccessSettings, () => html`
            <rad-link 
              href=${generate(Routes.OrganizationSettingsMain)} 
              class="settings"
            >Settings</rad-link>
          `)}
        </div>
        <div id="extra">
          <span id="agentStatus" data-tooltip='${lastCheckIn}'>
          ${h(isAgentAlive, () => html`<i class="fas fa-check"></i>`)}
          ${h(!isAgentAlive, () => html`<i class="fas fa-sync-alt"></i>`)}           
          </span>
          ${h(me, () => html`
            <span class="about-user">
              Logged in as:<br>
              ${me.displayName}
            </span>
          `)}
          <button @click=${toggleInfoModal}><i class="fas fa-info"></i></button>
          <a href=${logoutUrl}><i class="fas fa-sign-out-alt"></i></a>
        </div>
      </nav>
      <company-info-modal-with-support
        class="info-modal" 
        ?active=${showInfoModal} 
        @close=${toggleInfoModal}
      ></company-info-modal-with-support>
      ${h(showOverlay, html`<rad-overlay></rad-overlay>`)}
    `;
  }

  togglePatients = () => {
    this.dispatchEvent(new CustomEvent('toggle-patients', {
      bubbles: true,
      composed: true
    }));
  };

  toggleInfoModal = () => {
    this.showInfoModal = !this.showInfoModal;
    this.toggleAttribute('overlaid');
  };

  loadAgentHealthInterval = (force = true) => {
    this.requestNames.agentHealthInterval = http.get('./weekly-check/api/{organization}/agent-health-interval');
  };

  loadAgentHealth = (force = true) => {
    this.requestNames.agentHealth = http.get('./weekly-check/api/{organization}/agent-health', {}, force);
  };

  stateChanged = state => {
    const { requestNames } = this;
    
    const agentHealthIntervalRequest = http.getRequest(requestNames.agentHealthInterval, state);
    const agentHealthRequest = http.getRequest(requestNames.agentHealth, state);

    this.showOverlay = showOverlaySelector(state);
    this.me = meSelector(state);
    this.organizationId = organizationIdSelector(state);
    
    if (agentHealthIntervalRequest?.ok) {
      this.agentHealthInterval = agentHealthIntervalRequest.data;      
      this.loadAgentHealth();
      setInterval(()=> {
        this.loadAgentHealth();
      }, this.agentHealthInterval * 60 * 1000);
      http.flush(agentHealthIntervalRequest);
    }

    if (agentHealthRequest?.ok) {
      this.lastCheckIn = agentHealthRequest.data;
      this.isAgentAlive = getAgentStatus(this.lastCheckIn, this.agentHealthInterval);
      http.flush(agentHealthRequest);
    }
  }
}

customElements.define('weekly-check-header', WeeklyCheckHeader);