import Histogram from '../../../../utils/Histogram.js';

const buildHistogram = data => {
  const histogram = new Histogram();

  for (let i = 0; i < data.length; i++) {
    histogram.incrementValue(data[i]);
  }

  return histogram;
};

export default buildHistogram;