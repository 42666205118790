// Basically no tools
import {
  CrosshairsTool, PanTool, SegmentationDisplayTool,
  StackScrollMouseWheelTool,
  ToolGroupManager,
  ZoomTool
} from '@cornerstonejs/tools';
import { MouseBindings } from '@cornerstonejs/tools/dist/cjs/enums/index.js';
import singleFile from '../../../../utils/singleFile.js';

export const ToolGroupTypes = {
  Primary: 'primary',
  Simple: 'simple'
};

/*
 * Important note: The returned toolGroup must be activated by calling toolGroup.activate()
 * This is so tools that require multiple viewports, like Crosshairs, will work properly with our split-up
 * registrations.
 */
const createToolGroup = singleFile(
  async (toolGroupId, type = ToolGroupTypes.Primary) => {
    // If we've already made it, we won't create it again.
    // This allows for sharing a tool group between related images.
    const existing = ToolGroupManager.getToolGroup(toolGroupId);

    if (existing) {
      return existing;
    }

    let toolGroup;
    if (type === 'primary') {
      toolGroup = createPrimaryToolGroup(toolGroupId);
    } else {
      toolGroup = createEmptyToolGroup(toolGroupId);
    }

    toolGroup.activate = () => activateToolGroup(toolGroup, type);

    return toolGroup;
  }, 'tool-group-created'
);

const activateToolGroup = (toolGroup, type) => {
  if (type === ToolGroupTypes.Primary) {
    activatePrimaryToolGroup(toolGroup);
  }

  // No need to activate empty.
};

function getReferenceLineSlabThicknessControlsOn(viewportId) {
  return false; 
}

const createPrimaryToolGroup = toolGroupId => {
  const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);

  toolGroup.addTool(ZoomTool.toolName);
  toolGroup.addTool(StackScrollMouseWheelTool.toolName);

  toolGroup.addTool(CrosshairsTool.toolName, {
    getReferenceLineSlabThicknessControlsOn
  });
  toolGroup.addTool(PanTool.toolName);

  toolGroup.addTool(SegmentationDisplayTool.toolName);

  activatePrimaryToolGroup(toolGroup);
 
  return toolGroup;
};

const activatePrimaryToolGroup = toolGroup => {
  if (toolGroup.getViewportIds().length > 1) {
    // Crosshairs must have at least 2 viewports to be useful
    toolGroup.setToolActive(CrosshairsTool.toolName, {
      bindings: [{ mouseButton: MouseBindings.Primary }]
    });
  }

  toolGroup.setToolActive(PanTool.toolName, {
    bindings: [{ mouseButton: MouseBindings.Auxiliary }]
  });

  toolGroup.setToolActive(ZoomTool.toolName, {
    bindings: [
      { mouseButton: MouseBindings.Secondary }
    ]
  });

  toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);
  toolGroup.setToolEnabled(SegmentationDisplayTool.toolName);
};

const createEmptyToolGroup = toolGroupId =>
  ToolGroupManager.createToolGroup(toolGroupId);

export default createToolGroup;