import { partitionRx } from '../utils';
import { css, html, LitElement } from 'lit';
import resolveDataBase, { getValue, getPassState, hasValue, getInactive, isActive } from '../utils/resolveData';
import patientStyles from '../styles/patient';
import './patient-rx-match-modal';
import PassStateTable, {
  SHOW_FOR_EMPTY_VALUE,
  SHOW_FOR_NON_PASS,
  SHOW_NON_EMPTY_VALUE
} from '../../../common/pass-state-table';
import h from '../../../../utils/h';
import { filterInactive } from '../utils';
import { hideOverlay, showOverlay } from '../../../../redux/app/actions';
import { store } from '../../../../redux/store';
import { INFO_ICON_TAG } from '../../../common/rad-table';
import verifiedByMessageGetter from '../../../../utils/verifiedByMessageGetter';

const resolveData = ({
  data, field, unit = '', htmlClass = '', showIcon = SHOW_FOR_NON_PASS, showValue = true, ...rest
}) =>
  resolveDataBase({ data, field, unit, showIcon, showValue, htmlClass, defaultPrecision: 0, ...rest });

// Search for a NON-empty then return so we don't have to always check them all.
export const isRxTableEmpty = (links, unlinkedPlans) => {
  const hasAnyLinked = links.length && links.some(({ rx, plans }) =>
    rows.some(({ plan: planField, prescription: rxField, skip }) =>
      (!skip || !skip({ rx, plans }))
        && (plans.some(plan => isActive(plan[planField]))
          || isActive(rx[rxField]))
    )
  );

  const hasAnyUnlinked = unlinkedPlans.length && unlinkedPlans.some(plan =>
    rows.some(({ plan: planField, skip }) => (!skip || !skip({ plans: [plan] })) && isActive(plan[planField]))
  );

  return !hasAnyLinked && !hasAnyUnlinked;
};

const rows = [
  {
    header: 'Approval Status',
    prescription: 'approvalStatus',
    plan: 'approvalStatus'
  },  
  {
    header: 'Site',
    prescription: 'site', // rx only
    skip: ({ prescriptions }) =>
      prescriptions?.every(prescription => getInactive(prescription?.site))
  },
  {
    header: 'Method of delivery',
    prescription: 'methodOfDelivery', // rx only -> phaseType, technique, mode
    skip: ({ prescriptions }) =>
      prescriptions?.every(prescription => getInactive(prescription?.phaseType)
        && getInactive(prescription?.technique) && getInactive(prescription?.mode))
  },
  {
    header: 'Dose Per Fraction',
    prescription: 'dosePerFraction',
    plan: 'dosePerFraction'
  },
  {
    header: 'Delivered / Total Fractions',
    inline: true,
    prescription: 'totalFractions',
    planTitle: plan => hasValue(plan.deliveredFractions) && plan.deliveredFractions.tooltipText
      ? plan.deliveredFractions.tooltipText
      : '',
    plan: plan => hasValue(plan.deliveredFractions) || hasValue(plan.totalFractions)
      ? [
        {
          value: plan.deliveredFractions.tooltipText
            ? getValue(plan.deliveredFractions) + INFO_ICON_TAG
            : getValue(plan.deliveredFractions),
          rawValue: getValue(plan.deliveredFractions),
          passState: getPassState(plan.deliveredFractions),
          isInactive: getInactive(plan.deliveredFractions)
        },
        '/',
        {
          value: getValue(plan.totalFractions),
          passState: getPassState(plan.totalFractions),
          isInactive: getInactive(plan.totalFractions)
        }
      ] : '-',
    planVerifyField: ['deliveredFractions', null, 'totalFractions'],
    planVerifyEach: true,
    planVerifiedBy: [
      verified => `Delivered Fractions ${verifiedByMessageGetter(verified, true)}`,
      null,
      verified => `Total Fractions ${verifiedByMessageGetter(verified, true)}`
    ],
    skip: ({ plans }) =>
      plans.every(plan => getInactive(plan?.deliveredFractions) && getInactive(plan?.totalFractions))
  },
  {
    header: 'Delivered / Total Dose',
    inline: true,
    prescription: 'totalDose',
    planTitle: plan => hasValue(plan.deliveredDose) && plan.deliveredDose.tooltipText
      ? plan.deliveredDose.tooltipText
      : '',
    plan: plan => hasValue(plan.deliveredDose) || hasValue(plan.totalDose)
      ? [
        {
          value: plan.deliveredDose.tooltipText
            ? getValue(plan.deliveredDose) + INFO_ICON_TAG
            : getValue(plan.deliveredDose),
          rawValue: getValue(plan.deliveredDose),
          passState: getPassState(plan.deliveredDose),
          isInactive: getInactive(plan.deliveredDose),
          tooltipText: plan.deliveredDose.tooltipText,
          tooltip: plan.deliveredDose.tooltipText
        },
        '/',
        {
          value: getValue(plan.totalDose),
          passState: getPassState(plan.totalDose),
          isInactive: getInactive(plan.totalDose)
        }
      ] : '-',
    planVerifyField: ['deliveredDose', null, 'totalDose'],
    planVerifiedBy: [
      verified => `Delivered Dose ${verifiedByMessageGetter(verified, true)}`,
      null,
      verified => `Total Dose ${verifiedByMessageGetter(verified, true)}`
    ],
    planVerifyEach: true,
    skip: ({ plans }) =>
      plans.every(plan => getInactive(plan?.deliveredDose) && getInactive(plan?.totalDose))
  },
  {
    header: 'Energy',
    prescription: 'energies',
    plan: 'energy'
  },
  {
    header: 'Treatment Frequency',
    prescription: 'planFrequency',
    plan: 'planFrequency',
    showIcon: [SHOW_FOR_NON_PASS, SHOW_FOR_EMPTY_VALUE],
    showValue: SHOW_NON_EMPTY_VALUE
  },
  {
    header: 'Treatment Order',
    prescription: 'planOrder',
    plan: 'planOrder',
    showIcon: [SHOW_FOR_NON_PASS, SHOW_FOR_EMPTY_VALUE],
    showValue: SHOW_NON_EMPTY_VALUE
  },
  {
    header: 'Bolus Order',
    prescription: 'bolusAndFrequency',
    plan: 'bolusAndFrequency',
    showIcon: [SHOW_FOR_NON_PASS, SHOW_FOR_EMPTY_VALUE],
    showValue: SHOW_NON_EMPTY_VALUE
  },
  {
    header: 'Treatment Scheduling',
    prescription: 'planScheduling',
    plan: 'planScheduling'
  },
  {
    header: 'Breakpoint',
    prescription: 'breakPoint',
    plan: 'breakPoint',
    skip: ({ prescriptions }) =>
      prescriptions?.every(prescription => getInactive(prescription?.breakPoint))
  },
  {
    header: 'Image Order',
    prescription: 'imageOrderAndScheduling',
    plan: 'imageOrderAndScheduling',
    showIcon: [SHOW_FOR_NON_PASS, SHOW_FOR_EMPTY_VALUE],
    showValue: SHOW_NON_EMPTY_VALUE
  },
  {
    header: 'Gating',
    prescription: 'gating',
    plan: 'gating',
    skip: ({ prescriptions }) =>
      prescriptions?.every(prescription => getInactive(prescription?.gating)),
    showIcon: [SHOW_FOR_NON_PASS, SHOW_FOR_EMPTY_VALUE],
    showValue: SHOW_NON_EMPTY_VALUE
  },
  {
    header: 'Note',
    prescription: 'note',
    plan: 'note',
    phi: true
  }
];

// Temporary for AAPM
class RxPassStateTable extends PassStateTable {
  static styles = [
    PassStateTable.styles,
    css`    
      .capital-case {
        text-transform: capitalize;
      }
    `
  ].flat();
}

class PatientRx extends LitElement {
  static styles = [
    patientStyles,
    css`    
      header {
        display: flex;
        flex-direction: row;
      }
      
      header h4 {
        flex: 4;
        border-radius: 5px 0 0 0;
      }
      
      header button {
        flex: 1;
        margin: 0;
        padding: 0;
        min-width: 240px;
        border-radius: 0 5px 0 0;
      }
      
      th.rx:not(:first-child),
      td.rx:not(:first-child),
      th.unlinked,
      td.unlinked {
        border-left: 2px solid var(--side-border);
      }
    `
  ].flat();

  static properties = {
    prescriptions: { type: Object },
    plans: { type: Object },
    showMatchButton: { type: Boolean },
    showLinkButton: { type: Boolean },
    showUnlinkButton: { type: Boolean },
    showMatchModal: { type: Boolean },
    links: { type: Array },
    unlinkedPlans: { type: Array },
    courseSer: { type: String },
    courseTitle: { type: String },
    verifications: { type: Array }
  };

  constructor() {
    super();

    this.links = [];
    this.unlinkedPlans = [];
  }

  updated(changed) {
    if (changed.has('prescriptions') || changed.has('plans')) {
      const { prescriptions, plans } = this;

      const { links, unlinkedPlans } = partitionRx(prescriptions, plans);
      this.links = links;
      this.unlinkedPlans = unlinkedPlans;

      this.showLinkButton = plans.length === 1 && prescriptions.length === 1 && !prescriptions[0].plans.length;
      this.showUnlinkButton = plans.length === 1 && prescriptions.length === 1 && prescriptions[0].plans.length
        && !prescriptions[0].plans[0].linkLocked;

      this.showMatchButton = plans.length >= 1 && prescriptions.length >= 1
        && (plans.length > 1 || prescriptions.length > 1)
        && (prescriptions.some(rx => rx.plans.some(plan => !plan.linkLocked))
          || plans.some(plan => !prescriptions.some(rx => rx.plans.find(rxPlan => rxPlan.id === plan.id)))
        );
    }
  }

  render() {
    const { prescriptions, plans, showLinkButton, showUnlinkButton, showMatchButton, showMatchModal,
      courseTitle, buildData, handleLinkPlan, handleUnlinkPlan, handleMatchPlans, handleMatchSave,
      handleMatchCancel } = this;

    const data = buildData();

    if (!data) return;

    return html`
      <section class="rx">
        <header>
          <h4>
            ${plans.length
              ? prescriptions.length
                ? 'Prescription / Plan Overview'
                : 'Plan Overview'
              : 'Prescription Overview'
            }
          </h4>
          ${showLinkButton ? html`
            <button
              @click=${handleLinkPlan}
            >
              Link Prescription / Plan
            </button>
          ` : ''}
          ${showUnlinkButton ? html`
            <button
              @click=${handleUnlinkPlan}
            >
              Unlink Prescription / Plan
            </button>
          ` : ''}
          ${showMatchButton ? html`
            <button
              @click=${handleMatchPlans}
            >
              Link Prescriptions / Plans
            </button>
          ` : ''}
        </header>
        <rx-pass-state-table
          even
          .data=${data}          
        ></rx-pass-state-table>
      </section>
      ${showMatchModal ? html`
        <patient-rx-match-modal
          .prescriptions=${prescriptions}
          .plans=${plans}
          title=${courseTitle}
          @save=${handleMatchSave}
          @cancel=${handleMatchCancel}
        ></patient-rx-match-modal>
      ` : ''}
    `;
  }

  buildData = () => {
    const { links, unlinkedPlans, verifications } = this;

    if (!links || !unlinkedPlans || isRxTableEmpty(links, unlinkedPlans)) return false;

    return [
      [
        '',
        ...(links?.flatMap(({ rx: { id: rxId }, plans }) => [
          { class: 'strong-left', value: `Rx name\n${rxId}` },
          ...plans.map(({ id }) => `Plan name\n${id}`)
        ]) ?? []),
        ...(unlinkedPlans?.map(({ id }, index) => ({
          class: h(index === 0, 'strong-left'),
          value: `Plan name\n${id}`
        })) ?? [])
      ],
      ...rows.filter(({ skip }) => !skip || !skip(this))
        .map(({ htmlClass = '', header, prescription: rxField, plan: planField, unit, planVerifyEach,
          planVerifiedBy, showIcon, showValue, inline, planTitle = () => '', planVerifyField, rxVerifyField,
          ...cellAttributes }) => [
          header,
          ...links.flatMap(({ rx, plans }) => [
            Object.assign(
              resolveData({
                data: rx,
                field: rxField,
                unit,
                htmlClass: `strong-left ${htmlClass}`,
                verifyKey: rx.key,
                verifyField: rxVerifyField || rxField,
                verifications
              }),
              cellAttributes
            ),
            ...plans.map(plan => ({
              ...resolveData({
                data: plan,
                field: planField,
                unit,
                htmlClass,
                showIcon,
                showValue,
                verifyKey: plan.key,
                verifyField: planVerifyField || planField,
                verifyEach: planVerifyEach,
                verifications,
                tooltipText: planTitle(plan),
                tooltip: planTitle(plan)
              }),
              inline,
              verifiedBy: planVerifiedBy,
              ...cellAttributes
            }))
          ]),
          ...unlinkedPlans.map(plan => ({
            ...resolveData({
              data: plan,
              field: planField,
              unit,
              verifyKey: plan.key,
              verifyField: planVerifyField || planField,
              verifyEach: planVerifyEach,
              htmlClass: `strong-left ${htmlClass}`,
              verifications,
              tooltipText: planTitle(plan),
              tooltip: planTitle(plan)
            }),
            inline,
            verifiedBy: planVerifiedBy,
            ...cellAttributes
          }))
        ]).filter(filterInactive)
    ];
  };

  handleLinkPlan = () => {
    const { prescriptions, plans } = this;

    this.updateLinks({ [prescriptions[0].ser]: [plans[0].ser] });
  };

  handleUnlinkPlan = () => {
    this.updateLinks({});
  };

  handleMatchPlans = () => {
    this.showMatchModal = true;
    store.dispatch(showOverlay());
  };

  handleMatchSave = ({ detail: { links } }) => {
    this.updateLinks(links);
    this.showMatchModal = false;
    store.dispatch(hideOverlay());
  };

  handleMatchCancel = () => {
    this.showMatchModal = false;
    store.dispatch(hideOverlay());
  };

  updateLinks = links => {
    const { courseSer } = this;

    this.dispatchEvent(new CustomEvent('links-change', {
      bubbles: true,
      composed: true,
      detail: {
        courseSer,
        links: Object.entries(links).map(([prescriptionSer, planSers]) => ({ prescriptionSer, planSers }))
      }
    }));
  };
}

customElements.define('rx-pass-state-table', RxPassStateTable);
customElements.define('patient-rx', PatientRx);