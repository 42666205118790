/**
 * Checks if the content type matches the target type.
 * @param {string} contentType The content type to check. Should not have wildcards.
 * @param {string} targetType The target type, which can contain wildcards.
 * @return {boolean}
 */
const contentTypeMatches = (contentType, targetType) => {
  if (!contentType) return false;

  const [actualA, actualB] = contentType.toLowerCase().split('/');
  const [targetA, targetB] = targetType.toLowerCase().split('/');

  return (targetA === '*' || actualA === targetA)
    && (targetB === '*' || actualB === targetB);
};

export default contentTypeMatches;