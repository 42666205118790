/**
 * @param {[Number, Number]} point
 * @param {Number} x
 * @param {Number} y
 * @return {[Number, Number]}
 */
const translatePoint = (point, x, y) => [
  point[0] + x,
  point[1] + y
];

export default translatePoint;