import { mat3, mat4 } from 'gl-matrix';
import { newInstance as newInstance$1, obj, setGet, get } from '../../macros.js';
import vtkViewNode from '../SceneGraph/ViewNode.js';
import { registerOverride } from './ViewNodeFactory.js';

// vtkOpenGLActor methods
// ----------------------------------------------------------------------------

function vtkOpenGLActor(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkOpenGLActor'); // Builds myself.

  publicAPI.buildPass = function (prepass) {
    if (prepass) {
      model._openGLRenderWindow = publicAPI.getFirstAncestorOfType('vtkOpenGLRenderWindow');
      model.openGLRenderer = publicAPI.getFirstAncestorOfType('vtkOpenGLRenderer');
      model.context = model._openGLRenderWindow.getContext();
      publicAPI.prepareNodes();
      publicAPI.addMissingNodes(model.renderable.getTextures());
      publicAPI.addMissingNode(model.renderable.getMapper());
      publicAPI.removeUnusedNodes(); // we store textures and mapper

      model.ogltextures = null;
      model.activeTextures = null;

      for (var index = 0; index < model.children.length; index++) {
        var child = model.children[index];

        if (child.isA('vtkOpenGLTexture')) {
          if (!model.ogltextures) {
            model.ogltextures = [];
          }

          model.ogltextures.push(child);
        } else {
          model.oglmapper = child;
        }
      }
    }
  };

  publicAPI.traverseOpaqueZBufferPass = function (renderPass) {
    if (!model.renderable || !model.renderable.getNestedVisibility() || model.openGLRenderer.getSelector() && !model.renderable.getNestedPickable()) {
      return;
    }

    publicAPI.apply(renderPass, true);
    model.oglmapper.traverse(renderPass);
    publicAPI.apply(renderPass, false);
  }; // we draw textures, then mapper, then post pass textures


  publicAPI.traverseOpaquePass = function (renderPass) {
    if (!model.renderable || !model.renderable.getNestedVisibility() || !model.renderable.getIsOpaque() || model.openGLRenderer.getSelector() && !model.renderable.getNestedPickable()) {
      return;
    }

    publicAPI.apply(renderPass, true);
    model.oglmapper.traverse(renderPass);
    publicAPI.apply(renderPass, false);
  }; // we draw textures, then mapper, then post pass textures


  publicAPI.traverseTranslucentPass = function (renderPass) {
    if (!model.renderable || !model.renderable.getNestedVisibility() || model.renderable.getIsOpaque() || model.openGLRenderer.getSelector() && !model.renderable.getNestedPickable()) {
      return;
    }

    publicAPI.apply(renderPass, true);
    model.oglmapper.traverse(renderPass);
    publicAPI.apply(renderPass, false);
  };

  publicAPI.activateTextures = function () {
    // always traverse textures first, then mapper
    if (!model.ogltextures) {
      return;
    }

    model.activeTextures = [];

    for (var index = 0; index < model.ogltextures.length; index++) {
      var child = model.ogltextures[index];
      child.render();

      if (child.getHandle()) {
        model.activeTextures.push(child);
      }
    }
  };

  publicAPI.queryPass = function (prepass, renderPass) {
    if (prepass) {
      if (!model.renderable || !model.renderable.getVisibility()) {
        return;
      }

      if (model.renderable.getIsOpaque()) {
        renderPass.incrementOpaqueActorCount();
      } else {
        renderPass.incrementTranslucentActorCount();
      }
    }
  };

  publicAPI.opaqueZBufferPass = function (prepass, renderPass) {
    return publicAPI.opaquePass(prepass, renderPass);
  };

  publicAPI.opaquePass = function (prepass, renderPass) {
    if (prepass) {
      model.context.depthMask(true);
      publicAPI.activateTextures();
    } else if (model.activeTextures) {
      for (var index = 0; index < model.activeTextures.length; index++) {
        model.activeTextures[index].deactivate();
      }
    }
  }; // Renders myself


  publicAPI.translucentPass = function (prepass, renderPass) {
    if (prepass) {
      model.context.depthMask(false);
      publicAPI.activateTextures();
    } else if (model.activeTextures) {
      for (var index = 0; index < model.activeTextures.length; index++) {
        model.activeTextures[index].deactivate();
      }
    }
  };

  publicAPI.getKeyMatrices = function () {
    // has the actor changed?
    if (model.renderable.getMTime() > model.keyMatrixTime.getMTime()) {
      model.renderable.computeMatrix();
      mat4.copy(model.keyMatrices.mcwc, model.renderable.getMatrix());
      mat4.transpose(model.keyMatrices.mcwc, model.keyMatrices.mcwc);

      if (model.renderable.getIsIdentity()) {
        mat3.identity(model.keyMatrices.normalMatrix);
      } else {
        mat3.fromMat4(model.keyMatrices.normalMatrix, model.keyMatrices.mcwc);
        mat3.invert(model.keyMatrices.normalMatrix, model.keyMatrices.normalMatrix);
        mat3.transpose(model.keyMatrices.normalMatrix, model.keyMatrices.normalMatrix);
      }

      model.keyMatrixTime.modified();
    }

    return model.keyMatrices;
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  context: null,
  keyMatrixTime: null,
  keyMatrices: null,
  activeTextures: null
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Inheritance

  vtkViewNode.extend(publicAPI, model, initialValues);
  model.keyMatrixTime = {};
  obj(model.keyMatrixTime, {
    mtime: 0
  });
  model.keyMatrices = {
    normalMatrix: mat3.identity(new Float64Array(9)),
    mcwc: mat4.identity(new Float64Array(16))
  }; // Build VTK API

  setGet(publicAPI, model, ['context']);
  get(publicAPI, model, ['activeTextures']); // Object methods

  vtkOpenGLActor(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = newInstance$1(extend); // ----------------------------------------------------------------------------

var vtkActor = {
  newInstance: newInstance,
  extend: extend
}; // Register ourself to OpenGL backend if imported

registerOverride('vtkActor', newInstance);

export { vtkActor as default, extend, newInstance };
