import { css } from 'lit';
import RadConfirm from '../../common/rad-confirm';

class PatientModal extends RadConfirm {
  static styles = [
    RadConfirm.styles,
    css`    
      #container {
        width: 40%;
        min-width: 600px;
        min-height: unset;
      }
    `
  ].flat();
}

customElements.define('patient-modal', PatientModal);