import { isActive } from './resolveData';

const isDatedTableEmpty = (rows, skipData, dates, data) =>
  !(rows.length && rows.some(({ skip, field, chart }) =>
    (!skip || !skip(skipData))
      // If it is a chart, it should have a skip, and if we didn't skip it, we'll want to show it.
      //  Otherwise, we'll check out the data.
      && (chart || dates
        .flatMap(({ times }) => times)
        .some(({ asString }) => {
          const source = data.find(({ episodeDateTime }) => episodeDateTime === asString);

          return source && isActive(source[field]);
        }))
  ));

export default isDatedTableEmpty;