import { css } from 'lit';
import chartStyles from './styles-chart';

const tableStyles = css`
  :host {
    display: block;
    font-family: "Segoe UI", Helvetica, sans-serif;
  }

  * {
    box-sizing: border-box;
  }

  [wrapper] {
    position: relative;
    overflow: hidden;
  }

  table {
    border-collapse: collapse;
    border: 1px solid #dae0e4;
  }
  
  table[main] {
    width: 100%;
  }
  
  :not(thead) > tr:nth-child(2n) {
    background: rgba(249, 250, 251, .8);
  }
  
  col:nth-child(2n) {
    background: rgb(251, 253, 253);
  }
  
  :not(thead) > col:nth-child(2n+1) {
    background: #FFF;
  }
  
  table {
    table-layout: fixed;
    min-width: 100%;
  }
  
  td, th {
    white-space: normal;
    overflow-wrap: anywhere;
  }
  
  .fluid table :not([chart]) > td > div,
  .fluid table :not([chart]) > th > div {
    width: 116px; /* prevent it from being 95.5 and wrapping */
  }
  
  .fluid table tr > :nth-child(n+1) {
    width: 163px; /* should be div width + horizontal padding + 1 */
  }
  
  .fixed-1 table,
  .fixed-2 table,
  .fixed-3 table,
  .fixed-4 table,
  .fixed-5 table,
  .fixed-6 table {
    width: 100%;
  }
  
  .fixed-1 table tr > :nth-child(n+1) {
    width: calc(100% - 200px);
  }

  .fixed-2 table tr > :nth-child(n+1) {
    width: calc(50% - (200px / 2));
  }

  .fixed-3 table tr > :nth-child(n+1) {
    width: calc(33.3333% - (200px / 3));
  }

  .fixed-4 table tr > :nth-child(n+1) {
    width: calc(25% - (200px / 4));
  }

  .fixed-5 table tr > :nth-child(n+1) {
    width: calc(20% - (200px / 5));
  }

  .fixed-6 table tr > :nth-child(n+1) {
    width: calc(16.6667% - (200px / 6));
  }

  table[sticky] {
    position: absolute;
    top: 0;
    left: 0;
    background: #FFF;
    z-index: 1000;
  }
  
  table[sticky][rows],
  table[sticky][corner] {
    border-top: 1px solid rgb(218, 224, 228) !important;
  }
  
  /* If we need more columns/rows to be able to freeze, just add more CSS */
  .freeze-columns-1 table[sticky][columns] tr > :nth-child(n+2),
  .freeze-columns-1 table[sticky][columns] tr > :nth-child(n+2) *,
  .freeze-columns-1 table[sticky][columns] tr > [spanned]:nth-child(n+1),
  .freeze-columns-1 table[sticky][columns] tr > [spanned]:nth-child(n+1) *,
  .freeze-columns-1 table[sticky][corner] tr > :nth-child(n+2),
  .freeze-columns-1 table[sticky][corner] tr > :nth-child(n+2) *,
  .freeze-columns-1 table[sticky][corner] tr > [spanned]:nth-child(n+1),
  .freeze-columns-1 table[sticky][corner] tr > [spanned]:nth-child(n+1) *,
  .freeze-columns-2 table[sticky][columns] tr > :nth-child(n+3),
  .freeze-columns-2 table[sticky][columns] tr > :nth-child(n+3) *,
  .freeze-columns-2 table[sticky][columns] tr > [spanned]:nth-child(n+2),
  .freeze-columns-2 table[sticky][columns] tr > [spanned]:nth-child(n+2) *,
  .freeze-columns-2 table[sticky][corner] tr > :nth-child(n+3),
  .freeze-columns-2 table[sticky][corner] tr > :nth-child(n+3) *,
  .freeze-columns-2 table[sticky][corner] tr > [spanned]:nth-child(n+2),
  .freeze-columns-2 table[sticky][corner] tr > [spanned]:nth-child(n+2) *,
  .freeze-columns-3 table[sticky][columns] tr > :nth-child(n+4),
  .freeze-columns-3 table[sticky][columns] tr > :nth-child(n+4) *,
  .freeze-columns-3 table[sticky][columns] tr > [spanned]:nth-child(n+3),
  .freeze-columns-3 table[sticky][columns] tr > [spanned]:nth-child(n+3) *,
  .freeze-columns-3 table[sticky][corner] tr > :nth-child(n+4),
  .freeze-columns-3 table[sticky][corner] tr > :nth-child(n+4) *,
  .freeze-columns-3 table[sticky][corner] tr > [spanned]:nth-child(n+3),
  .freeze-columns-3 table[sticky][corner] tr > [spanned]:nth-child(n+3) *,
  .freeze-columns-4 table[sticky][columns] tr > :nth-child(n+5),
  .freeze-columns-4 table[sticky][columns] tr > :nth-child(n+5) *,
  .freeze-columns-4 table[sticky][columns] tr > [spanned]:nth-child(n+4),
  .freeze-columns-4 table[sticky][columns] tr > [spanned]:nth-child(n+4) *,
  .freeze-columns-4 table[sticky][corner] tr > :nth-child(n+5),
  .freeze-columns-4 table[sticky][corner] tr > :nth-child(n+5) *,
  .freeze-columns-4 table[sticky][corner] tr > [spanned]:nth-child(n+4),
  .freeze-columns-4 table[sticky][corner] tr > [spanned]:nth-child(n+4) *,
  .freeze-columns-5 table[sticky][columns] tr > :nth-child(n+6),
  .freeze-columns-5 table[sticky][columns] tr > :nth-child(n+6) *,
  .freeze-columns-5 table[sticky][columns] tr > [spanned]:nth-child(n+5),
  .freeze-columns-5 table[sticky][columns] tr > [spanned]:nth-child(n+5) *,
  .freeze-columns-5 table[sticky][corner] tr > :nth-child(n+6),
  .freeze-columns-5 table[sticky][corner] tr > :nth-child(n+6) *,
  .freeze-columns-5 table[sticky][corner] tr > [spanned]:nth-child(n+5),
  .freeze-columns-5 table[sticky][corner] tr > [spanned]:nth-child(n+5) *,
  .freeze-rows table[sticky][rows] tbody,
  .freeze-rows table[sticky][rows] tbody *,
  .freeze-rows table[sticky][corner] tbody,
  .freeze-rows table[sticky][corner] tbody *{
    visibility: collapse !important;
    pointer-events: none !important;
  }
  
  table[sticky] {
    background: none !important;
    border-color: transparent !important;
    pointer-events: none !important;
  }
  
  table[sticky] * {
    pointer-events: auto;
  }
  
  table[sticky][columns] {
    border-bottom: 1px solid rgb(218, 224, 228) !important;
  }
  
  table[sticky][columns] thead,
  table[sticky][columns] tbody {
    border-color: transparent !important;
  }
  
  .freeze-columns-1 table[sticky][columns] td:not(:nth-child(n+2)):not([spanned]),
  .freeze-columns-1 table[sticky][corner] td:not(:nth-child(n+2)):not([spanned]),
  .freeze-columns-1 table[sticky][columns] th:not(:nth-child(n+2)):not([spanned]),
  .freeze-columns-1 table[sticky][corner] th:not(:nth-child(n+2)):not([spanned]),
  .freeze-columns-2 table[sticky][columns] td:not(:nth-child(n+3)):not([spanned]),
  .freeze-columns-2 table[sticky][corner] td:not(:nth-child(n+3)):not([spanned]),
  .freeze-columns-2 table[sticky][columns] th:not(:nth-child(n+3)):not([spanned]),
  .freeze-columns-2 table[sticky][corner] th:not(:nth-child(n+3)):not([spanned]),
  .freeze-columns-3 table[sticky][columns] td:not(:nth-child(n+4)):not([spanned]),
  .freeze-columns-3 table[sticky][corner] td:not(:nth-child(n+4)):not([spanned]),
  .freeze-columns-3 table[sticky][columns] th:not(:nth-child(n+4)):not([spanned]),
  .freeze-columns-3 table[sticky][corner] th:not(:nth-child(n+4)):not([spanned]),
  .freeze-columns-4 table[sticky][columns] td:not(:nth-child(n+5)):not([spanned]),
  .freeze-columns-4 table[sticky][corner] td:not(:nth-child(n+5)):not([spanned]),
  .freeze-columns-4 table[sticky][columns] th:not(:nth-child(n+5)):not([spanned]),
  .freeze-columns-4 table[sticky][corner] th:not(:nth-child(n+5)):not([spanned]),
  .freeze-columns-5 table[sticky][columns] td:not(:nth-child(n+6)):not([spanned]),
  .freeze-columns-5 table[sticky][corner] td:not(:nth-child(n+6)):not([spanned]),
  .freeze-columns-5 table[sticky][columns] th:not(:nth-child(n+6)):not([spanned]),
  .freeze-columns-5 table[sticky][corner] th:not(:nth-child(n+6)):not([spanned]),
  .freeze-columns-6 table[sticky][columns] td:not(:nth-child(n+7)):not([spanned]),
  .freeze-columns-6 table[sticky][corner] td:not(:nth-child(n+7)):not([spanned]),
  .freeze-columns-6 table[sticky][columns] th:not(:nth-child(n+7)):not([spanned]),
  .freeze-columns-6 table[sticky][corner] th:not(:nth-child(n+7)):not([spanned]) {
    border-right: 1px solid #dae0e4;
  }

  table[sticky][corner] {
    z-index: 1001;
  }

  tbody, thead {
    line-height: 18px;
    text-align: left;
  }

  thead {
    background: #f6f8f9;
    font-size: 11px;
    font-weight: 700;
    color: #6b8393;
    border-bottom: 1px solid #dae0e4;
  }

  thead tr:first-child th {
    padding-top: 8px;
  }

  thead tr:last-child th {
    padding-bottom: 8px;
  }

  thead th {
    padding: 0 12px;
  }

  thead th[colspan] {
    text-align: center;
  }

  tr:not([no-column-header]) th:not([spanned]):first-child, 
  tr:not([no-column-header]) td:not([spanned]):first-child {
    width: 200px;
    min-width: 200px;
  }
  
  tr:not([no-column-header]) th:not([spanned]):first-child {
    border-right: 1px solid #dae0e4;
  }

  tbody, tbody th {
    font-size: 13px;
    font-weight: 400;
    color: #313c43;
  }

  tbody th, tbody td {
    padding: 8px 34px 8px 12px;
  }
  
  tbody[collapsed] {
    display: none;
  }
  
  th, td {
    min-width: 50px;
    vertical-align: top;
    position: relative;
  }

  tr:not([chart]) ul {
    margin: 0;
    padding: 0;
  }
  
  tr:not([chart]) li {
    margin: 0 0 1em;
  }
  
  td span[nested] {
    display: block;
  }

  .info-icon:after,
  [show-info-icon]:after {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    content: '\uf05a';    
    margin-left: 4px;
    cursor: pointer;
  }
  
  [show-info-icon]:after {
    position: relative;
    top: 1px;
    margin: 0;
  }
  
  verify-check-box {
    position: absolute;
    top: 7px;
    right: 8px;
  }
  
  [inline] verify-check-box {
    display: inline;
    position: static;
  }
  
  .verified-by {
    padding-bottom: 4px;
    display: block;
    padding-top: 4px;
    border-top: 1px solid #999;
    margin-top: 2px;
  }
  
  ${chartStyles}
`;

export default tableStyles;