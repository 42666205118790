import { html, css, LitElement } from 'lit';
import patientStyles from '../styles/patient';
import fontAwesome from '../../../../utils/font-awesome';
import { APPROVE_PERMISSION, isCompletedStatus, isEndOfTreatmentStatus, statuses } from '../common';
import { toDateTime } from '../../../../utils/date/format';
import h from '../../../../utils/h';
import { hasPermission, maybeFilterDates } from '../utils';
import { connect } from '../../../../utils/redux';
import { store } from '../../../../redux/store';
import { meSelector, organizationIdSelector } from '../../../../redux/app/selectors';

import '../../../common/phi-value';
import '../../../common/rad-confirm';
import './patient-chart-check-history';
import './patient-documentation';
import './patient-end-of-treatment';
import './patient-rx';
import './patient-initial';
import './patient-plan';
import { canLoad } from '../../image-view/redux/actions.js';

class WeeklyCheckPatient extends connect(store)(LitElement) {
  static styles = [
    patientStyles,
    css`    
      :host {
        padding-bottom: 200px !important;
        position: relative;
      }
      
      #patient-info {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      
      #patient-id {
        flex: 1;
      }
      
      #patient-id h1 {
        font-size: 40px;
        font-weight: 600;
        margin: 24px 0 8px;
      }
      
      #patient-id span {
        font-size: 24px;
        color: var(--neutral-4);
        font-weight: 400;
        margin-left: 4px;
      }
      
      #last-treatment {
        flex: 0 1 250px;
        margin: 16px 0 0;
        display: flex;
        align-items: center;
      }
      
      #last-treatment i {
        color: var(--primary-color);
        margin: 2px 8px 0 0;
        font-size: 17px;
        text-align: right;
        flex: 1;
      }

      #last-treatment ul {
        white-space: nowrap;
        padding: 0;
        list-style: none;
        margin: 0;
        text-align: right;
      }
  
      .comments {
        padding: 12px;
        border: 1px solid var(--neutral-6);
      }
      
      hr {
        margin: 20px 0;
      }
      
      #last-treatment i {
        cursor: pointer;
      }
  
      ul.diagnosis-list {
        font-size: 1.1em;
        margin-top: 0;
      }
  
      .flex {
        display: flex;
        justify-content: start;
        align-items: baseline;
      }
      
      #select-template {
        text-align: center;
        font-size: 1.2em;
        font-weight: 700;
      }
      
      :host(:not([dock-approve])) {
        display: flex;
        overflow: hidden !important;
        flex-direction: column;
        padding: 0 !important;
      }
      
      :host(:not([dock-approve])) #main-wrapper {
        flex: 1;
        overflow: auto;
        padding: 16px;
      }
      
      #approve[float] {
        flex: 0 0 100px;
        z-index: 9997;
        background: var(--light-color);
        border-top: 1px solid var(--side-border);
        padding: 8px 16px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
      
      #approve[float] section {
        flex: 1;
        display: flex;
        flex-direction: row;
        box-shadow: none;
        background: none;
        min-width: auto;
        margin: 0;
        align-items: center;
        gap: 16px;
      }
      
      #approve[float] h4,
      #approve[float] .comments {
        border: 0;
        padding: 0;
      }
      
      #approve[float] h4 {
        font-size: .9em;
        text-align: center;
      }
      
      #approve[float] .comments {
        flex: 1;
      }
      
      #approve[float] textarea {
        width: 100%;
        resize: none;
      }
      
      #approve[float] buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      
      #approve[float] button {
        font-size: .9em;
        width: 110px;
      }
  
      #approve:not([float]) textarea {
        display: block;
        width: 100%;
        font-size: 14px;
        padding: 8px;
        border-radius: 2px;
        border: 1px solid var(--neutral-5);
        transition: .25s;
        resize: none;
      }
  
      #approve:not([float]) textarea:hover {
        display: block;
        font-size: 14px;
        padding: 8px;
        border-radius: 2px;
        border: 1px solid var(--neutral-4);
        transition: .25s;
      }
  
      #approve:not([float]) textarea:focus {
        outline: none;
        border: 1px solid var(--primary-color);
        transition: .25s;
      }
  
      #approve:not([float]) buttons button {
        font-size: 15px;
        font-weight: 400;
        padding: 12px 24px;
        min-width: 96px;
        background: var(--primary-color);
        transition: all .25s;
        margin: 8px 16px auto 0;
        border-radius: 5px;
      }
  
      #approve:not([float]) buttons button:hover {
        background: var(--blue-2);
      }
    `
  ].flat();

  static properties = {
    patient: { type: Object },
    showLoadNextConfirm: { type: Boolean },
    checkTemplates: { type: Array },
    me: { type: Object },
    organizationId: { type: String },
    showPreTreatmentDates: { type: Boolean },
    dockApprove: { type: Boolean, attribute: 'dock-approve' },
    currentDicom: { type: Object },
    showAll: { type: Boolean }
  };

  updated(changed) {
    if (changed.has('patient')) {
      if (changed.get('patient')?.id !== this.patient?.id) {
        const approveCommentElement = this.shadowRoot.querySelector('textarea');

        approveCommentElement && (approveCommentElement.value = '');
      }

      if (this.patient) {
        store.dispatch(canLoad());
      }
    }
  }

  render() {
    const { patient, showLoadNextConfirm, checkTemplates, me, organizationId, showPreTreatmentDates, dockApprove,
      currentDicom, showAll,
      handleRefreshPatient, handleLoadNextPatient, handleApprovePatient,
      handleLoadNextConfirm, handleLoadNextCancel, renderCourseHeaderWithDiagnoses } = this;
    const { status, courses, lastName, firstName, id, lastTreatment, reviewAccepted, approvals,
      overlappingCourses, lastUpdateDateTime: lastUpdated, verifications, hospitalId,
      departmentName, primaryOncologist } = patient;

    const assignedCheckTemplate = patient.courses
      .every(({ plans }) => plans.every(({ checkTemplate: { wasAutoAssigned } }) => !wasAutoAssigned))
      || checkTemplates?.length === 1;

    return html`
      ${fontAwesome}
      <span id="top"></span>
      <div id="main-wrapper">
        <div id="patient-info">
          <div id="patient-id">
            <h1><phi-value>${lastName}, ${firstName} <span>(${id})</span></phi-value></h1>
            <span style="font-size: 18px">
              ${hospitalId}, ${departmentName}${h(primaryOncologist, html`, ${primaryOncologist}`)}
            </span>            
          </div>
          <div id="last-treatment">
            <i 
              title="Click to get the latest data from ARIA."
              class="fas fa-sync-alt"
              @click=${handleRefreshPatient}
            ></i> 
            <ul>
              <li>
                <span>Last Treatment:</span>
                <span>${toDateTime(lastTreatment)}</span>
              </li>
              <li>
                <span>Last Updated:</span>
                <span>${toDateTime(lastUpdated)}</span>
              </li>
            </ul>
          </div>
        </div>
        ${courses.map((course, courseIndex) => {
          const { id, ser, prescriptions, plans, diagnoses, initialTreatmentChecks, documentations = [],
            endOfTreatmentChecks, documentationWeightLine, documentationWeightShow,
            documentationWeightUnitOfMeasure, treatmentDates: allTreatmentDates, imagingDates: allImagingDates, 
            documentationDates: allDocumentationDates, firstDate } = course;
          const courseNumber = courseIndex + 1;
          const courseSectionId = `course${courseNumber}`;
          const courseTitle = `Course: ${id}`;
          const isCourseOverlapping = overlappingCourses.includes(ser); 
          const treatmentDates = maybeFilterDates(!showPreTreatmentDates, allTreatmentDates, firstDate);
          const imagingDates = maybeFilterDates(!showPreTreatmentDates, allImagingDates, firstDate);
          const documentationDates = maybeFilterDates(!showPreTreatmentDates, allDocumentationDates, firstDate);
    
          return html`
              ${renderCourseHeaderWithDiagnoses(id, courseSectionId, diagnoses)}
              <patient-rx
                section
                id=${`${courseSectionId}-rx`}
                .prescriptions=${prescriptions}
                .plans=${plans}
                .courseTitle=${courseTitle}
                .courseSer=${ser}
                .verifications=${verifications}
              ></patient-rx>
              ${h(assignedCheckTemplate, html`
                ${h(isCourseOverlapping || course.plans.some(plan => plan.treatments.length), html`
                <patient-initial
                  section
                  id=${`${courseSectionId}-initial`}
                  .checks=${initialTreatmentChecks}
                  .verifications=${verifications}
                  .verifyKey=${course.key}
                  .verifyField=${'initialTreatmentChecks'}
                ></patient-initial>
                `)}
                <patient-documentation
                  section
                  id=${`${courseSectionId}-documents`}
                  .documentations=${documentations}
                  .dates=${documentationDates}
                  .weightLine=${documentationWeightLine}
                  ?weightShow=${documentationWeightShow}
                  .weightUnitOfMeasure=${documentationWeightUnitOfMeasure}
                  .showPreTreatmentDates=${showPreTreatmentDates}
                  .course=${course}
                  .verifications=${verifications}
                ></patient-documentation>
                ${plans.map((plan, index) => h(plan.treatments.length || plan.imagings.length, html`
                  <patient-plan
                    ?showAll=${showAll}
                    section
                    id=${`${courseSectionId}-plan${index + 1}`}
                    .plan=${plan}
                    courseNumber=${courseNumber}
                    planNumber=${index + 1}
                    .treatmentDates=${treatmentDates}
                    .imagingDates=${imagingDates}
                    .showPreTreatmentDates=${showPreTreatmentDates}
                    .verifications=${verifications}
                    .currentDicom=${currentDicom}
                    .patientId=${patient.id}
                  ></patient-plan>
                `))}
                ${h((isEndOfTreatmentStatus(status) || isCompletedStatus(status))
                    && (isCourseOverlapping || course.plans.some(plan => plan.treatments.length)), html`
                  <patient-end-of-treatment
                    section
                    id=${`${courseSectionId}-eotChecks`}
                    .endOfTreatmentChecks=${endOfTreatmentChecks}
                    .verifications=${verifications}
                    .verifyKey=${course.key}
                    .verifyField=${'endOfTreatmentChecks'}
                  ></patient-end-of-treatment>
              `)}
            `)}
          `;
        })}
        ${h(assignedCheckTemplate, html`
          <patient-chart-check-history
            section
            id="history"
            .approvals=${approvals}
            .checkTemplates=${checkTemplates}
          ></patient-chart-check-history>
        `)}
        ${h(!assignedCheckTemplate, html`
          <div id="select-template">
            Select check templates on the right to calculate checks.
          </div>
        `)}
      </div>
      ${h(assignedCheckTemplate, html`
        <div id="approve" ?float=${!dockApprove}>
          ${h(hasPermission(APPROVE_PERMISSION, me, organizationId), html`
            <section>
              <h4>Approval<br>Comments</h4>
              <div class="comments">
                <textarea id="approval-comment" rows="3"></textarea>
              </div>
            </section>
          `)}
          <buttons>
            ${h(hasPermission(APPROVE_PERMISSION, me, organizationId), html`<button
                @click=${handleApprovePatient}
                ?disabled=${status.toLowerCase() === statuses.completed.toLowerCase() || reviewAccepted}
              >
                Approve and Load Next
              </button>`)}
            <button
              @click=${handleLoadNextPatient}
            >
              Load Next Patient
            </button>
          </buttons>
        </div>
      `)}
      
      ${showLoadNextConfirm ? html`
        <rad-confirm
          @confirm=${handleLoadNextConfirm}
          @cancel=${handleLoadNextCancel}
        >
          Would you like to load the next patient?<br>
          Text entered into the Approval Comments will not be saved.
        </rad-confirm>
      ` : ''}
    `;
  }

  renderCourseHeaderWithDiagnoses = (id, courseSectionId, diagnoses) => {
    const diagnosisList = html`<ul class="diagnosis-list"> ${diagnoses.map((diagnosis, index) => {
      const { diagnosisId, description, lateralityType } = diagnosis;

      return html`<li>${diagnosisId} ${description}${h(lateralityType, `, ${lateralityType}`)}</li>`;
    })}</ul>`;

    const headerWithDiagnoses =
      html`<h3 section id=${courseSectionId}><span>Course:</span> ${id}</h3> ${diagnosisList}`;
    const isDiagnosisOnSameLine = diagnoses.length === 1;

    return html`
      ${h(isDiagnosisOnSameLine, html`<header class="flex"> ${headerWithDiagnoses} <\header>`)}
      ${h(!isDiagnosisOnSameLine, html`${headerWithDiagnoses}`)}
    `;
  }

  handleLoadNextConfirm = () => {
    this.showLoadNextConfirm = false;
    this.loadNextPatient();
  };

  handleLoadNextCancel = () => {
    this.showLoadNextConfirm = false;
  };

  handleApprovePatient = () => {
    this.approveAndLoadNextPatient();
  };

  handleLoadNextPatient = () => {
    if (this.shadowRoot.querySelector('textarea')?.value) {
      this.showLoadNextConfirm = true;
    } else {
      this.loadNextPatient();
    }
  };

  handleRefreshPatient = () => {
    this.dispatchEvent(new CustomEvent('refresh-patient', {
      bubbles: true,
      composed: true
    }));
  };

  loadNextPatient = () => {
    this.dispatchEvent(new CustomEvent('load-next-patient', {
      bubbles: true,
      composed: true
    }));
  };

  approveAndLoadNextPatient = () => {
    const comment = this.shadowRoot.querySelector('#approval-comment').value;

    this.dispatchEvent(new CustomEvent('approve-patient', {
      bubbles: true,
      composed: true,
      detail: {
        comment
      }
    }));
  };

  stateChanged(state) {
    this.me = meSelector(state);
    this.organizationId = organizationIdSelector(state);
  }
}

customElements.define('weekly-check-patient', WeeklyCheckPatient);
