const safeSelect = (obj, path) => {
  const parts = path.split(/\./g);
  let current = obj;

  while (current && parts.length) {
    current = current[parts.shift()];
  }

  return current;
};

export default safeSelect;