/**
 * Normalizes to the RGBA string (with #) supported by CSS and similar. (E.g., #FF0000FF)
 * Supports anything that toComponentArray can handle for input.
 *
 * @param {string|[int, int, int]|[int, int, int, int]}color
 * @return {string}
 */
import toComponentArray from './toComponentArray.js';

const normalizeToRGBAString = color => {
  const hexColor = toComponentArray(color)
    .map(v => `00${v.toString(16)}`.slice(-2)).join('')
    .toUpperCase();

  return `#${hexColor}`;
};

export default normalizeToRGBAString;