import clone from './clone';
import deepEquals from './deepEquals';
import deepFreeze from './deepFreeze';
import safeSelect from './safeSelect';
import valuesMatch from './valuesMatch';

export {
  clone,
  deepEquals,
  deepFreeze,
  safeSelect,
  valuesMatch
};