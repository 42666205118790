import { connect } from '../../../utils/redux';
import { css, html, LitElement } from 'lit';
import fontAwesome from '../../../utils/font-awesome';
import commonStyles from '../../styles-common';
import { store } from '../../../redux/store';
import router, { generate } from '../../../router';
import './weekly-check-header';
import './weekly-check-patient/index.js';
import './patients-sidebar';
import './sections-sidebar';
import queue from '../../../utils/queue';
import { cache } from '@cornerstonejs/core';
import {
  statusFilters,
  FilterModes,
  DateOptions,
  isCompletedStatus,
  isEndOfTreatmentStatus,
  stateFilters
} from './common';
import {
  meSelector,
  organizationIdSelector,
  organizationSelector,
  organizationSlugSelector,
  patientIdSelector, settingsLoadedSelector,
  settingsSelector
} from '../../../redux/app/selectors';
import { buildErrorData, mapEpisodicPatientData, maybeFilterDates, partitionRx, sortByStatus } from './utils';
import { Routes } from '../../../routes';
import querySelectorAllShadow from '../../../utils/dom/querySelectorAllShadow';
import { canAccess } from '../../../utils/auth';
import { fromDateString, toDateString, daysAgo } from '../../../utils/date';
import { toTime, toDay } from '../../../utils/date/format';
import { padBefore } from '../../../utils/string';
import distinct from '../../../utils/immutable/array/distinct';
import http from '../../../utils/redux/http';
import h from '../../../utils/h';
import { getInactive, getValue } from './utils/resolveData';
import { updatePatient, disableVerify, enableVerify } from '../../../redux/app/actions';
import { download } from '../../../utils/browser';
import { getHidePhi, showPhi } from '../../../utils/phi';
import { addToast as addToastBase } from '../../../utils/redux/toasts/actions';
import { getCookie } from '../../../utils/cookie';
import './patient-modal';
import '../document-view/document-viewer';
import { isRxTableEmpty as isRxTableEmpty } from './weekly-check-patient/patient-rx';

import { sortBy } from '../../../utils/array';
import { isDocumentationTableEmpty } from './weekly-check-patient/patient-documentation';
import { isPlanDetailTableEmpty } from './weekly-check-patient/plan-details';
import { isImagingTableEmpty } from './weekly-check-patient/plan-imaging';
import { isFieldsTableEmpty } from './weekly-check-patient/plan-fields';
import filterForSkippedDates from './utils/filterForSkippedDates';
import { getQueryParam } from '../../../utils/query';
import { removeValueMatching } from '../../../utils/immutable/array';
import updateErrorCounts from './utils/updateErrorCounts';
import '../image-view/image-viewer.js';
import { getBlankPopoutUrl, DICOM_POPOUT_TARGET } from '../image-view/utils/getPopoutUrl';
import setPopoutUrl from '../../../utils/popout/setPopoutUrl.js';
import getPopoutUrl from '../../../utils/popout/getPopoutUrl.js';

const MIN_PATIENT_SEARCH_LENGTH = 3;
const LAST_PATIENT_COOKIE = 'last-patient';
const LAST_FILTERS_COOKIE = 'last-filters';

const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

const addToast = (...args) => store.dispatch(addToastBase(...args));
const verifyToastId = 'verify';

const errorDataReplacer = (k, v) =>
  v instanceof Error ? `Error: ${v.message}\n\n${v.stack}` : v;

const dateToString = date =>
    `${date.getFullYear()}-${padBefore(date.getMonth() + 1, 2)}-${padBefore(date.getDate(), 2)}`;

const byName = (a, b) => a.lastName < b.lastName
  ? -1
  : a.lastName > b.lastName
    ? 1
    : a.firstName < b.firstName
      ? -1
      : 1;

const defaultFilters = {
  patientId: null,
  mode: FilterModes.DATE,
  dateOption: DateOptions.THIS_WEEK,
  start: daysAgo(7),
  end: new Date(),
  statuses: statusFilters,
  initialMoreThanFractions: true,
  weeklyMoreThanFractions: true,
  all: getQueryParam('all') ?? false,
  states: stateFilters,
  departmentSers: [],
  hospitalIds: [],
  machineIds: [],
  initialFractionsCount: 1,
  weeklyFractionsCount: 5,
  billingEligible: false
};

const entriesToObject = arrOfEntries => arrOfEntries
  .reduce((result, [key, value]) => Object.assign(result, { [key]: value }), {});

const topLevelSections = [
  { courses: true },
  { id: 'history', title: 'Chart Approvals' }
];

const courseSections = [
  {
    id: 'rx',
    title: 'Rx/Plan Overview',
    errorKey: 'prescriptions',
    condition: (_, course) => {
      const { links, unlinkedPlans } = partitionRx(course.prescriptions, course.plans);

      return !isRxTableEmpty(links, unlinkedPlans);
    }
  },
  {
    id: 'initial',
    title: 'Initial Treatment Checks',
    errorKey: 'initial',
    condition: (patient, course) => course.plans.some(plan => plan.initialTreatmentChecks.length)
      && course.plans.some(plan => plan.initialTreatmentChecks.some(c => !c.IsInactive))
      && (patient.overlappingCourses.includes(course.ser) || course.plans.some(plan => plan.treatments.length))
  },
  {
    id: 'documents',
    title: 'Documentation',
    errorKey: 'documentation',
    condition: (patient, course) => course.documentations?.length
      && !isDocumentationTableEmpty(
        course.documentationDates,
        course.documentations,
        { weightShow: course.documentationWeightShow }
      )
  },
  { plans: true },
  {
    id: 'eotChecks',
    title: 'EoT Checks',
    errorKey: 'eotChecks',
    condition: (patient, course) => (isCompletedStatus(patient.status) || isEndOfTreatmentStatus(patient.status))
            && course.endOfTreatmentChecks.length
            && course.endOfTreatmentChecks.some(c => !c.IsInactive)
            && (patient.overlappingCourses.includes(course.ser) || course.plans.some(plan => plan.treatments.length))
  }
];

const planSections = [
  { checkTemplate: true, title: 'Check Template' },
  {
    id: 'refpts', title: 'Ref Points', errorKey: 'refpts', condition: (_, { treatments, referencePointDoses = [] }) =>
            referencePointDoses?.length && !getInactive(referencePointDoses[0]) && treatments.length
  },
  {
    id: 'details',
    title: 'Plan',
    errorKey: 'details',
    condition: (patient, plan) => plan.treatments.length
      && !isPlanDetailTableEmpty(plan, patient.courses.find(c => c.plans.find(p => p.ser === plan.ser)).treatmentDates)
  },
  {
    id: 'imaging',
    title: 'Imaging',
    errorKey: 'imaging',
    condition: (patient, plan) => (plan.treatments.length || plan.imagings.length)
      && !isImagingTableEmpty(plan, patient.courses.find(c => c.plans.find(p => p.ser === plan.ser)).imagingDates)
  },
  {
    id: 'fields',
    title: 'Field(s)',
    errorKey: 'fields',
    condition: (patient, plan) => plan.treatments.length
      && !isFieldsTableEmpty(plan, patient.courses.find(c => c.plans.find(p => p.ser === plan.ser)).treatmentDates)
  }
];

const generateCouchLines = (data, fields, dateData, transformValue = v => v) => dateData
  .flatMap(({ times }) => times)
  .reduce((lines, { asString }, i) => {
    const entry = (data || []).find(({ episodeDateTime }) => episodeDateTime === asString);

    entry
      ? fields.map(field => {
        const fieldValue = entry[field];

        if (typeof fieldValue === 'object') {
          return {
            passState: fieldValue.passState,
            value: transformValue(fieldValue.value ?? fieldValue.actualValue),
            tooltipText: fieldValue.tooltipText ?? fieldValue.value
          };
        }

        return { passState: 'NA', value: transformValue(fieldValue) };
      }).forEach(({ value, passState, tooltipText }, j) => {
        lines[j].points.push(!isNaN(parseFloat(value))
          ? { value: parseFloat(value), passState, tooltipText }
          : null
        );
      }) : lines.forEach(({ points }) => points.push(null));

    return lines;
  }, [
    { color: '#36F', points: [] },
    { color: '#393', points: [] },
    { color: '#DA3', points: [] }
  ]);

const generateAngleLines = (data, fields, dateData) => {
  let mode = undefined;
  let hadNonZero = false;

  const lines = generateCouchLines(data, fields, dateData, v => {
    v = parseFloat(v);

    if (!isNaN(v) && v !== 0) {
      hadNonZero = true;
    }

    if (v < 0) {
      if (mode && mode !== '180') {
        throw new Error('Multiple modes');
      }

      mode = '180';
      return v;
    } else if (v > 180) {
      if (mode && mode !== '360') {
        throw new Error('Multiple modes');
      }

      mode = '360';
      return v - 360;
    }

    return v;
  });

  return { lines, mode: mode || (hadNonZero ? '180' : 'skip') };
};

const generateWeightLine = (data, fields, dateData) => dateData
  .flatMap(({ times }) => times)
  .reduce((line, { asString }) => {
    const entry = (data || []).find(({ dateTime }) => dateTime === asString);

    entry
      ? fields.flatMap(field => entry[field])
        .forEach((passStateValue, j) => {
          const value = getValue(passStateValue) === '-' ? undefined : getValue(passStateValue);
          const tooltipText = passStateValue?.tooltipText;

          line[j].points.push(value && !isNaN(parseFloat(value?.split(' ')[0]))
            ? { value: parseFloat(value?.split(' ')[0]), tooltipText }
            : null
          );
        })
      : line.forEach(({ points }) => points.push(null));

    return line;
  }, [
    { color: '#36F', points: [] }
  ]);

const createDateData = (dateStr, noTime = false) => {
  const dateObj = fromDateString(dateStr);

  return {
    asString: dateStr,
    asObject: dateObj,
    date: toDay(dateObj),
    time: noTime ? '' : toTime(dateObj),
    dayOfWeek: DAYS_OF_WEEK[dateObj.getDay()]
  };
};

const toMidnight = date => {
  const newDate = new Date(date);

  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);

  return newDate;
};

const sortDateData = ({ asObject: a }, { asObject: b }) =>
  a < b ? -1 : b > a ? 1 : 0;

const buildDates = (dateStrs, allDateStrs = null) => {
  const distinctStrs = distinct(dateStrs);
  distinctStrs.sort();

  const distinctStrsAll = distinct(allDateStrs ?? dateStrs);
  distinctStrsAll.sort();

  const dateObjects = distinctStrs.map(fromDateString);
  const dateObjectsAll = distinctStrsAll.map(fromDateString);

  const dateData = distinctStrs.map(dateStr => createDateData(dateStr, false));
  const datesToAdd = [];

  const [start] = dateObjectsAll;
  const end = dateObjectsAll[dateObjectsAll.length - 1];
  const current = toMidnight(start);
  let index = 0;

  while (current < end) {
    const afterNext = new Date(current);
    afterNext.setDate(afterNext.getDate() + 1);

    if (dateObjects[index] < afterNext) {
      while (dateObjects[index] < afterNext) {
        index++;
      }

      current.setDate(current.getDate() + 1);
    } else {
      datesToAdd.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
  }

  dateData.push(...datesToAdd
    .filter(date => date.getDay() !== 0 && date.getDay() !== 6)
    .map(dateObj => createDateData(toDateString(dateObj), true))
  );

  // Get all datetimes, ensure at least one of each day is present in the results
  if (allDateStrs) {
    const allDates = distinct(allDateStrs).map(dateStr => createDateData(dateStr, false));

    allDates.forEach(allDateObj => {
      const { date: allDate } = allDateObj;

      if (!dateData.find(({ date }) => allDate === date)) {
        dateData.push(allDateObj);
      }
    });
  }

  dateData.sort(sortDateData);

  return dateData.reduce((result, { date, dayOfWeek, ...timeData }) => {
    if (!result.length || result[result.length - 1].date !== date) {
      result.push({ date, dayOfWeek, times: [timeData] });
    } else {
      result[result.length - 1].times.push(timeData);
    }

    return result;
  }, []);
};

class WeeklyPlanCheckView extends connect(store)(LitElement) {
  static styles = [
    commonStyles,
    css`
      :host {
        display: flex !important;
        flex-flow: column;
      }
  
      .four-oh {
        width: 60%;
        text-align: center;
        padding: 32px 32px 48px;
        font-size: 1.1rem;
        margin: 72px auto auto;
        color: var(--neutral-2);
        max-width: 720px;
        background: white;
      }
  
      .four-oh.modal {
        padding: 48px 32px 48px;
        background: var(--neutral-8);
        border-radius: 5px;
        border: var(--neutral-7);
        box-shadow: 4px 4px 4px rgb(0 0 0 / 10%);
      }
  
      .four-oh.modal a {
        color: #3498DB;
        text-decoration: none;
        cursor: pointer;
      }
  
      .four-oh i {
        margin-top: 16px;
        color: var(--blue-3);
      }
  
      weekly-check-header {
        flex: 0;
        z-index: 9999;
      }
  
      main {
        color: var(--neutral-2);
        display: flex;
        flex-flow: row nowrap;
        flex: 1 1 auto;
        font-family: "Segoe UI", Helvetica, sans-serif;
        font-size: 13px;
        height: 0;
        background: var(--neutral-7);
      }
  
      patients-sidebar,
      sections-sidebar {
        display: flex;
        flex-flow: column nowrap;
        flex: 1;
        min-width: 225px;
        max-width: 250px;
        background: var(--light-color);
        border: solid var(--side-border);
        padding: 16px;
        overflow: auto;
      }
  
      patients-sidebar {
        border-width: 0 1px 0 0;
      }
  
      sections-sidebar {
        border-width: 0 0 0 1px;
      }
  
      sections-sidebar h2 {
        margin: 6px -16px 16px;
        padding: 0 16px 10px;
      }
  
      #patients-sidebar-wrapper,
      #sections-sidebar-wrapper,
      #document-viewer-wrapper,
      #dicom-viewer-wrapper,
      #sections-sidebar-outer-wrapper {
        display: flex;
        flex-flow: column nowrap;
        flex: 1;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        transition: min-width .5s, max-width .5s, padding .1s;
        max-width: 0;
        min-width: 0;
        width: 0;
      }
      
      #document-viewer-wrapper,
      #dicom-viewer-wrapper {
        flex: 4;
      }
  
      #sections-sidebar-outer-wrapper {
        overflow: visible;
        max-height: 100%;
      }
      
      #sections-sidebar-wrapper {
        overflow: hidden !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      
      #sections-sidebar-outer-wrapper:not([open]) {
        padding-left: 16px;
        cursor: pointer;
        border-left: 1px solid var(--side-border);
      }
  
      #patients-sidebar-wrapper[open],
      #sections-sidebar-wrapper[open],
      #document-viewer-wrapper[open],
      #dicom-viewer-wrapper[open],
      #sections-sidebar-outer-wrapper[open] {
        width: auto;
        min-width: 225px;
        max-width: 250px;
        overflow: visible;
        z-index: 9997;
      }
  
      #patients-sidebar-wrapper[open] {
        z-index: 9998;
      }
      
      #document-viewer-wrapper[open],
      #dicom-viewer-wrapper[open] {
        max-width: 50%;
      }
  
      weekly-check-patient {
        flex: 4;
        overflow: auto;
        padding: 16px;
      }
  
      #updating {
        position: absolute;
        background: rgba(0, 0, 0, .8);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10000;
      }
  
      #updating:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: 'Updating Links...';
        font-size: 2rem;
        color: #FFF;
      }
  
      patient-modal #container {
        padding: 24px;
      }
  
      patient-modal loading {
        animation: spin 2s steps(8, end) infinite;
        font-size: 3em;
      }
  
      patient-modal table {
        margin: 8px auto;
        padding: 4px;
        border: 1px solid var(--neutral-6);
      }
  
      patient-modal thead {
        background: var(--neutral-7);
      }
  
      patient-modal table th,
      patient-modal table td {
        padding: 4px 16px;
      }
  
      .error {
        position: relative;
      }
  
      .error .wrapper {
        position: absolute;
        top: 100%;
        z-index: 10000;
        max-height: 0;
        transition: max-height linear .3s;
        overflow: hidden;
        background: #FFF;
        left: 0;
        right: 0;
      }
  
      .error p:first-child {
        cursor: pointer;
      }
  
      .error[show-data] .wrapper {
        display: block;
        max-height: 500px;
        border-bottom: 1px solid #333;
      }
  
      .error .toggle {
        position: absolute;
        top: 16px;
        right: 16px;
        pointer-events: none;
      }
  
      .error .toggle:after {
        content: '\uf077';
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
      }
  
      .error[show-data] .toggle:after {
        content: "\uf078";
      }
  
      .error pre {
        margin: 16px 0 0;
        background: #F0F0F0;
        height: 300px;
        overflow: auto;
        padding: 16px;
      }
  
      .loading {
        display: none;
      }
  
      .loading[patients-loaded] {
        flex: 5;
        display: block;
        z-index: 99999;
      }
  
      .loading[patient-loaded], .loading[patients-loaded][patient-loaded] {
        flex: 1;
        display: block;
      }
  
      .loading span {
        font-size: 3em;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        text-align: center;
        vertical-align: middle;
      }
  
      .loading span .spinner {
        animation: spin 2s steps(8, end) infinite;
      }
  
      .hide-phi {
        padding: 4px 8px;
        font-family: "Segoe UI", Helvetica, sans-serif;
        cursor: pointer;
      }
      
      toggle-button {
        cursor: pointer;
        position: absolute;
        top: 16px;
        left: 0px;
        transform: translateX(-50%);
        border: 1px solid var(--side-border);
        background: var(--light-color);
        border-radius: 50%;
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        padding: 0 0 0 6px;
        width: 24px;
        height: 24px;
        z-index: 9999;
      }
      
      [open] toggle-button {
        opacity: 0;
        transition: .5s opacity;
        padding-left: 7px;
      }
      
      [open]:hover toggle-button {
        opacity: 1;
      }
      
      toggle-button:before {
        content: '\uf104';
        font-size: 16px;
        max-height: 17px;
      }
      
      [open] toggle-button:before {
        content: '\uf105';
      }
  
      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    `
  ].flat();

  static properties = {
    active: { type: Boolean },
    organization: { type: String },
    organizationId: { type: String },
    patientId: { type: String },
    patient: { type: Object },
    patients: { type: Array },
    totals: { type: Object },
    filters: { type: Object },
    machines: { type: Array },
    hospitals: { type: Array },
    departments: { type: Array },
    error: { type: String },
    patientsOpen: { type: Boolean },
    sectionsOpen: { type: Boolean },
    settings: { type: Object },
    updatingLinks: { type: Boolean },
    initialLoad: { type: Boolean },
    currentSection: { type: String },
    checkTemplates: { type: Array },
    currentTemplates: { type: Object },
    showTemplateSaveModal: { type: Boolean },
    showUpdatingPatientModal: { type: Boolean },
    showConfirmDefaultsModal: { type: Boolean },
    errorData: { type: Object },
    showErrorData: { type: Boolean },
    showPreTreatmentDates: { type: Boolean },
    showToast: { type: Boolean },
    noPatients: { type: Boolean },
    noOrganization: { type: Boolean },
    patientNotFound: { type: Boolean },
    hidePhi: { type: String },
    documentId: { type: String },
    documentType: { type: String },
    dockApprove: { type: Boolean },
    showRefreshingPatientModal: { type: Boolean },
    dicom: { type: Object }
  };

  get filteredPatients() {
    const { filters, patients, machines } = this;
    const passState = 'pass';
    const hasPassStateFilter = filters.states.includes(passState);
    const otherStateFilters = filters.states.filter(f => f !== passState);

    if (filters?.mode === FilterModes.PATIENT) {
      const patient = patients.find(({ id }) => id === filters.patientId);

      return patient ? [patient] : patients;
    }

    return patients
      ?.filter(p =>
        !filters.machineIds?.length
        // The filters.machineIds look like "machineId|departmentSer|hospitalId", so we have to split
        //  them apart and check each bit
        || filters.machineIds?.map(v => {
          const { 0: machineId } = v.split('|');
          const machineDepartmentSerAndHospitalId = v;

          return { machineId, machineDepartmentSerAndHospitalId };
        }).some(({ machineId, machineDepartmentSerAndHospitalId }) =>
          !p.machineDepartmentSerAndHospitalIds?.length
          || (p.machineIds.includes(machineId)
            && p.machineDepartmentSerAndHospitalIds.includes(machineDepartmentSerAndHospitalId))
          || (machines?.every(x => {
            const z = `${x.id}|${x.departmentSer}|${x.hospitalId}`;

            return !p.machineIds.includes(x.id) || !p.machineDepartmentSerAndHospitalIds.includes(z);
          }))
        )
      )
      ?.filter(p => !filters.hospitalIds?.length
        || filters.hospitalIds?.includes(p.hospitalId)
      )
      ?.filter(p => !filters.departmentSers?.length
        || filters.departmentSers?.includes(parseInt(p.departmentSer))
      )
      ?.filter(p => filters.statuses.includes(p.status)
        && (
          (p.status === 'initial'
            && (!filters.initialMoreThanFractions || p.fractionsToReview >= filters.initialFractionsCount))
          || (p.status === 'weekly'
            && (!filters.weeklyMoreThanFractions || p.fractionsToReview >= filters.weeklyFractionsCount))
          || (p.status !== 'initial' && p.status !== 'weekly')
        )
      )?.filter(p =>
        p.passStates?.some(s => otherStateFilters.includes(s.toLowerCase()))
        || (hasPassStateFilter
          && ((p.passStates?.length === 1 && p.passStates[0].toLowerCase() === passState) // only PASS
            || (p.passStates?.length === 2 // PASS and/or INDETERMINATE
              && p.passStates.find(ps => ps.toLowerCase() === passState)
              && p.passStates.find(ps => ps.toLowerCase() === 'indeterminate')
            )
          )
        )
      )?.filter(p =>
        !filters.billingEligible || p.status !== 'weekly' || p.isEligibleForBilling
      );
  }

  constructor() {
    super();

    this.requestNames = {};
    this.userFiltersLoaded = false;
    this.patientsOpen = true;
    this.sectionsOpen = true;
    this.settings = settingsSelector();
    this.currentTemplates = {};
    this.errors = [];
    this.buildFilters();

    window.onunhandledrejection = event => {
      const error = event.reason || event.message || true;
      this.error = error;
      this.errors.push(error);
      this.errorData = buildErrorData(this.error, this.errors);
    };

    this.hidePhi = getHidePhi();
    window.addEventListener('hide-phi', () => {
      this.hidePhi = getHidePhi();
    });
  }

  updated(changed) {
    const { patientId, patients, organization, organizationId, error, initialLoad, machines, hospitals,
      checkTemplates, departments } = this;

    if (error) return;

    if (changed.has('showPreTreatmentDates')) {
      this.generatePoints();
      this.requestUpdate();
    }

    if (changed.has('settings')) {
      this.buildFilters();
    }

    if (changed.has('active') && this.active && this.patient) {
      this.loadPatient(this.patient.id, true);
    }

    // Not changed.has('me') because sometimes they can happen about the same time and would get missed.
    if (this.me && this.verifyWhenReady) {
      this.verifyWhenReady = false;
      this.doVerifyChange(this.verifyParameters);
      this.verifyParameters = undefined;
    }

    if (changed.has('filters')) {
      const { filters, patientId } = this;
      const oldFilters = changed.get('filters') || {};

      if ((filters.mode === FilterModes.PATIENT
        && (filters.mode !== oldFilters.mode || filters.all !== oldFilters.all))
        || (
          filters.mode === FilterModes.DATE
          && filters.start
          && filters.end
          && (
            filters.mode !== oldFilters.mode
            || (oldFilters.start && oldFilters.start.toDateString()) !== filters.start.toDateString()
            || (oldFilters.end && oldFilters.end.toDateString()) !== filters.end.toDateString()
            || oldFilters.all !== filters.all
          )
        )
      ) {
        this.loadPatients();
      }

      if (filters.all !== oldFilters.all) {
        this.loadPatient(patientId, true);
      }
    }

    if (organization && organizationId && !initialLoad) {
      this.initialLoad = true;

      if (!patients) {
        this.loadPatients();
      }

      if (!machines) {
        this.loadMachines();
      }

      if (!hospitals) {
        this.loadHospitals();
      }

      if (!departments) {
        this.loadDepartments();
      }

      if (!checkTemplates) {
        this.loadCheckTemplates();
      }
    } else if (patientId && (!this.patient || this.patient.id !== patientId)) {
      this.loadPatient(patientId);
    }

    if (!this.plan || this.plan.patient !== this.patient) {
      this.updateComplete.then(() => {
        setTimeout(() => {
          const plan = this.shadowRoot.querySelector('weekly-check-patient');

          if (plan) {
            this.plan = plan;

            if (location.hash.length) {
              this.scrollToSection(location.hash.slice(1));
            }
          }
        }, 0);
      });
    }
  }

  render() {
    const { patient, patients, patientId, totals, filters, currentSection, machines, error, patientsOpen, hospitals,
      updatingLinks, checkTemplates, currentTemplates, showTemplateSaveModal, showPreTreatmentDates, hidePhi,
      showUpdatingPatientModal, filteredPatients, settings, sectionsOpen, showRefreshingPatientModal,
      noPatients, patientNotFound, noOrganization, documentId, documentType, departments, dockApprove,
      dicom, filters: { all: showAll },
      handleTemplateChange, handlePatientChange, handleSectionChange,
      handleFiltersChange, handleLinksChange, handleRefreshPatient, handleTogglePatients, handleLoadNextPatient,
      handleApprovePatient, handlePatientScroll, handleTemplateSave, handleTogglePreTreatmentDates,
      handleDocumentOpen, handleDocumentClose, handleToggleSectionsSidebar, handleVerifyChange,
      renderTemplateSaveModal, renderUpdatingPatientModal, renderError,
      renderNoPatients, renderPatientNotFound, renderNoOrganization, renderHidePhiHeader,
      renderRefreshingPatientModal
    } = this;

    if (error) {
      console.error(error);
    }

    if (noOrganization) {
      return renderNoOrganization();
    }

    return html`
    ${fontAwesome}
    ${h(hidePhi, renderHidePhiHeader)}
    ${h(error, renderError)}
    <weekly-check-header
      @toggle-patients=${handleTogglePatients}
    ></weekly-check-header>
    <main ?document-open=${documentId}>
      ${h(patients, html`
        ${updatingLinks ? html`<div id="updating"></div>` : ''}
          <div
            id="patients-sidebar-wrapper"
            ?open=${patientsOpen}
          >
            <patients-sidebar
              currentPatientId=${patientId}
              .patients=${filteredPatients}
              .totals=${totals}
              .filters=${filters}
              .machines=${machines}
              .hospitals=${hospitals}
              .departments=${departments}
              .minSearchLength=${MIN_PATIENT_SEARCH_LENGTH}
              @patient-change=${handlePatientChange}
              @filters-change=${handleFiltersChange}
            ></patients-sidebar>
          </div>
      `)}
      ${h(!error && !patientNotFound && (patientId !== patient?.id || !patients), html`
        <div class="loading" ?patients-loaded=${patients} ?patient-loaded=${patientId === patient?.id}>
          <span>
            <i class="spinner fa fa-spinner"></i>
            Loading
          </span>
        </div>
      `)}
      ${h(noPatients, renderNoPatients())}
      ${h(patientNotFound, renderPatientNotFound())}
      ${h(!noPatients && !patientNotFound && patient && patientId === patient?.id, () => html`
        <weekly-check-patient
          ?showAll=${showAll}
          .patient=${patient}
          .showPreTreatmentDates=${showPreTreatmentDates}
          .checkTemplates=${checkTemplates}
          .currentDicom=${dicom}
          @toggle-pre-treatment-dates=${handleTogglePreTreatmentDates}
          @refresh-patient=${handleRefreshPatient}
          @links-change=${handleLinksChange}
          @load-next-patient=${handleLoadNextPatient}
          @approve-patient=${handleApprovePatient}
          @scroll=${handlePatientScroll}
          @verified-change=${handleVerifyChange}
          @dicom-open=${this.handleDicomOpen}
          ?dock-approve=${dockApprove}
        ></weekly-check-patient>
        <div id="document-viewer-wrapper" ?open=${documentId}>
          <document-viewer
            .id=${documentId}
            .type=${documentType}
            @document-close=${handleDocumentClose}
          ></document-viewer>
        </div>
        <div id="dicom-viewer-wrapper" ?open=${dicom}>
          ${h(dicom, () => html`
            <image-viewer
              .patient=${patient}
              .planSer=${dicom.planSer}
              .fraction=${dicom.fraction}
              .groupIndex=${dicom.groupIndex}
              .dicomGroupData=${dicom.dicomGroupData}
              show-buttons
              @dicom-close=${this.handleDicomClose}
              @dicom-change=${this.handleDicomChange}
            ></image-viewer>
          `)}
        </div>
        <div
          id="sections-sidebar-outer-wrapper"
          ?open=${sectionsOpen}
          @click=${handleToggleSectionsSidebar}
        >
          <div
            id="sections-sidebar-wrapper"
            ?open=${sectionsOpen}
          >
            <sections-sidebar
              .sections=${topLevelSections}
              .courseSections=${courseSections}
              .planSections=${planSections}
              .patient=${patient}
              .checkTemplates=${checkTemplates?.filter(t => t.isLatest)}
              .currentTemplates=${currentTemplates}
              currentSection=${currentSection}
              .latestSettingsRevision=${settings.revisionNumber}
              .patientSettingsRevision=${patient.settingsRevision}
              @section-change=${handleSectionChange}
              @template-change=${handleTemplateChange}
              @template-save=${handleTemplateSave}
              @document-open=${handleDocumentOpen}
            ></sections-sidebar>
          </div>
          <toggle-button @click=${handleToggleSectionsSidebar}></toggle-button>
        </div>
      `)}
      ${h(showTemplateSaveModal, renderTemplateSaveModal())}
      ${h(showUpdatingPatientModal, renderUpdatingPatientModal())}
      ${h(showRefreshingPatientModal, renderRefreshingPatientModal())}
    </main>
  `;
  }

  renderHidePhiHeader = () => html`
    <div class="hide-phi" @click=${this.handleShowPhi}>
      PHI is currently being hidden so you can take screenshots or video. Click here to show PHI again.
    </div>
  `;

  renderNoPatients = () => html`
    <div class="four-oh">
      <i class="far fa-question-circle fa-3x"></i>
      <h1>No Patients Match Current Filters</h1>
      <p>
        There are <b>no patients</b> that match the currently selected filters.
      </p>
      <p>
        <span>←</span> Please adjust them to view patients.
      </p>
    </div>
  `;

  renderPatientNotFound = () => html`
    <div class="four-oh">
      <i class="far fa-question-circle fa-3x"></i>
      <h1>Patient Not Found</h1>
      <p>
        The patient you request <b>could not be found</b>.
      </p>
      <p>
        <span>←</span> Please use the sidebar to select a different patient.
      </p>
    </div>
  `;

  /* eslint-disable max-len */
  renderNoOrganization = () => html`
    <div class="four-oh modal">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ban" class="svg-inline--fa fa-ban fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="52px" height="52px">
        <path fill="#FF0000" d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"></path>
      </svg>
      <h1>Access Denied</h1>
      <p>
        Sorry, you don't have permission to view this organization.
      </p>
      <p>
        <a @click=${this.handleReturnToPrevious} id="back"><span>←</span> Return to the previous page</a>
      </p>
    </div>
  `;
  /* eslint-enable max-len */

  handleReturnToPrevious = () => {
    history.back();
  }

  renderError = () => {
    if (localStorage.getItem('killError')) return;

    const { errorData, showErrorData, handleDownloadErrorData, handleDownloadErrorCopy,
      handleErrorShowToggle } = this;

    return html`
      <div class="error" ?show-data=${showErrorData}>
        <p @click=${handleErrorShowToggle}>
          An error has occurred. Please refresh the page and try again.
        </p>
        <span class="toggle"></span>
        <div class="wrapper">
          <p>If contacting support, including this anonymized data will help diagnose the problem.</p>
          <button @click=${handleDownloadErrorData}>Download as File</button>
          <button @click=${handleDownloadErrorCopy}>Copy To Clipboard</button>
          <pre>${JSON.stringify(errorData, errorDataReplacer, 2)}</pre>
        </div>
      </div>
    `;
  };

  handleToggleSectionsSidebar = event => {
    event.stopPropagation();

    // Only allow clicking the main bar if we are closed.
    if (this.sectionsOpen && event.target.tagName.toLowerCase() !== 'toggle-button') return;

    this.sectionsOpen = !this.sectionsOpen;
  }

  handleDicomOpen = ({ detail }) => {
    this.closeDocument();

    this.dicom = detail;
    this.patientsOpenBeforeDocument = this.patientsOpen;
    this.sectionsOpenBeforeDocument = this.sectionsOpen;
    this.patientsOpen = false;
    this.sectionsOpen = false;
  };

  handleDicomClose = () => {
    this.closeDicom();
  }

  handleDicomChange = ({ detail }) => {
    this.dicom = detail;
  };

  closeDicom = () => {
    if (this.dicom) {
      this.patientsOpen = this.patientsOpen || this.patientsOpenBeforeDocument;
      this.sectionsOpen = this.sectionsOpen || this.sectionsOpenBeforeDocument;
    }

    this.dicom = null;
  }

  handleDocumentOpen = event => {
    const { detail: { id, type } } = event;

    this.documentId = id;
    this.documentType = type;
    this.patientsOpenBeforeDocument = this.patientsOpen;
    this.sectionsOpenBeforeDocument = this.sectionsOpen;
    this.patientsOpen = false;
    this.sectionsOpen = false;
  }

  handleDocumentClose = () => {
    this.closeDocument();
  }

  closeDocument = () => {
    if (this.documentId) {
      this.patientsOpen = this.patientsOpen || this.patientsOpenBeforeDocument;
      this.sectionsOpen = this.sectionsOpen || this.sectionsOpenBeforeDocument;
    }

    this.documentId = undefined;
    this.documentType = undefined;
  }

  handleShowPhi = () => {
    showPhi();
  }

  handleTogglePreTreatmentDates = () => {
    this.showPreTreatmentDates = !this.showPreTreatmentDates;
  };

  handleDownloadErrorData = () => {
    const { errorData } = this;

    download(JSON.stringify(errorData, errorDataReplacer, 2), `error-data-${Date.now()}.json`);
  };

  handleDownloadErrorCopy = () => {
    const element = this.shadowRoot.querySelector('.error pre');
    const input = document.createElement('textarea');

    document.body.appendChild(input);

    input.value = element.innerText;
    input.select();

    document.execCommand('copy');

    document.body.removeChild(input);
  };

  handleErrorShowToggle = () => {
    this.showErrorData = !this.showErrorData;
  };

  renderTemplateSaveModal = () => {
    const { handleTemplateSaveConfirm, handleTemplateSaveCancel } = this;

    return html`
    <patient-modal
      confirmText="Yes, update"
      cancelText="No, don't update"
      @confirm=${handleTemplateSaveConfirm}
      @cancel=${handleTemplateSaveCancel}
    >
      <h3>Are you sure?</h3>
      <p>Updating the check templates will rerun all checks for the affected plans.</p>
    </patient-modal>
  `;
  };

  renderUpdatingPatientModal = () => {
    return html`
      <patient-modal
        confirmText=""
        cancelText=""
      >
        <h3>Please wait...</h3>
        <p>Running checks using the selected check template</p>
        <loading class="fad fa-spinner"></loading>
      </patient-modal>
    `;
  };

  renderRefreshingPatientModal = () => {
    return html`
      <patient-modal
        confirmText=""
        cancelText=""
      >
        <h3>Please wait...</h3>
        <p>Refreshing patient with latest data from ARIA</p>
        <loading class="fad fa-spinner"></loading>
      </patient-modal>
    `;
  };

  handleConfirmDefaultsConfirm = () => {
    this.showConfirmDefaultsModal = false;
    this.showConfirmDefaultsModal = false;
  };

  handleTemplateSaveConfirm = () => {
    this.showTemplateSaveModal = false;
    this.showUpdatingPatientModal = true;

    this.assignTemplates();
  };

  assignTemplates = (autoAssignedOnly = false, backgroundRequest = false) => {
    const { currentTemplates, patient } = this;

    const toUpdate = Object.assign(
      {},
      entriesToObject(
        patient.courses.flatMap(c => c.plans)
          .filter(p => p.checkTemplate.wasAutoAssigned)
          .map(plan => [plan.ser, { id: plan.checkTemplate.id }])
      ),
      !autoAssignedOnly ? entriesToObject(
        patient.courses.flatMap(({ plans }) =>
          plans.map(plan =>
            [plan.ser, { id: (currentTemplates || {})[plan.ser]?.id || plan.checkTemplate.id }]
          )
        )
      ) : {}
    );

    const name = http.post('./weekly-check/api/{organization}/patient/{patientId}/check-templates', {
      name: backgroundRequest ? 'background-assign' : undefined,
      body: Object.entries(toUpdate)
        .map(([planSer, template]) => ({
          planSer: parseInt(planSer),
          templateId: template.id
        }))
    });

    if (!backgroundRequest) {
      this.requestNames.saveTemplates = name;
    } else {
      this.requestNames.saveTemplatesBackground = name;
    }
  };

  handleTemplateSaveCancel = () => {
    this.showTemplateSaveModal = false;
  };

  handleTemplateChange = ({ detail: currentTemplates }) => {
    this.currentTemplates = currentTemplates;
  };

  handleTemplateSave = () => {
    this.showTemplateSaveModal = true;
  };

  handlePatientScroll = () => {
    const parent = this.shadowRoot.querySelector('weekly-check-patient');
    const padding = 10;

    if (!parent) return;

    const { top: parentTop } = parent.getBoundingClientRect();

    const sections = [...querySelectorAllShadow(parent, '[section]')]
      .filter(element => element.getBoundingClientRect().height > 0)
      .map(element => ({ id: element.id, top: element.getBoundingClientRect().top }));

    const current = sections.reduce(
      ({ id: currentId, top: currentTop }, { id, top }) =>
        top - parentTop <= padding && top > currentTop ? { id, top } : { id: currentId, top: currentTop },
      { top: Number.MIN_SAFE_INTEGER }
    );

    this.dockApprove = false;
    this.currentSection = current.id;
  };

  handleTogglePatients = () => {
    this.patientsOpen = !this.patientsOpen;
  };

  handleFiltersChange = async ({ detail: { filters } }) => {
    this.filters = filters;

    // Update the popout URL if it is non-null and not set to blank and has showAll in it
    const popoutUrl = await getPopoutUrl(DICOM_POPOUT_TARGET);

    if (popoutUrl?.match(/showAll=[01]/)) {
      await setPopoutUrl(DICOM_POPOUT_TARGET, popoutUrl.replace(/showAll=[01]/, `showAll=${filters.all ? 1 : 0}`));
    }

    if (this.userFiltersLoaded) {
      this.saveUserFilters(filters);
    }
  };

  changePatient = patientId => {
    this.blankDicomPopoutInBetweenPatients(patientId); 

    this.currentSection = undefined;

    router.navigate(
      generate(Routes.WeeklyCheckPatient, { patient: patientId })
    );

    this.scrollToTop();
  };

  handlePatientChange = ({ detail: { patientId } }) => {
    this.changePatient(patientId);
  };

  blankDicomPopoutInBetweenPatients = patientId => {
    const blankUrl = getBlankPopoutUrl(patientId);
    // if (window.openDicomPopout && !window.openDicomPopout.closed) openInPopout(blankUrl, DICOM_POPOUT_TARGET);
    setPopoutUrl(DICOM_POPOUT_TARGET, blankUrl);
  }

  handleSectionChange = ({ detail: { sectionId } }) => {
    history.pushState(
      '',
      '',
          `${location.protocol}//${location.host}${location.pathname}#${sectionId}`
    );

    this.scrollToSection(sectionId);
  };

  handleLinksChange = ({ detail: { links, courseSer } }) => {
    const { patient: { ser: patientSer } } = this;
    this.updatingLinks = true;

    this.requestNames.patientLink = http.put('./weekly-check/api/{organization}/patient/{patientId}/link', {
      body: {
        patientSer,
        courseSer,
        links
      }
    });
  };

  handleRefreshPatient = () => {
    const id = this.patientId;

    if (!id || !this.organization) return;

    this.closeDocument();

    const name = id;

    this.showRefreshingPatientModal = true;

    http.post('./weekly-check/api/{organization}/patient/{id}/updateData', {
      name,
      pathParams: { id }
    }, true);
  };

  handleLoadNextPatient = () => {
    const { patient, getNextPatientInStatus, filters: { statuses: currentStatusFilters }, filteredPatients } = this;

    const sortedStatusFilters = statusFilters.filter(status => currentStatusFilters.includes(status));
    const patientsByStatus = sortByStatus(filteredPatients);
    const currentStatus = patient.status || 'weekly';
    let statusIndex = sortedStatusFilters.findIndex(s => s.toLowerCase() === currentStatus.toLowerCase());

    while (statusIndex < sortedStatusFilters.length) {
      const patientId = getNextPatientInStatus(patientsByStatus[sortedStatusFilters[statusIndex]]);

      if (patientId) {
        this.changePatient(patientId);
        return;
      }

      statusIndex++;
    }

    this.loadPatients();
  };

  getNextPatientInStatus = patientStatusSet => {
    const { patient } = this;

    const patientIndex = patientStatusSet.findIndex(p => p.id === patient.id);

    if (patientIndex + 1 <= patientStatusSet.length - 1) {
      return patientStatusSet[patientIndex + 1].id;
    }

    return false;
  };

  handleApprovePatient = () => {
    const { patient } = this;

    if (patient?.courses.flatMap(c => c.plans).filter(p => p.checkTemplate.wasAutoAssigned).length) {
      this.assignTemplates(true, true);
      return;
    }

    this.doApprovePatient();
  };

  handleVerifyChange = ({ detail }) => {
    addToast('Saving verify...', { id: verifyToastId, timeout: 1000 });
    store.dispatch(disableVerify());

    if (!this.me) {
      this.verifyWhenReady = true;
      this.verifyParameters = detail;
    } else {
      this.doVerifyChange(detail);
    }
  };

  doVerifyChange = ({ key, field, index, indexed, tooltip, value, passState, checked }) => {
    const verification = {
      key,
      field,
      index: indexed ? index.toString() : undefined,
      tooltipText: tooltip,
      value: JSON.stringify(value),
      passState,
      verifier: `${this.me.displayName}`,
      dateTime: new Date()
    };

    const newVerifications = (!checked)
      ? removeValueMatching(
        this.patient.verifications,
        v => v.key === key && v.field === field && (!indexed || v.index === index)
      ) : [...this.patient.verifications, verification];

    this.patient = updateErrorCounts({ ...this.patient, verifications: newVerifications });

    this.requestNames.saveVerify =
      http.post(`./weekly-check/api/{organization}/patient/{patientId}/${checked ? 'verify' : 'deverify'}`, {
        body: verification
      });
  };

  doApprovePatient = () => {
    if (this.disableApprove) return;

    const { patient } = this;
    const comment = this.shadowRoot.querySelector('weekly-check-patient')
      .shadowRoot.querySelector('#approval-comment').value;

    const body = {
      comment,
      patientSer: patient.ser,
      fractions:
              patient.courses.flatMap(course =>
                course.plans.map(plan =>
                  ({
                    planSer: plan.ser,
                    planId: plan.id,
                    fraction: Math.max(...plan.treatments.map(t => t.fraction)),
                    event: Math.max(...plan.treatments.map(t => t.event)),
                    lastRadiationHstrySer: Math.max(...plan.treatments.map(t => t.lastRadiationHstrySer)),
                    checkTemplateId: plan.checkTemplate.id,
                    checkTemplateName: plan.checkTemplate.name,
                    checkTemplateRevisionNumber: plan.checkTemplate.revisionNumber,
                    courseId: course.id,
                    startFraction: patient.minFractionByPlan[plan.ser]
                  })
                )
              ).filter(a => a.fraction && a.fraction > 0) // filter out plans which have no fractions treated
    };

    this.requestNames.patientApprove =
      http.post('./weekly-check/api/{organization}/patient/{patientId}/approve', { body });
    this.disableApprove = true;
    this.backgroundPatientSer = patient.ser;
  };

  loadPatient(id, force = false) {
    if (!id || !this.organization) return;

    const { filters } = this;

    this.closeDocument();

    const name = id;

    http.get('./weekly-check/api/{organization}/patient/{id}', {
      name,
      pathParams: { id },
      query: { showAll: filters.all || getQueryParam('all') ? 1 : 0 }
    }, force);
  }

  loadPatients(force = false) {
    const { filters = {}, organization } = this;
    const query = { showAll: filters.all ? '1' : '0' };

    if (!organization) return;

    if (filters.mode === FilterModes.DATE && filters.start && filters.end) {
      Object.assign(query, {
        start: dateToString(filters.start),
        end: dateToString(filters.end)
      });
    }

    this.requestNames.patients =
      http.get('./weekly-check/api/{organization}/patients', { query, name: 'load-patients' }, force);
  }

  loadMachines() {
    this.requestNames.machines = http.get('./weekly-check/api/{organization}/machines');
  }

  loadHospitals() {
    this.requestNames.hospitals = http.get('./weekly-check/api/{organization}/hospitals');
  }

  loadDepartments() {
    this.requestNames.departments = http.get('./weekly-check/api/{organization}/departments');
  }

  loadCheckTemplates() {
    this.requestNames.checkTemplates = http.get('./weekly-check/api/{organization}/check-templates?all=1');
  }

  loadUserFilters() {
    this.requestNames.userFilters = http.get('./weekly-check/api/{organization}/user-filters');
  }

  scrollToTop() {
    const { plan } = this;

    if (!plan) return;

    const container = plan.shadowRoot.querySelector('#main-wrapper');

    container.scrollLeft = 0;
    container.scrollTop = 0;
  }

  scrollToSection(sectionId) {
    if (this.scrollQueue) {
      this.scrollQueue.abort();
    }

    this.scrollQueue = queue(() => {
      this.doScrollToSection(sectionId);
    }, {
      element: this,
      time: 100,
      name: 'scroll to section'
    }, () => {
      const section = this.getSectionForScroll(sectionId);
      const header = this.shadowRoot.querySelector('weekly-check-header');

      return section && header;
    }).start();
  }

  getSectionForScroll = sectionId => {
    const { plan } = this;

    if (sectionId === 'top') return this;

    if (/^course\d+-plan\d+-/.test(sectionId)) {
      const [, planSectionId] = sectionId.match(/^(course\d+-plan\d+)-(.*)$/);
      const planSection = plan.shadowRoot.querySelector(`#${planSectionId}`);

      if (planSection) {
        return (planSection.shadowRoot || planSection).querySelector(`#${sectionId}`);
      }
    } else {
      return plan.shadowRoot.querySelector(`#${sectionId}`);
    }
  };

  doScrollToSection = (sectionId, moreShiftCount = 0) => {
    const { plan } = this;

    const target = plan.shadowRoot.querySelector('#main-wrapper');
    const section = this.getSectionForScroll(sectionId);

    if (!section) throw new Error('Unable to find section.');

    const stepTime = 10;
    const timeToTarget = 200;
    const distanceToTarget = section.offsetTop - 9 - target.scrollTop;
    const direction = distanceToTarget > 0 ? 1 : -1;
    const stepDistance = Math.max(1, Math.abs((stepTime / timeToTarget) * distanceToTarget));

    let remaining = Math.abs(distanceToTarget);

    const step = () => {
      target.scrollTop = Math.max(0, target.scrollTop + (direction * Math.min(remaining, stepDistance)));

      remaining -= Math.min(remaining, stepDistance);

      if (remaining > 0) {
        this.timeoutId = setTimeout(step, stepTime);
      } else {
        setTimeout(() => {
          if (this.currentSection !== sectionId && moreShiftCount < 2) {
            this.doScrollToSection(sectionId, moreShiftCount + 1);
          }
        }, 1);
      }
    };

    clearTimeout(this.timeoutId);
    step();
  };

  buildFilters() {
    this.loadUserFilters();

    if (!this.filters) {
      this.filters = defaultFilters;
    }
  }

  saveUserFilters(filters) {
    this.requestNames.saveUserFilters = http.post('./weekly-check/api/{organization}/user-filters', {
      body: {
        dateOption: filters.dateOption.key,
        startDate: filters.start,
        endDate: filters.end,
        statuses: filters.statuses,
        initialMoreThanFractions: filters.initialMoreThanFractions,
        weeklyMoreThanFractions: filters.weeklyMoreThanFractions,
        hospitalIds: filters.hospitalIds,
        machineIds: filters.machineIds,
        departmentSers: filters.departmentSers,
        initialFractionsCount: filters.initialFractionsCount,
        weeklyFractionsCount: filters.weeklyFractionsCount,
        billingEligible: filters.billingEligible
      }
    }, true);
  }

  updatePatients = patientsData => {
    const { totals, patients } = patientsData;

    if (this.patients === patients) return;

    this.patients = patients;
    this.totals = totals;

    if (!this.patientId) {
      let patient = getCookie(LAST_PATIENT_COOKIE);

      if (!patient) {
        const [firstWeeklyPatient] =
          ['initial', 'weekly', 'endOfTreatment', 'completed'].flatMap(status =>
            this.filteredPatients
              .filter(p => p.status === status)
              .sort(byName)
          );

        patient = firstWeeklyPatient?.id;
      }

      if (!patient) {
        this.noPatients = true;
        return;
      }

      router.navigate(generate(Routes.WeeklyCheckPatient, { patient }));
    }
  };

  updatePatient = episodicPatientData => {
    let patientData;

    this.noPatients = false;
    this.patientNotFound = false;

    if (episodicPatientData === this.episodicPatient) return;

    cache.purgeCache(); // purge the cornerstone cache

    this.showPreTreatmentDates = false;
    this.episodicPatient = episodicPatientData;

    try {
      patientData = updateErrorCounts(mapEpisodicPatientData(episodicPatientData));
    } catch (ex) {
      console.error(ex);
      return;
    }

    this.patient = patientData;
    this.disableApprove = false;

    this.showConfirmDefaultsModal = patientData.courses.flatMap(c => c.plans)
      .filter(p => p.checkTemplate.wasAutoAssigned)
      .length > 0;

    this.generatePoints();

    store.dispatch(updatePatient(this.patient));

    const expiration = new Date();
    expiration.setMinutes(expiration.getMinutes() + 30);
    document.cookie = `${LAST_PATIENT_COOKIE}=${patientData.id};expires=${expiration.toUTCString()}`;
    this.saveStickyFilters();
  };

  saveStickyFilters = () => {
    const { filters } = this;

    const expiration = new Date();
    expiration.setMinutes(expiration.getMinutes() + 30);

    document.cookie = `${LAST_FILTERS_COOKIE}=${filters.states?.join(',') ?? ''};expires=${expiration.toUTCString()}`;
  };

  generatePoints = () => {
    const { patient: patientData, showPreTreatmentDates } = this;

    this.currentTemplates = {};

    patientData.courses.forEach(course => {
      const { plans = [], documentations = [] } = course;

      const treatmentDates = plans.flatMap(({ treatments = [] }) =>
        treatments.map(({ episodeDateTime }) => episodeDateTime));
      const imagingDates = plans.flatMap(({ imagings = [] }) =>
        imagings.map(({ episodeDateTime }) => episodeDateTime));
      const documentationDates = documentations.map(({ episodeDateTime }) => episodeDateTime);

      const allCourseDates = [...treatmentDates, ...imagingDates, ...documentationDates];
      course.treatmentDates = buildDates(treatmentDates, allCourseDates);
      course.imagingDates = buildDates(imagingDates, allCourseDates);
      course.documentationDates = buildDates(documentationDates, allCourseDates);

      const firstTime = new Date(Math.min(
        ...treatmentDates.map(d => new Date(d).getTime()), ...imagingDates.map(d => new Date(d).getTime())
      ));

      course.firstDate = new Date(firstTime);
      course.firstDate.setHours(0);
      course.firstDate.setMinutes(0);
      course.firstDate.setSeconds(0);
      course.firstDate.setMilliseconds(0);

      plans.forEach(plan => {
        plan.treatmentCouchLines = generateCouchLines(
          plan.treatments,
          ['couchVerticalShift', 'couchLongitudeShift', 'couchLatitudeShift'],
          maybeFilterDates(!showPreTreatmentDates, course.treatmentDates, course.firstDate)
            .reduce(filterForSkippedDates(plan.skipTreatmentTimes), [])
        );

        plan.imagingCouchLines = generateCouchLines(
          plan.imagings,
          ['verticalShift', 'longitudinalShift', 'lateralShift'],
          maybeFilterDates(!showPreTreatmentDates, course.imagingDates, course.firstDate)
            .reduce(filterForSkippedDates(plan.skipImagingTimes), [])
        );

        const { lines: imagingLines, mode: imagingMode } = generateAngleLines(
          plan.imagings,
          ['yawAngle', 'pitchAngle', 'rollAngle'],
          maybeFilterDates(!showPreTreatmentDates, course.imagingDates, course.firstDate)
            .reduce(filterForSkippedDates(plan.skipImagingTimes), [])
        );

        plan.imagingAngleLines = imagingLines;
        plan.imagingAngleMode = imagingMode;

        const { lines: treatmentLines, mode: treatmentMode } = generateAngleLines(
          plan.treatments,
          ['couchYawAngleShift', 'couchPitchAngleShift', 'couchRollingAngleShift'],
          maybeFilterDates(!showPreTreatmentDates, course.treatmentDates, course.firstDate)
            .reduce(filterForSkippedDates(plan.skipTreatmentTimes), [])
        );

        plan.treatmentAngleLines = treatmentLines;
        plan.treatmentAngleMode = treatmentMode;
        plan.imagingCouchShow = plan.imagingCouchLines.some(({ points }) =>
          points.filter(point => Boolean(point) && (Boolean(point.value) || point.value === 0)).length);
        plan.treatmentCouchShow = plan.treatmentCouchLines.some(({ points }) =>
          points.filter(point => Boolean(point) && (Boolean(point.value) || point.value === 0)).length);
      });

      course.documentationWeightUnitOfMeasure = getValue(documentations
        ?.find(({ weights }) => weights?.length && !weights.isInactive)?.weights[0])?.split(' ')[1];

      course.documentationWeightLine = generateWeightLine(
        course.documentations,
        ['weights'],
        maybeFilterDates(!showPreTreatmentDates, course.documentationDates, course.firstDate)
      );

      course.documentationWeightShow = course.documentationWeightLine.some(({ points }) =>
        points.filter(point => Boolean(point) && (Boolean(point.value) || point.value === 0)).length);
    });
  };

  checkCurrentPatient() {
    const { patient } = this;

    this.requestNames.checkPatient = http.get(`./weekly-check/api/check/{organizationId}/${patient.ser}`);
  }

  checkPatientBackground() {
    const { backgroundPatientSer } = this;

    if (!backgroundPatientSer) return;

    this.requestNames.checkPatientBackground = http.get(
          `./weekly-check/api/check/{organizationId}/${backgroundPatientSer}`,
          { name: `check-background-${backgroundPatientSer}` }
    );
  }

  updateUserFilters(userFilters) {
    const dateOption = DateOptions[userFilters.dateOption];

    this.filters = Object.keys(userFilters).length > 0 ? {
      ...this.filters,
      patientId: null,
      mode: FilterModes.DATE,
      dateOption: dateOption,
      start: userFilters.dateOption === 'CUSTOM' ? new Date(userFilters.startDate) : daysAgo(-dateOption.start),
      end: userFilters.dateOption === 'CUSTOM' ? new Date(userFilters.endDate) : daysAgo(-dateOption.end),
      statuses: userFilters.statuses || [],
      initialMoreThanFractions: userFilters.initialMoreThanFractions,
      weeklyMoreThanFractions: userFilters.weeklyMoreThanFractions,
      a4444ll: getQueryParam('all') ?? false,
      states: getCookie(LAST_FILTERS_COOKIE)?.split(',') ?? stateFilters,
      departmentSers: userFilters.departmentSers,
      hospitalIds: userFilters.hospitalIds,
      machineIds: userFilters.machineIds,
      weeklyFractionsCount: userFilters.weeklyFractionsCount,
      initialFractionsCount: userFilters.initialFractionsCount,
      billingEligible: userFilters.billingEligible
    } : defaultFilters;

    this.userFiltersLoaded = true;
  }

  stateChanged(state) {
    const organization = organizationSelector(state);

    this.settings = settingsSelector(state);
    this.noOrganization = !this.settings && settingsLoadedSelector(state);

    if (!organization) {
      if (organizationSlugSelector(state)) {
        router.navigate(generate(
          Routes.WeeklyCheckOrganization,
          { organization: organizationSlugSelector(state) }
        ));
      }

      return;
    }

    const { requestNames, updatePatients, updatePatient } = this;
    const patientId = patientIdSelector(state);

    if (this.patientId !== patientId) {
      this.loadPatient(patientId);
      this.patientNotFound = false;
    }

    // allow reuse of earlier request
    const patientRequest = http.getRequest(patientId, state);
    const patientsRequest = http.getRequest(requestNames.patients, state);
    const machinesRequest = http.getRequest(requestNames.machines, state);
    const hospitalsRequest = http.getRequest(requestNames.hospitals, state);
    const departmentsRequest = http.getRequest(requestNames.departments, state);
    const approveRequest = http.getRequest(requestNames.patientApprove, state);
    const linkRequest = http.getRequest(requestNames.patientLink, state);
    const checkTemplatesRequest = http.getRequest(requestNames.checkTemplates, state);
    const saveTemplatesRequest = http.getRequest(requestNames.saveTemplates, state);
    const checkPatientRequest = http.getRequest(requestNames.checkPatient, state);
    const userFiltersRequest = http.getRequest(requestNames.userFilters, state);
    const checkPatientBackgroundRequest = http.getRequest(requestNames.checkPatientBackground, state);
    const saveTemplatesBackgroundRequest = http.getRequest(requestNames.saveTemplatesBackground, state);
    const saveVerifyRequest = http.getRequest(requestNames.saveVerify, state);

    patientsRequest?.ok && patientsRequest.data && updatePatients(patientsRequest.data);

    if (patientRequest?.completed) {
      this.showRefreshingPatientModal = false;

      if (patientRequest?.ok && patientRequest.data) {
        updatePatient(patientRequest.data);
      } else {
        this.patientNotFound = true;
      }
    }

    if (machinesRequest?.ok) {
      this.machines = machinesRequest.data
        .sort(sortBy('id'));

      if (!this.filters.machineIds?.length) {
        this.filters = { ...this.filters,
          machineIds: this.machines.map(({ id, departmentSer, hospitalId }) =>
           `${id}|${departmentSer}|${hospitalId}`) };
      }

      http.flush(machinesRequest);
    }

    if (departmentsRequest?.ok) {
      this.departments = departmentsRequest.data
        .sort(sortBy('departmentName', 'departmentId'));

      if (!this.filters.departmentSers?.length) {
        this.filters = { ...this.filters, departmentSers: this.departments.map(({ departmentSer }) => departmentSer) };
      }

      http.flush(departmentsRequest);
    }

    if (hospitalsRequest?.ok) {
      this.hospitals = hospitalsRequest.data
        .sort(sortBy('id'));

      if (!this.filters.hospitalIds?.length) {
        this.filters = { ...this.filters, hospitalIds: this.hospitals.map(({ id }) => id) };
      }

      http.flush(hospitalsRequest);
    }

    if (checkTemplatesRequest?.ok) {
      this.checkTemplates = checkTemplatesRequest.data;
      this.currentTemplates = {};

      http.flush(checkTemplatesRequest);
    }

    if (approveRequest?.ok) {
      this.handleLoadNextPatient();
      this.checkPatientBackground();

      this.billingSent = approveRequest.data.billingSent;

      http.flush(approveRequest);
    }

    if (saveTemplatesBackgroundRequest?.ok) {
      this.doApprovePatient();

      http.flush(saveTemplatesBackgroundRequest);
    }

    if (saveVerifyRequest?.completed) {
      store.dispatch(enableVerify());

      if (saveVerifyRequest?.ok) {
        addToast('Verify saved.', { id: verifyToastId });
      } else {
        addToast('Error saving verify. Please try again.', { id: verifyToastId, color: 'var(--bad-color)' });
      }

      http.flush(saveVerifyRequest);
    }

    if (checkPatientBackgroundRequest?.ok) {
      this.loadPatients(true);
      this.billingSent && addToast('Success! The appointment will now be sent to ARIA.');
      this.billingSent = false;

      http.flush(checkPatientBackgroundRequest);
    }

    if (linkRequest?.ok) {
      this.checkCurrentPatient();
    }

    if (saveTemplatesRequest?.ok) {
      this.checkCurrentPatient();
    }

    if (checkPatientRequest?.completed) {
      this.showUpdatingPatientModal = false;

      if (checkPatientRequest?.ok) {
        this.updatingLinks = false;
        this.loadPatient(patientId, true);
      } else {
        window.dispatchEvent(new PromiseRejectionEvent('unhandledrejection', {
          promise: Promise.reject(new Error('Patient could not be checked.'))
        }));
      }
    }

    if (userFiltersRequest?.ok) {
      this.updateUserFilters(userFiltersRequest.data);
      http.flush(userFiltersRequest);
    }

    Object.assign(this, {
      organization,
      patientId,
      organizationId: organizationIdSelector(state),
      settings: settingsSelector(state),
      me: meSelector(state)
    });
  }
}

customElements.define(
  'weekly-plan-check-view',
  canAccess(() => true)(WeeklyPlanCheckView)
);