import { QUEUE, LOADING, DONE, CAN_LOAD } from './types.js';

export const canLoad = () => dispatch =>
  dispatch({
    type: CAN_LOAD
  });

export const queue = ({ planSer, fraction, groupIndex, priority = 10000 }) => dispatch =>
  dispatch({
    type: QUEUE,
    planSer,
    fraction,
    groupIndex,
    priority
  });

export const loadStarted = ({ planSer, fraction, groupIndex }) => dispatch =>
  dispatch({
    type: LOADING,
    planSer,
    fraction,
    groupIndex
  });

export const loadDone = ({ planSer, fraction, groupIndex }) => dispatch =>
  dispatch({
    type: DONE,
    planSer,
    fraction,
    groupIndex
  });