﻿import { html, css, LitElement } from 'lit';
import adminStyles from '../styles-admin';
import '../auth-header';
import fontAwesome from '../../../../utils/font-awesome';
import { buildRelationshipTree } from '../utils';
import '../../../common/rad-confirm';
import '../../../common/check-box';
import { formToObject } from '../../../../utils/dom';
import { pad } from '../../../../utils/string';
import { removeValue, replaceValue } from '../../../../utils/immutable/array';
import { mapByKey } from '../../../../utils/immutable/array';
import http from '../../../../utils/redux/http';
import { connect } from '../../../../utils/redux';
import { store } from '../../../../redux/store';
import defer from '../../../../utils/defer';
import h from '../../../../utils/h';
import { organizationSelector, organizationSlugSelector } from '../../../../redux/app/selectors.js';
import router, { generate } from '../../../../router.js';
import { Routes } from '../../../../routes.js';

// eslint-disable-next-line max-len
const EMAIL_PATTERN = '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])';
const EMAIL_REGEX = new RegExp(EMAIL_PATTERN);
const OVERLAY_CLEAR_DELAY = 3000; // ms before overlay starts to fade
const OVERLAY_CLEAR_FADE = 1000; // ms it takes to fade once it starts

const removeAtFromEmail = email =>
  email.split('@')[0];

const buildDescendants = (organizationId, organizations) => {
  const { children } = organizations.find(({ id }) => organizationId === id) || {};

  return children
    ? children.flatMap(child => [child, ...buildDescendants(child, organizations)])
    : [];
};

class UsersView extends connect(store)(LitElement) {
  static styles =
    [
      adminStyles,
      css`
        :host {
          position: relative;
        }
    
        main {
          display: flex;
        }
    
        rad-confirm {
          --min-width: 400px;
        }
    
        rad-confirm:not([active]) {
          display: none;
          background: #F00;
        }
    
        #user-list {
          border-right: 1px solid var(--side-border);
          position: relative;
        }
    
        #user-list .existing-wrapper {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 30%;
          overflow: auto;
          padding: 16px;
        }
    
        #user-list .add-delete-wrapper {
          position: absolute;
          left: 0;
          right: 0;
          top: 70%;
          bottom: 0;
          overflow: auto;
          padding: 16px;
        }
    
        #user-list .updated-wrapper {
          position: absolute;
          left: 0;
          right: 0;
          top: 75%;
          bottom: 0;
          overflow: auto;
          padding: 16px;
        }
    
        #user-list h2 {
          border: solid var(--neutral-6);
          border-width: 0 0 1px;
          padding: 0 16px 10px;
          margin: 0 -16px 16px;
          font-size: 18px;
          line-height: 1.3333333;
        }
    
        #user-list #search-button {
          position: absolute;
          top: 18px;
          right: 16px;
          z-index: 6000;
          transition: right .2s linear;
        }
    
        #user-list [show] #search-button {
          right: 20px;
        }
    
        #user-list #search {
          left: calc(100% - 16px);
          z-index: 5500;
          position: absolute;
          top: 16px;
          right: 16px;
          width: auto;
          transition: left .2s linear;
          overflow: hidden;
        }
    
        #user-list #search input {
          padding: 4px 8px;
        }
    
        #user-list [show] #search {
          left: 16px;
        }
    
        #user-list select {
          max-width: 100%;
          padding: 4px 8px;
          margin-bottom: 16px;
        }
    
        #user-list ul {
          list-style: none;
          padding: 0;
          margin: 0;
          flex: 1;
        }
    
        #user-list li {
          font-size: 14px;
          line-height: 1.2;
          margin: 0 -16px;
          padding: 6px 16px;
          position: relative;
        }
    
        #user-list li:hover,
        #user-list li[active] {
          cursor: pointer;
          background: var(--neutral-6);
        }
    
        #user-list span.email {
          display: block;
          font-size: 10px;
          color: var(--neutral-5);
        }
    
        #user-list li:hover span.email,
        #user-list li[active] span.email {
          color: var(--neutral-3);
        }
    
        .error-mark,
        #user-list li[has-errors]:after {
          font-family: 'Font Awesome 5 Pro';
          content: "\uf12a"; /* ! */
          font-weight: 900;
          color: var(--bad-color);
          position: absolute;
          right: 16px;
          top: 10px;
          font-size: 1.1rem;
        }
    
        #user-list .button {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 8px 16px;
          background: var(--primary-color);
          color: var(--neutral-10);
          display: block;
          border: 0;
          z-index: 5000;
          text-align: center;
          font-size: 26px;
        }
    
        #user-list .button:hover {
          cursor: pointer;
          background: var(--primary-1);
        }
    
        #user {
          position: relative;
        }
    
        #user h1 {
          font-size: 40px;
          font-weight: 600;
        }
    
        .remove {
          width: 1px;
          white-space: nowrap;
        }
    
        td:nth-child(1),
        td:nth-child(2) {
          text-align: left;
        }
    
        td.all {
          color: var(--neutral-5);
        }
    
        .row {
          margin-bottom: 20px;
        }
    
        label, button {
          display: inline-block;
        }
    
        label {
          margin-right: 20px;
        }
    
        label#email {
          width: 300px;
        }
    
        button {
          background: var(--primary-color);
          color: var(--neutral-10);
          border: 0;
        }
    
        #reset-password {
          margin-left: 336px;
        }
    
        #reset-password:hover {
          cursor: pointer;
          background: var(--neutral-1);
        }
    
        #add-role {
          background: 0;
          padding: 0;
          color: var(--neutral-1);
        }
    
        #add-role:hover {
          cursor: pointer;
          color: var(--primary-color);
        }
    
        input {
          display: block;
          width: 100%;
          padding: 8px;
        }
    
        check-box {
          position: relative;
          top: 5px;
          margin-left: 20px;
        }
    
        .banner {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background: var(--neutral-3);
          color: var(--neutral-9);
          padding: 4px 16px;
        }
    
        .fa-trash-alt:hover {
          cursor: pointer;
          color: var(--primary-color);
        }
    
        .fa-plus:hover {
          cursor: pointer;
          color: var(--primary-color);
        }
    
        .fa-sync-alt:hover {
          cursor: pointer;
          color: var(--primary-color);
        }

        .error {
          color: #900;
        }
      `
    ].flat();

  static properties = {
    users: { type: Array },
    searchUsers: { type: Array },
    user: { type: Object },
    roles: { type: Object },
    updatedUsers: { type: Array },
    currentUserId: { type: String },
    currentOrganizationId: { type: String },
    organizations: { type: Object },
    showSaved: { type: Boolean },
    relationshipTree: { type: Array },
    showResetPasswordModal: { type: Boolean },
    showOverlay: { type: Boolean },
    showAddRoleModal: { type: Boolean },
    overlayMessage: { type: String },
    showSearch: { type: Boolean },
    searchTerm: { type: String },
    updatedCurrentUser: { type: Object },
    showDeleteUserModal: { type: Boolean },
    deletedUsers: { type: Array },
    addOrgSelectedOrg: { type: String },
    addOrgAvailableRoles: { type: Array },
    appUsers: { type: Array }
  };

  updated(changed) {
    const { validateUser, updateComplete } = this;

    if (changed.has('currentUserId') && changed.get('user')) {
      updateComplete.then(() => {
        validateUser(changed.get('user'));
      });
    }

    if (changed.has('addOrgSelectedOrg') && this.addOrgSelectedOrg !== undefined) {
      updateComplete.then(() => {
        const val = this.shadowRoot.querySelector('[name=organizationId]')?.value;
        this.addOrgAvailableRoles = [...(this.organizations[val]?.roles || {})];
      });
    }
  }

  constructor() {
    super();

    this.updatedUsers = [];
    this.deletedUsers = [];
    this.appUsers = [];

    this.requestNames = {};
    this.loadData();
  }

  render() {
    const {
      showSaved, users, organizations, currentOrganizationId, relationshipTree, currentUserId, roles,
      updatedUsers, showResetPasswordModal, showOverlay, overlayMessage, showAddRoleModal, showSearch, searchTerm,
      showDeleteUserModal, deletedUsers, addOrgAvailableRoles,
      renderUserInfo, renderUserListItem, handleSave, handleSavedComplete, handleAddNew,
      handleFilterUsers, handleResetPasswordCancel, handleResetPasswordConfirm,
      handleAddRoleCancel, handleAddRoleConfirm, handleSearchChange, handleSearchToggleClick,
      handleDeleteUserCancel, handleDeleteUserConfirm, handleDeleteUser, handleOrgSelect, handleGetUsersFromAria
    } = this;

    if (!users || !organizations || !roles) return;

    const organizationsToShow = [currentOrganizationId, ...organizations[currentOrganizationId].descendants];

    return html`
      ${fontAwesome}
      <auth-header
        savedMessage="Users saved successfully!"
        ?showSaved=${showSaved}
        @save=${handleSave}
        @saved-complete=${handleSavedComplete}
      ></auth-header>
      <main>
        <div id="user-list" class="sidebar">
          <div class="existing-wrapper">
            <h2>Users</h2>
            <div class="search-wrapper" ?show=${showSearch}>
              <span
                id="search-button"
                @click=${handleSearchToggleClick}
              >
                <i class="far fa-search"></i>
              </span>
              <div id="search">
                <input type="text" @keyup=${handleSearchChange}/>
              </div>
            </div>
            <select
              @change=${handleFilterUsers}
            >
              ${relationshipTree.map(({ organizationId, level }) => html`
                <option
                  value=${organizationId}
                  ?selected=${currentOrganizationId === organizationId}
                >
                  ${pad('', level, '-')} ${organizations[organizationId].name}
                </option>
              `)}
            </select>
            <ul>
              ${users.filter(({ organizations: userOrganizations, firstName, lastName, email,
                activeDirectoryUsername, aliasName, displayName, username } = {}) =>
      searchTerm ? (
        firstName?.toLowerCase().includes(searchTerm.toLowerCase())
        || lastName?.toLowerCase().includes(searchTerm.toLowerCase())
        || email?.toLowerCase().includes(searchTerm.toLowerCase())
        || activeDirectoryUsername?.toLowerCase().includes(searchTerm.toLowerCase())
        || aliasName?.toLowerCase().includes(searchTerm.toLowerCase())
        || displayName?.toLowerCase().includes(searchTerm.toLowerCase())
        || username?.toLowerCase().includes(searchTerm.toLowerCase())
      ) : organizationsToShow.some(id => userOrganizations?.find(o => `${o.organizationId}` === `${id}`))
    ).map(renderUserListItem)}
            </ul>
          </div>
          <div class="add-delete-wrapper">
            <span
              @click=${handleGetUsersFromAria}
            ><i class="fas fa-sync-alt"></i></span>
            <span
              @click=${handleAddNew}
            ><i class="fas fa-plus"></i></span>
            <span
              @click=${handleDeleteUser}
            ><i class="far fa-trash-alt"></i></span>
          </div>
          <div class="updated-wrapper">
            <h2>Unsaved Changes</h2>
            <ul>
              ${updatedUsers.map(renderUserListItem).concat(deletedUsers?.map(renderUserListItem))}
            </ul>
          </div>
        </div>
        <div id="user" class="content">
          ${updatedUsers.length || deletedUsers?.length ? html`
            <div class="banner">User changes are not saved until you click the Save button.</div>
          ` : ''}
          <h1>User Info</h1>
          ${!currentUserId ? html`
            <p>Please select a user on the left to edit.</p>
          ` : renderUserInfo()}
          ${currentUserId ? html`

          ` : ''}
        </div>
      </main>
      <rad-confirm
        confirmText="Yes, reset password"
        cancelText="No, cancel"
        title="Reset User's Password?"
        ?active=${showResetPasswordModal}
        @confirm=${handleResetPasswordConfirm}
        @cancel=${handleResetPasswordCancel}
      >
        <p>
          Once you click "Yes", their password will be changed and
          they will be required to complete the Reset Password steps
          they will receive via email before they are able to log-in.
        </p>
        <p>
          Do you wish to proceed?
        </p>
      </rad-confirm>
      <rad-confirm
        confirmText="Add Role"
        cancelText="Cancel"
        title="Add Role"
        ?active=${showAddRoleModal}
        @confirm=${handleAddRoleConfirm}
        @cancel=${handleAddRoleCancel}
      >
        <form>
          <label>
            Organization:
            <select name="organizationId" @change=${handleOrgSelect}>
              ${relationshipTree.map(({ organizationId, level }) => html`
                <option value=${organizationId}>
                  ${pad('', level, '-')} ${organizations[organizationId].name}
                </option>
              `)}
            </select>
          </label>
          <label>
            Role:
            <select name="roleId">
              ${Object.values(addOrgAvailableRoles ?? organizations[currentOrganizationId].roles ?? []).map(({
      id,
      name
    }) => html`
                <option value=${id}>
                  ${name}
                </option>
              `)}
            </select>
          </label>
        </form>
      </rad-confirm>
      <rad-confirm
        confirmText="Yes, delete user"
        cancelText="No, cancel"
        title="Delete User?"
        ?active=${showDeleteUserModal}
        @confirm=${handleDeleteUserConfirm}
        @cancel=${handleDeleteUserCancel}
      >
        <p>
          Once you click "Yes", the user ${users.find(u => u?.id === currentUserId)?.email} will be marked for deletion.
        </p>
        <p>
          Do you wish to proceed?
        </p>
      </rad-confirm>
      <div
        id="overlay"
        ?active=${showOverlay}
        ?fade=${overlayMessage}
      >
        ${overlayMessage}
      </div>
    `;
  }

  renderUserListItem = ({ firstName, lastName, email, id, newUser, hasErrors, displayName }) => {
    const { currentUserId, isUpdatedUser, isDeletedUser, handleUserClick } = this;

    return html`
      <li
        .id=${id}
        .newUser=${newUser}
        .updatedUser=${isUpdatedUser(id)}
        .deletedUser=${isDeletedUser(id)}
        ?active=${currentUserId === id}
        ?has-errors=${hasErrors}
        @click=${handleUserClick}
      >
        ${firstName || lastName ? html`
          ${firstName} ${lastName}
        ` : displayName ? html`${displayName}` : 'New User'}
        ${email ? html`<span class="email">${removeAtFromEmail(email)}@</span>` : ''}
      </li>
    `;
  };

  renderUserInfo = () => {
    const {
      user, organizations, appUsers, isUpdatedUser, isDeletedUser,
      handleResetPassword, handleUserUpdate, handleActiveChange, handleAddRole, handleRemoveRole, handleRevertUser      
    } = this;

    const {
      id, email, username, activeDirectoryUsername, displayName, isActiveDirectoryUser,
      organizations: userOrganizations, active, newUser, errors = {}      
    } = user;

    return html`
      <form @change=${handleUserUpdate}>
        ${newUser ? html`<p>New users will have password reset emails sent upon save.</p>` : ''}
        ${h(errors?.length, () => errors.map(error => html`
          <ul class="errors">${error}</ul>`))}
        ${isUpdatedUser(id) || isDeletedUser(id) ? html`
          <button
            type="button"
            @click=${handleRevertUser}
          >${newUser ? html`Cancel Creation` : 'Revert Changes'}
          </button>
        ` : ''}
        <div class="row">
          <label id="email">
            Email:
            <input type="text" .value=${email || ''} name="email" required pattern=${EMAIL_PATTERN} />
            ${h(!email && errors.email, html`<span class="error">Please enter a valid email address.</span>`)}
          </label>
          <label>
            <check-box ?checked=${active} @change=${handleActiveChange}></check-box>
            User Active
          </label>
          ${h(!newUser && (email?.length > 0 && !isActiveDirectoryUser), html`
            <button
              type="button"
              @click=${handleResetPassword}
              id="reset-password"
            >Reset Password
            </button>
          `)}
        </div>
        <div class="row">
          <label id="email">Active Directory Username:
          <input type="text" .value=${activeDirectoryUsername || ''}
           name="activeDirectoryUsername" autocomplete='off' />
          ${h(errors.activeDirectoryUsername,
             html`<span class="error">Please enter a valid Active Directory Username.</span>`)}
          </label>
        </div>
        <div class="row">
        <label id="email">
            ARIA&reg; Username:
            <input list="appusernames-list" type="text"
              .value=${this.getAppUserKeyOrValue('value', username) || username || ''}
              name="username" autocomplete='off' />
            <datalist id="appusernames-list">
              ${Object.entries(appUsers)                  
                  .sort(([_, avalue], [__, bvalue]) => avalue.localeCompare(bvalue))
                  .map(([key, value]) => html`
                  <option 
                    .key=${key} 
                    ?selected=${key === username}                    
                  >
                    ${value}
                  </option>
                  `)
              }
            </datalist>            
          </label>
        </div>
        <div class="row">          
          <label id="email">
            Display Name:
            <input type="text" .value=${displayName || ''} name="displayName" required/>
            ${h(errors.displayName, html`<span class="error">Please enter a display name.</span>`)}
          </label>         
        </div>
        ${h(errors.organizations, html`<span class="error">Please add at least one role.</span>`)}
        <table>
          <colgroup>
            <col span="2">
            <col class="remove">
          </colgroup>
          <thead>
          <tr>
            <th>Organization</th>
            <th>Role</th>
            <th>Remove</th>
          </tr>
          </thead>
          <tbody>
          ${userOrganizations?.map(({ organizationId: id, roleIds: userRoles }) =>
      userRoles?.map(role =>
        h(organizations[id] && organizations[id].roles.find(r => r.id === role), () => html`
                <tr
                  .organizationId=${id}
                  .roleId=${role}
                >
                  <td>${organizations[id].name}</td>
                  <td>${organizations[id].roles.find(r => r.id === role).name}</td>
                  <td>
                    <i
                      class="far fa-trash-alt"
                      @click=${handleRemoveRole}
                    ></i>
                  </td>
                </tr>
              `)
      )
    )}
          </tbody>
        </table>
        <button
          type="button"
          id="add-role"
          @click=${handleAddRole}
        >
          <i class="fas fa-plus"></i>
        </button>
      </form>
    `;
  };

  isUpdatedUser = userId => {
    const { updatedUsers } = this;

    return Boolean(updatedUsers.find(({ id }) => id === userId)) || undefined;
  };

  isDeletedUser = userId => {
    const { deletedUsers } = this;

    return Boolean(deletedUsers.find(({ id }) => id === userId)) || undefined;
  };

  handleOrgSelect = event => {
    this.addOrgSelectedOrg = event.target.value;
  };

  handleSearchToggleClick = () => {
    const { showSearch } = this;

    this.showSearch = !showSearch;
  };

  handleSearchChange = async ({ currentTarget: { value } }) => {
    this.searchTerm = value;
  };

  handleUserUpdate = ({ currentTarget }) => {
    const { user, updatedUsers } = this;

    const updatedUser = {
      ...user,
      ...formToObject(currentTarget)
    };

    this.user = updatedUser;
    this.updatedUsers = replaceValue(updatedUsers, user, updatedUser);
  };

  handleResetPassword = () => {
    this.showResetPasswordModal = true;
  };

  handleGetUsersFromAria = () => {    
    this.requestNames.GetAppUsersFromAria = http.get('./auth/api/users/getFromAria');    
  };

  handleAddNew = () => {
    const { updatedUsers, generateTemporaryId } = this;

    const newUser = {
      firstName: '',
      lastName: '',
      email: '',
      active: false,
      credentials: '',
      newUser: true,
      organizations: [],
      id: generateTemporaryId(),
      username: ''
    };

    this.updatedUsers = [...updatedUsers, newUser];

    this.user = newUser;
    this.currentUserId = newUser.id;
  };

  handleRevertUser = () => {
    const { user, updatedUsers, deletedUsers, users } = this;
    const isDeleted = this.isDeletedUser(user.id);
    const userToRevert = this.isUpdatedUser(user.id) ? updatedUsers.find(u => u.id === user.id)
      : isDeleted ? deletedUsers.find(u => u.id === user.id) : undefined;

    this.updatedUsers = this.isUpdatedUser(user.id) && userToRevert
      ? removeValue(updatedUsers, userToRevert)
      : [...updatedUsers];
    this.deletedUsers = isDeleted && userToRevert ? removeValue(deletedUsers, userToRevert) : [...deletedUsers];

    if (isDeleted) {
      this.users = [...users, userToRevert];
    }

    if (user.newUser) {
      this.currentUserId = null;
    } else {
      this.user = this.originalUser;
    }
  };

  handleActiveChange = ({ detail: { checked } }) => {
    const { user, updatedUsers } = this;

    const updatedUser = {
      ...user,
      active: checked
    };

    this.user = updatedUser;
    this.updatedUsers = replaceValue(updatedUsers, user, updatedUser);
  };

  handleAddRole = () => {
    // reset modal values before display
    this.shadowRoot.querySelector('[name=organizationId]').selectedIndex = 0;
    this.addOrgSelectedOrg = this.currentOrganizationId;
    this.addOrgAvailableRoles = this.organizations[this.currentOrganizationId].roles;
    this.showAddRoleModal = true;
  };

  handleRemoveRole = ({ currentTarget }) => {
    const { organizationId, roleId } = currentTarget.closest('tr');
    const { user, updatedUsers } = this;

    const updatedUser = { ...user };
    const userOrganization = updatedUser.organizations.find(org => org.organizationId === organizationId);

    if (userOrganization.roleIds.length === 1 && userOrganization.roleIds[0] === roleId) {
      updatedUser.organizations = removeValue(updatedUser.organizations, userOrganization);
    } else {
      updatedUser.organizations = replaceValue(
        updatedUser.organizations,
        userOrganization, {
          ...userOrganization,
          roleIds: removeValue(userOrganization.roleIds, roleId)
        }
      );
    }

    this.user = updatedUser;
    this.updatedUsers = replaceValue(updatedUsers, user, updatedUser);
  };

  handleAddRoleConfirm = ({ currentTarget }) => {
    const { user, updatedUsers } = this;
    const form = currentTarget.querySelector('form');
    const { organizationId, roleId } = formToObject(form);

    const userOrganization = user.organizations.find(org => org.organizationId === organizationId)
      || { organizationId: organizationId, roleIds: [] };

    const updatedUser = {
      ...user,
      organizations: replaceValue(
        user.organizations,
        userOrganization,
        {
          ...userOrganization,
          roleIds: [...userOrganization.roleIds, roleId]
        }
      )
    };

    this.user = updatedUser;
    this.updatedUsers = replaceValue(updatedUsers, user, updatedUser);

    this.showAddRoleModal = false;
  };

  handleAddRoleCancel = () => {
    this.showAddRoleModal = false;
  };

  handleResetPasswordConfirm = () => {
    const { currentUserId } = this;

    this.requestNames.resetPassword = http.post('./auth/api/user/password/reset',
      { body: { userId: currentUserId } });
  };

  finishResetPassword = () => {
    this.overlayMessage = 'User password reset. They should receive an email shortly.';
    this.showOverlay = true;

    clearTimeout(this.overlayClearId); // clear previous ones
    clearTimeout(this.overlayMessageClearId);

    this.overlayClearId = setTimeout(() => {
      this.showOverlay = undefined;

      this.overlayMessageClearId = setTimeout(() => {
        this.overlayMessage = '';
      }, OVERLAY_CLEAR_FADE);
    }, OVERLAY_CLEAR_DELAY);

    this.showResetPasswordModal = false;
  };

  handleResetPasswordCancel = () => {
    this.showResetPasswordModal = false;
  };

  handleUserClick = async ({ currentTarget: { id: userId, newUser, updatedUser, deletedUser } }) => {
    const { updatedUsers, currentUserId } = this;

    if (userId === currentUserId) {
      return;
    }

    if (newUser || updatedUser || deletedUser) {
      this.user = updatedUsers.concat(this.deletedUsers).find(({ id }) => id.toString() === userId.toString());
      this.currentUserId = userId;
    } else {
      this.requestNames.getUser = http.get('./auth/api/user/{userId}', { pathParams: { userId } });
    }
  };

  handleDeleteUser = () => {
    this.showDeleteUserModal = true;
  };

  handleDeleteUserCancel = () => {
    this.showDeleteUserModal = false;
  };

  handleDeleteUserConfirm = () => {
    const { deletedUsers, currentUserId } = this;
    const currentUser = this.users.find(u => u.id === currentUserId);

    this.deletedUsers = [...deletedUsers, currentUser];
    this.users = removeValue(this.users, currentUser);

    this.showDeleteUserModal = false;
  };

  deleteUser = user => {
    const promise = defer();

    this.saveUserRequests = this.saveUserRequests !== undefined ? [...this.saveUserRequests] : [];

    this.saveUserRequests.push({
      requestName: http.delete('./auth/api/user/{userId}', { name: user.id, pathParams: { userId: user.id } }),
      promise
    });

    return promise;
  };

  updateUser = user => {
    this.originalUser = user;
    this.user = user;
    this.currentUserId = user.id;
  };

  handleFilterUsers = ({ currentTarget }) => {
    this.currentOrganizationId = currentTarget.value;
  };

  handleSave = async () => {
    const { updatedUsers, deletedUsers, validateUsers, saveUser, deleteUser } = this;

    if (!validateUsers() || updatedUsers.concat(deletedUsers).length === 0) {
      return;
    }

    await Promise.all(updatedUsers.map(saveUser));
    await Promise.all(deletedUsers.map(deleteUser));

    this.saveUserRequests = [];
    this.updatedUsers = [];
    this.deletedUsers = [];
    this.showSaved = true;

    if (deletedUsers.find(u => u.id === this.currentUserId)) {
      this.currentUserId = undefined;
      this.user = undefined;
    }

    this.loadUsers();
  };

  handleSavedComplete = () => {
    this.showSaved = false;
  };

  loadUsers = () => {
    this.requestNames.getUsers = http.get('./auth/api/users');
  };

  loadAppUsers = () => {
    this.requestNames.getAppUsers = 
      http.get('/weekly-check/api/{organization}/appusers', { credentials: 'include', mode: 'cors' });
  };

  loadData = () => {
    this.loadUsers();
    this.loadAppUsers();
    Object.assign(this.requestNames, {
      organizations: http.get('./auth/api/organization/{organization}'),
      permissions: http.get('./auth/api/organization/{organization}/permissions')
    });
  };

  updateUsers = users => {
    const { currentUserId } = this;

    this.users = users.map(user => ({
      ...user,
      organizations: user.organizations.map(({ ...organization }) => organization)
    }));

    if (currentUserId) {
      this.user = this.users.find(({ id }) => id === currentUserId) || undefined;
      this.currentUserId = this.user ? currentUserId : undefined;
    }
  };

  updateOrganizations = ({ organizations, id: organizationId }) => {
    Object.assign(this, {
      organizations: organizations.reduce((result, { id, ...rest }) => Object.assign(result, {
        [id]: { ...rest, descendants: buildDescendants(id, organizations) }
      }), {}),
      relationshipTree: buildRelationshipTree(
        organizationId,
        mapByKey(organizations, 'id')
      ),
      currentOrganizationId: organizationId
    });
  };

  updatePermissions = ({ roles }) => {
    this.roles = mapByKey(roles, 'id');
  };

  generateTemporaryId = () =>
    `temp${Date.now()}`;

  validateUsers = () => {
    const { updatedUsers, validateUser } = this;

    const areValid = updatedUsers.map(validateUser);

    return areValid.every(Boolean);
  };

  validateUser = user => {
    const { user: currentUser, updatedUsers, getValidationErrors } = this;
    const errors = getValidationErrors(user);

    if (errors.hasErrors) {
      const updatedUser = { ...user, errors, hasErrors: true };
      this.updatedUsers = replaceValue(updatedUsers, user, updatedUser);

      currentUser === user && (this.user = updatedUser);

      return false;
    }

    return true;
  };

  getValidationErrors = user => {
    const errors = [];

    user.email?.length > 0 && !EMAIL_REGEX.test(user.email?.toLowerCase()) && errors.push('email');
    user.activeDirectoryUsername?.length > 0 && user.activeDirectoryUsername.indexOf('\\') === -1 
      && errors.push('activeDirectoryUsername');
    !user.displayName && errors.push('displayName');
    (!user.organizations || !user.organizations.length) && errors.push('organizations');

    return errors.reduce((result, field) => Object.assign(result, { [field]: true }),
      { hasErrors: errors.length });
  };

  saveUser = user => {
    const newUser = this.mapUserForSave(user);
    delete newUser.id;

    this.saveUserRequests = this.saveUserRequests !== undefined ? [...this.saveUserRequests] : [];

    const promise = defer();

    if (user.newUser) {
      delete newUser.newUser;

      this.saveUserRequests.push({
        requestName: http.post('./auth/api/user', { name: user.email, body: newUser }),
        promise
      });
    } else {
      this.saveUserRequests.push({
        requestName: http.put('./auth/api/user/{userId}',
          { name: user.id, pathParams: { userId: user.id }, body: newUser }),
        promise
      });
    }

    return promise;
  };

  mapUserForSave = user => {
    const newUser = { ...user };

    newUser.organizations.forEach(org => {
      org.organizationId = org.id;
      delete org.id;
    });
    

    newUser.username = this.getAppUserKeyOrValue('key', newUser.username) || newUser.username;
    
    return newUser;
  };

  getAppUserKeyOrValue = (type, lookup) => {
    const appUsers = Object.entries(this.appUsers).map(([key, value]) => ({ key, value }) );
    if (type === 'key') {
      return appUsers.find(z=>z.value === lookup)?.key;
    } else {
      return appUsers.find(z=>z.key === lookup)?.value;
    }
  };

  stateChanged(state) {
    const {
      requestNames, saveUserRequests, updateUser, updateUsers, updateOrganizations, updatePermissions,
      finishResetPassword
    } = this;

    if (!organizationSelector(state)) {
      if (organizationSlugSelector(state)) {
        router.navigate(generate(
          Routes.AdminUsers,
          { organization: organizationSlugSelector(state) }
        ));
      }

      return;
    }


    const getUserResponse = http.getRequest(requestNames.getUser, state);
    const getUsersResponse = http.getRequest(requestNames.getUsers, state);
    const organizationsResponse = http.getRequest(requestNames.organizations, state);
    const permissionsResponse = http.getRequest(requestNames.permissions, state);
    const resetPasswordResponse = http.getRequest(requestNames.resetPassword, state);
    const deleteUserResponse = http.getRequest(requestNames.deleteUser, state);
    const getAppUsersResponse = http.getRequest(requestNames.getAppUsers, state);
    const getLatestAppUsersFromAriaResponse = http.getRequest(requestNames.GetAppUsersFromAria, state);

    if (getUserResponse?.ok) {
      updateUser(getUserResponse.data);
      http.flush(getUserResponse);
    }

    if (getUsersResponse?.ok) {
      updateUsers(getUsersResponse.data);

      if (this.user && this.updatedCurrentUser?.id === this.user?.id) {
        this.users = replaceValue(this.users, this.user, this.updatedCurrentUser);
        this.user = this.updatedCurrentUser;
        this.updatedCurrentUser = null;
      }

      http.flush(getUsersResponse);
    }

    if (organizationsResponse?.ok) {
      updateOrganizations(organizationsResponse.data);
      http.flush(organizationsResponse);
    }

    if (permissionsResponse?.ok) {
      updatePermissions(permissionsResponse.data);
      http.flush(permissionsResponse);
    }

    if (getAppUsersResponse?.ok) {
      this.appUsers = getAppUsersResponse.data;
      http.flush(getAppUsersResponse);
    }

    if (getLatestAppUsersFromAriaResponse?.ok) {
      this.loadUsers();
      http.flush(getLatestAppUsersFromAriaResponse);
    }

    if (resetPasswordResponse?.ok) {
      finishResetPassword();
      http.flush(resetPasswordResponse);
    }

    if (deleteUserResponse?.ok) {
      this.loadUsers();
      http.flush(deleteUserResponse);
    }

    saveUserRequests?.forEach(({ requestName, promise }) => {
      const response = http.getRequest(requestName);

      if (response?.ok) {
        if (this.user.id === response.data.id) {
          this.updatedCurrentUser = response.data;
        } else {
          this.updatedCurrentUser = undefined;
        }

        promise.resolve();
        http.flush(response);
      }
    });
  }
}

customElements.define('users-view', UsersView);