/**
 * Given an array of points (expressed as [x, y]), rotates each point about a given origin.
 * @param {[Number, Number][]} points
 * @param {[Number, Number]} Origin
 * @param {Number} angle Rotation angle, in radians
 * @return {[Number, Number][]} New array with the translated points.
 */
import translatePointBy from './translatePointBy.js';

const rotatePoints = (points, angle, [originX, originY]) =>
  points.map(translatePointBy(-originX, -originY))
    .map(([x, y]) => [
      (x * Math.cos(angle)) - (y * Math.sin(angle)),
      (x * Math.sin(angle)) + (y * Math.cos(angle))
    ])
    .map(translatePointBy(originX, originY));

export default rotatePoints;