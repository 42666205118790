const download = (data, filename = 'download.json') => {
  const downloadData = typeof data === 'object'
    ? JSON.stringify(data, null, 2)
    : data;

  const element = document.createElement('a');
  element.setAttribute('href', 'data:application/json;charset=utf-8,'
    + encodeURIComponent(downloadData));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export default download;