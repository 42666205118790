import vtkAbstractMapper from './AbstractMapper.js';
import { u as uninitializeBounds } from '../../Common/Core/Math/index.js';

// vtkAbstractMapper methods
// ----------------------------------------------------------------------------

function vtkAbstractMapper3D(publicAPI, model) {
  publicAPI.getBounds = function () {
    return 0;
  };

  publicAPI.getBounds = function (bounds) {
    publicAPI.getBounds();

    for (var i = 0; i < 6; i++) {
      bounds[i] = model.bounds[i];
    }
  };

  publicAPI.getCenter = function () {
    publicAPI.getBounds();

    for (var i = 0; i < 3; i++) {
      model.center[i] = (model.bounds[2 * i + 1] + model.bounds[2 * i]) / 2.0;
    }

    return model.center.slice();
  };

  publicAPI.getLength = function () {
    var diff = 0.0;
    var l = 0.0;
    publicAPI.getBounds();

    for (var i = 0; i < 3; i++) {
      diff = model.bounds[2 * i + 1] - model.bounds[2 * i];
      l += diff * diff;
    }

    return Math.sqrt(l);
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  bounds: [1, -1, 1, -1, 1, -1],
  center: [0, 0, 0]
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Inheritance

  vtkAbstractMapper.extend(publicAPI, model, initialValues);

  if (!model.bounds) {
    uninitializeBounds(model.bounds);
  }

  if (!model.center) {
    model.center = [0.0, 0.0, 0.0];
  }

  vtkAbstractMapper3D(publicAPI, model);
} // ----------------------------------------------------------------------------

var vtkAbstractMapper3D$1 = {
  extend: extend
};

export { vtkAbstractMapper3D$1 as default, extend };
