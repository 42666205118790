/* global process */
// hack so parcel doesn't tree-shake anything away
import './components/views/image-view/index.js';
import './components/views/document-view/index.js';
import './components/views/weekly-plan-check-view/index.js';
import './components/views/auth/password-forgot-view';
import './components/views/auth/password-reset-view';
import './components/views/auth/permissions-view';
import './components/views/auth/organizations-view';
import './components/views/auth/users-view';
import './components/views/auth/login-view';
import './components/views/error-404-view.js';
import './components/views/image-view/index.js';
import './components/views/blank-image-view.js';
import { html } from 'lit';

export const Routes = {
  Root: 'root',
  WeeklyCheckLanding: 'weekly-check-landing',
  WeeklyCheckOrganization: 'weekly-check-organization',
  WeeklyCheckPatient: 'weekly-check-patient',
  NotFound404: '404-not-found',
  OrganizationSettingsMain: 'organization-settings-main',
  OrganizationSettingsSub: 'organization-settings-sub',
  Document: 'document',
  Image: 'image',
  Landing: 'landing',
  Login: 'login',
  Logout: 'logout',
  AdminUsers: 'auth-users',
  AdminOrganizations: 'auth-organizations',
  AdminPermissions: 'auth-permissions',
  AdminRoot: 'landing-root',
  AdminLanding: 'landing-admin',
  ResetPassword: 'reset-password',
  ForgotPassword: 'forgot-password',
  BlankImage: 'images-not-loaded'
};

const routes = {
  [Routes.Root]: {
    route: '/',
    forward: Routes.WeeklyCheckLanding
  },
  [Routes.WeeklyCheckLanding]: {
    route: '/weekly-check',
    view: () => import('./components/views/weekly-plan-check-view/index.js'),
    title: 'WeeklyCheck',
    render: () => html`<weekly-plan-check-view></weekly-plan-check-view>`
  },
  'organization-without-patient': {
    route: '/weekly-check/:organization',
    forward: Routes.WeeklyCheckOrganization
  },
  [Routes.WeeklyCheckPatient]: {
    route: '/weekly-check/:organization/patient/:patient',
    view: () => import('./components/views/weekly-plan-check-view/index.js'),
    title: 'WeeklyCheck',
    render: () => html`<weekly-plan-check-view></weekly-plan-check-view>`
  },
  [Routes.WeeklyCheckOrganization]: {
    route: '/weekly-check/:organization/patient',
    view: () => import('./components/views/weekly-plan-check-view/index.js'),
    title: 'WeeklyCheck',
    render: () => html`<weekly-plan-check-view></weekly-plan-check-view>`
  },
  [Routes.OrganizationSettingsMain]: {
    route: '/weekly-check/:organization/settings',
    view: () => import('./components/views/settings-view/index.js'),
    title: 'WeeklyCheck Settings',
    render: () => html`<settings-view></settings-view>`
  },
  [Routes.OrganizationSettingsSub]: {
    route: '/weekly-check/:organization/settings/:page',
    view: () => import('./components/views/settings-view/index.js'),
    title: 'WeeklyCheck Settings',
    render: () => html`<settings-view></settings-view>`
  },
  [Routes.Document]: {
    route: '/weekly-check/:organization/document/:documentType/:documentId',
    view: () => import('./components/views/document-view/index.js'),
    title: 'WeeklyCheck - Document',
    render: () => html`<document-view></document-view>`
  },
  [Routes.Image]: {
    route: '/weekly-check/:organization/image/:patient/:planSer/:fraction/:groupIndex',
    view: () => import('./components/views/image-view/index.js'),
    title: 'WeeklyCheck - Image',
    render: () => html`<image-view></image-view>`
  },
  [Routes.NotFound404]: {
    notFound: true,
    view: () => import('./components/views/error-404-view.js'),
    title: '404 Not Found',
    render: () => html`<error-404-view></error-404-view>`
  },
  [Routes.Login]: {
    route: '/auth/login',
    view: () => import('./components/views/auth/login-view'),
    title: 'Login',
    render: () => html`<login-view></login-view>`
  },
  [Routes.Logout]: {
    route: '/auth/logout',
    forward: '/login'
  },
  [Routes.AdminRoot]: {
    route: '/auth',
    view: () => import('./components/views/auth/users-view'),
    title: 'Admin - Users',
    render: () => html`<users-view></users-view>`
  },
  [Routes.AdminLanding]: {
    route: '/auth/:organization',
    forward: Routes.AdminUsers
  },
  [Routes.AdminUsers]: {
    route: '/auth/:organization/users',
    view: () => import('./components/views/auth/users-view'),
    title: 'Admin - Users',
    render: () => html`<users-view></users-view>`
  },
  [Routes.AdminOrganizations]: {
    route: '/auth/:organization/organizations',
    view: () => import('./components/views/auth/organizations-view'),
    title: 'Admin - Organizations',
    render: () => html`<organizations-view></organizations-view>`
  },
  [Routes.AdminPermissions]: {
    route: '/auth/:organization/permissions',
    view: () => import('./components/views/auth/permissions-view'),
    title: 'Admin - Permissions',
    render: () => html`<permissions-view></permissions-view>`
  },
  [Routes.ResetPassword]: {
    route: '/auth/password/reset',
    view: () => import('./components/views/auth/password-reset-view'),
    title: 'Password Reset',
    render: () => html`<password-reset-view></password-reset-view>`
  },
  [Routes.ForgotPassword]: {
    route: '/auth/password/forgot',
    view: () => import('./components/views/auth/password-forgot-view'),
    title: 'Forgot Password',
    render: () => html`<password-forgot-view></password-forgot-view>`
  },
  [Routes.BlankImage]: {
    route: '/weekly-check/:organization/image/:patient/blank',
    view: () => import('./components/views/blank-image-view.js'),
    title: 'Images Not Loaded ',
    render: () => html`<blank-image-view></blank-image-view>`
  }
};

if (process.env.NODE_ENV !== 'production') {
  Routes.Dev = 'dev';
  Routes.DevImageViewer = 'dev-image-viewer';

  routes[Routes.Dev] = {
    route: '/dev',
    view: () => import('./dev/dev-view.js'),
    render: () => html`<dev-view></dev-view>`
  };

  routes[Routes.DevImageViewer] = {
    route: '/dev/image',
    view: () => import('./dev/dev-image-viewer.js'),
    title: 'Dev Image Viewer',
    render: () => html`<dev-image-viewer></dev-image-viewer>`
  };
}

export default routes;
