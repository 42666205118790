const formToObject = form => {
  const obj = {};
  const formData = new FormData(form);

  for (const [key, value] of formData.entries()) {
    obj[key] = value;
  }

  return obj;
};

export default formToObject;