// @TODO APC-2245 Merge with redux/http so it still uses state, but also keeps promises.
import queryToString from './utils/queryToString';
import contentTypeMatches from './utils/contentTypeMatches';
import applyParams from './utils/applyParams';

class RadHttpError extends Error {
  response = undefined;

  constructor(response, message) {
    super(message || response?.error?.message);

    this.response = response;
  }
}

class RadHttp {
  /**
   * @typedef RadHttpOptions
   * @property {Object.<string, *>} [headers] Key/value pairs for headers to send with the request.
   * @property {*} [body] The body to pass along with the request. If it is an object, it will be JSON strinigified.
   *   Use rawBody if you want to avoid stringification. Do not use with rawBody.
   * @property {*} [rawBody] The raw body to be passed along exactly as is. Do not use with body.
   * @property {Object.<string, string|number>} [query] Key/value pairs to make up the querystring.
   * @property {Object.<string, *>} [params]
   * @property {'json'|'blob'|'text'|'raw'} [type] The expected type of the response.
   *   If the expected type does not line up with the expected type, an error is thrown.
   *   If 'raw' is specified, the HttpResponse will have an undefined data and it won't be parsed, so it can be
   *     parsed by the user.
   * @property {Object} [fetchOptions] Additional options to be passed to fetch directly.
   */

  /**
   * @typedef RadHttpResponse
   * @type {string} url The actual URL given to fetch.
   * @type {Object} options The actual options given to fetch.
   * @type {Response} response The response from fetch()
   * @type {'json'|'blob'|'text'} type The type of response. It is based on the Content-Type and matches the following:
   *   - application/json => json
   *   - text/* => text
   *   - anything else => blob
   *   If RadHttpOptions.type was specified, it is always this value.
   * @type {Object|Blob|string} data? The parsed data, parsed based on the type,
   *   using either .json(), .blob() or .text()
   * @type {Error} error? The error produced while attempting the request, or returned by the server.
   *   Only possible from the server if type is JSON.
   */

  /**
   * @return {Object.<string, string>} The default URL parameters.
   */
  getDefaultParam = () => ({});

  /**
   * Performs an HTTP request.
   * @param {string} verb
   * @param {string} url The URL, which can contain param keys wrapped in curly brackets.
   *   E.g., /patient/{patientId}
   * @param {RadHttpOptions} options
   * @return {Promise.<RadHttpResponse>}
   *
   * Note: option.type defaults to 'json'. If you want a different type, you'll have to manually specify.
   */
  async request(verb, url, { headers = {}, body = undefined, rawBody = undefined,
    query = {}, params = {}, type = 'json', fetchOptions = {} } = {}) {
    try {
      if (body && rawBody) throw new Error('Cannot use "body" and "rawBody" in the same request.');
      if (type && !['json', 'blob', 'text'].includes(type)) throw new Error(`Unknown expected type: ${type}`);

      const actualUrl = `${applyParams(url, params, this.getDefaultParam)}${queryToString(query)}`;
      
      const options = {
        headers: { 'Content-Type': 'application/json' },
        body,
        method: verb,
        ...fetchOptions
      };

      const response = await fetch(actualUrl, options);
      
      const [contentType] = response.headers.get('Content-Type')?.split(';') || [];
      let data;
      let error;

      try {
        if (contentType === 'application/json' && (!type || type === 'json')) {
          const result = await response.json();
          type = 'json';

          data = result.data ?? result;
          error = result?.error;
        } else if (contentTypeMatches(contentType, 'text/*') && (!type || type === 'text')) {
          data = await response.text();
          type = 'text';
        } else if (!type || type === 'blob') {
          data = await response.blob();
          type = 'blob';
        }
      } catch (error) {
        throw new RadHttpError({ response, url: actualUrl, options, error });
      }

      if (!data) {
        throw RadHttp.#buildTypeMismatchError(type, contentType, response, url, options);
      }

      return {
        url: actualUrl,
        options,
        response,
        type,
        data,
        error
      };
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * @param {string} url
   * @param {RadHttpOptions} [options={}]
   * @return {Promise.<RadHttpResponse>}
   */
  async get(url, options = {}) {
    return this.request('GET', url, options);
  }

  /**
   * @param {string} url
   * @param {RadHttpOptions} options
   * @return {Promise.<RadHttpResponse>}
   */
  async put(url, options = {}) {
    return this.request('PUT', url, options);
  }

  /**
   * @param {string} url
   * @param {RadHttpOptions} options
   * @return {Promise.<RadHttpResponse>}
   */
  async post(url, options = {}) {
    return this.request('POST', url, options);
  }


  /**
   * @param {string} url
   * @param {RadHttpOptions} options
   * @return {Promise.<RadHttpResponse>}
   */
  async delete(url, options = {}) {
    return this.request('DELETE', url, options);
  }

  /**
   * @param {string} url
   * @param {RadHttpOptions} options
   * @return {Promise.<RadHttpResponse>}
   */
  async patch(url, options = {}) {
    return this.request('PATCH', url, options);
  }

  static #buildTypeMismatchError(type, contentType, response, url, options) {
    return new RadHttpError({
      response,
      url,
      options,
      type
    }, `Response expected type ${type}, but Content-Type was ${contentType} which is not acceptable.`);
  }
}

const radHttp = new RadHttp();

export default radHttp;