import * as cornerstoneTools from '@cornerstonejs/tools';
const {
  segmentation
} = cornerstoneTools;

const toggleSegmentationVisibility = async (segmentationRepresentationUID, toolGroupId, visibility) => {
  // https://www.cornerstonejs.org/docs/concepts/cornerstone-tools/segmentation/config#visibility-api
  segmentation.config.visibility.setSegmentationVisibility(toolGroupId, segmentationRepresentationUID, visibility);
};

export default toggleSegmentationVisibility;
