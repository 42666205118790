import { html, css, LitElement } from 'lit';
import fontAwesome from '../../utils/font-awesome';

export class CheckBox extends LitElement {
  // Colors set here are defaults.
  // To specify colors in specific locations, apply them to check-box
  //  by overriding --color, --hover-color or --disabled-color.
  static styles = css`
    :host {}

    label {
      cursor: pointer;
    }
  
    input[type="checkbox"],
    i {
      display: none !important;
      color: var(--neutral-4);
      cursor: pointer;
      vertical-align: middle;
      font-size: 22px;
      margin-right: 3px;
    }
    
    i:hover {
      color: var(--neutral-2);
    }
    
    input:not([partial]):checked ~ i.checked,
    input:not([partial]):not(:checked) ~ i.unchecked,
    input[partial] ~ i.partial {
      display: inline !important;
    }
    
    [disabled] i {
      color: var(--neutral-6);
      pointer-events: none;
    }
  `;

  static properties = {
    id: { type: String },
    disabled: { type: Boolean },
    checked: { type: Boolean },
    readonly: { type: Boolean },
    partial: { type: Boolean }
  };

  get isDisabled() {
    return this.disabled || this.readonly;
  }

  get isLabelDisabled() {
    return this.disabled;
  }
  
  handleChange(event) {   
    this.dispatchEvent(new CustomEvent('change', { detail: { target: this, checked: event.currentTarget.checked } }));
  }
  
  render() {
    const { checked, readonly, handleChange, partial, isDisabled, isLabelDisabled } = this;
    
    return html`
      ${fontAwesome}
      <label 
        ?disabled=${isLabelDisabled} 
        ?readonly=${readonly}
      >
        <input
          type="checkbox" 
          .checked=${checked}
          @change=${handleChange}
          ?disabled=${isDisabled}
          ?partial=${partial}
        />
        <i class="partial fas fa-minus-square"></i>
        <i class="checked fas fa-check-square"></i>
        <i class="unchecked far fa-square"></i>
        <slot></slot>
      </label>
    `;
  }
}

customElements.define('check-box', CheckBox);