/* eslint-disable no-console */
/* Set queueDebug.enabled to true to enable easier debugging */
const queueDebug = { enabled: false };

/*
  Will call condition every time one of the options triggers.
  If the condition returns true, it will call the action, then
  begin watching the next element in the queue if there is one.

  When condition or action are called, they will be passed options as their parameter.

  Usage:
      queue(action, condition, options)
        .then(action, condition, options)
        .then(action, condition, options)
        .start();

  condition and options are optional, though really only make sense to omit in `then()` statements.
  Lacking a condition means it will always run the action when a trigger occurs.
  Lacking options means it will trigger the action immediately (ignoring the condition).

  options can contain any mix of the following:
  - element - Give a LitElement, triggers whenever an element is modified
  - store - Give a Redux store, triggers whenever the state is modified
  - time - Given an amount of time in milliseconds, triggers after that many milliseconds
  - name - Gives the queue step a name for debugging purposes.

  Note: The element option hijacks the updated method for the element.
 */
const queue = (action, options = undefined, condition = undefined) => {
  queueDebug.enabled
    && console.debug(`Created new queue ${options?.name ? `with '${options.name}' step` : ''}.`);

  const chain = [{ action, options, condition }];

  const start = () => {
    startWatch(chain);

    return { abort: () => abort(chain) };
  };

  const then = (action, options = undefined, condition = undefined) => {
    chain.push({ options, condition, action });

    queueDebug.enabled
      && console.debug(`Added${options?.name ? ` '${options.name}'` : ''} step ${chain.length - 1} to chain.`);

    return { then, start };
  };

  return { then, start };
};

const abort = chain => {
  queueDebug.enabled
    && console.debug('Aborting chain.');

  if (chain.__current) {
    chain.__current.__executed = true;
    unwatch(chain.__current);
  }
};

const startWatch = chain => {
  const { action, options, condition } = chain.shift();
  chain.__current = options;

  queueDebug.enabled
    && console.debug(
      `Starting queue ${options?.name ? `with step '${options.name}', ${chain.length + 1} steps total.` : ''}.`,
      { action, options, condition, remaining: chain }
    );

  watch({ action, options, condition, chain, stepNumber: 1, stepCount: chain.length + 1 });
};

const watchNext = (chain, stepNumber, stepCount) => {
  if (!chain.length) {
    queueDebug.enabled
      && console.debug('Chain complete.');
    return;
  }

  const { action, options, condition } = chain.shift();
  chain.__current = options;

  queueDebug.enabled
    && console.debug(`Watching next in queue, ${buildNameFragment(options, stepNumber + 1, stepCount)}.`,
      { action, options, condition, remaining: chain });

  watch({ action, options, condition, chain, stepNumber: stepNumber + 1, stepCount });
};

const buildNameFragment = ({ name }, stepNumber, stepCount) => name
  ? `'${name} (step ${stepNumber} of ${stepCount})'`
  : `${stepNumber} of ${stepCount}`;

const watch = ({ action, options, condition, chain, stepNumber, stepCount }) => {
  if (!options) {
    queueDebug.enabled
      && console.debug(`No options given, running step ${stepNumber} or ${stepCount} immediately.`);

    execute(action, chain, stepNumber, stepCount);
    return;
  }

  const { element, time, store } = options;
  const nameFragment = buildNameFragment(options, stepNumber, stepCount);

  if (element) {
    queueDebug.enabled
      && console.debug(`Watching for element changes for ${nameFragment}.`, element);

    const originalUpdated = element.updated;

    element.updated = changed => {
      check({ action, options, condition, chain, stepNumber, stepCount });

      originalUpdated && originalUpdated.bind(element)(changed);
    };

    options.__originalUpdated = originalUpdated;
  }

  if (time) {
    queueDebug.enabled
      && console.debug(`Waiting for timer for ${nameFragment}.`, time);

    const timeCheck = () => {
      queueDebug.enabled
        && console.debug(`Checking based on timer for ${nameFragment}.`);

      if (!check({ action, options, condition, chain, stepNumber, stepCount })) {
        options.__timeoutId = setTimeout(timeCheck, time);
      }
    };

    options.__timeoutId = setTimeout(timeCheck, time);
  }

  if (store) {
    queueDebug.enabled
      && console.debug(`Watching for store changes for ${nameFragment}`);

    options.__unsubscribe = store.subscribe(() => {
      queueDebug.enabled
        && console.debug(`Checking based on store change for ${nameFragment}`);

      check({ action, options, condition, chain, stepNumber, stepCount });
    });
  }
};

const unwatch = options => {
  if (options.element) {
    options.element.updated = options.__originalUpdated;
  }

  if (options.time) {
    clearTimeout(options.__timeoutId);
  }

  if (options.store) {
    options.__unsubscribe();
  }
};

const check = ({ action, options, condition, chain, stepNumber, stepCount }) => {
  if (options.__executed) return;

  const nameFragment = buildNameFragment(options, stepNumber, stepCount);

  if (!condition || condition(options)) {
    queueDebug.enabled
      && (!condition
        ? console.debug(`No condition provided, executing ${nameFragment}.`)
        : console.debug(`Condition passed, executing ${nameFragment}.`)
      );

    execute(action, options, chain, stepNumber, stepCount);
    return true;
  }

  return false;
};

const execute = (action, options, chain, stepNumber, stepCount) => {
  if (options.__executed) return;
  options.__executed = true; // prevent multiple triggers

  const nameFragment = buildNameFragment(options, stepNumber, stepCount);

  queueDebug.enabled
    && console.debug(`Executing ${nameFragment}.`);

  const result = action(options);

  const next = () => {
    queueDebug.enabled
      && console.debug(`Executed ${nameFragment}.`);

    unwatch(options);
    watchNext(chain, stepNumber, stepCount);
  };

  result?.then
    ? result.then(next)
    : next();
};

export default queue;