import { QUEUE, LOADING, DONE, CAN_LOAD } from './types.js';
import { QUEUED, LOADING as LOADING_STATUS, READY } from './statuses.js';
import { isLoading, isReady } from './selectors.js';
import createKey from './createKey.js';

const INITIAL_STATE = {};

// Call to kick off dicoms loading, ideally once the rest of the page is loaded.
const canLoad = state => !state?.dicoms?.canLoad ? ({
  ...state,
  dicoms: {
    ...(state.dicoms || {}),
    canLoad: true
  }
}) : state;

const queue = (state, { planSer, fraction, groupIndex, priority }) =>
  // No change if it is already READY or LOADING
  (!isReady(state, { planSer, fraction, groupIndex })
    && !isLoading(state, { planSer, fraction, groupIndex }))
    ? ({
      ...state,
      dicoms: {
        ...(state.dicoms || {}),
        [createKey(planSer, fraction, groupIndex)]: {
          status: QUEUED,
          priority
        }
      }
    }) : state;

const loading = (state, { planSer, fraction, groupIndex }) =>
  // No change if it is already READY
  !isReady(state, { planSer, fraction, groupIndex })
    ? ({
      ...state,
      dicoms: {
        ...(state.dicoms || {}),
        [createKey(planSer, fraction, groupIndex)]: {
          status: LOADING_STATUS
        }
      }
    }) : state;

const done = (state, { planSer, fraction, groupIndex }) => ({
  ...state,
  dicoms: {
    ...(state.dicoms || {}),
    [createKey(planSer, fraction, groupIndex)]: {
      status: READY
    }
  }
});

const reducers = {
  [CAN_LOAD]: canLoad,
  [QUEUE]: queue,
  [LOADING]: loading,
  [DONE]: done
};

const dicom = (state = INITIAL_STATE, action) =>
  (reducers[action.type] || (state => state))(state, action);

export default dicom;