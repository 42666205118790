import { html, css, LitElement } from 'lit';
import h from '../../../utils/h';
import { connect } from '../../../utils/redux';
import http from '../../../utils/redux/http';
import { store } from '../../../redux/store';
import { generate } from '../../../router';
import { Routes } from '../../../routes';
import '../../common/rad-link';
import { getQueryParam } from '../../../utils/query';

class PasswordResetView extends connect(store)(LitElement) {
  static styles = css`
    * {
      box-sizing: border-box;
      font-family: 'Lato', 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    }

    :host {
      padding: 7% 24px 0;
      background: radial-gradient(92.65% 93.51% at 48.89% 8.18%, rgba(38, 111, 160, 0) 0, #266fa0 100%), #3498db;
    }

    .logo {
      text-align: center;
      margin: 8px auto 24px;
    }

    img {
      width: 100%;
      min-width: 180px;
      max-width: 320px;
      margin: 0 auto 32px;
    }

    .error, .message, div, form {
      display: block;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      border-radius: 5px;
      font-size: 16px;
    }

    .error, .message {
      border: 2px solid #a94442;
      margin-bottom: 16px;
      color: #a94442;
      text-align: center;
      transition: ease-in-out .15s;
    }

    .message {
      border-color: #555555;
      color: #6b8393;
    }

    form,
    .error,
    .message {
      background: white;
      padding: 16px 32px;
      box-shadow: 0 8px 16px rgba(49, 60, 67, .24);
    }

    form h1 {
      color: #42525C;
      text-align: center;
    }

    label {
      display: block;
      width: 100%;
      font-size: 16px;
      color: #6b8393;
      font-weight: 400;
      display: inline-block;
      margin: 8px 0;
    }

    input {
      display: block;
      height: 48px;
      padding: 10px 16px;
      margin-top: 4px;
      line-height: 1.3333333;
      width: 100%;
      color: #555;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #ccc;
      transition: ease-in-out .15s;
    }

    button {
      text-transform: uppercase;
      background-color: #3498db;
      border: none;
      border-radius: 2px;
      font-weight: 600;
      padding: 16px 20px;
      transition: .25s;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      text-rendering: geometricPrecision;
      -webkit-font-smoothing: antialiased;
      display: block;
      width: 100%;
      margin: 24px 0;
    }

    a {
      color: #3498DB;
      text-decoration: none;
    }
  `;

  static properties = {
    password: { type: String },
    confirm: { type: String },
    token: { type: String },
    passwordUpdated: { type: Boolean },
    error: { type: Object }
  };

  constructor() {
    super();

    this.token = getQueryParam('token');
  }

  render() {
    const { handleSubmit, error, handlePasswordChange, handleConfirmChange, password, getPasswordErrors,
      confirm, token, passwordUpdated } = this;

    const imageHtml = html`
      <div class="logo">
        <img src="./images/chartcheck-logo.png">
      </div>
    `;

    if (error) {
      return html`
        ${imageHtml}
        <div class="error">
          ${error}
        </div>
      `;
    }

    if (!token) {
      return html`
        ${imageHtml}
        <div class="error">
          Your password reset request is invalid. Please follow the link from the email.
        </div>
      `;
    }

    if (passwordUpdated) {
      return html`
        ${imageHtml}
        <div class="message">
          <p>
            Your password has been updated successfully.
          </p>
          <p>
            <rad-link href=${generate(Routes.Login)}>Proceed to Login</rad-link>
          </p>
        </div>
      `;
    }

    const passwordErrors = password ? getPasswordErrors(password) : [];

    return html` 
      ${imageHtml}
      <form
        @submit=${handleSubmit}
      >     
        <div>
          <h1>Reset Password</h1>
        </div>
        <label>
          Password
          <input 
            type="password" 
            name="password" 
            @change=${handlePasswordChange}
          >
          ${h(passwordErrors.length, () => html`
            <ul>
              ${passwordErrors.map(error => html`<li>${error}</li>`)}
            </ul>
          `)}
        </label>
        <label>
          Re-enter Password
          <input 
            type="password" 
            name="confirm" 
            @change=${handleConfirmChange}
          >
          ${h(confirm && confirm !== password, html`
            <ul>
              <li>Must match password.</li>
            </ul>
          `)}
        </label>
        <button type="submit">Change Password</button>
      </form>
    `;
  }

  handlePasswordChange = ({ currentTarget: { value } }) => {
    this.password = value;
  };

  handleConfirmChange = ({ currentTarget: { value } }) => {
    this.confirm = value;
  };

  getPasswordErrors(password) {
    const errors = [];

    if (!password || password.length < 8) errors.push('Must be at least 8 characters long.');
    if (password?.length > 60) errors.push('Must be less than 60 characters long.');
    if (!/[0-9]/.test(password)) errors.push('Must contain at least one number.');

    return errors;
  }


  handleSubmit = event => {
    const { password, confirm, token, getPasswordErrors } = this;

    event.preventDefault();

    if (getPasswordErrors(password).length || password !== confirm) return;

    this.passwordSaveName = http.post('./auth/api/user/password/reset/complete',
      { body: { token, password } }, false, () => {});
  };

  stateChanged(state) {
    const { passwordSaveName } = this;

    const passwordSaveRequest = http.getRequest(passwordSaveName, state);

    if (passwordSaveRequest?.completed) {
      if (passwordSaveRequest?.response.ok) {
        this.passwordUpdated = true;
      } else {
        this.error = html`
          <p>
            There was an error updating your password. The token may be invalid or expired. 
            Please go to the <rad-link href=${generate(Routes.Login)}>login page</rad-link> 
            again and use "Forgot Password" to receive a new forgot password email and try again.
          </p>
          <p>
            If the problem persists, please contact your administrator or support.
          </p>
        `;
      }
    }
  }
}

customElements.define('password-reset-view', PasswordResetView);