import { css } from 'lit';
import commonStyles from '../../../styles-common';

const gridLineColor = css`#333`;

const passStateStyle = css`
  .pass-state-cell {
    position: relative;
    display: block;
  }

  .pass-state-cell .value {
    display: none;
  }

  .pass-state-cell[show-icon]:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 500;
  }

  .pass-state-cell[show-icon][state=FAIL]:before {
    content: '\uF00D';
    font-weight: 300;
    font-size: 22px;
  }

  .pass-state-cell[show-icon][state=PASS]:before {
    content: '\uF00C';
    font-weight: 400;
  }

  .pass-state-cell[show-icon][state=CONDITION]:before {
    content: '\uF2EC';
    font-weight: 500;
    font-size: 14px;
  }

  .pass-state-cell[show-icon][state=OVERRIDE]:before {
    content: '\uF12A';
    font-weight: 600;
    font-size: 16px;
    margin-right: 4px;
  }

  .pass-state-cell[show-value] .value {
    display: inline;
  }
`;

const patientStyles = [
  commonStyles,
  css`
    :host {
      display: block;
    }
    
    section h4 {
      border-radius: 5px 5px 0 0;
    }
       
    h3 {
      position: relative;
      font-size: 1.85em;
      margin: 24px 0 8px;
      color: var(--neutral-2);
    }
       
    h3 span {
      color: var(--primary-color);
    }
    
    table tr {
      display: flex;
    }
    
    table tbody tr:nth-child(2n) {
      background: var(--neutral-9);
    }
    
    .rx thead th:not(:first-of-type),
    .rx tbody td {
      flex-basis: calc((100% - 200px) / 4);
    }
    
    .overview thead th:not(:first-of-type),
    .overview tbody td,
    .imaging thead th:not(:first-of-type),
    .imaging tbody td,
    .documentation thead th:not(:first-of-type),
    .documentation tbody td,
    .checks thead th:not(first-of-type),
    .checks tbody td:not(:first-of-type),
    .treatment thead th:not(:first-of-type),
    .treatment tbody td,
    .treatment .field-item .field-start td,
    .treatment .field-item > tr > td {
      flex-basis: calc((100% - 200px) / 6 );
      width: calc((100% - 200px) / 6  );
    }
  
    h2 {
      margin: 8px 16px 0 0;
      padding: 0 0 8px;
    }
  
    #patients h2,
    #sections h2 {
      border-bottom: 1px solid var(--side-border);
      margin: 8px 16px 16px 0;
    }
  
    h4 {
      margin: 0;
      padding: 12px;
      border-color: var(--side-border);
      border-width: 1px 1px 0 1px;
      border-style: solid;
      font-size: 16px;
    }
    
    #patients ul,
    #sections ul {
      list-style: none;
      margin: 0 -16px;
      padding: 0;
    }
    
    #patients li,
    #sections li {
      cursor: pointer;
      padding: 5px 10px 5px 40px;
      font-size: 15px;
      line-height: 21px;
      position: relative;
    }
    
    #patients li span {
      font-size: 13px;
      color: var(--neutral-4);
      pointer-events: none;
    }
    
    #patients li:before {
      content: '\uF00D';
      font-family: 'Font Awesome 5 Pro';
      color: var(--bad-color);
      font-weight: 800;
      position: absolute;
      left: 16px;
    }
    
    #patients li[passed]:before {
       content: '\uF00C';
       color: var(--good-color);
     }
    
    #patients li[current],
    #patients li[current] span {
      background: var(--selected-color); 
      color: white;
    }
    
    #patients li[current]:before {
      color: var(--light-color);
    }
  
    #patients li[current] ul.mobile-list,
    #patients li ul.mobile-list {
      display: none;
    }
  
    #plan .patient-info {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  
    #plan .patient-info .patient-id {
      display: inline-flex;
    }
  
    #plan .patient-info .patient-id p {
      color: var(--neutral-4);
      margin-bottom: 0;
    }
  
    #plan .patient-info h5.site {
      margin: 0;
      padding: 8px 12px 9px;
      color: var(--neutral-4);
    }
    
    #patients li:not([current]):hover,
    #sections li:not([current]):hover {
      background: var(--side-border);
    }
    
    #sections li {
      padding: 5px 16px;
    }
    
    #sections li[current] {
      background: var(--side-border);
    }
    
    #sections span {
      float: right;
      position: relative;
      width: 28px;
      text-align: right;
    }
    
    #sections span.errors:before {
      content: '\uF00D';
      font-family: 'Font Awesome 5 Pro';
      color: var(--bad-color);
      position: absolute;
      top: 1px;
      left: 0;
      font-weight: 900;
    }
    
    #sections span.all-clear:before {
      content: '\uF00C';
      font-family: 'Font Awesome 5 Pro';
      color: var(--good-color);
      position: absolute;
      top: 1px;
      left: -1px;
      font-weight: 900;
    }
    
    button {
      font-size: 15px;
      font-weight: 400;
      padding: 12px 20px;
      min-width: 96px;
      color: white;
      background: var(--primary-color);
      transition: all 0.25s ease 0s;
      margin: 10px 10px 10px 0;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      outline: none;
    }
    
    button:hover {
      background: var(--button-hover-color);
    }
    
    button[disabled] {
      background: var(--neutral-5);
    }
    
    table {
      width: 100%;
      border: 1px solid var(--side-border);
      border-collapse: collapse;
    }
    
    td, th {
      border: 0;
      text-align: left;
      padding: 8px 12px;
    }
    
    tbody th,
    thead th:first-child,
    thead tr th:first-of-type,
    tr.field-start th,
    .checks tbody tr td:first-of-type {
      text-align: left;
      border-right: 1px solid var(--side-border);
      width: 200px !important;
      flex: 0 0 200px;
      word-wrap: break-word;
    }
  
    .checks tbody tr td:first-of-type,
    .checks thead tr th:first-of-type {
      border-right: none;
    }
    
    tbody tr:not(.field-start) th {
      font-weight: normal;
    }
  
    tbody,
    thead {
      font-size: 13px;
      line-height: 18px;
    }
  
    thead,
    tr.field-start {
      background: var(--side-background);
      border-bottom: 1px solid var(--side-border);
      color: var(--neutral-4);
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px;
    }
    
    td.passed {
      color: var(--good-color);
    }
    
    td.failed {
      color: var(--bad-color);
    }
    
    tr:not(.no-pass) td.passed::before,
    td.failed::before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      display: inline;
      margin-right: 2px;
    }
    
    tr:not(.no-pass) td.passed::before {
      content: '\uf00C';
      color: var(--good-color);
    }
    
    td.failed::before {
      content: '\uf00d';
      color: var(--bad-color);
    }
    
    .field-item .field-start {
      position: relative;
      transition: .25s;
    }
    
    .field-item .field-start:hover {
      background: var(--side-border);
      transition: .25s;
    }
    
    .treatment .collapsible tbody,
    .treatment .collapsible thead {
      display: block;
      width: 100%;
    }
    
    tbody.field-item tr.field-start {
      border-top: 1px solid var(--side-border);
      position: relative;
    }
    
    table[has-chart] tr :nth-child(2) {
      box-sizing: border-box !important;
      flex: 0 0 0 !important;
    }
  
    .table-chart .axis {
      position: relative;   
      padding: 0;
    }
    
    .table-chart .label {
      position: absolute;
      right: 45px;
      top: 50%;
      display: block;
      white-space: pre;
      text-align: center;
      line-height: 1;
      transform: translate(50%, -50%) rotate(-90deg);
    }
    
    .table-chart .axis .values {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: right;
    }
    
    .table-chart .axis .values li {
      flex: 1;
      padding: 10px;
    }
    
    .table-chart .column {
      position: relative;
      text-align: center;
      background-repeat: repeat-y;
      background-image: linear-gradient(0, ${gridLineColor} 0, ${gridLineColor} 1px, transparent 1px);
    }
    
    .table-chart .point {
      position: absolute;
      left: 17px;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      display: inline-block;
      text-indent: -10000px;
      border-radius: 50%;
    }
    
    .table-chart .point.override {
      z-index: 5000;
    }
    
    .table-chart .point.override i {
      z-index: 9000;
      width: 20px;
      height: 20px;
      position: absolute;
      text-indent: 0;
      color: #FFF;
      font-size: 12px;
      line-height: 16px;
      top: -5px;
      left: -5px;
      display: block;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
      background: inherit;
      border-radius: 50%;
      padding-top: 2px;
      box-sizing: border-box;
    }
    
    .table-chart .line {
      position: absolute;
      left: 50%;      
      bottom: 50%;
      pointer-events: none;
    }
    
    .table-chart .line.higher {
      top: 30%;
      bottom: auto;
    }
    
    .table-chart th {
      position: relative;
    }
    
    .table-chart th ul {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      list-style: none;
      text-align: left;
      margin: 0;
      padding: 0;
    }
    
    .table-chart th li {
      height: 33.3%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 12px;
    }
  
    @media (max-width: 1080px) {
      main {
        flex-flow: row wrap;
      }
   
      main #patients,
      main #sections {
        max-width: 50%;
        flex-basis: 50%;
        flex-flow: column nowrap;
      }
   
      main #patients {
        order: 1;
      }
  
      main #plan {
        order: 3;
      }
   
      main #sections {
        order: 2;
      }
  
      .patients-large {
        display: none;
      }
  
      main #patients .patients-small {
        display: block;
      }
   
      #patients li[current] ul.mobile-list {
        display: block;
      }
   
      #patients li[current] ul.mobile-list li {
        background: none;
        color: --button-hover-color: #333;
      }
   
      #patients li[current] ul.mobile-list li[current] {
        background: var(--selected-color); 
        color: white;
      }
    }
   
    @media (max-width: 550px) {
      main {
        flex-flow: column nowrap;
      }
   
      main #sections {
        display: none;
      }
    }
    
    table[even] tr {
      display: table-row;
    }
    
    table[even] th,
    table[even] td {
      width: auto !important;
    }
    
    sticky-container {
      z-index: 7000;
    }
    
    ${passStateStyle}
  `
].flat();

export default patientStyles;