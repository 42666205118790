import { cache, ImageVolume } from '@cornerstonejs/core';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData.js';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray.js';

/**
 * Partly based on volumeLoader.createAndCacheDerivedVolume.
 *
 * Creates a volume with the data provided from the backend
 *
 * @param {string} volumeId The new volume id.
 * @param {ImageVolumeMetaData} volumeMetadata volume metadata from the backend
 * @param {ImageVolume} volumeDetails The volume details from the backend
 * @param {ScalarData} volumeScalarData The volume data from the backend
 * @return {Promise<ImageVolume>}
 */
const createImageVolume = async ({ volumeId, volumeMetadata, volumeDetails, volumeScalarData }) => {
  const { dimensions, spacing, origin, direction } = volumeDetails;
  const scalarLength = dimensions[0] * dimensions[1] * dimensions[2];
  const numBytes = scalarLength * 4;

  const scalarArray = vtkDataArray.newInstance({
    name: 'Pixels',
    numberOfComponents: 1,
    values: volumeScalarData
  });

  const imageData = vtkImageData.newInstance();

  imageData.setDimensions(dimensions);
  imageData.setSpacing(spacing);
  imageData.setDirection(direction);
  imageData.setOrigin(origin);
  imageData.getPointData().setScalars(scalarArray);

  const copy = new ImageVolume({
    volumeId,
    metadata: getVolumeMetadata(volumeMetadata),
    dimensions: [...dimensions],
    spacing: spacing,
    origin: origin,
    direction: direction,
    imageData,
    scalarData: volumeScalarData,
    sizeInBytes: numBytes,
    referencedVolumeId: volumeId
  });

  const volumeLoadObject = {
    promise: Promise.resolve(copy)
  };

  await cache.putVolumeLoadObject(volumeId, volumeLoadObject);

  return copy;
};

const getVolumeMetadata = volumeMetadata => {
  const voiLut = [];

  voiLut.push({
    windowWidth: volumeMetadata.windowWidth,
    windowCenter: volumeMetadata.windowCenter
  });
  
  return {
    BitsAllocated: volumeMetadata.bitsAllocated,
    BitsStored: volumeMetadata.bitsStored,
    SamplesPerPixel: volumeMetadata.samplesPerPixel,
    HighBit: volumeMetadata.highBit,
    PhotometricInterpretation: volumeMetadata.photometricInterpretation,
    PixelRepresentation: volumeMetadata.pixelRepresentation,
    Modality: volumeMetadata.modality,
    ImageOrientationPatient: volumeMetadata.imageOrientationPatient,
    PixelSpacing: volumeMetadata.pixelSpacing,
    FrameOfReferenceUID: volumeMetadata.frameOfReferenceUID,
    Columns: volumeMetadata.columns,
    Rows: volumeMetadata.rows,
    // This is a reshaped object and not a dicom tag:
    voiLut: voiLut,
    SeriesInstanceUID: volumeMetadata.seriesInstanceUID
  };
};

export default createImageVolume;