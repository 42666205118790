import macro from '../../macros.js';

var DEFAULT_VIEW_API = navigator.gpu ? 'WebGPU' : 'WebGL';
var VIEW_CONSTRUCTORS = Object.create(null); // ----------------------------------------------------------------------------
// static methods
// ----------------------------------------------------------------------------

function registerViewConstructor(name, constructor) {
  VIEW_CONSTRUCTORS[name] = constructor;
}
function listViewAPIs() {
  return Object.keys(VIEW_CONSTRUCTORS);
}
function newAPISpecificView(name) {
  var initialValues = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return VIEW_CONSTRUCTORS[name] && VIEW_CONSTRUCTORS[name](initialValues);
} // ----------------------------------------------------------------------------
// vtkRenderWindow methods
// ----------------------------------------------------------------------------

function vtkRenderWindow(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkRenderWindow'); // Add renderer

  publicAPI.addRenderer = function (renderer) {
    if (publicAPI.hasRenderer(renderer)) {
      return;
    }

    renderer.setRenderWindow(publicAPI);
    model.renderers.push(renderer); // for (this->Renderers->InitTraversal(rsit);
    //      (aren = this->Renderers->GetNextRenderer(rsit)); )
    //   {
    //   aren->SetAllocatedRenderTime
    //     (1.0/(this->DesiredUpdateRate*this->Renderers->GetNumberOfItems()));
    //   }

    publicAPI.modified();
  }; // Remove renderer


  publicAPI.removeRenderer = function (renderer) {
    model.renderers = model.renderers.filter(function (r) {
      return r !== renderer;
    });
    publicAPI.modified();
  };

  publicAPI.hasRenderer = function (ren) {
    return model.renderers.indexOf(ren) !== -1;
  }; // get an API specific view of this data


  publicAPI.newAPISpecificView = function (name) {
    var initialValues = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return newAPISpecificView(name || model.defaultViewAPI, initialValues);
  }; // Add renderer


  publicAPI.addView = function (view) {
    if (publicAPI.hasView(view)) {
      return;
    }

    view.setRenderable(publicAPI);
    model.views.push(view);
    publicAPI.modified();
  }; // Remove renderer


  publicAPI.removeView = function (view) {
    model.views = model.views.filter(function (r) {
      return r !== view;
    });
    publicAPI.modified();
  };

  publicAPI.hasView = function (view) {
    return model.views.indexOf(view) !== -1;
  }; // handle any pre render initializations


  publicAPI.preRender = function () {
    model.renderers.forEach(function (ren) {
      // make sure we have a camera
      if (!ren.isActiveCameraCreated()) {
        ren.resetCamera();
      }
    });
  };

  publicAPI.render = function () {
    publicAPI.preRender();

    if (model.interactor) {
      model.interactor.render();
    } else {
      model.views.forEach(function (view) {
        return view.traverseAllPasses();
      });
    }
  };

  publicAPI.getStatistics = function () {
    var results = {
      propCount: 0,
      invisiblePropCount: 0
    };
    model.renderers.forEach(function (ren) {
      var props = ren.getViewProps();
      props.forEach(function (prop) {
        if (prop.getVisibility()) {
          results.propCount += 1;
          var mpr = prop.getMapper && prop.getMapper();

          if (mpr && mpr.getPrimitiveCount) {
            var pcount = mpr.getPrimitiveCount();
            Object.keys(pcount).forEach(function (keyName) {
              if (!results[keyName]) {
                results[keyName] = 0;
              }

              results[keyName] += pcount[keyName];
            });
          }
        } else {
          results.invisiblePropCount += 1;
        }
      });
    });
    results.str = Object.keys(results).map(function (keyName) {
      return "".concat(keyName, ": ").concat(results[keyName]);
    }).join('\n');
    return results;
  };

  publicAPI.captureImages = function () {
    var format = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'image/png';
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    macro.setImmediate(publicAPI.render);
    return model.views.map(function (view) {
      return view.captureNextImage ? view.captureNextImage(format, opts) : undefined;
    }).filter(function (i) {
      return !!i;
    });
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  defaultViewAPI: DEFAULT_VIEW_API,
  renderers: [],
  views: [],
  interactor: null,
  neverRendered: true,
  numberOfLayers: 1
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Build VTK API

  macro.obj(publicAPI, model);
  macro.setGet(publicAPI, model, ['interactor', 'numberOfLayers', 'views', 'defaultViewAPI']);
  macro.get(publicAPI, model, ['neverRendered']);
  macro.getArray(publicAPI, model, ['renderers']);
  macro.event(publicAPI, model, 'completion'); // Object methods

  vtkRenderWindow(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkRenderWindow'); // ----------------------------------------------------------------------------

var vtkRenderWindow$1 = {
  newInstance: newInstance,
  extend: extend,
  registerViewConstructor: registerViewConstructor,
  listViewAPIs: listViewAPIs,
  newAPISpecificView: newAPISpecificView
};

export { vtkRenderWindow$1 as default, extend, listViewAPIs, newAPISpecificView, newInstance, registerViewConstructor };
