import translatePointsBy from '../../../../utils/math/translatePointsBy.js';
import rotatePointsAbout from '../../../../utils/math/rotatePointsAbout.js';

// All lengths are in millimeters

const SHORT_TICK_LENGTH = 12;
const MEDIUM_TICK_LENGTH = 18;
const LONG_TICK_LENGTH = 25;

// The distance we build the graticule
// Can probably shrink this down substantially, but went with a big number to start
const START = -1000;
const END = 1000;

/**
 * @param {[Number, Number]}center
 * @param {Number} angle In radians
 * @return {([Number, Number][])[]}
 */
const buildGraticule = ([x, y], angle) => {
  // Builds the whole thing at 0,0, then translates to the correct position and rotates
  return [
    buildHorizontalLine(),
    buildVerticalLine(),
    ...buildTicks()
  ].map(translatePointsBy(x, y))
    .map(rotatePointsAbout(angle, [x, y]));
};

const buildHorizontalLine = () => {
  return [
    [START, 0],
    [END, 0]
  ];
};

const buildVerticalLine = () => {
  return [
    [0, START],
    [0, END]
  ];
};

const buildTicks = () =>
  // One tick every 1cm/10mm
  new Array((END - START) / 10).fill(0)
    .map((_, i) => (i * 10) + START)
    .flatMap(v => {
      // v is the changing value along each axis
      // we'll get both axes done at the same time
      const tickLength = v % 100 === 0 // 10cm
        ? LONG_TICK_LENGTH
        : v % 50 === 0 // 5cm
          ? MEDIUM_TICK_LENGTH
          : SHORT_TICK_LENGTH;

      return [
        // Horizontal line tick
        [
          [v, -tickLength / 2],
          [v, tickLength / 2]
        ],
        // Vertical line tick
        [
          [-tickLength / 2, v],
          [tickLength / 2, v]
        ]
      ];
    });

export default buildGraticule;