import { REQUEST_FLUSH, REQUEST_COMPLETED, REQUEST_ERRORED, REQUEST_PROCESSED, REQUEST_STARTED } from './types';
import { toQueryString } from '../../query';

const normalizeBody = body =>
  body instanceof Blob || typeof body === 'string' || typeof body !== 'object'
    ? body
    : JSON.stringify(body);

const applyPathParams = (url, pathParams, getDefaultParam) =>
  url.replace(/{([^}]+)}/g, (_, param) => pathParams[param] ?? getDefaultParam(param));

const getDataType = response => {
  const contentType = response.headers.get('Content-Type')?.split(/;/)[0];

  if (!contentType || response.status === 204) return null;
  if (contentType === 'application/json') return 'json';
  if (/^text\//.test(contentType)) return 'text';

  return 'blob';
};

const getMimeType = type => {
  switch (type) {
    case 'json':
      return 'application/json';
    case 'blob':
      return 'application/octet-stream';
    default:
      return 'text/plain';
  }
};

const httpRequest = ({
  method, url, type, options: { body, query = {}, pathParams = {}, ...options }, getDefaultParam, force
}) =>
  fetch(
    applyPathParams(url, pathParams, getDefaultParam)
      + toQueryString({ ...query, ...(force ? { force: Date.now() } : {}) }), {
      method,
      body: normalizeBody(body),
      ...options,
      headers: { 'Content-Type': getMimeType(type), ...options.headers }
    }
  );

const processRequest = async (dispatch, name,
  { method, url, type, options, getDefaultParam, responseHandler, force }) => {
  const abortController = new AbortController();
  const request = httpRequest({
    method, url, type,
    options: { signal: abortController.signal, ...options }, getDefaultParam, force
  });

  dispatch({
    type: REQUEST_STARTED,
    name,
    method,
    url,
    options,
    abortController,
    request
  });

  let response;
  try {
    response = await request;
    const dataType = getDataType(response);
    let data = response.status !== 204 && dataType && await response[dataType]();

    if (dataType === 'json' && data.data) {
      ({ data } = data);
    }

    if (dataType && type && dataType !== type) {
      const error = new Error(`Expected data type ${type}, but type was ${dataType}`);

      dispatch({
        type: REQUEST_ERRORED,
        name,
        error: error.message,
        response,
        request,
        data,
        status: response.status
      });

      responseHandler({ method, url, options, request, response, error, data, dataType });
    } else {
      dispatch({
        type: REQUEST_COMPLETED,
        name,
        response,
        request,
        data,
        dataType,
        status: response.status,
        ok: response.status >= 200 && response.status <= 299,
        completed: true
      });

      responseHandler({ method, url, options, request, response, data, dataType });
    }
  } catch (error) {
    // Note: Aborting will cause this to trigger, but the reducer will see it was an old request
    //  and not actually store the error.
    dispatch({
      type: REQUEST_ERRORED,
      name,
      error,
      request,
      response
    });

    !abortController.signal.aborted
      && responseHandler({ method, url, options, request, response, error });
  }
};

export const httpAction = (name, {
  method, url, type, options = {}, getDefaultParam = () => undefined,
  responseHandler = () => {}, force
}) =>
  dispatch => {
    processRequest(dispatch, name, { method, url, type, options, getDefaultParam, responseHandler, force })
      .catch(error => dispatch({
        type: REQUEST_ERRORED,
        name,
        error
      }));
  };

export const processAction = name => dispatch => {
  dispatch({
    type: REQUEST_PROCESSED,
    name
  });
};

export const flushAction = name => dispatch => {
  dispatch({
    type: REQUEST_FLUSH,
    name
  });
};
