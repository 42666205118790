import { LitElement, html, css } from 'lit';
import { getHidePhi, HidePhiConstants } from '../../utils/phi';

class PhiValue extends LitElement {
  static styles = css`
    [hidePhi=blur] {
      filter: blur(10px);
    }
    
    [hidePhi=redact] {
      color: #000 !important;
      filter: brightness(0);
      background: #000;
    }
  `;

  static properties = {
    hidePhi: { type: String }
  };

  constructor() {
    super();

    this.hidePhi = getHidePhi();

    window.addEventListener(HidePhiConstants.EVENT_NAME, () => {
      this.hidePhi = getHidePhi();
    });
  }

  render() {
    const { hidePhi } = this;

    return html`
      <span hidePhi=${hidePhi}>
        <slot></slot>
      </span>
    `;
  }
}

customElements.define('phi-value', PhiValue);