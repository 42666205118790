import { html, LitElement } from 'lit';

class Error404View extends LitElement {
  render() {
    return html`
      <div class="four-oh">
        <h1>404 - Page Not Found</h1>
      </div>
    `;
  }
}

customElements.define('error-404-view', Error404View);
