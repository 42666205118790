import magnitude from '../math/magnitude';

const roundTo = [0, 1, 2, 5, 10];
const minSteps = 3;

// Note: It is important to use a && b || c instead of a ? b : c here,
//   because normalizeWithIncrement may not give a value and we want it to fall through too.
const normalize = ({ axis, dataMin, dataMax, defaultSteps = 10, defaultSubSteps = 5, maxSteps = 10 }) =>
  (axis.increment && normalizeWithIncrement({ axis, dataMin, dataMax, defaultSteps, defaultSubSteps, maxSteps }))
    || normalizeForSteps({ axis, dataMin, dataMax, defaultSteps, defaultSubSteps, maxSteps });

const normalizeWithIncrement = ({ axis, dataMin, dataMax, defaultSteps, defaultSubSteps, maxSteps }) => {
  const { subSteps, increment, subIncrement, label, transform } = axis;
  const min = axis.min || dataMin;
  const max = axis.max || dataMax;
  const roundedMin = increment * (
    min >= 0
      ? Math.floor(Math.abs(min) / increment)
      : -Math.ceil(Math.abs(min) / increment)
  );
  const roundedMax = increment * (
    max >= 0
      ? Math.ceil(Math.abs(max) / increment)
      : -Math.floor(Math.abs(max) / increment)
  );
  const steps = (Math.abs(roundedMin - roundedMax) / increment) + 1;

  if (steps <= maxSteps) {
    const stepsNeeded = ((roundedMax - roundedMin) / increment) + 1;
    const halfDiff = (minSteps - stepsNeeded) / 2;

    return {
      label,
      min: roundedMin - (stepsNeeded >= minSteps ? 0 : Math.floor(halfDiff) * increment),
      max: roundedMax + (stepsNeeded >= minSteps ? 0 : Math.ceil(halfDiff) * increment),
      steps: Math.max(steps, minSteps),
      subSteps: subSteps || defaultSubSteps,
      increment,
      subIncrement: subIncrement || increment / (subSteps || defaultSubSteps),
      transform: transform || (v => v)
    };
  }

  return undefined;
};

const normalizeForSteps = ({ axis, dataMin, dataMax, defaultSteps, defaultSubSteps, maxSteps }) => {
  const min = axis.min || dataMin;
  const max = axis.max || dataMax;
  const steps = Math.min(axis.steps || defaultSteps, maxSteps);
  const subSteps = axis.subSteps || defaultSubSteps;
  const roundedIncrement = (max - min) / (steps - 1);
  const mag = magnitude(roundedIncrement);
  const increment = roundTo.find(v =>
    v >= roundedIncrement / (10 ** mag)
  ) * (10 ** mag);

  const normalMin = (min >= 0
    ? Math.floor(Math.abs(min) / increment)
    : -Math.ceil(Math.abs(min) / increment)) * increment;

  const normalMax = increment * (
    max >= 0
      ? Math.ceil(Math.abs(max) / increment)
      : -Math.floor(Math.abs(max) / increment)
  );
  const normalSteps = Math.max(minSteps, Math.ceil((normalMax - normalMin) / increment)) + 1;
  const stepsNeeded = ((normalMax - normalMin) / increment) + 1;
  const halfDiff = (minSteps - stepsNeeded) / 2;

  return {
    label: axis.label,
    min: normalMin - (stepsNeeded >= minSteps ? 0 : Math.floor(halfDiff) * increment),
    max: normalMax + (stepsNeeded >= minSteps ? 0 : Math.ceil(halfDiff) * increment),
    steps: normalSteps,
    subSteps,
    increment,
    subIncrement: increment / subSteps,
    transform: axis.transform || (v => v)
  };
};

export default normalize;