import * as cornerstoneTools from '@cornerstonejs/tools';
const {
  Enums: csToolsEnums,
  segmentation
} = cornerstoneTools;

const turnOnSegmentation = async (structureIdNumber, toolGroupId) => {
  const [segmentationRepresentationUID] = await segmentation.addSegmentationRepresentations(toolGroupId, [
    {
      segmentationId: structureIdNumber,
      type: csToolsEnums.SegmentationRepresentations.Labelmap
    }
  ]);

  return segmentationRepresentationUID;
};

export default turnOnSegmentation; 
