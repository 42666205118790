import _defineProperty from '@babel/runtime/helpers/defineProperty';
import macro from '../../macros.js';
import vtkFieldData from './DataSetAttributes/FieldData.js';
import Constants from './DataSetAttributes/Constants.js';
import vtkDataArray from '../Core/DataArray.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var AttributeTypes = Constants.AttributeTypes,
    AttributeCopyOperations = Constants.AttributeCopyOperations;
var vtkWarningMacro = macro.vtkWarningMacro; // ----------------------------------------------------------------------------
// vtkDataSetAttributes methods
// ----------------------------------------------------------------------------

function vtkDataSetAttributes(publicAPI, model) {
  var attrTypes = ['Scalars', 'Vectors', 'Normals', 'TCoords', 'Tensors', 'GlobalIds', 'PedigreeIds'];

  function cleanAttributeType(attType) {
    // Given an integer or string, convert the result to one of the
    // strings in the "attrTypes" array above or null (if
    // no match is found)
    var cleanAttType = attrTypes.find(function (ee) {
      return AttributeTypes[ee.toUpperCase()] === attType || typeof attType !== 'number' && ee.toLowerCase() === attType.toLowerCase();
    });

    if (typeof cleanAttType === 'undefined') {
      cleanAttType = null;
    }

    return cleanAttType;
  } // Set our className


  model.classHierarchy.push('vtkDataSetAttributes');

  publicAPI.checkNumberOfComponents = function (x) {
    return true;
  }; // TODO


  publicAPI.setAttribute = function (arr, uncleanAttType) {
    var attType = cleanAttributeType(uncleanAttType);

    if (arr && attType.toUpperCase() === 'PEDIGREEIDS' && !arr.isA('vtkDataArray')) {
      vtkWarningMacro("Cannot set attribute ".concat(attType, ". The attribute must be a vtkDataArray."));
      return -1;
    }

    if (arr && !publicAPI.checkNumberOfComponents(arr, attType)) {
      vtkWarningMacro("Cannot set attribute ".concat(attType, ". Incorrect number of components."));
      return -1;
    }

    var currentAttribute = model["active".concat(attType)];

    if (currentAttribute >= 0 && currentAttribute < model.arrays.length) {
      if (model.arrays[currentAttribute] === arr) {
        return currentAttribute;
      }

      publicAPI.removeArrayByIndex(currentAttribute);
    }

    if (arr) {
      currentAttribute = publicAPI.addArray(arr);
      model["active".concat(attType)] = currentAttribute;
    } else {
      model["active".concat(attType)] = -1;
    }

    publicAPI.modified();
    return model["active".concat(attType)];
  };

  publicAPI.setActiveAttributeByName = function (arrayName, attType) {
    return publicAPI.setActiveAttributeByIndex(publicAPI.getArrayWithIndex(arrayName).index, attType);
  };

  publicAPI.setActiveAttributeByIndex = function (arrayIdx, uncleanAttType) {
    var attType = cleanAttributeType(uncleanAttType);

    if (arrayIdx >= 0 && arrayIdx < model.arrays.length) {
      if (attType.toUpperCase() !== 'PEDIGREEIDS') {
        var arr = publicAPI.getArrayByIndex(arrayIdx);

        if (!arr.isA('vtkDataArray')) {
          vtkWarningMacro("Cannot set attribute ".concat(attType, ". Only vtkDataArray subclasses can be set as active attributes."));
          return -1;
        }

        if (!publicAPI.checkNumberOfComponents(arr, attType)) {
          vtkWarningMacro("Cannot set attribute ".concat(attType, ". Incorrect number of components."));
          return -1;
        }
      }

      model["active".concat(attType)] = arrayIdx;
      publicAPI.modified();
      return arrayIdx;
    }

    if (arrayIdx === -1) {
      model["active".concat(attType)] = arrayIdx;
      publicAPI.modified();
    }

    return -1;
  };

  publicAPI.getActiveAttribute = function (attType) {
    // Given an integer enum value or a string (with random capitalization),
    // find the matching string in attrTypes.
    var cleanAttType = cleanAttributeType(attType);
    return publicAPI["get".concat(cleanAttType)]();
  }; // Override to allow proper handling of active attributes


  publicAPI.removeAllArrays = function () {
    model.arrays = [];
    attrTypes.forEach(function (attType) {
      model["active".concat(attType)] = -1;
    });
  }; // Override to allow proper handling of active attributes


  publicAPI.removeArray = function (arrayName) {
    model.arrays = model.arrays.filter(function (entry, idx) {
      if (arrayName === entry.data.getName()) {
        // Found the array to remove, but is it an active attribute?
        attrTypes.forEach(function (attType) {
          if (idx === model["active".concat(attType)]) {
            model["active".concat(attType)] = -1;
          }
        });
        return false;
      }

      return true;
    });
  }; // Override to allow proper handling of active attributes


  publicAPI.removeArrayByIndex = function (arrayIdx) {
    model.arrays = model.arrays.filter(function (entry, idx) {
      return idx !== arrayIdx;
    });
    attrTypes.forEach(function (attType) {
      if (arrayIdx === model["active".concat(attType)]) {
        model["active".concat(attType)] = -1;
      }
    });
  };

  attrTypes.forEach(function (value) {
    var activeVal = "active".concat(value);

    publicAPI["get".concat(value)] = function () {
      return publicAPI.getArrayByIndex(model[activeVal]);
    };

    publicAPI["set".concat(value)] = function (da) {
      return publicAPI.setAttribute(da, value);
    };

    publicAPI["setActive".concat(value)] = function (arrayName) {
      return publicAPI.setActiveAttributeByIndex(publicAPI.getArrayWithIndex(arrayName).index, value);
    };

    publicAPI["copy".concat(value, "Off")] = function () {
      var attType = value.toUpperCase();
      model.copyAttributeFlags[AttributeCopyOperations.PASSDATA][AttributeTypes[attType]] = false;
    };
  });

  publicAPI.initializeAttributeCopyFlags = function () {
    // Default to copying all attributes in every circumstance:
    model.copyAttributeFlags = [];
    Object.keys(AttributeCopyOperations).filter(function (op) {
      return op !== 'ALLCOPY';
    }).forEach(function (attCopyOp) {
      model.copyAttributeFlags[AttributeCopyOperations[attCopyOp]] = Object.keys(AttributeTypes).filter(function (ty) {
        return ty !== 'NUM_ATTRIBUTES';
      }).reduce(function (a, b) {
        a[AttributeTypes[b]] = true;
        return a;
      }, []);
    }); // Override some operations where we don't want to copy:

    model.copyAttributeFlags[AttributeCopyOperations.COPYTUPLE][AttributeTypes.GLOBALIDS] = false;
    model.copyAttributeFlags[AttributeCopyOperations.INTERPOLATE][AttributeTypes.GLOBALIDS] = false;
    model.copyAttributeFlags[AttributeCopyOperations.COPYTUPLE][AttributeTypes.PEDIGREEIDS] = false;
  };

  publicAPI.initialize = macro.chain(publicAPI.initialize, publicAPI.initializeAttributeCopyFlags); // Process dataArrays if any

  if (model.dataArrays && Object.keys(model.dataArrays).length) {
    Object.keys(model.dataArrays).forEach(function (name) {
      if (!model.dataArrays[name].ref && model.dataArrays[name].type === 'vtkDataArray') {
        publicAPI.addArray(vtkDataArray.newInstance(model.dataArrays[name]));
      }
    });
  }

  var superShallowCopy = publicAPI.shallowCopy;

  publicAPI.shallowCopy = function (other, debug) {
    superShallowCopy(other, debug);
    model.arrays = other.getArrays().map(function (arr) {
      var arrNew = arr.newClone();
      arrNew.shallowCopy(arr, debug);
      return {
        data: arrNew
      };
    });
  };

  publicAPI.initializeAttributeCopyFlags();
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  activeScalars: -1,
  activeVectors: -1,
  activeTensors: -1,
  activeNormals: -1,
  activeTCoords: -1,
  activeGlobalIds: -1,
  activePedigreeIds: -1
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Object methods

  vtkFieldData.extend(publicAPI, model, initialValues);
  macro.setGet(publicAPI, model, ['activeScalars', 'activeNormals', 'activeTCoords', 'activeVectors', 'activeTensors', 'activeGlobalIds', 'activePedigreeIds']);

  if (!model.arrays) {
    model.arrays = {};
  } // Object specific methods


  vtkDataSetAttributes(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkDataSetAttributes'); // ----------------------------------------------------------------------------

var vtkDataSetAttributes$1 = _objectSpread({
  newInstance: newInstance,
  extend: extend
}, Constants);

export { vtkDataSetAttributes$1 as default, extend, newInstance };
