﻿import { html, css, LitElement } from 'lit';
import '../auth-header';
import adminStyles from '../styles-admin';
import '../../../common/rad-confirm';
import '../../../common/check-box';
import { removeValue, distinct } from '../../../../utils/immutable/array';
import http from '../../../../utils/redux/http';
import { connect } from '../../../../utils/redux';
import { store } from '../../../../redux/store';
import fontAwesome from '../../../../utils/font-awesome';

class PermissionsView extends connect(store)(LitElement) {
  static styles = [
    adminStyles,
    css`    
      :host {
        position: relative;
      }
  
      main {
        display: flex;
      }    
      
      rad-confirm {
        --min-width: 400px;
      }
  
      rad-confirm:not([active]) {
        display: none;
        background: #F00;
      }
  
      #products {
        border-right: 1px solid var(--side-border);
      }
      
      #products h2 {
        border: solid var(--neutral-6);
        border-width: 0 0 1px;
        padding: 0 16px 10px;
        margin: 0 -16px 16px;
        font-size: 18px;
        line-height: 1.3333333;
      }
      
      #products ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      
      #products li {
        font-size: 14px;
        line-height: 1.5;
      }
      
      #permissions {
        flex: 1;
        padding: 16px;
      }
      
      #permissions h1 {
        font-size: 40px;
        font-weight: 600;
      }
      
      #permissions th:first-child {
        width: 250px;
      }
  
      .fa-trash-alt[disabled] {
        cursor: none;
        color: var(--neutral-6);
      }
  
      .fa-trash-alt:not([disabled]):hover {
        cursor: pointer;
        color: var(--primary-color);
      }
  
      .fa-plus:hover {
        cursor: pointer;
        color: var(--primary-color);
      }
    `
  ].flat();

  static properties = {
    permissions: { type: Array },
    roles: { type: Array },
    products: { type: Array },
    mappedPermissions: { type: Object },
    mappedRoles: { type: Object },
    mappedProducts: { type: Object },
    productRoles: { type: Array },
    productPermissions: { type: Array },
    newPermissions: { type: Object },
    showSaved: { type: Boolean },
    selectedRoleId: { type: Object },
    selectedProductId: { type: Object },
    showRoleDelete: { type: Boolean },
    rolesToDelete: { type: Array },
    canDelete: { type: Boolean }
  };

  updated(changed) {
    if (changed.has('selectedRoleId') && this.selectedRoleId !== undefined) {
      this.updateComplete.then(() => {
        const element = this.shadowRoot.querySelector('#roleName');

        element?.focus();
      });
    }

    if (changed.has('canDelete') && this.canDelete) {
      this.updateComplete.then(()=> this.handleRoleDelete);
    }
  }
  
  constructor() {
    super();
        
    this.showRoleDelete = false;
    this.newPermissions = {};

    this.requestNames = {};
    this.loadData();
  }
  
  render() {
    const { products, showSaved, showRoleDelete, handleSave, handleSavedComplete, renderPermissionsTable,
      handleRoleDeleteCancel } = this;

    if (!products) return;
    
    return html`
      ${fontAwesome}
      <auth-header
        savedMessage="Permissions saved successfully!"
        ?showSaved=${showSaved}
        @save=${handleSave}
        @saved-complete=${handleSavedComplete}
      ></auth-header>
      <main>
        <div id="products" class="sidebar">
          <h2>Products</h2>
          <ul>
            ${products.map(({ name, id }) => html`
              <li>${name}</li>
            `)}
          </ul>
        </div>
        <div id="permissions" class="content">
          ${products.map(({ name, id }) => html`
            <h1>${name}</h1>
            ${renderPermissionsTable(id)}
          `)}        
        </div>
      </main>
      <rad-confirm
          confirmText=""
          cancelText="Ok"
          @cancel=${handleRoleDeleteCancel}
          ?active=${showRoleDelete}
        >
          <h3>Role ${this.roles.find(r=>r.id === this.selectedRoleId)?.name} cannot be deleted.</h3>
          <p>
            There are ${this.roles.find(r=>r.id === this.selectedRoleId)?.userCount} users assigned.
          </p>
      </rad-confirm>
    `;
  }
  
  renderPermissionsTable = productId => {
    const { productPermissions, roles, mappedPermissions,
      isChecked, handlePermissionChange, handleRoleAdd, handleRoleDelete, handleRoleSelect, handleRoleBlur,
      handleRoleKeyPressDown } = this;
    
    return html`
      <table>
        <thead>
          <tr>
            <th>Permissions</th>
            ${roles.map(({ name, id }) =>
              this.selectedRoleId !== undefined 
              && this.selectedProductId === productId 
              && (this.selectedRoleId === id || name === 'New Role')
                ? html`
                  <th 
                    @click=${handleRoleSelect}
                    .roleId=${id}
                    .productId=${productId}
                  ><input type=text
                    id=roleName
                    @blur=${handleRoleBlur}
                    @keypress=${handleRoleKeyPressDown}
                    .roleId=${id}
                    placeholder="New Role"
                    value=${name}
                  ></th>`
                : html`
                  <th 
                    @click=${handleRoleSelect}                
                    .roleId=${id}
                    .productId=${productId}
                  >${name}</th>
                `                          
            )}            
            <th>
            <div class="add-delete-wrapper">
            <span
              @click=${handleRoleAdd}
              .productId=${productId}
            ><i class="fas fa-plus"></i></span>
            <span
              @click=${handleRoleDelete}              
              .productId=${productId}
              ?disabled=${this.selectedRoleId === undefined}
            ><i class="far fa-trash-alt" ?disabled=${this.selectedRoleId === undefined}></i></span>
          </div>
            </th>
          </tr>
        </thead>
        <tbody>
          ${productPermissions[productId].map(permissionId => html`
            <tr>
              <th>${mappedPermissions[permissionId].name}</th>
              ${roles.map(({ id: roleId }) => html`
                <td>
                  <check-box
                    ?checked=${isChecked(productId, roleId, permissionId)}
                    @change=${handlePermissionChange}
                    .productId=${productId}
                    .roleId=${roleId}
                    .permissionId=${permissionId}
                  />
                </td>
              `)}
            </tr>
          `)}
        </tbody>
      </table>  
    `;
  };
  
  isChecked = (productId, roleId, permissionId) => {
    const { mappedProducts, newPermissions } = this;
    
    if (newPermissions[productId] && newPermissions[productId][roleId]) {
      return newPermissions[productId][roleId].includes(permissionId);
    }
    
    return mappedProducts[productId]
      ?.rolePermissions.find(({ role }) => role === roleId)
      ?.permissions.includes(permissionId);
  };
  
  handleSave = async () => {
    const { newPermissions, mappedProducts, roles } = this;

    const rolesToSave = roles.map(({ id, name }) => ({
      id,
      name,
      permissions: distinct(
        Object.values(newPermissions).some(rolePermissions => rolePermissions[id])
          ? Object.values(newPermissions).flatMap(rolePermissions => rolePermissions[id] || [])
          : Object.values(mappedProducts)
            .flatMap(({ rolePermissions }) =>
              rolePermissions?.find(({ role }) => role === id)?.permissions ?? []
            )
      )
    }));

    this.showSaved=true;

    http.put('./auth/api/organization/{organization}/permissions', { body: { roles: rolesToSave } });
  };
  
  handleSavedComplete = () => {
    this.showSaved = false;
  };
  
  handlePermissionChange = ({ currentTarget: { permissionId, roleId, productId }, detail: { checked } }) => {
    const { newPermissions, mappedProducts } = this;
    
    const currentRolePermissions = mappedProducts[productId]?.rolePermissions
      ?.find(({ role }) => role === roleId)?.permissions ?? [];
    
    this.newPermissions = { 
      ...newPermissions,
      [productId]: {
        ...(newPermissions[productId] || {}),
        [roleId]: checked 
          ? [...((newPermissions[productId] || {})[roleId] || currentRolePermissions), permissionId] 
          : removeValue((newPermissions[productId] || {})[roleId] || currentRolePermissions, permissionId)
      }
    };
  };

  handleRoleBlur = event => {
    this.handleRenameRole(event.composedPath()[0].value, event.composedPath()[0].roleId);

    this.canDelete = true;

    document.activeElement?.blur();
  };

  handleRoleKeyPressDown = event => {
    if (event.keyCode === 13) {
      document.activeElement?.blur();
    }
  };

  handleRoleSelect = ({ currentTarget: { roleId, productId } }) => {
    this.selectedRoleId = roleId;
    this.selectedProductId = productId;
  };

  handleRoleAdd = ({ currentTarget: { productId } }) => {
    const { roles, generateTemporaryId } = this;
    const tempId = generateTemporaryId();

    this.selectedRoleId = tempId;
    this.selectedProductId = productId;

    const newRole = { 
      id: tempId,
      name: '',
      permissions: []
    };

    this.roles = [...roles, newRole];    
  };

  handleRoleDelete = () => {
    if (this.selectedRoleId === undefined) return;

    const { rolesToDelete } = this;
    const roleToRemove = this.roles.find(r=> r.id === this.selectedRoleId);

    if (roleToRemove.userCount > 0) {
      this.showRoleDelete = true;      
      return;
    }

    this.rolesToDelete = [...(rolesToDelete ?? []), roleToRemove];
    this.roles = [...removeValue(this.roles, roleToRemove)];
    
    this.selectedRoleId = undefined;
    this.selectedProductId = undefined;
    this.canDelete = false;
  };

  handleRoleDeleteCancel = () => {
    this.showRoleDelete = false;
  };

  handleRenameRole = (name, roleId) => {
    const role = this.roles.find(r => r.id === roleId);

    if (role) {
      role.name = name;
    }
  };
  
  generateTemporaryId = () =>
    `temp${Date.now()}`;

  loadData = () => {
    this.requestNames.get = http.get('./auth/api/organization/{organization}/permissions');
  };

  updatePermissions = result => {
    const { products, permissions, roles } = result;

    const mappedPermissions = permissions.reduce((result, { id, ...rest }) => ({ ...result, [id]: rest }), {});
    const mappedRoles = roles.reduce((result, { id, ...rest }) => ({ ...result, [id]: rest }), {});
    
    const { productRoles, productPermissions } = products.reduce(
      ({ productRoles, productPermissions }, { id }) => {
        const thisProductPermissions = permissions.filter(({ applicableProducts }) => applicableProducts.includes(id))
          .map(({ id }) => id);
        const thisProductRoles = roles.filter(({ permissions }) =>
          permissions.some(permissionId => thisProductPermissions.find(id => id === permissionId))
        ).map(({ id }) => id);

        return {
          productRoles: {
            ...productRoles,
            [id]: thisProductRoles
          },
          productPermissions: {
            ...productPermissions,
            [id]: thisProductPermissions
          }
        };
      }, { productRoles: {}, productPermissions: {} });

    const mappedProducts = products.reduce((result, { id, ...rest }) => ({ ...result, [id]: {
      ...rest,
      rolePermissions: productRoles[id].map(role => ({ role, permissions: mappedRoles[role].permissions }))
    } }), {});
    
    Object.assign(this, { products, permissions, roles, productRoles, productPermissions, mappedProducts, 
      mappedPermissions, mappedRoles });
  };

  stateChanged(state) {
    const { requestNames, updatePermissions } = this;

    const getResponse = http.getRequest(requestNames.get, state);
    const putResponse = http.getRequest(requestNames.put, state);

    if (getResponse?.ok) {
      updatePermissions(getResponse.data);
      http.flush(getResponse);
    }

    if (putResponse?.ok) {
      this.showSaved = true;
      http.flush(putResponse);
    }
  }
}

customElements.define('permissions-view', PermissionsView);