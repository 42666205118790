import { html, css, LitElement, unsafeCSS } from 'lit';
import h from '../../../utils/h';
import fontAwesome from '../../../utils/font-awesome';
import sidebarStyle from './styles/sidebar';
import {
  meSelector,
  organizationIdSelector,
  settingsSelector,
  showOverlaySelector
} from '../../../redux/app/selectors';
import { connect } from '../../../utils/redux';
import { store } from '../../../redux/store';
import { styleMap } from 'lit/directives/style-map.js';
import { ASSIGN_CHECK_TEMPLATE_PERMISSION } from './common';
import { hasPermission } from './utils';
import openInPopout from '../../../utils/openInPopout';
import getDocumentViewUrl from '../../../utils/getDocumentViewUrl';
import { toDateTime } from '../../../utils/date/format';
import '../../rad-overlay';
import PassState from '../../../PassState';

const PASS = unsafeCSS(PassState.PASS);
const VERIFIED = unsafeCSS(PassState.VERIFIED);
const FAIL = unsafeCSS(PassState.FAIL);
const CONDITION = unsafeCSS(PassState.CONDITION);
const OVERRIDE = unsafeCSS(PassState.OVERRIDE);
const INDETERMINATE = unsafeCSS(PassState.INDETERMINATE);
const EXCEPTION = unsafeCSS(PassState.EXCEPTION);

const sectionUrl = sectionId =>
    `${location.pathname}#${sectionId}`;

class SectionsSidebar extends connect(store)(LitElement) {
    static styles = [
      sidebarStyle,
      css`
        :host {
          display: flex;
          flex-direction: column;
        }

        /* @TODO Remove after back-end is fixed */
        .nested h3 .status {
            display: none !important;
        }

        .quicknav span {
          right: 16px;
          position: absolute;
          width: 28px;
          font-weight: normal;
          text-align: center;
        }

        .quicknav span.status:before {
          font-family: 'Font Awesome 5 Pro';
          position: relative;
          margin: 1px 6px auto auto;
          font-weight: 900;
        }
        
        .quicknav span.status[pass-state=${FAIL}]:before {
          content: '\uF00D';
        }
        
        .quicknav span.status[pass-state=${PASS}]:before,
        .quicknav span.status[pass-state=${VERIFIED}]:before {
          content: '\uF00C';
        }


        .quicknav span.status[pass-state=${CONDITION}]:before {
          content: '\uF2EC';
          font-weight: 500;
        }

        .quicknav span.status[pass-state=${OVERRIDE}]:before {
          content: '\uF12A';
        }
        
        .quicknav span.status[pass-state=${INDETERMINATE}]:before {
          content: '\uf128';
        }
        
        .quicknav span.status[pass-state=${EXCEPTION}]:before {
          content: '\uf06a';
          color: #F00 !important;
        }

        h2.quicknav {
          margin: 6px -16px 16px;
          padding: 0 16px 10px;
        }

        ul.quicknav {
          --indent: 25px;
          flex: 1;
          overflow: auto;
        }

        ul.quicknav > li.nested > ul > li > ul > li {
          margin-left: -12px;
          padding-left: calc(40px + var(--indent));
        }

        ul.quicknav > li.nested > ul > li:before,
        ul.quicknav > li.nested > ul > li > ul > li:before {
          content: ' ';
          position: absolute;
          display: block;
          width: 6px;
          height: 16px;
          border: solid var(--neutral-6);
          border-width: 0 0 1px 0;
          left: 16px;
          top: 0;
        }

        ul.quicknav > li.nested > ul > li > h3 {
          margin: -5px -16px 0 -28px;
          padding: 6px 16px 6px 28px;
        }

        ul.quicknav > li.nested > ul > li > ul > li:before {
          left: calc(28px + var(--indent));
          border-width: 0 0 1px 1px;
        }

        li ul li {
          padding-left: 28px; /* 16px * 2 */
        }

        [current] {
          background: var(--hover-color);
        }

        button {
          font-size: 13px;
          font-weight: 400;
          padding: 12px;
          min-width: 96px;
          border: none;
          background: var(--primary-color);
          transition: all 0.25s ease 0s;
          margin: 8px 0 12px;
          outline: none;
          color: white;
          border-radius: 5px;
          cursor: pointer;
        }

        button:hover {
          background: var(--button-hover-color);
        }

        select {
          display: block;
          margin-top: 4px;
        }

        select[unassigned] {
          background: #C00;
          color: var(--light-color);
        }

        .documents {
          flex: 0 0 290px;
          display: flex;
          flex-direction: column;
          overflow: auto;
          margin: 0 -16px -16px;
          padding: 0 16px 0;
          border-top: 1px solid #CCC;
        }

        .documents h3 {
          font-size: 1.2rem;
          margin: 0;
          line-height: 1.8;
        }

        .documents ul {
          overflow: hidden auto;
          flex: 1;
        }

        .documents li {
          font-weight: normal;
          line-height: 16px;
          cursor: pointer;
          padding-left: 32px;
          position: relative;
        }

        .documents li span {
          white-space: nowrap;
          display: block;
        }

        .documents li:before {
          font-family: 'Font Awesome 5 Pro';
          font-weight: 500;
          content: '\uf15b';
          color: #333;
          position: absolute;
          left: 16px;
          top: 6px;
        }

        .documents li[doc-type="pdf"]:before {
          content: '\uf1c1';
        }

        .documents li[doc-type="word"]:before {
          content: '\uf1c2';
        }

        .documents li[doc-type="image"]:before,
        .documents li[doc-type="tiff"]:before {
          content: '\uf1c5';
        }

        .documents li a {
          position: absolute;
          top: 2px;
          right: 0;
          padding: 3px 19px 3px;
          background: var(--light-color); /* same as main background */
        }

        .documents li:hover a {
          background: var(--hover-color); /* same as main hover */
        }

        .documents li a:after {
          font-family: 'Font Awesome 5 Pro';
          font-weight: 900;
          content: '\uf08e';
        }

        .documents input {
          line-height: 2;
          border-radius: 4px;
          border: 1px solid var(--neutral-5);
          padding: 4px 8px;
          outline: var(--primary-3);
          margin-bottom: 4px;
        }

        [date] {
          font-size: .8em;
        }

        info-icon:before {
          cursor: pointer;
          font-family: 'Font Awesome 5 Pro';
          content: '\uf059';
          font-weight: 900;
        }
      `
    ].flat();

    static properties = {
      sections: { type: Array },
      courseSections: { type: Array },
      planSections: { type: Array },
      patient: { type: Object },
      currentSection: { type: String },
      settings: { type: Object },
      checkTemplate: { type: Array },
      currentTemplates: { type: Object },
      patientSettingsRevision: { type: Number },
      latestSettingsRevision: { type: Number },
      me: { type: Object },
      organizationId: { type: String },
      documentsFilter: { type: String },
      showOverlay: { type: Boolean }
    };

    constructor() {
      super();

      this.documentsFilter = '';

      this.settings = settingsSelector();
    }

    render() {
      const { sections, renderSection, renderCourseSections, handleTemplateSave, patient, currentTemplates,
        latestSettingsRevision, patientSettingsRevision, checkTemplates, showOverlay } = this;

      const showUpdateButton = checkTemplates?.length
        && (
          (currentTemplates && Object.values(currentTemplates).filter(Boolean).length)
            || patient.courses.some(({ plans }) =>
              plans.some(({ checkTemplate: { id, revisionNumber, wasAutoAssigned } }) => {
                // If it is deleted, we consider it the latest if it is within 1 of the most recent revisionNumber
                //   otherwise, it should be exactly the revision number.
                const latestVersion = checkTemplates?.find(t => t.id === id && t.isLatest);

                return latestVersion?.revisionNumber - (latestVersion?.isDeleted ? 1 : 0) !== revisionNumber
                  || wasAutoAssigned;
              })
            ) || patientSettingsRevision !== latestSettingsRevision
        );

      return html`
      ${fontAwesome}
      ${h(showUpdateButton, html`
        <button @click=${handleTemplateSave}>Apply Check Templates Update</button>
      `)}
      <ul class="quicknav">
        <li style="font-size: 18px; line-height: 20px;">
          <phi-value .sectionId=${'top'} @click=${this.handleSectionClick}>
            ${patient.lastName}, ${patient.firstName}
            <span style="color: var(--neutral-4); position: sticky; font-size: 16px;">(${patient.id})</span>
          </phi-value>
        </li>
        ${sections.filter(({ condition = () => true }) => condition(patient)).map(({ id, title, courses }) => html`
          ${courses
                ? renderCourseSections()
                : renderSection(id, title, null)
            }
        `)}
      </ul>
      ${this.renderDocumentList()}
      ${h(showOverlay, html`<rad-overlay></rad-overlay>`)}
    `;
    }

    renderDocumentList = () => {
      const { patient, documentsFilter, handleDocumentOpen, handleDocumentPopout, handleDocumentFilter } = this;

      // If patient.displayDocuments is undefined, we don't show the documents list.
      if (!patient.displayDocuments) return;

      return html`
      <div class="documents">
        <h3>Documents</h3>
        <input placeholder="Type to filter..." @input=${handleDocumentFilter}>
        <ul @click=${handleDocumentOpen}>
          ${patient.displayDocuments
                ?.filter(({ templateId, type }) => {
                    const words = documentsFilter.split(' ').map(w => w.toLowerCase());

                    return words.every(word =>
                        templateId.toLowerCase().includes(word)
                        || type.toLowerCase().includes(word)
                    );
                })
                ?.map(({ displayDocumentId, templateId, type, date }) => html`
              <li
                doc-type=${type}
                .docType=${type}
                .docId=${displayDocumentId}
                title=${templateId}
              >
                <span>${templateId}</span>
                <span date>${h(date, () => toDateTime(date))}</span>
                <a href=${getDocumentViewUrl(displayDocumentId, type)} @click=${handleDocumentPopout}></a>
              </li>
          `)}
        </ul>
      </div>
    `;
    };

    renderSection = (id, title, errors) => {
      const { currentSection, handleSectionClick, renderStatus } = this;

      return html`
      <li
        .sectionId=${id}
        ?current=${currentSection === id}
        ?errors=${errors}
        @click=${handleSectionClick}
      >
        <a href=${sectionUrl(id)}>
          ${title}
          ${renderStatus(errors)}
        </a>
      </li>
    `;
    };

    renderCourseSections = () => {
      const { currentSection, courseSections, renderSection, renderPlanSections, handleSectionClick, patient,
        renderStatus } = this;

      return html`
      ${patient.courses.map((course, index) => {
            const courseNumber = index + 1;
            const sectionId = `course${courseNumber}`;

            return html`
          <li class="nested">
            <h3
              .sectionId=${sectionId}
              ?current=${currentSection === sectionId}
              @click=${handleSectionClick}
            >
              <a href=${sectionUrl(sectionId)}>
                Course: ${course.id}
                ${renderStatus(course.totalErrorCount)}
              </a>
            </h3>
            <ul>
              ${courseSections.filter(({ condition = () => true }) => condition(patient, course))
                    .map(({ id, title, errorKey, plans }) =>
                        plans
                            ? renderPlanSections(course, sectionId)
                            : renderSection(`${sectionId}-${id}`, title, course.errorCounts[errorKey])
                    )}
            </ul>
          </li>
        `;
        })}
    `;
    };

    renderPlanSections = (course, courseSectionId) => {
      const { currentSection, planSections, patient, renderPlanSection, renderCheckTemplate,
        handleSectionClick } = this;
      const { plans } = course;

      return plans
        .map((plan, index) => {
          const planNumber = index + 1;
          const sectionId = `${courseSectionId}-plan${planNumber}`;

          return html`
          <li class="nested">
            <h3
              .sectionId=${sectionId}
              ?current=${currentSection === sectionId}
              @click=${handleSectionClick}
            >
              <a href=${sectionUrl(sectionId)}>
                Plan: ${plan.id}
              </a>
            </h3>
            <ul>
              ${planSections.filter(({ condition = () => true }) => condition(patient, plan))
                        .map(({ id, title, errorKey, checkTemplate }) =>
                            checkTemplate ? renderCheckTemplate(plan, title)
                                : renderPlanSection(plan, sectionId, id, title, errorKey))}
            </ul>
          </li>
        `;
        });
    };

    renderPlanSection = (plan, planSectionId, id, title, errorKey) => {
      const { currentSection, handleSectionClick, renderStatus } = this;

      const errors = plan.errorCounts[errorKey];
      const sectionId = `${planSectionId}-${id}`;

      return html`
      <li
        .sectionId=${sectionId}
        ?current=${currentSection === sectionId}
        ?errors=${errors}
        @click=${handleSectionClick}
      >
        <a href=${sectionUrl(sectionId)}>
          ${title}
          ${renderStatus(errors)}
        </a>
      </li>
    `;
    };

    renderCheckTemplate = (plan, title) => {
      const { checkTemplates, currentTemplates, me, organizationId, handleTemplateChange } = this;

      const current = currentTemplates[plan.ser];
      const selected = checkTemplates?.find(({ id }) => id === plan.checkTemplate.id);
      const { wasAutoAssigned } = plan.checkTemplate;
      const selectedIsPatientTemplate = !current && checkTemplates?.filter(n =>
        n.name === selected?.name && n.folder === selected?.folder && !n.isDeleted && n.id !== selected?.id).length;

      return html`
      <li
        .plan=${plan}
      >
        ${title}
        ${h(selectedIsPatientTemplate, () => html`
          <info-icon title=
            "This plan uses a deleted check template, &#10;and a new template with the same name has been created."
          ></info-icon>
        `)}
        ${h(hasPermission(ASSIGN_CHECK_TEMPLATE_PERMISSION, me, organizationId), html`
          <select @change=${handleTemplateChange} ?unassigned=${wasAutoAssigned && !current}>
            ${h(wasAutoAssigned, html`<option selected disabled hidden>-- Select Template --</option>`)}
            ${h(!checkTemplates?.length, () => html`<option>-- Loading --</option>`)}
            ${h(checkTemplates?.length, () => checkTemplates
                ?.filter(checkTemplate => !checkTemplate.isDeleted
                    || current?.id === checkTemplate.id
                    || selected?.id === checkTemplate.id
                )?.map(checkTemplate => html`
                <option
                  .checkTemplate=${checkTemplate}
                  ?selected=${(current ? current === checkTemplate : selected === checkTemplate)}
                >
                    ${checkTemplate.name} ${checkTemplate.folder && `(${checkTemplate.folder})`}
                    ${h(checkTemplate === selected && selectedIsPatientTemplate, html`(patient)`)}
                </option>
              `)
            )}
          </select>
        `)}
        ${h(!hasPermission(ASSIGN_CHECK_TEMPLATE_PERMISSION, me, organizationId), html`
          ${h(wasAutoAssigned, html`<div>No template assigned</div>`)}
          ${h(
                selected && !wasAutoAssigned,
                () => html`<div>${selected.name} ${selected.folder && `(${selected.folder})`}</div>`
            )}
        `)}
      </li>
    `;
    };

    renderStatus = errors => {
      const { passState } = errors ?? {};
      const { settings: { colors } } = this;

      return html`
        <span
          class="status"
          pass-state=${passState}
          style=${styleMap({ color: colors[passState?.toLowerCase()] ?? 'inherit' })}
        ></span>
      `;
    };

    handleDocumentOpen = event => {
      const { docId, docType } = event.target.closest('li');

      event.stopPropagation();

      if (!docId || !docType) return;

      this.dispatchEvent(new CustomEvent('document-open', {
        detail: { id: docId, type: docType },
        bubbles: true,
        composed: true
      }));
    };

    handleDocumentPopout = event => {
      const href = event.currentTarget.getAttribute('href');

      event.preventDefault();
      event.stopPropagation();

      openInPopout(href);
    };

    handleDocumentFilter = event => {
      const { value } = event.currentTarget;

      this.documentsFilter = value;
    }

    getPlanIdBySer = planSer => {
      const { patient } = this;

      return patient.courses?.find(course => course.plans.find(plan => plan.ser === planSer))?.id;
    };

    handleTemplateChange = event => {
      const { currentTemplates } = this;
      const { target } = event;
      const template = target.querySelector(':checked').checkTemplate;
      const { plan } = target.closest('li');

      this.dispatchEvent(new CustomEvent('template-change', { detail: { ...currentTemplates, [plan.ser]: template } }));
    };

    handleTemplateSave = () => {
      this.dispatchEvent(new CustomEvent('template-save'));
    };

    handleSectionClick(event) {
      this.dispatchEvent(new CustomEvent('section-change', {
        bubbles: true,
        detail: {
          sectionId: event.currentTarget.sectionId
        }
      }));
    }

    stateChanged(state) {
      this.showOverlay = showOverlaySelector(state);
      this.settings = settingsSelector(state);
      this.me = meSelector(state);
      this.organizationId = organizationIdSelector(state);
    }
}

customElements.define('sections-sidebar', SectionsSidebar);