import { html, css, LitElement } from 'lit';
import '../../common/check-box.js';
import '../../common/color-square.js';
import removeValue from '../../../utils/immutable/array/removeValue.js';
import toggleSegmentationVisibility from './utils/toggleSegmentationVisibility.js';
import h from '../../../utils/h';
import { ImageTypes } from './utils/initializeDicomGroup.js';
import normalizeToRGBAString from '../../../utils/color/normalizeToRGBAString.js';
import { replaceValue } from '../../../utils/immutable/array/index.js';

const defaultEnabledStructures = ['PTV', 'EXTERNAL', 'LAYER', 'CHARTCHECK'];

class StructureToggle extends LitElement {
  static styles = css`
    :host {
      overflow: visible;
      width: 200px;
      z-index: 50;
      z-index: 99999;
    }
    
    :host > div {
      position: relative;
      width: 200px;
      height: 100%;
      display: block;
    }
    
    color-square {
      transform: translateY(6px);
    }
    
    li span {
      transform: translateY(2px);
      display: inline-block;
    }
    
    handle {
      position: absolute;
      top: 0;
      right: 22px;
      cursor: pointer;
    }
    
    :host([open]) handle:after {
      color: var(--primary-color);
    }
    
    handle:after {
      content: "S";
      font-size: 36px;
      color: #FFF;
    }
    
    ul {
      display: none;
      position: absolute;
      right: 10px;
      top: 30px;
      bottom: 0;
      width: 200px;
      list-style: none;
      border: 2px solid var(--neutral-4);   
      padding: 8px 16px;
      overflow-y: auto;
      border-radius: 8px;
    }
    
    :host([open]) ul {
      display: block;
      background: #FFF;
      border: 1px solid var(--neutral-4);
    }
    
    i.loading:after {
      content: "";
      font-family: "Font Awesome 5 Pro";
      animation: 2s steps(8) 0s infinite normal none running spin;
      display: inline-block;
      font-weight: 900;
      font-style: normal;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `;

  static properties = {
    type: { type: String },
    open: { type: Boolean, reflect: true },
    enabledStructures: { type: Array },
    structures: { type: Array },
    toolGroupId: { type: String },
    loadedStructures: { type: Array }
  }

  async updated(changed) {
    const { structures } = this;

    if (changed.has('structures') && !this.enabledStructures) {
      this.enabledStructures = structures
        .filter(({ type }) => defaultEnabledStructures.includes(type))
        .map(({ number }) => number);
    }

    // Turn off structures if they aren't marked enabled as they load.
    if (changed.has('loadedStructures') && this.enabledStructures) {
      const newStructures = await Promise.all(this.structures.map(structure =>
        this.toggleStructure(structure, defaultEnabledStructures.includes(structure.type))
      ));

      this.dispatchChange(newStructures);
    }
  }

  render() {
    const { structures, enabledStructures, loadedStructures } = this;

    if (!structures || !enabledStructures) return;

    return html`
      <div>
        <handle @click=${this.handleOpenToggle}></handle>
        <ul>
          <li class="select-all">
            <check-box
              @change=${this.handleSelectAllChange}
              .checked=${this.enabledStructures.length === this.structures.length}
            ></check-box>
            <span>-- Select All --</span>
          </li>
          ${structures.map(structure => html`
            <li title=${structure.type}>
              <check-box
                @change=${this.handleStructureChange}
                .checked=${enabledStructures.includes(structure.number)}
                .value=${structure}
              ></check-box>
              <color-square color=${normalizeToRGBAString(structure.color)}></color-square>
              ${h(!loadedStructures?.includes(structure.number), () => html`<i class="loading" />`)}
              <span>${structure.label}</span>
            </li>
          `)}
        </ul>
      </div>
    `;
  }

  async handleSelectAllChange({ currentTarget: { checked } }) {
    if (checked) {
      this.enabledStructures = [];
    } else {
      this.enabledStructures = this.structures.map(({ number }) => number);
    }

    const newStructures = await Promise.all(this.structures.map(structure =>
      this.toggleStructure(structure, !checked)
    ));

    this.dispatchChange(newStructures);
  }

  handleOpenToggle(event) {
    event.stopPropagation();
    event.stopImmediatePropagation();

    const { open } = this;

    this.open = !open;

    if (this.open) {
      document.addEventListener('click', this.handleBlur);
      document.addEventListener('blur', this.handleBlur);
    }
  }

  handleBlur = event => {
    event.stopPropagation();
    event.stopImmediatePropagation();

    if (event.composedPath().includes(this.shadowRoot.querySelector('ul'))) return;

    this.open = false;

    document.removeEventListener('click', this.handleBlur);
    document.removeEventListener('blur', this.handleBlur);
  };

  async handleStructureChange({ currentTarget: { checked, value: structure } }) {
    const { enabledStructures } = this;
    
    if (checked) {
      this.enabledStructures = removeValue(this.enabledStructures, structure.number);
    } else {
      this.enabledStructures = [...enabledStructures, structure.number];
    }

    const newStructure = await this.toggleStructure(structure, !checked);
    const newStructures = replaceValue(this.structures, structure, newStructure);
    this.dispatchChange(newStructures);
  }

  async toggleStructure(structure, visible) {
    const { type, toolGroupId } = this;

    if (type === ImageTypes.ThreeD) {
      await toggleSegmentationVisibility(structure.segmentationsRepresentationUID, toolGroupId, visible);
      return structure;
    } else {
      return {
        ...structure,
        visible
      };
    }
  }

  dispatchChange(newStructures) {
    const { type } = this;

    if (type === ImageTypes.ThreeD) return; // don't dispatch changes on 3D

    this.dispatchEvent(new CustomEvent('change', { detail: newStructures, bubbles: true, composed: true }));
  }
}

customElements.define('structure-toggle', StructureToggle);