import { html, css, LitElement } from 'lit';
import h from '../../../utils/h';
import { connect } from '../../../utils/redux';
import http from '../../../utils/redux/http';
import { store } from '../../../redux/store';
import { getQueryParam } from '../../../utils/query';

class PasswordForgotView extends connect(store)(LitElement) {
  static styles = css`
    * {
      box-sizing: border-box;
      font-family: 'Lato', 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    }

    :host {
      padding: 7% 24px 0;
      background: radial-gradient(92.65% 93.51% at 48.89% 8.18%, rgba(38, 111, 160, 0) 0, #266fa0 100%), #3498db;
    }

    .logo {
      text-align: center;
      margin: 8px auto 24px;
    }

    img {
      width: 100%;
      min-width: 180px;
      max-width: 320px;
      margin: 0 auto 32px;
    }

    .error, .message, div, form {
      display: block;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      border-radius: 5px;
      font-size: 16px;
    }

    .error, .message {
      border: 2px solid #a94442;
      margin-bottom: 16px;
      color: #a94442;
      text-align: center;
      transition: ease-in-out .15s;
    }

    .message {
      border-color: #555555;
      color: #6b8393;
    }

    form,
    .error,
    .message {
      background: white;
      padding: 16px 32px;
      box-shadow: 0 8px 16px rgba(49, 60, 67, .24);
    }

    form h1 {
      color: #42525C;
      text-align: center;
    }

    label {
      display: block;
      width: 100%;
      font-size: 16px;
      color: #6b8393;
      font-weight: 400;
      display: inline-block;
      margin: 8px 0;
    }

    input {
      display: block;
      height: 48px;
      padding: 10px 16px;
      margin-top: 4px;
      line-height: 1.3333333;
      width: 100%;
      color: #555;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #ccc;
      transition: ease-in-out .15s;
    }

    button {
      text-transform: uppercase;
      background-color: #3498db;
      border: none;
      border-radius: 2px;
      font-weight: 600;
      padding: 16px 20px;
      transition: .25s;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      text-rendering: geometricPrecision;
      -webkit-font-smoothing: antialiased;
      display: block;
      width: 100%;
      margin: 24px 0;
    }

    a {
      color: #3498DB;
      text-decoration: none;
    }
  `;

  static properties = {
    email: { type: String },
    error: { type: Boolean },
    sent: { type: Boolean }
  };

  constructor() {
    super();

    this.token = getQueryParam('token');
  }

  render() {
    const { handleSubmit, handleEmailChange, error, sent } = this;

    const imageHtml = html`
      <div class="logo">
        <img src="./images/chartcheck-logo.png">
      </div>
    `;

    if (sent) {
      return html`
        ${imageHtml}
        <div class="message">
          <p>
            If your email was found in the system, you should receive a reset password email shortly. Please
            follow the link in the email to finish resetting your password.
          </p>
          <p>
            If you do not receive an email, please contact your administrator or support.
          </p>
        </div>
      `;
    }

    return html` 
      ${imageHtml}
      ${h(error, () => html`
        <div class="error">
          <p>
            There was an error requesting a password reset. Please wait a minute and try again.
          </p>
          <p>
            If you continue to have problems, please contact your administrator or support.
          </p>
        </div>
      `)}
      <form
        @submit=${handleSubmit}
      >     
        <div>
          <h1>Forgot Password</h1>
        </div>
        <label>
          Username / Email
          <input 
            type="text" 
            name="email" 
            @change=${handleEmailChange}
          >
        </label>
        <button type="submit">Change Password</button>
      </form>
    `;
  }

  handleEmailChange = ({ currentTarget: { value } }) => {
    this.email = value;
  }

  handleSubmit = event => {
    const { email } = this;

    event.preventDefault();

    this.sendName = http.post('./auth/api/user/password/reset', { body: { email } }, false, () => {});
  };

  stateChanged(state) {
    const { sendName } = this;

    const sendRequest = http.getRequest(sendName, state);

    if (sendRequest?.completed) {
      if (sendRequest?.response.ok) {
        this.sent = true;
      } else {
        this.error = true;
      }
    }
  }
}

customElements.define('password-forgot-view', PasswordForgotView);