// H?H:MM [AM|PM]; e.g., 12:01 AM, 3:24 PM
import toDateObject from '../toDateObject';
import { padBefore } from '../../string';

const toTime = date => {
  const dateObj = toDateObject(date);
  const hours = dateObj.getHours() % 12;
  const period = dateObj.getHours() < 12 ? 'AM' : 'PM';
  const minutes = padBefore(dateObj.getMinutes(), 2);

  return `${hours || 12}:${minutes} ${period}`;
};

export default toTime;