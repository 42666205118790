﻿import { html, css, LitElement } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import fontAwesome from '../../../../utils/font-awesome';
import { removeValue, mapByKey } from '../../../../utils/immutable/array';
import { connect } from '../../../../utils/redux';
import http from '../../../../utils/redux/http';
import { pad } from '../../../../utils/string';
import adminStyles from '../styles-admin';
import { buildRelationshipTree } from '../utils';
import { store } from '../../../../redux/store';
import '../../../common/check-box';
import '../../../common/rad-confirm';
import '../auth-header';

const OVERLAY_CLEAR_DELAY = 3000; // ms before overlay starts to fade
const OVERLAY_CLEAR_FADE = 1000; // ms it takes to fade once it starts

const LEVEL_PADDING = 20; // # of px per level to indent
const INITIAL_PADDING = 15; // px padding to left of all tds; needs enough to clear arrow

const COLLAPSE_DIRECT = css`direct`;
const COLLAPSE_CHILD = css`child`;

const getOrganizationIdFromElement = cellElement =>
  cellElement.closest('tr').organizationId;

let lastNewOrganizationId = 0;

class OrganizationsView extends connect(store)(LitElement) {
  static styles = [
    adminStyles,
    css`    
      :host {
        display: flex;
        flex-direction: column;
      }
      
      rad-confirm {
        --min-width: 400px;
      }
      
      table {
        width: 100%;
      }
      
      .active,
      .delete,
      .move {
        width: 1px;
        white-space: nowrap;
      }
      
      td:first-child {
        text-align: left;
        cursor: pointer;
      }
      
      [valid] td:first-child {
        border: 1px dashed #333;
      }
      
      tbody tr:hover {
        background: var(--neutral-6) !important;
      }
      
      [hasChildren] td:first-child:before {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        content: '\uf0d7';
        display: inline-block;
        width: 10px;
        text-align: right;
        margin: 0 5px 0 -15px; /* right = (td left padding) + padding, left = -(td left padding) */
      }
      
      [hasChildren][collapsed] td:first-child:before {
        content: '\uf0da';
      }
      
      [collapsed=${COLLAPSE_CHILD}] {
        display: none;
      }
      
      [disabled] {
        color: var(--neutral-5);
      }
      
      i:hover {
        cursor: pointer;
        color: var(--primary-color);
      }
      
      rad-confirm:not([active]) {
        display: none;
      }
      
      button {
        background: var(--primary-color);
        color: var(--neutral-10);
        margin: 0 0 16px auto;
        display: block;
        border: 0;
        cursor: pointer;
      }
      
      button:hover {
        background: var(--primary-1);
      }
      
      label,
      label input,
      label select {
        display: block;
        width: 100%;
        text-align: left;
      }
      
      label {
        margin-bottom: 10px;
      }
      
      form[validated] input:invalid {
        border-color: #F00;
      }
    `
  ].flat();

  static properties = {
    showSaved: { type: Boolean },
    rootOrganizationId: { type: String }, // root organization for the page
    organizations: { type: Object },
    collapsed: { type: Array },
    deleteTarget: { type: String },
    moveTarget: { type: String },
    overlayMessage: { type: String },
    showOverlay: { type: Boolean },
    addingOrganization: { type: Boolean }
  };

  constructor() {
    super();

    this.collapsed = [];

    this.loadData();
  }

  render() {
    const { organizations, rootOrganizationId, showSaved,
      renderOrganization, renderAddOrganization, handleSave, handleSavedComplete } = this;

    if (!organizations) return;

    return html`
      ${fontAwesome}
      <auth-header
        savedMessage="Organizations saved successfully!"
        ?showSaved=${showSaved}
        @save=${handleSave}
        @saved-complete=${handleSavedComplete}
      ></auth-header>
      <main class="content">
        <table>
          <colgroup>
            <col />
            <col class="active" />
          </colgroup>
          <thead>
            <th scope="col">Organization</th>
            <th scope="col">Active</th>
          </thead>
          <tbody>
            ${renderOrganization(rootOrganizationId)}
          </tbody>
        </table>
      </main>
      ${renderAddOrganization()}
    `;
  }

  renderOrganization = (id, level = 0) => {
    const { organizations, rootOrganizationId, isCollapsed, isValidMoveTarget, renderOrganization,
      handleRowClick, handleActiveChange, getAncestors } = this;

    if (!organizations[id]) {
      throw new Error(`Unknown organization: ${id}`);
    }

    const { name, active, children = [] } = organizations[id];
    const ancestorsDisabled = !getAncestors(id).every(orgId => organizations[orgId].active);
    const isRoot = id === rootOrganizationId;

    return html`
      <tr
        collapsed=${ifDefined(isCollapsed(id))}
        .organizationId=${id}
        ?hasChildren=${children.length}
        ?disabled=${!active || ancestorsDisabled}
        ?valid=${isValidMoveTarget(id)}
      >
        <td
          @click=${handleRowClick}
          style=${styleMap({
      paddingLeft: `${INITIAL_PADDING + (LEVEL_PADDING * level)}px`
    })}
        >${name}</td>
        <td>
          ${!isRoot ? html`
            <check-box 
              readonly
              ?checked=${active}
              @change=${handleActiveChange} 
              ?disabled=${ancestorsDisabled}
            ></check-box>
          ` : ''}
        </td>
      </tr>
      ${children.map(childOrganizationId =>
      renderOrganization(childOrganizationId, level + 1, id))
    }
    `;
  };

  renderAddOrganization = () => {
    const { addingOrganization, organizations, rootOrganizationId, buildUniquePattern,
      handleAddOrganizationCancel, handleAddOrganizationConfirm, handleClearError } = this;

    const relationshipList = buildRelationshipTree(rootOrganizationId, organizations);
    const uniquePattern = buildUniquePattern();

    return html`
      <rad-confirm 
        ?active=${addingOrganization}
        title="Add Organization"
        confirmText="Add Organization"
        cancelText="Cancel"
        @confirm=${handleAddOrganizationConfirm}
        @cancel=${handleAddOrganizationCancel}
      >
        <form>
          <label>
            Name:
            <input 
              type="text" 
              name="organizationName" 
              required
              pattern=${uniquePattern}
              @keydown=${handleClearError}
            />
          </label>
          <label>
            Parent: 
            <select name="parentId">
              ${relationshipList.map(({ organizationId, level }) => html`
                <option value=${organizationId}>
                  ${pad('', level, '-')} ${organizations[organizationId].name}
                </option>
              `)}
            </select> 
          </label>
        </form>
      </rad-confirm>
    `;
  };

  buildUniquePattern = () => {
    const { organizations } = this;

    return `^(?!${Object.values(organizations).map(({ name }) => name).join('|')}).*$`;
  };

  isValidMoveTarget = organizationId => {
    const { moveTarget, getAncestors } = this;

    if (!moveTarget) {
      return false;
    }

    return organizationId !== moveTarget && !getAncestors(organizationId).includes(moveTarget);
  };

  isCollapsed = organizationId => {
    const { getAncestors, collapsed } = this;

    const ancestors = getAncestors(organizationId);

    return this.collapsed.some(id => ancestors.includes(id))
      ? COLLAPSE_CHILD
      : collapsed.includes(organizationId)
        ? COLLAPSE_DIRECT
        : undefined;
  };

  handleClearError = ({ currentTarget }) => {
    currentTarget.blur();
    currentTarget.focus();
  };

  handleMove = ({ currentTarget }) => {
    this.moveTarget = getOrganizationIdFromElement(currentTarget);

    clearTimeout(this.overlayClearId); // clear previous ones
    clearTimeout(this.overlayMessageClearId);

    this.overlayMessage = undefined;
    this.showOverlay = false;
  };

  handleMoveCancel = () => {
    this.moveTarget = undefined;
  };

  handleSavedComplete = () => {
    this.showSaved = false;
  };

  handleRowClick = ({ currentTarget }) => {
    const { moveTarget, toggleCollapse, moveOrganization } = this;
    const organizationId = getOrganizationIdFromElement(currentTarget);

    if (moveTarget) {
      moveOrganization(moveTarget, organizationId);
      this.moveTarget = undefined;
    } else {
      toggleCollapse(organizationId);
    }
  };

  handleActiveChange = ({ currentTarget, detail: { checked } }) => {
    const { organizations } = this;
    const organizationId = getOrganizationIdFromElement(currentTarget);
    const organization = organizations[organizationId];

    this.organizations = {
      ...organizations,
      [organizationId]: {
        ...organization,
        active: checked
      }
    };
  };

  handleDelete = ({ currentTarget }) => {
    this.deleteTarget = getOrganizationIdFromElement(currentTarget);
  };

  handleDeleteConfirm = () => {
    const { deleteTarget } = this;

    this.deleteOrganization(deleteTarget);
    this.deleteTarget = undefined;
  };

  handleDeleteCancel = () => {
    this.deleteTarget = undefined;
  };

  handleAddOrganization = () => {
    this.addingOrganization = true;
  };

  handleAddOrganizationConfirm = ({ currentTarget }) => {
    const { organizations, createOrganizationId } = this;
    const form = currentTarget.querySelector('form');
    const organizationNameInput = form.querySelector('[name=organizationName]');

    if (organizationNameInput.validity.valueMissing) {
      organizationNameInput.setCustomValidity('Please enter an organization name.');
    } else if (organizationNameInput.validity.patternMismatch) {
      organizationNameInput.setCustomValidity('Organization name must be unique.');
    } else {
      organizationNameInput.setCustomValidity('');
    }

    if (!form.reportValidity()) {
      return;
    }

    const formData = new FormData(form);

    const name = formData.get('organizationName');
    const id = createOrganizationId();
    const parentId = formData.get('parentId');

    form.reset();
    this.addingOrganization = false;

    this.organizations = {
      ...organizations,
      [parentId]: {
        ...organizations[parentId],
        children: [...organizations[parentId].children, id]
      },
      [id]: { id, name, active: true }
    };
  };

  handleAddOrganizationCancel = () => {
    this.addingOrganization = false;
  };

  deleteOrganization = organizationId => {
    const { organizations } = this;

    const newOrganizations = {
      ...organizations
    };

    delete newOrganizations[organizationId];

    this.organizations = newOrganizations;

    const [parentId] = this.getAncestors(organizationId);

    if (parentId && organizations[parentId]) {
      const parent = organizations[parentId];

      this.organizations = {
        ...organizations,
        [parentId]: {
          ...parent,
          children: removeValue(parent.children, organizationId)
        }
      };
    }
  };

  loadData = () => {
    this.requestName = http.get('./auth/api/organization/{organization}');
  };

  updateOrganization = data => {
    const { id, organizations } = data;

    Object.assign(this, {
      organizations: mapByKey(organizations),
      rootOrganizationId: id
    });
  };

  toggleCollapse = organizationId => {
    const { collapsed } = this;
    const index = collapsed.indexOf(organizationId);

    this.collapsed = index === -1
      ? [...collapsed, organizationId]
      : removeValue(collapsed, organizationId);
  };

  // ordered closest to furthest
  getAncestors = (organizationId, currentId, ancestorsOfCurrent = []) => {
    const { getAncestors, rootOrganizationId, organizations } = this;
    currentId = currentId || rootOrganizationId;

    if (currentId === organizationId) {
      return ancestorsOfCurrent;
    }

    const children = organizations[currentId].children || [];

    if (children.find(id => id === organizationId)) { // direct parent
      return [currentId, ...ancestorsOfCurrent];
    }

    return children
      .flatMap(child => getAncestors(organizationId, child, [currentId, ...ancestorsOfCurrent]))
      .filter(Boolean);
  };

  moveOrganization = (targetId, newParentId) => {
    const { getAncestors, organizations } = this;

    if (targetId === newParentId || getAncestors(newParentId).includes(targetId)) {
      this.overlayMessage = 'Cannot move organization to itself or its descendant.';
      this.showOverlay = true;

      clearTimeout(this.overlayClearId); // clear previous ones
      clearTimeout(this.overlayMessageClearId);

      this.overlayClearId = setTimeout(() => {
        this.showOverlay = undefined;

        this.overlayMessageClearId = setTimeout(() => {
          this.overlayMessage = '';
        }, OVERLAY_CLEAR_FADE);
      }, OVERLAY_CLEAR_DELAY);
      return;
    }

    const [parentId] = getAncestors(targetId);

    if (parentId === newParentId) {
      return;
    }

    const parent = organizations[parentId];
    const newParent = organizations[newParentId];

    this.organizations = {
      ...organizations,
      [parentId]: {
        ...parent,
        children: removeValue(parent.children, targetId)
      },
      [newParentId]: {
        ...newParent,
        children: [...(newParent.children || []), targetId]
      }
    };
  };

  createOrganizationId = () =>
    --lastNewOrganizationId;

  stateChanged = state => {
    const { requestName, updateOrganization } = this;

    const response = http.getRequest(requestName, state);

    if (response?.ok) {
      updateOrganization(response.data);
      http.flush(response);
    }
  };
}

customElements.define('organizations-view', OrganizationsView);