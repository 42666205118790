import { css } from 'lit';

const chartStyles = css`      
  [chart] td, [chart] th {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  [chart] td {
    background: #FFF !important;
  }
  
  [chart]:nth-child(2n) td {
    background: #fafbfc !important;
  }
  
  [chart] ul {
    list-style: none;
    margin: 0;
    padding: 0 0 1px;
  }
  
  [chart] [headers] {
    position: relative;
    min-height: 230px;
  }
  
  [chart] [headers] ul {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  
  [chart] [headers] li {
    display: table;
  }
  
  [chart] [headers] span {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
  }
  
  [chart] [axis] {
    position: relative;
  }
  
  [chart] [axis] span {
    line-height: 1;
    position: absolute;
    transform: translate(-50%, -50%) rotate(-90deg) translateY(50%);
    left: 20%;
    top: 50%;
    white-space: nowrap;
  }
  
  [chart] [axis] li {
    text-align: right;
    line-height: 38px;
  }
  
  [chart] [step] {
    position: relative;
  }
  
  [chart] [step] div {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: -1px 0;
    border: solid #666;
    border-width: 1px 0;
    image-rendering: pixelated;
  }
  
  [chart] [point] {
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 17px;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  
  [chart] [point] [value] {
    text-indent: -10000px;
  }
  
  [chart] [line] {
    position: absolute;
    height: 1px;
    padding: 0;
    margin: 0;
    left: 17px;
    z-index: 1;
  }
`;

export default chartStyles;