export default class Histogram {
  #values = {};
  #max = 0;

  get max() {
    return this.#max;
  }

  getValue(i) {
    return this.#values[i] ?? 0;
  }

  getWeightedValue(i) {
    const v = this.getValue(i);

    return v < 2 ? v : Math.sqrt(v);
  }

  incrementValue(i) {
    this.#values[i] = (this.#values[i] ?? 0) + 1;
    this.#max = Math.max(this.#max, i);
  }
}