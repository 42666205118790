﻿import { html, css, LitElement } from 'lit';
import h from '../../../utils/h';
import { connect } from '../../../utils/redux';
import http from '../../../utils/redux/http';
import { store } from '../../../redux/store';
import { generate } from '../../../router';
import { Routes } from '../../../routes';
import { configSelector } from '../../../redux/app/selectors';
import '../../common/rad-link';
import { getQueryParam, hasQueryParam } from '../../../utils/query';
import { updateMe } from '../../../redux/app/actions';

class LoginView extends connect(store)(LitElement) {
  static styles = css`
    * {
      box-sizing: border-box;
      font-family: 'Lato', 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    }
    
    :host {
      padding: 7% 24px 0;
      background: radial-gradient(92.65% 93.51% at 48.89% 8.18%,rgba(38,111,160,0) 0,#266fa0 100%),#3498db;
    }

    .logo {
      text-align: center;
      margin: 8px auto 24px;
    }

    img {
      width: 100%;
      min-width: 180px;
      max-width: 320px;
      margin: 0 auto 32px;
    }
    
    .error, div, form {
      display: block;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      border-radius: 5px;
      font-size: 16px;
    }
    
    .error {
      border: 2px solid #a94442;
      margin-bottom: 16px;
      color: #a94442;
      text-align: center;
      transition: ease-in-out .15s;
      opacity: 1;
    }
    
    .error[flash] {
      opacity: 0;
    }
    
    form,
    .error {
      background: white;
      padding: 16px 32px;
      box-shadow: 0 8px 16px rgba(49,60,67,.24);
    }

    form h1 {
      color: #42525C;
      text-align: center;
    }
    
    label {
      display: block;
      width: 100%;
      font-size: 16px;
      color: #6b8393;
      font-weight: 400;
      display: inline-block;
      margin: 8px 0;
    }

    input {
      display: block;
      height: 48px;
      padding: 10px 16px;
      margin-top: 4px;
      line-height: 1.3333333;
      width: 100%;
      color: #555;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #ccc;
      transition: ease-in-out .15s;
    }

    button {
      text-transform: uppercase;
      background-color: #3498db;
      border: none;
      border-radius: 2px;
      font-weight: 600;
      padding: 16px 20px;
      transition: .25s;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      text-rendering: geometricPrecision;
      -webkit-font-smoothing: antialiased;
      display: block;
      width: 100%;
      margin: 24px 0;
    }
    
    p {
      text-align: center;
    }
    
    rad-link {
      color: #FFF;
      text-decoration: none;
    }
  `;

  static properties = {
    error: { type: String },
    loggedOut: { type: Boolean },
    flashError: { type: Boolean },
    domains: { type: Array }
  };

  constructor() {
    super();

    this.loggedOut = hasQueryParam('logged_out');
    this.domains = [];
    this.loadDomains();
  }

  updated(changed) {
    if (changed.has('flashError')) {
      if (!changed.has('error')) {
        setTimeout(() => {
          this.flashError = false;
        }, 100);
      } else {
        this.flashError = false;
      }
    }
  }

  render() {
    const { handleSubmit, error, loggedOut, flashError, domains } = this;

    return html`
      <div class="logo">
        <img src="./images/chartcheck-logo.png">
      </div>
      ${h(error, html`
        <div class="error" ?flash=${flashError}>
          ${error}
        </div>
      `)}
      ${h(loggedOut, html`
        <div>
          You have been logged out.
        </div>
      `)}
      <form
        @submit=${handleSubmit}
      >     
        <div>
          <h1>Sign In</h1>
        </div>
        <label>
          Domain
          <input list="domains-list" type="text" name="domain" autocomplete='off'
           .value=${domains?.length ? domains[0] : ''}> 
          <datalist id="domains-list">
              ${Object.entries(domains)                  
                  .map(([key, value]) => html`
                  <option>${value}</option>
                  `)
              }
            </datalist> 
        </label>
        <label>
          Username
          <input type="text" name="username">
        </label>
        <label>
          Password
          <input type="password" name="password">
        </label>
        <button type="submit">Sign In</button>
      </form>
      <p>
        <rad-link href=${generate(Routes.ForgotPassword)}>Can't remember your password?</rad-link>
      </p>
    `;
  }

  loadDomains() {
    this.domainsRequestName = http.get('./weekly-check/api/{organization}/domains');
  }

  handleSubmit = event => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const body = {};

    for (const [key, value] of formData.entries()) {
      body[key] = value;
    }

    this.loginRequestName = http.post('./auth/api/login', { body }, false, () => {});
  };

  finishLogin = response => {
    if (!response?.ok) {
      this.error = response.status === 403
        ? 'Account locked from too many failed attempts. Please reset your password to unlock.'
        : 'Login failed. Please try again.';
      
      this.loggedOut = false;
      this.flashError = true;
    } else {
      // @TODO Make more robust / clean up
      const redirect = getQueryParam('ReturnUrl');

      // Kick-off update to user data
      this.meRequestName = http.get('/auth/api/me', { force: true });

      window.location.href = (redirect && decodeURIComponent(redirect))
        || this.config?.defaultLoginRedirect
        || generate(Routes.WeeklyCheckLanding);
    }
  };

  stateChanged(state) {
    const { loginRequestName, meRequestName, finishLogin, domainsRequestName } = this;

    const loginResponse = http.getRequest(loginRequestName, state);
    const meResponse = http.getRequest(meRequestName, state);
    this.config = configSelector(state);

    const domainsResponse = http.getRequest(domainsRequestName, state);

    if (loginResponse?.completed) {
      finishLogin(loginResponse);
      http.flush(loginResponse);
    }

    if (meResponse?.ok) {
      store.dispatch(updateMe(meResponse.data));
      http.flush(meResponse);
    }
    
    if (domainsResponse?.ok) {
      this.domains = domainsResponse.data;      
      http.flush(domainsResponse);
    }
  }
}

customElements.define('login-view', LoginView);