import macro from '../../macros.js';
import vtkWebGPUShaderCache from './ShaderCache.js';
import vtkWebGPUSimpleMapper from './SimpleMapper.js';

// vtkWebGPUFullScreenQuad methods
// ----------------------------------------------------------------------------

function vtkWebGPUFullScreenQuad(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkWebGPUFullScreenQuad');

  publicAPI.replaceShaderPosition = function (hash, pipeline, vertexInput) {
    var vDesc = pipeline.getShaderDescription('vertex');
    vDesc.addBuiltinOutput('vec4<f32>', '@builtin(position) Position');
    var code = vDesc.getCode();
    code = vtkWebGPUShaderCache.substitute(code, '//VTK::Position::Impl', ['output.tcoordVS = vec2<f32>(vertexBC.x * 0.5 + 0.5, 1.0 - vertexBC.y * 0.5 - 0.5);', 'output.Position = vec4<f32>(vertexBC, 1.0);']).result;
    vDesc.setCode(code);
  };

  model.shaderReplacements.set('replaceShaderPosition', publicAPI.replaceShaderPosition);

  publicAPI.updateBuffers = function () {
    var buff = model.device.getBufferManager().getFullScreenQuadBuffer();
    model.vertexInput.addBuffer(buff, ['vertexBC']);
    model.numberOfVertices = 6;
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Inheritance

  vtkWebGPUSimpleMapper.extend(publicAPI, model, initialValues); // Object methods

  vtkWebGPUFullScreenQuad(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkWebGPUFullScreenQuad'); // ----------------------------------------------------------------------------

var vtkWebGPUFullScreenQuad$1 = {
  newInstance: newInstance,
  extend: extend
};

export { vtkWebGPUFullScreenQuad$1 as default, extend, newInstance };
