import { html } from 'lit';

const fontAwesome = html`
  <link
    href="images/fontawesome/css/all.css"
    rel="stylesheet"
    crossorigin="anonymous"
  />
`;

export default fontAwesome;