const downloadCsv = (data, filename = 'download.csv') => {
  const downloadData = data;
  
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/csv;charset=utf-8,'
      + encodeURIComponent(downloadData));
  element.setAttribute('download', filename);
  
  element.style.display = 'none';
  document.body.appendChild(element);
  
  element.click();
  
  document.body.removeChild(element);
};
  
export default downloadCsv;
  