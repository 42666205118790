/**
 * @param {string} name
 * @param {string?} value
 * @param {Date?} expires
 * @param {string?} domain
 */
const setCookie = (name, value, expires = undefined, domain = document.location.hostname) => {
  document.cookie = [
    `${name}=${value || ''}`,
    domain && `domain=${domain}`,
    expires && `expires=${expires.toUTCString()}`
  ].filter(Boolean)
    .join(';');
};

export default setCookie;