/**
 * Gets the (first) element from the parent using the given selector.
 * If the element doesn't exist, it will create one of elementType (or selector if omitted),
 *   append it to the parent, and return the element.
 *
 * @param {Element} parent
 * @param {string} selector
 * @param {string} [elementType] If the selector is the same as the elementType,
 *   you can omit this and selector will be used instead.
 * @return {Element}
 */
const getOrCreateElement = (parent, selector, elementType = undefined) => {
  const element = parent.querySelector(selector);

  if (element) return element;

  const newElement = document.createElement(elementType || selector);
  parent.appendChild(newElement);

  return newElement;
};

export default getOrCreateElement;