import { html, css, LitElement } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import clamp from '../../utils/math/clamp.js';
import eatEvent from '../../utils/event/eatEvent.js';
import h from '../../utils/h';

/* value in the slider is between 0 and 1 */
class NumberSlider extends LitElement {
  static styles = css`
    div {
      position: relative;
      width: 100%;
      height: 20px;
    }
    
    handle, bar {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    
    bar {
      background: var(--neutral-6);
      left: 0;
      right: 0;
      height: 8px;
    }
    
    handle {
      cursor: pointer;
      height: 12px;
      width: 8px;
      background: var(--primary-color);
      transform: translate(-50%, -50%);
    }
    
    span {
      position: absolute;
      color: #FFF;
      margin: -2px 10px 0;
    }
    
    span[left] {
      right: 100%;
    }
    
    span[right] {
      left: 100%;
    }
  `;

  static properties = {
    value: { type: Number },
    leftLabel: { type: String, attribute: 'left-label' },
    rightLabel: { type: String, attribute: 'right-label' }
  }

  render() {
    const { value, leftLabel, rightLabel } = this;

    return html`
      <div @click=${eatEvent}>
        ${h(leftLabel, () => html`<span left>${leftLabel}</span>`)}
        ${h(rightLabel, () => html`<span right>${rightLabel}</span>`)}
        <bar></bar>
        <handle 
          @mousedown=${this.handleMouseDown}
          style=${styleMap({ left: `${value * 100}%` })}
        ></handle>
      </div>
    `;
  }

  handleMouseDown(event) {
    document.addEventListener('mousemove', this.handleMove);
    document.addEventListener('mouseup', this.handleStop);
    document.addEventListener('blur', this.handleStop);
  }

  handleStop = event => {
    document.removeEventListener('mousemove', this.handleMove);
    document.removeEventListener('mouseup', this.handleStop);
    document.removeEventListener('blur', this.handleStop);
  };

  handleMove = event => {
    event.stopPropagation();

    const handle = this.shadowRoot.querySelector('handle');

    const { movementX } = event;
    const { offsetLeft } = handle;
    const width = handle.parentNode.offsetWidth;

    this.value = clamp((offsetLeft + movementX) / width, 0, 1);

    this.dispatchChange();
  };

  dispatchChange = () => {
    const { value } = this;

    this.dispatchEvent(new CustomEvent('change', { detail: { value } }));
  }
}

customElements.define('number-slider', NumberSlider);