﻿import { css } from 'lit';
import commonStyles from '../../styles-common';

const OVERLAY_CLEAR_FADE = 1000; // ms it takes to fade once it starts

const adminStyles =
  [
    commonStyles,
    css`  
      :host {
        --primary-1: var(--orange-1);
        --primary-2: var(--orange-2);
        --primary-3: var(--orange-3);
        --primary-4: var(--orange-4);
        --primary-5: var(--orange-5);
        --primary-6: var(--orange-6);
        --primary-7: var(--orange-7);
        font-family: "Segoe UI", Helvetica, sans-serif;
      }
      
      :host {
        display: flex !important;
        flex-direction: column;      
      }
          
      check-box {
        --color: var(--primary-color);
        --hover-color: var(--primary-2);
      }
      
      .sidebar,
      .content {
        flex: 1;
        padding: 16px;
      }
      
      .sidebar {
        min-width: 200px;
        max-width: 250px;
        overflow: auto;
        display: flex;
        flex-direction: column;
      }
      
      main:not(.content) {
        display: flex;
        flex: 1 1 auto;
        height: 0;
        flex-flow: row nowrap;
      }
      
      table {
        border-collapse: collapse;
      }
      
      thead {
        font-size: 11px;
        font-weight: 700;
      }
      
      thead tr {
        color: var(--neutral-4);
        background: var(--neutral-8);
      }
      
      tbody {
        font-size: 13px;
      }
      
      tbody tr:nth-child(2n) {
        background: var(--neutral-9);
      }
      
      tbody th {
        font-weight: normal;
      }
      
      th, td {
        padding: 8px 12px;
      }
      
      
      th {
        text-align: left;
      }
      
      td {
        text-align: center;
      }
      
      #overlay {
        position: fixed;
        top: 100px;
        background: var(--neutral-3);
        color: var(--neutral-6);
        display: inline-block;
        left: 50%;
        transform: translateX(-50%);
        line-height: 1.5;
        padding: 8px 16px;
        opacity: 1;
      }
      
      #overlay[fade] {
        transition: opacity ${OVERLAY_CLEAR_FADE}ms;
      }
      
      #overlay:not([active]) {
        opacity: 0;
        pointer-events: none;
      }
      
      #overlay span {
        margin-left: 10px;
        cursor: pointer;
        padding: 4px 8px;
        background: var(--neutral-9);
        color: var(--neutral-3);
      }
      
      #overlay span:hover {
        background: var(--neutral-4);
        color: var(--neutral-6);
      }
    `
  ].flat();

export default adminStyles;