// Warning: Very inefficient, use with caution.
const querySelectorAllShadow = (element, selector) => [
  ...element.querySelectorAll(selector),
  ...querySelectorAllDescendants(element, selector),
  ...(element.shadowRoot ? [
    ...element.shadowRoot.querySelectorAll(selector),
    ...querySelectorAllDescendants(element.shadowRoot, selector)
  ] : [])
];

const querySelectorAllDescendants = (element, selector) => Array.from(element.querySelectorAll('*'))
  .filter(el => el.shadowRoot)
  .flatMap((el, i, arr) => querySelectorAllShadow(el, selector));

export default querySelectorAllShadow;