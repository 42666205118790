import { FilterModes } from '../common';
import { sortBy } from '../../../../utils/array';

const filterPatients = (patients, filters) => {
  const { moreThanFive, hospitalId, machineId, mode } = filters;

  return patients
    .filter(patient =>
      (
        mode === FilterModes.PATIENT
          || (!moreThanFive
          || patient.status !== 'weekly'
          || patient.fractionsToReview >= 5)
      ) && (!hospitalId || hospitalId === patient.hospitalId)
        && (!machineId || patient.machineIds.includes(machineId))
    ).sort(sortBy('reviewAccepted', 'lastName', 'firstName'));
};

export default filterPatients;