import getPopoutOpened from './getPopoutOpened.js';
import { POPOUT_WAIT_DELAY } from './constants.js';
import setPopoutUrl from './setPopoutUrl.js';

const openPopoutIfNeeded = async (name, url = undefined, delay = POPOUT_WAIT_DELAY) => {
  const date = await getPopoutOpened(name);

  if (url) {
    await setPopoutUrl(name, url);
  }

  if (!date || (Date.now() - date.getTime() > delay)) {
    openPopout(url);
  }
};

const openPopout = (url = undefined) => {
  return window.open(
    url ?? 'about:blank',
    '',
    'top=100,left=200,width=1030,height=500,noopener=true,noreferer=true'
  );
};

export default openPopoutIfNeeded;